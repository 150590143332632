import React from "react";
import PropTypes from "prop-types";

import SubcriteriumEvaluation from "../SubcriteriumEvaluation";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import InputAdornment from "@material-ui/core/InputAdornment";

export const SubcriteriumEvaluationSorted = ({
  commodityId,
  criteria,
  type,
  ...props
}) =>
  criteria
    .sort((criteriumA, criteriumB) => {
      if (!criteriumA) {
        return 1;
      } else if (!criteriumB) {
        return -1;
      }
      return criteriumA.location - criteriumB.location;
    })
    .map(criterium => (
      <SubcriteriumEvaluation
        commodityId={commodityId}
        criteriumId={criterium.id}
        type={type}
        index={criterium.id}
        key={criterium.id}
      />
    ));
SubcriteriumEvaluationSorted.propTypes = {
  commodityId: PropTypes.number,
  criteria: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
};

export const EvaluationData = ({ handleChange, record, ...props }) => (
  <React.Fragment>
    <Grid item xs={4} md={3}>
      <InputLabel>Maturity Date</InputLabel>
      <TextField
        fullWidth
        type="date"
        variant="outlined"
        value={record.maturityDate || ""}
        onChange={handleChange("maturityDate")}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        rows={4}
        variant="outlined"
        multiline={true}
        label="Comments"
        placeholder="Comments ..."
        value={record.comments || ""}
        InputProps={{
          maxLength: 350,
          endAdornment: (
            <InputAdornment position="end">{`${
              (record.comments || "").length
            }/350`}</InputAdornment>
          )
        }}
        onChange={handleChange("comments")}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        variant="outlined"
        rows={4}
        multiline={true}
        label="Grower Comments"
        placeholder="Grower Comments ..."
        value={record.growerComments || ""}
        InputProps={{
          maxLength: 200,
          endAdornment: (
            <InputAdornment position="end">{`${
              (record.growerComments || "").length
            }/200`}</InputAdornment>
          )
        }}
        onChange={handleChange("growerComments")}
      />
    </Grid>
  </React.Fragment>
);

EvaluationData.propTypes = {
  record: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

const styles = theme => ({
  likeButton: {
    marginRight: 10
  }
});

export const GrowerFeelings = withStyles(styles)(
  ({ growerLike, handleNonEventChange, ...props }) => (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2" color="textPrimary" gutterBottom>
            Grower's Overall Feeling
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={props.classes.likeButton}
            size="small"
            aria-label="Dislike"
            color={growerLike === false ? "primary" : "default"}
            onClick={handleNonEventChange("growerLike", false)}
          >
            <ThumbDownIcon />
          </Button>
          <Button
            className={props.classes.likeButton}
            size="small"
            aria-label="Like"
            color={growerLike === true ? "primary" : "default"}
            onClick={handleNonEventChange("growerLike", true)}
          >
            <ThumbUpIcon />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
);

GrowerFeelings.propTypes = {
  growerLike: PropTypes.bool,
  handleNonEventChange: PropTypes.func.isRequired
};
GrowerFeelings.defaultProps = {
  growerLike: false
};
