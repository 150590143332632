import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import {
  updateVariety,
  createVariety,
  updateVarietyLocally,
  removeVariety,
  deleteVariety
} from "../../../actions/varieties";
import { LoadingSpinner } from "../../../ui";
import { CommoditiesMultipleSelect } from "../../commodities";
import { VarietyButtons, VarietyInputs, SubVarieties } from "./views";
import Grid from "@material-ui/core/Grid";

class VarietyEdit extends Component {
  constructor(props) {
    super(props);
    let header = "Create";
    if (props.update) {
      header = "Update";
    }
    this.state = {
      header: header,
      update: props.update || false,
      created: false,
      deleted: false
    };
  }

  removeSubVariety = id => () => {
    this.props.removeVariety(id);
  };

  handleChange = name => e => {
    this.props.updateVarietyLocally(this.props.id, name, e.target.value);
  };

  handleDateChange = name => momentObj => {
    if (momentObj === null) {
      this.props.updateVarietyLocally(this.props.id, name, null);
    } else {
      const dateObj = moment(momentObj._d);
      const dateStr = dateObj.format("YYYY-MM-DD");
      this.props.updateVarietyLocally(this.props.id, name, dateStr);
    }
  };

  onSave = () => {
    const { variety } = this.props.varieties;
    if (this.state.update) {
      this.props.updateVariety(this.props.id, variety);
    } else {
      this.setState({ ...this.state, created: true }, () => {
        this.props.createVariety(variety);
      });
    }
  };

  onDelete = () => {
    this.setState({ ...this.state, deleted: true }, () => {
      this.props.deleteVariety(this.props.id);
    });
  };

  setCommodities = commodities => {
    const commodityIds = commodities.map(commodity => commodity.id);
    this.props.updateVarietyLocally(
      this.props.id,
      "commodityIds",
      commodityIds
    );
    this.props.updateVarietyLocally(this.props.id, "commodities", commodities);
  };

  setSupplier = supplier => {
    const id = supplier ? supplier.id : null;
    this.props.updateVarietyLocally(this.props.id, "companyId", id);
    this.props.updateVarietyLocally(this.props.id, "supplier", supplier);
  };

  render() {
    const { variety, error, waiting, combineWaiting } = this.props.varieties;
    const title = this.state.header + " Variety";
    if (waiting || (this.state.created && !variety.id && !error)) {
      return <LoadingSpinner />;
    }
    if (this.state.deleted && !variety.id) {
      return <Redirect push to="/varieties" />;
    }
    if (this.state.created && variety.id) {
      return <Redirect push to="/varieties" />;
    }
    const supplier = variety.supplier;
    return (
      <Grid container spacing={2} style={{ margin: 20 }}>
        <VarietyInputs
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          setSupplier={this.setSupplier}
          title={title}
          variety={variety}
          supplier={supplier}
        />
        <Grid item xs={12}>
          <CommoditiesMultipleSelect
            label={"Associated Commodities"}
            setCommodities={this.setCommodities}
            initialItems={variety.commodities}
          />
        </Grid>
        <SubVarieties
          subVarieties={variety.experimentalVarieties || []}
          removeSubVariety={this.removeSubVariety}
          combineWaiting={combineWaiting}
        />
        <VarietyButtons
          update={this.props.update}
          varietyId={variety.id}
          onSave={this.onSave}
          onDelete={this.onDelete}
        />
      </Grid>
    );
  }
}

const mapStateToProps = ({ varieties, commodities, companies }) => ({
  varieties,
  commodities,
  companies
});

export default connect(mapStateToProps, {
  createVariety,
  updateVariety,
  updateVarietyLocally,
  removeVariety,
  deleteVariety
})(VarietyEdit);
