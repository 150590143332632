import React from "react";
import NumberFormat from "react-number-format";
import DeleteConfirmation from "../../DeleteConfirmation";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import Button from "@material-ui/core/Button";

function NumberFormatWeight(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isNumericString={true}
      decimalScale={2}
      allowNegative={false}
      isAllowed={values => {
        if (values.value <= 1 && values.value >= 0) {
          return true;
        }
        return false;
      }}
    />
  );
}

function NumberFormatScore(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      allowNegative={false}
      isNumericString={true}
      decimalScale={0}
      isAllowed={values => {
        if (values.value <= 10) {
          return true;
        }
        return false;
      }}
    />
  );
}

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button
    size="small"
    aria-label="Delete"
    fullWidth
    className={props.classes.deleteButton}
    onClick={confirmDelete}
  >
    <DeleteIcon />
  </Button>
);

const styles = theme => ({
  subCriteriaRow: {
    marginTop: 15,
    marginBottom: 5
  },
  subCriteriaItem: {
    paddingRight: 10,
    paddingTop: 5
  },
  changeIcon: {
    paddingTop: 20
  },
  deleteButtonGrid: {
    paddingTop: 10
  },
  deleteButton: {
    height: "100%"
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.palette.secondary.main,
    subCriteriaItem: {
      marginTop: 10,
      padding: 3,
      marginRight: 0
    },
    deleteButton: {},
    changeIcon: {
      paddingTop: 25
    }
  }
});

export const View = withStyles(styles)(
  ({
    subcriterium,
    handleChange,
    onDelete,
    computeWeightedScore,
    ...props
  }) => (
    <React.Fragment>
      <Grid item xs={1} sm={1} className={props.classes.changeIcon}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <ViewHeadlineIcon />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11} sm={4} className={props.classes.subCriteriaItem}>
        <TextField
          fullWidth
          required={true}
          label="Sub Criterium Name"
          variant="outlined"
          onChange={handleChange("name")}
          value={subcriterium.name}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={props.classes.subCriteriaItem}>
        <TextField
          fullWidth
          label="Score"
          required={true}
          variant="outlined"
          onChange={handleChange("score")}
          value={subcriterium.score}
          InputProps={{
            inputComponent: NumberFormatScore
          }}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={props.classes.subCriteriaItem}>
        <TextField
          fullWidth
          label="Weight"
          required={true}
          variant="outlined"
          onChange={handleChange("weight")}
          value={subcriterium.weight}
          InputProps={{
            inputComponent: NumberFormatWeight
          }}
        />
      </Grid>
      <Grid item xs={6} sm={2} className={props.classes.subCriteriaItem}>
        <TextField
          disabled
          fullWidth
          variant="outlined"
          value={computeWeightedScore(subcriterium.score, subcriterium.weight)}
        />
      </Grid>
      <Grid item xs={6} sm={1} className={props.classes.deleteButtonGrid}>
        <DeleteConfirmation onDelete={onDelete}>
          <DeleteButton {...props} />
        </DeleteConfirmation>
      </Grid>
    </React.Fragment>
  )
);
