import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  deleteFieldVariety,
  setFieldVarietyLocally
} from "../../../actions/field_varieties";

import DeleteConfirmation from "../../DeleteConfirmation";
import { View } from "./views";

class FieldVarietyChip extends Component {
  handleDelete = () => {
    const { trial } = this.props.trials;
    const { fieldVariety } = this.props;
    this.props.deleteFieldVariety(trial.id, fieldVariety.id);
    this.props.openEvaluation(false);
  };

  setSelectedFieldVariety = () => {
    this.props.openEvaluation(true);
    const { fieldVariety } = this.props;
    this.props.setFieldVarietyLocally(fieldVariety.id);
  };

  isCompleted = fieldVariety => {
    // has comments || // maturity date
    if (!fieldVariety.comments || !fieldVariety.maturityDate) {
      return false;
    }
    // has commodityId
    if (!fieldVariety.commodityId) {
      return false;
    }
    return true;
  };

  render() {
    const { fieldVariety } = this.props;
    const { varieties } = this.props.varieties;
    const variety = varieties[fieldVariety.varietyId] || {};
    return (
      <DeleteConfirmation onDelete={this.handleDelete}>
        <View
          variety={variety}
          fieldVariety={fieldVariety}
          setSelectedFieldVarietey={this.setSelectedFieldVariety}
          isCompleted={this.isCompleted}
        />
      </DeleteConfirmation>
    );
  }
}

FieldVarietyChip.propTypes = {
  fieldVariety: PropTypes.object.isRequired,
  openEvaluation: PropTypes.func.isRequired
};

const mapStateToProps = ({ trials, varieties }) => ({ trials, varieties });

export default connect(mapStateToProps, {
  deleteFieldVariety,
  setFieldVarietyLocally
})(FieldVarietyChip);
