import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { capitalize } from "../../../helpers/utils";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

export const CompanyButtons = ({ onSave, ...props }) => (
  <Grid item xs={12}>
    <Grid container>
      <Grid item xs={3}>
        <Button
          size="large"
          component={Link}
          to="/companies"
          variant="contained"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

CompanyButtons.propTypes = {
  onSave: PropTypes.func.isRequired
};

const styles = theme => ({
  headerContainer: {
    marginTop: 20
  },
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  chip: {
    margin: theme.spacing(2, 4)
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  divider: {
    height: theme.spacing(2)
  }
});

export const CompanyEditableItems = withStyles(styles)(
  ({ title, company, companyCategories, regions, handleChange, ...props }) => (
    <div>
      <Grid container spacing={2} className={props.classes.headerContainer}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Name"
            value={company.name || ""}
            onChange={handleChange("name")}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="region-list">Regions</InputLabel>
            <Select
              fullWidth
              multiple
              label="Regions"
              renderValue={selected => {
                let name = [];
                Object.values(regions).forEach(region => {
                  if (selected.includes(region.id)) {
                    name.push(region.name);
                  }
                });
                return name.join(", ");
              }}
              value={company.regionIds || []}
              onChange={handleChange("regionIds")}
              input={
                <OutlinedInput
                  name="regionIds"
                  labelWidth={65}
                  id="region-list"
                />
              }
            >
              {Object.values(regions).map((region, index) => (
                <MenuItem key={index} value={region.id}>
                  {capitalize(region.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="category-list">Categories</InputLabel>
            <Select
              fullWidth
              multiple
              label="Categories"
              renderValue={selected => {
                let name = [];
                Object.values(companyCategories).forEach(companyCategory => {
                  if (selected.includes(companyCategory.id)) {
                    name.push(capitalize(companyCategory.name));
                  }
                });
                return name.join(", ");
              }}
              value={company.companyCategoryIds || []}
              onChange={handleChange("companyCategoryIds")}
              input={
                <OutlinedInput
                  name="companyCategoryIds"
                  labelWidth={75}
                  id="company-category-list"
                />
              }
            >
              {Object.values(companyCategories).map(
                (companyCategory, index) => (
                  <MenuItem key={index} value={companyCategory.id}>
                    {capitalize(companyCategory.name)}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={company.address || ""}
            onChange={handleChange("address")}
            label="Address"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={company.address2 || ""}
            onChange={handleChange("address2")}
            label="Apt/Suite #"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={company.city || ""}
            onChange={handleChange("city")}
            label="City"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={company.state || ""}
            onChange={handleChange("state")}
            label="State"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={company.country || ""}
            onChange={handleChange("country")}
            label="Country"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={company.zipcode || ""}
            onChange={handleChange("zipcode")}
            label="Zip Code"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={company.website || ""}
            onChange={handleChange("website")}
            label="Website"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={company.phoneNumber || ""}
            onChange={handleChange("phoneNumber")}
            label="Phone Number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={company.faxNumber || ""}
            onChange={handleChange("faxNumber")}
            label="Fax Number"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </div>
  )
);

CompanyEditableItems.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  companyCategories: PropTypes.object.isRequired,
  regions: PropTypes.object.isRequired
};
