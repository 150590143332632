import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { TagRow } from "./views";
import { updateTag, deleteTag, updateTagLocally } from "../../../actions/tags";
import Grid from "@material-ui/core/Grid";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 10,
  margin: 0,

  // change background colour if dragging
  background: isDragging ? "rgba(208, 2, 27, 1)" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#D3D3D3" : "#fafafa",
  padding: 15
});

class TagDragAndDropArea extends Component {
  handleChange = id => e => {
    this.props.updateTagLocally(id, "name", e.target.value);
  };
  onDelete = (labelId, id) => () => {
    this.props.deleteTag(labelId, id);
  };
  sortTags = () => {
    const { labels } = this.props.labels;
    const label = labels[this.props.id] || {};
    const { tags } = this.props.tags;
    if (
      label.tags === undefined ||
      label.tags === null ||
      label.tags.length === 0
    ) {
      return [];
    }
    return Object.values(label.tags)
      .sort((tagAId, tagBId) => {
        const tagA = tags[tagAId];
        const tagB = tags[tagBId];
        if (tagA.location > tagB.location) {
          return 1;
        } else if (tagA.location < tagB.location) {
          return -1;
        }
        return 0;
      })
      .map(tagId => tags[tagId]);
  };
  onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const tagsList = this.sortTags();
    const [removed] = tagsList.splice(result.source.index, 1);
    tagsList.splice(result.destination.index, 0, removed);
    tagsList.forEach((tag, index) => {
      this.props.updateTagLocally(tag.id, "location", index);
    });
  };
  render() {
    const droppableId = `tag-droppable`;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <Grid
              container
              spacing={2}
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.sortTags().map((tag, index) => (
                <Draggable key={tag.id} draggableId={tag.id} index={index}>
                  {(provided, snapshot) => (
                    <Grid
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      item
                      xs={12}
                    >
                      <TagRow
                        tag={tag}
                        handleChange={this.handleChange}
                        onDelete={this.onDelete}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

TagDragAndDropArea.propTypes = {};

const mapStateToProps = ({ labels, tags }) => ({
  labels,
  tags
});
export default connect(mapStateToProps, {
  updateTag,
  updateTagLocally,
  deleteTag
})(TagDragAndDropArea);
