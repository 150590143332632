import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";
import { fetchCommoditiesByVariety } from "./commodities";

export const FETCH_FIELD_VARIETIES = "FETCH_FIELD_VARIETIES";
export const FETCH_FIELD_VARIETIES_SUCCESS = "FETCH_FIELD_VARIETIES_SUCCESS";
export const FETCH_FIELD_VARIETIES_FAILURE = "FETCH_FIELD_VARIETIES_FAILURE";

export const FETCH_FIELD_VARIETIES_PAGINATION =
  "FETCH_FIELD_VARIETIES_PAGINATION";
export const FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS =
  "FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS";
export const FETCH_FIELD_VARIETIES_PAGINATION_FAILURE =
  "FETCH_FIELD_VARIETIES_PAGINATION_FAILURE";

export const FETCH_FIELD_VARIETY = "FETCH_FIELD_VARIETY";
export const FETCH_FIELD_VARIETY_SUCCESS = "FETCH_FIELD_VARIETY_SUCCESS";
export const FETCH_FIELD_VARIETY_FAILURE = "FETCH_FIELD_VARIETY_FAILURE";

export const CREATE_FIELD_VARIETY = "CREATE_FIELD_VARIETY";
export const CREATE_FIELD_VARIETY_SUCCESS = "CREATE_FIELD_VARIETY_SUCCESS";
export const CREATE_FIELD_VARIETY_FAILURE = "CREATE_FIELD_VARIETY_FAILURE";

export const UPDATE_FIELD_VARIETY = "UPDATE_FIELD_VARIETY";
export const UPDATE_FIELD_VARIETY_SUCCESS = "UPDATE_FIELD_VARIETY_SUCCESS";
export const UPDATE_FIELD_VARIETY_FAILURE = "UPDATE_FIELD_VARIETY_FAILURE";

export const DELETE_FIELD_VARIETY = "DELETE_FIELD_VARIETY";
export const DELETE_FIELD_VARIETY_SUCCESS = "DELETE_FIELD_VARIETY_SUCCESS";
export const DELETE_FIELD_VARIETY_FAILURE = "DELETE_FIELD_VARIETY_FAILURE";

export const REMOVE_FIELD_VARIETY_LOCALLY = "REMOVE_FIELD_VARIETY_LOCALLY";
export const UPDATE_FIELD_VARIETY_LOCALLY = "UPDATE_FIELD_VARIETY_LOCALLY";
export const SET_FIELD_VARIETY_LOCALLY = "SET_FIELD_VARIETY_LOCALLY";

export const DELETE_IMAGE_FIELD_VARIETY = "DELETE_IMAGE_FIELD_VARIETY";
export const DELETE_IMAGE_FIELD_VARIETY_SUCCESS =
  "DELETE_IMAGE_FIELD_VARIETY_SUCCESS";
export const DELETE_IMAGE_FIELD_VARIETY_FAILURE =
  "DELETE_IMAGE_FIELD_VARIETY_FAILURE";

export const fetchFieldVarieties = trialId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_FIELD_VARIETIES,
        FETCH_FIELD_VARIETIES_SUCCESS,
        FETCH_FIELD_VARIETIES_FAILURE
      ],
      method: "get",
      endpoint: `/trials/${trialId}/field_varieties`,
      schema: Schemas.FIELD_VARIETIES
    }
  });

export const fetchFieldVarietiesByVarietyIds = varietyIds => (
  dispatch,
  getState
) =>
  dispatch(paginationCallApiObject(varietyIds, 1)).then(
    success => {
      checkIfMorePagesExist(varietyIds, success["meta"], dispatch);
    },
    error => {
      console.log("FETCH_FIELD_VARIETIES_PAGINATION_FAILURE", error);
    }
  );

const fetchMoreFieldVarietiesByVarietyIds = (varietyIds, nextPage) => (
  dispatch,
  getState
) => {
  return dispatch(paginationCallApiObject(varietyIds, nextPage)).then(
    success => {
      checkIfMorePagesExist(varietyIds, success["meta"], dispatch);
    },
    error => {
      console.log("FETCH_FIELD_VARIETIES_PAGINATION_FAILURE", error);
    }
  );
};

const paginationCallApiObject = (varietyIds, currentPage) => ({
  [CALL_API]: {
    types: [
      FETCH_FIELD_VARIETIES_PAGINATION,
      FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS,
      FETCH_FIELD_VARIETIES_PAGINATION_FAILURE
    ],
    method: "get",
    endpoint: `/field_varieties/by_variety_ids?ids=${varietyIds.join(
      ","
    )}&page=${currentPage}`,
    schema: Schemas.FIELD_VARIETIES
  }
});

const checkIfMorePagesExist = (varietyIds, meta, dispatch) => {
  if (meta && meta["totalPages"] > meta["currentPage"]) {
    dispatch(
      fetchMoreFieldVarietiesByVarietyIds(varietyIds, meta["currentPage"] + 1)
    );
  }
};

export const fetchFieldVariety = (trialId, id) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_FIELD_VARIETY,
        FETCH_FIELD_VARIETY_SUCCESS,
        FETCH_FIELD_VARIETY_FAILURE
      ],
      method: "get",
      endpoint: `/trials/${trialId}/field_varieties/${id}`,
      schema: Schemas.FIELD_VARIETY
    }
  });

export const createFieldVariety = (trialId, varietyId) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        CREATE_FIELD_VARIETY,
        CREATE_FIELD_VARIETY_SUCCESS,
        CREATE_FIELD_VARIETY_FAILURE
      ],
      method: "post",
      endpoint: `/trials/${trialId}/field_varieties`,
      schema: Schemas.FIELD_VARIETY,
      data: { fieldVarieties: { varietyId: varietyId } }
    }
  });

export const updateFieldVariety = (trialId, id, attributes) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        UPDATE_FIELD_VARIETY,
        UPDATE_FIELD_VARIETY_SUCCESS,
        UPDATE_FIELD_VARIETY_FAILURE
      ],
      method: "patch",
      endpoint: `/trials/${trialId}/field_varieties/${id}`,
      schema: Schemas.FIELD_VARIETY,
      data: { fieldVarieties: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Successfully saved the field variety evaluation"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to save the field varietey evaluation"
      }
    ]
  });

export const deleteFieldVariety = (trialId, id) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_FIELD_VARIETY,
        DELETE_FIELD_VARIETY_SUCCESS,
        DELETE_FIELD_VARIETY_FAILURE
      ],
      method: "delete",
      endpoint: `/trials/${trialId}/field_varieties/${id}`,
      schema: Schemas.FIELD_VARIETIES
    }
  }).then(() => {
    dispatch(removeFieldVarietyLocally(id));
  });

export const removeFieldVarietyLocally = id => (dispatch, getState) =>
  dispatch({
    type: REMOVE_FIELD_VARIETY_LOCALLY,
    id: id
  });

export const updateFieldVarietyLocally = (id, key, value) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_FIELD_VARIETY_LOCALLY,
    id: id,
    key: key,
    value: value
  });

export const setFieldVarietyLocally = id => (dispatch, getState) => {
  const { fieldVarieties } = getState().fieldVarieties;
  const fieldVariety = fieldVarieties[id];
  if (fieldVariety) {
    dispatch(
      fetchCommoditiesByVariety(
        "fieldVarieties",
        fieldVariety.varietyId,
        fieldVariety.commodityId
      )
    );
  }
  return dispatch({
    type: SET_FIELD_VARIETY_LOCALLY,
    id: id
  });
};

export const deleteFieldVarietyImage = (fieldVarietyId, imageId) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_IMAGE_FIELD_VARIETY,
        DELETE_IMAGE_FIELD_VARIETY_SUCCESS,
        DELETE_IMAGE_FIELD_VARIETY_FAILURE
      ],
      method: "post",
      endpoint: `/field_varieties/${fieldVarietyId}/image_delete`,
      schema: Schemas.FIELD_VARIETY,
      data: {
        imageId: imageId
      }
    }
  });
