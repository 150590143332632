import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { CommoditySelect } from "../../commodities";
import { AreaSelect } from "../../areas";
import { RegionSelect } from "../../regions";
import { RanchSelect } from "../../ranches";
import { GrowerSelect, SubGrowerSelect, ShipperSelect } from "../../companies";
import DeleteConfirmation from "../../DeleteConfirmation";

import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";

const trialHeaderStyles = theme => ({
  headerText: {
    marginTop: theme.headerText.marginTop,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  topButton: {
    marginTop: 25
  },
  [theme.breakpoints.down("xs")]: {
    topButton: {
      marginTop: 0
    },
    buttonGrid: {
      paddingTop: 0
    }
  },
  archiveToggle: {
    marginTop: 20
  }
});

export const TrialHeader = withStyles(trialHeaderStyles)(
  ({ trial, archive, handleArchiveOverrideChecked, handleAutoArchiveChecked, ...props }) => (
    <React.Fragment>
      <Grid item sm={3} xs={6}>
        <Typography
          variant="h2"
          gutterBottom
          className={props.classes.headerText}
        >
          Trial
        </Typography>
      </Grid>
      <Grid item sm={3} xs={6}>
        <FormControlLabel
          control={
            <Switch
              checked={trial.archiveOverride}
              onChange={handleArchiveOverrideChecked}
              value={trial.archiveOverride}
              color="primary"
              disabled={trial.autoArchive}
            />
          }
          className={props.classes.archiveToggle}
          label="Archive"
        />
        <FormControlLabel
          control={
            <Switch
              checked={trial.autoArchive}
              onChange={handleAutoArchiveChecked}
              value={trial.autoArchive}
              color="primary"
            />
          }
          className={props.classes.archiveToggle}
          label="Auto Archive"
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            component={Link}
            to={{ pathname: `/trials/plot_report/${trial.id}` }}
          >
            Plot Report
          </Button>
        </Grid>
      </Grid>
      <Grid item sm={3} xs={6}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            component={Link}
            to={{ pathname: `/trials/evaluation_report/${trial.id}` }}
          >
            Trial Report
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
);

TrialHeader.propTypes = {
  trial: PropTypes.object.isRequired,
  handleArchivedChecked: PropTypes.func.isRequired
};

const trialDetailStyles = theme => ({
  badge: {
    width: "100%"
  }
})
export const TrialDetails = withStyles(trialDetailStyles)(
  ({
  trial,
  handleChange,
  handleDateChange,
  setRecord,
  createRegion,
  createArea,
  createCommodity,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6} md={4}>
      <TextField
        fullWidth
        label="Name"
        value={trial.name || ""}
        disabled={true}
        onChange={handleChange("name")}
        variant="outlined"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <GrowerSelect
        grower={trial.grower || {}}
        setGrower={setRecord("grower")}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <SubGrowerSelect
        subGrower={trial.subGrower || {}}
        setSubGrower={setRecord("subGrower")}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <ShipperSelect
        shipper={trial.shipper || {}}
        setShipper={setRecord("shipper")}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <RanchSelect ranch={trial.ranch || {}} setRanch={setRecord("ranch")} />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <TextField
        fullWidth
        label="Lot #"
        variant="outlined"
        value={trial.lot || ""}
        onChange={handleChange("lot")}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <RegionSelect
        region={trial.region || {}}
        setRegion={setRecord("region")}
        createRegion={createRegion}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <AreaSelect
        area={trial.area || {}}
        setArea={setRecord("area")}
        createArea={createArea}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <CommoditySelect
        commodity={trial.commodity || {}}
        setCommodity={setRecord("commodity")}
        createCommodity={createCommodity}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <DatePicker
        value={trial.wetDate && moment(trial.wetDate, "YYYY-MM-DD")}
        inputVariant="outlined"
        onChange={handleDateChange("wetDate")}
        fullWidth
        clearable
        format={"MMMM Do, YYYY"}
        label="Start Wet Date"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <DatePicker
        value={
          trial.transplantDate && moment(trial.transplantDate, "YYYY-MM-DD")
        }
        inputVariant="outlined"
        onChange={handleDateChange("transplantDate")}
        fullWidth
        clearable
        format={"MMMM Do, YYYY"}
        label="Transplant Date"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <DatePicker
        value={
          trial.estHarvestDate && moment(trial.estHarvestDate, "YYYY-MM-DD")
        }
        inputVariant="outlined"
        onChange={handleDateChange("estHarvestDate")}
        fullWidth
        clearable
        format={"MMMM Do, YYYY"}
        label="Est. Harvest Date"
        helperText={`Recommended Harvest Date ${trial.tempEstimatedHarvestDate || "Not Available"}`}
      />
    </Grid>
    <Grid item xs={6} sm={4} md={2}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Cultural Practice"
          name="culturalPractice"
          value={trial.culturalPractice || "conventional"}
          onChange={handleChange("culturalPractice")}
        >
          <FormControlLabel
            value={"conventional"}
            control={<Radio color="primary" />}
            label="Conventional"
            labelPlacement="start"
          />
          <FormControlLabel
            value={"organic"}
            control={<Radio color="primary" />}
            label="Organic"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={6} sm={4} md={2}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Team Owner"
          name="salesTeam"
          value={
            trial.salesTeam && trial.salesTeam !== "false" ? "true" : "false"
          }
          onChange={handleChange("salesTeam")}
        >
          <FormControlLabel
            value={"false"}
            control={<Radio color="primary" />}
            label="PD Trial"
            labelPlacement="start"
          />
          <FormControlLabel
            value={"true"}
            control={<Radio color="primary" />}
            label="Sales Trial"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={4} md={2}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Cleaned"
          name="cleaned"
          value={trial.cleaned && trial.cleaned !== "false" ? "true" : "false"}
          onChange={handleChange("cleaned")}
        >
          <FormControlLabel
            value={"false"}
            control={<Radio color="primary" />}
            label="Not Cleaned"
            labelPlacement="start"
          />
          <FormControlLabel
            value={"true"}
            control={<Radio color="primary" />}
            label="Cleaned"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        multiline={true}
        variant="outlined"
        placeholder="Comments ..."
        label="Comments"
        rows={4}
        value={trial.comments || ""}
        onChange={handleChange("comments")}
      />
    </Grid>
  </React.Fragment>
))

TrialDetails.propTypes = {
  trial: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  setRecord: PropTypes.func.isRequired,
  createRegion: PropTypes.func.isRequired,
  createCommodity: PropTypes.func.isRequired
};

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button
    size="large"
    variant="contained"
    color="secondary"
    fullWidth
    onClick={confirmDelete}
  >
    Delete
  </Button>
);

export const TrialButtons = ({ onSave, onDelete, ...props }) => (
  <Grid item xs={12}>
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={6} md={4}>
        <DeleteConfirmation onDelete={onDelete}>
          <DeleteButton {...props} />
        </DeleteConfirmation>
      </Grid>
    </Grid>
  </Grid>
);

TrialButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};
