import React, { Component } from "react";
import { RanchesList } from "../containers/ranches";
import { Navbar } from "../containers";

class Ranches extends Component {
  render() {
    return (
      <Navbar currentPage="ranches">
        <RanchesList />
      </Navbar>
    );
  }
}

export default Ranches;
