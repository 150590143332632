import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANIES_BY_ID,
  FETCH_COMPANIES_BY_ID_SUCCESS,
  FETCH_COMPANIES_BY_ID_FAILURE,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_LOCALLY,
  CLEAR_COMPANY,
  CLEAR_COMPANIES,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  UPDATE_COMPANY_SEARCH
} from "../actions/companies";
import { FETCH_TRIAL_SUCCESS } from "../actions/trials";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS,
  CREATE_FIELD_VARIETY_SUCCESS
} from "../actions/field_varieties";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  companies: {},
  company: {},
  companiesById: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1,
  search: ""
};

export default function(state = INITIAL_STATE, action) {
  let company, companies;
  switch (action.type) {
    case UPDATE_COMPANY_SEARCH:
      let search = action.value || "";
      return { ...state, search: search };
    case CLEAR_COMPANY:
      return { ...state, company: {} };
    case CLEAR_COMPANIES:
      return { ...state, companies: {}, companiesById: {} };
    case UPDATE_COMPANY_LOCALLY:
      company = state.company;
      company[action.key] = action.value;
      return { ...state, company: company };
    case FETCH_TRIAL_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case CREATE_FIELD_VARIETY_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
      companies = combine(state.companies, action.data.companies);
      return { ...state, companies: companies };
    case FETCH_COMPANIES:
      return { ...state, companies: {}, error: null, waiting: true };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.data.companies || {},
        error: null,
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_COMPANIES_FAILURE:
      return { ...state, companies: {}, error: action.error, waiting: false };
    case FETCH_COMPANIES_BY_ID:
      return { ...state, companiesById: {}, error: null, waiting: true };
    case FETCH_COMPANIES_BY_ID_SUCCESS:
      return {
        ...state,
        companiesById: action.data.companies || {},
        error: null,
        waiting: false
      };
    case FETCH_COMPANIES_BY_ID_FAILURE:
      return {
        ...state,
        companiesById: {},
        error: action.error,
        waiting: false
      };
    case FETCH_COMPANY:
      return { ...state, company: {}, error: null, waiting: true };
    case FETCH_COMPANY_SUCCESS:
      company = getSingleEntity(action.data.companies);
      return { ...state, company: company, error: null, waiting: false };
    case FETCH_COMPANY_FAILURE:
      return { ...state, company: {}, error: action.error, waiting: false };
    case CREATE_COMPANY:
      return { ...state, error: null };
    case CREATE_COMPANY_SUCCESS:
      company = getSingleEntity(action.data.companies);
      return { ...state, company: company, error: null };
    case CREATE_COMPANY_FAILURE:
      return { ...state, error: action.error };
    case UPDATE_COMPANY:
      return { ...state, error: null };
    case UPDATE_COMPANY_SUCCESS:
      company = getSingleEntity(action.data.companies);
      return { ...state, company: company, error: null };
    case UPDATE_COMPANY_FAILURE:
      if (action.status === 412) {
        company = getSingleEntity(action.data.companies);
        return {
          ...state,
          error: action.error,
          company: company
        };
      }
      return { ...state, error: action.error };
    case DELETE_COMPANY:
      return { ...state, error: null, waiting: true };
    case DELETE_COMPANY_SUCCESS:
      companies = state.companies;
      delete companies[action.data.id];
      return { ...state, companies: companies, error: null, waiting: false };
    case DELETE_COMPANY_FAILURE:
      return { ...state, error: action.error, waiting: false };

    default:
      return state;
  }
}
