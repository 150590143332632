import React, { Component } from "react";
import { connect } from "react-redux";
import { getPlotBedNumbers, createPlotBedLocation } from "../../helpers";
import { PlotGridLayout } from "../../ui";
import {
  setSelectedPlotBeds,
  toggleSelectedPlotBed,
  clearSelectedPlotBeds
} from "../../actions/plot_beds";
import { setNurseVarietyLocally } from "../../actions/nurse_varieties";

class NurseryPlotGrid extends Component {
  constructor(props) {
    super(props);
    this.selectPlotBed = this.selectPlotBed.bind(this);
    this.iconColor = this.iconColor.bind(this);
  }

  filterPlotBedsByNursery = () => {
    const { nursery } = this.props.nurseries;
    const { nurseVarieties } = this.props.nurseVarieties;
    const { plotBeds } = this.props.plotBeds;
    const filteredNurseVarieties = {};
    Object.values(nurseVarieties).forEach(nurseVariety => {
      if (nurseVariety.nurseryId === nursery.id) {
        filteredNurseVarieties[nurseVariety.id] = nurseVariety;
      }
    });
    const filteredPlotBeds = {};
    Object.values(plotBeds).forEach(plotBed => {
      if (
        filteredNurseVarieties[plotBed.plotsId] &&
        plotBed.plotsType === "NurseVariety"
      )
        filteredPlotBeds[plotBed.key] = plotBed;
    });
    return filteredPlotBeds;
  };

  selectPlotBed = (rowIndex, colIndex) => {
    const { preSetVariety } = this.props.plotBeds;
    const locationId = createPlotBedLocation(rowIndex, colIndex);
    const plotBeds = this.filterPlotBedsByNursery();
    if (plotBeds[locationId]) {
      const nurseVarietyId = plotBeds[locationId].plotsId;
      this.props.setSelectedPlotBeds(nurseVarietyId);
      this.props.setNurseVarietyLocally(nurseVarietyId);
    } else {
      if (!preSetVariety) {
        this.props.toggleSelectedPlotBed(locationId, rowIndex, colIndex);
      } else {
        this.props.clearSelectedPlotBeds();
        this.props.toggleSelectedPlotBed(locationId, rowIndex, colIndex);
      }
    }
  };

  iconColor = locationId => {
    const { selected } = this.props.plotBeds;
    return selected[locationId] ? "selected" : "unselected";
  };

  setupPlots = () => {
    const { nursery } = this.props.nurseries;
    const plotRows = nursery.plotRows || 0;
    const plotCols = nursery.plotCols || 0;
    const plotType = nursery.plotType || "snake";

    const plotBeds = this.filterPlotBedsByNursery();
    const plotNumbers = getPlotBedNumbers(
      plotBeds,
      plotType,
      plotRows,
      plotCols
    );
    const rows = [];
    for (let rowIndex = parseInt(plotRows) - 1; rowIndex >= 0; rowIndex--) {
      const cols = [];
      for (let colIndex = 0; colIndex < parseInt(plotCols); colIndex++) {
        const locationId = createPlotBedLocation(rowIndex, colIndex);
        const plotNumber = plotNumbers[locationId];

        cols.push({
          key: locationId,
          rowIndex: rowIndex,
          colIndex: colIndex,
          color: this.iconColor(locationId),
          plotNumber: plotNumber
        });
      }
      rows.push(cols);
    }
    return rows;
  };

  render() {
    const rows = this.setupPlots();
    return <PlotGridLayout rows={rows} selectPlotBed={this.selectPlotBed} />;
  }
}

const mapStateToProps = ({ nurseries, nurseVarieties, plotBeds }) => ({
  nurseries,
  nurseVarieties,
  plotBeds
});

export default connect(mapStateToProps, {
  setSelectedPlotBeds,
  toggleSelectedPlotBed,
  setNurseVarietyLocally,
  clearSelectedPlotBeds
})(NurseryPlotGrid);
