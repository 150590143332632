import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    marginTop: 50
  }
});

const LoadingListSpinner = ({ ...props }) => (
  <Grid container direction="column" alignItems="center" justify="center">
    <Grid item xs={3}>
      <CircularProgress
        className={props.classes.root}
        size={100}
        thickness={7}
        color={"primary"}
      />
    </Grid>
  </Grid>
);

export default withStyles(styles)(LoadingListSpinner);
