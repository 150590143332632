import { SET_NOTIFICATION } from "./notifications";
import { CALL_API, AFTER_SUCCESS } from "../middleware/api";

export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_FAILURE = "SIGNIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

export const SET_TOKEN = "SET_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";

// Send Password Reset Token
export const SEND_PASSWORD_RESET_TOKEN = "SEND_PASSWORD_RESET_TOKEN";
export const SEND_PASSWORD_RESET_TOKEN_SUCCESS =
  "SEND_PASSWORD_RESET_TOKEN_SUCCESS";
export const SEND_PASSWORD_RESET_TOKEN_FAILURE =
  "SEND_PASSWORD_RESET_TOKEN_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const FETCH_FILE_TOKEN = "FETCH_FILE_TOKEN";
export const FETCH_FILE_TOKEN_SUCCESS = "FETCH_FILE_TOKEN_SUCCESS";
export const FETCH_FILE_TOKEN_FAILURE = "FETCH_FILE_TOKEN_FAILURE";

export const UPDATE_LIVE_LOCATION = "UPDATE_LIVE_LOCATION";
export const UPDATE_LIVE_LOCATION_SUCCESS = "UPDATE_LIVE_LOCATION_SUCCESS";
export const UPDATE_LIVE_LOCATION_FAILURE = "UPDATE_LIVE_LOCATION_FAILURE";

export const clearToken = () => ({
  type: CLEAR_TOKEN,
  payload: null
});

export const setToken = storedData => ({
  type: SET_TOKEN,
  payload: storedData
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: null
});

export const updateLiveLocation = (input = {}) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_LIVE_LOCATION,
    lat: input["lat"],
    lng: input["lng"],
    accuracy: input["accuracy"]
  });
};

export const fetchFileToken = () => ({
  [CALL_API]: {
    types: [
      FETCH_FILE_TOKEN,
      FETCH_FILE_TOKEN_SUCCESS,
      FETCH_FILE_TOKEN_FAILURE
    ],
    endpoint: "/authenticate_file",
    method: "post"
  }
});

export const signInUser = formValues => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [SIGNIN_USER, SIGNIN_USER_SUCCESS, SIGNIN_USER_FAILURE],
      endpoint: "/login",
      method: "post",
      data: {
        email: formValues["email"],
        password: formValues["password"]
      }
    }
  });

export const sendPasswordResetToken = formValues => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        SEND_PASSWORD_RESET_TOKEN,
        SEND_PASSWORD_RESET_TOKEN_SUCCESS,
        SEND_PASSWORD_RESET_TOKEN_FAILURE
      ],
      endpoint: "/users/forgot_password",
      method: "post",
      data: { email: formValues["email"] }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Sent reset password successfully"
      }
    ]
  });

export const resetPassword = (formValues, token) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
      endpoint: `/users/reset_password`,
      method: "post",
      data: {
        password: formValues["password"],
        passwordConfirmation: formValues["passwordConfirmation"],
        token: token
      }
    }
  });
