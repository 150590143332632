import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  EvaluationReportCard,
  ReportScoreCard,
  ScrollableImageCard,
  MultiEvaluationReportCard
} from "../../../ui";
import { ReportCommentCards, SupplierCard, TrialHeader } from "./views";
import Grid from "@material-ui/core/Grid";

class TrialVarietyReport extends Component {
  setFieldVariety = trialVariety => {
    let fieldVarietyId = trialVariety.fieldVarietyId;
    const { fieldVarieties } = this.props.fieldVarieties;
    let fieldVariety = fieldVarieties[fieldVarietyId] || {};
    if (!Object.keys(trialVariety).includes("fieldVarietyId")) {
      fieldVariety = trialVariety;
    }
    return fieldVariety;
  };

  getVarietySupplier = varietyId => {
    const { varieties } = this.props.varieties;
    const variety = varieties[varietyId];
    if (variety && variety.supplier) {
      const { companies } = this.props.companies;
      return companies[variety.supplier] || {};
    }
    return {};
  };

  mapEvaluations = record => {
    const evaluableType = Object.keys(record).includes("fieldVarietyId")
      ? "TrialVariety"
      : "FieldVariety";
    const { evaluations } = this.props.evaluations;
    const trialVarietyEvaluations = {};
    Object.values(evaluations).forEach(evaluation => {
      if (
        evaluation.evaluableId === record.id &&
        evaluation.evaluableType === evaluableType
      ) {
        trialVarietyEvaluations[evaluation.id] = evaluation;
      }
    });
    return Object.values(trialVarietyEvaluations);
  };

  mapMultiEvaluations = multEvaluationIds => {
    const { multiEvaluations } = this.props.multiEvaluations;
    return multEvaluationIds.map(id => multiEvaluations[id]);
  };

  render() {
    const { trials } = this.props.trials;
    const { record } = this.props;
    const trial = trials[record.trialId];

    const fieldVariety = this.setFieldVariety(record);
    const { varietyName, hideList, color } = this.props;
    const { variety } = record;
    const supplier = this.getVarietySupplier(variety);
    const { varieties } = this.props.varieties;
    const fieldVarietyVarietyName = (varieties[fieldVariety.varietyId] || {})
      .name;
    const evaluations = this.mapEvaluations(record);
    const multiEvaluations = this.mapMultiEvaluations(record.multiEvaluations);
    return (
      <Grid container spacing={2}>
        <TrialHeader trial={trial} hideList={hideList} color={color} />
        <SupplierCard
          color={color}
          varietyName={varietyName}
          supplierName={supplier.name}
          hideList={hideList}
          maturityDate={record.maturityDate}
        />
        <Grid item xs={12}>
          <EvaluationReportCard color={color} evaluations={evaluations} />
        </Grid>
        <Grid item xs={12}>
          <MultiEvaluationReportCard
            color={color}
            multiEvaluations={multiEvaluations}
          />
        </Grid>
        <ReportCommentCards
          color={color}
          comments={record.comments || ""}
          growerComments={record.growerComments || ""}
        />
        <Grid item xs={12}>
          <ReportScoreCard
            color={color}
            trialVarietyScore={record.score}
            fieldVarietyScore={fieldVariety.score}
            fieldVarietyName={fieldVarietyVarietyName}
          />
        </Grid>
        <Grid item xs={12}>
          <ScrollableImageCard color={color} images={record.images} />
        </Grid>
      </Grid>
    );
  }
}

TrialVarietyReport.propTypes = {
  record: PropTypes.object,
  hideList: PropTypes.object,
  mainVarietyBySubVarieties: PropTypes.object
};

const mapStateToProps = ({
  companies,
  evaluations,
  fieldVarieties,
  multiEvaluations,
  trials,
  varieties
}) => ({
  companies,
  evaluations,
  fieldVarieties,
  multiEvaluations,
  trials,
  varieties
});

export default connect(mapStateToProps, null)(TrialVarietyReport);
