import React from "react";
import DeleteConfirmation from "../../DeleteConfirmation";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const labelHeaderStyles = theme => ({
  progressGrid: {
    marginTop: 40,
    marginBottom: 40
  }
});

export const LabelHeader = withStyles(labelHeaderStyles)(
  ({ handleChange, label, ...props }) => (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Disease
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Name"
          value={label.name || ""}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  )
);

LabelHeader.propTypes = {
  label: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button
    variant="outlined"
    color="default"
    fullWidth
    className={props.classes.addCriteriumButton}
    onClick={confirmDelete}
  >
    Delete
  </Button>
);

const labelButtonsStyles = theme => ({
  headerContainer: {
    marginTop: 20
  },
  addCriteriumButton: {
    marginLeft: 0
  },
  progressGrid: {
    marginTop: 40,
    marginBottom: 40
  }
});

export const LabelButtons = withStyles(labelButtonsStyles)(
  ({ onSave, addTag, onDelete, ...props }) => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          className={props.classes.addCriteriumButton}
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DeleteConfirmation onDelete={onDelete}>
          <DeleteButton {...props} />
        </DeleteConfirmation>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          className={props.classes.addCriteriumButton}
          onClick={addTag}
        >
          Add New Tag
        </Button>
      </Grid>
    </Grid>
  )
);

LabelButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};
