import React from "react";
import PropTypes from "prop-types";

import TrialVarietyReport from "../TrialVarietyReport";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";
import PrintIcon from "@material-ui/icons/Print";
import FilterIcon from "@material-ui/icons/FilterList";
import ExcelIcon from "@material-ui/icons/Description";

const styles = theme => ({
  headerText: {
    marginTop: theme.headerText.marginTop
  },
  topButton: {
    marginTop: 25
  }
});

export const Header = withStyles(styles)(
  ({
    varieties,
    fetchCompaniesForEmail,
    downloadPDF,
    downloadExcel,
    processingReport,
    ...props
  }) => (
    <React.Fragment>
      <Grid item xs={6} className={props.classes.headerText}>
        {varieties.map(variety => (
          <Typography variant="h2" key={variety.id}>
            {variety.name}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            onClick={fetchCompaniesForEmail}
            disabled={processingReport}
          >
            <EmailIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            onClick={downloadExcel}
            disabled={processingReport}
          >
            <ExcelIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            onClick={downloadPDF}
            disabled={processingReport}
          >
            <PrintIcon />
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
);
Header.propTypes = {
  varieties: PropTypes.array.isRequired,
  fetchCompaniesForEmail: PropTypes.func.isRequired,
  downloadPDF: PropTypes.func.isRequired
};

export const FilterButton = ({ toggleFilterDrawer, ...porps }) => (
  <Grid item xs={12}>
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <Button
        size="large"
        variant="outlined"
        color="secondary"
        onClick={toggleFilterDrawer(true)}
      >
        <FilterIcon />
      </Button>
    </Grid>
  </Grid>
);

export const NoEvaluations = ({ ...props }) => (
  <Grid item xs={12}>
    <Grid container direction="row" justify="center" alignItems="center">
      <Typography variant="h4" gutterBottom>
        No Evaluations
      </Typography>
    </Grid>
  </Grid>
);

export const ReportColumns = ({
  filteredTrialAndFieldVarieties,
  getVarietyName,
  trialVarietyColor,
  hideList,
  graphData,
  ...props
}) => (
  <React.Fragment>
    {filteredTrialAndFieldVarieties.map((trialVariety, index) => (
      <Grid item md={4} sm={6} xs={12} key={index}>
        <TrialVarietyReport
          record={trialVariety}
          varietyName={getVarietyName(trialVariety)}
          color={trialVarietyColor(trialVariety, graphData)}
          hideList={hideList}
        />
      </Grid>
    ))}
  </React.Fragment>
);
ReportColumns.propTypes = {
  filteredTrialAndFieldVarieties: PropTypes.array.isRequired,
  hideList: PropTypes.object.isRequired
};
