import React from "react";
import { compose, withProps } from "recompose";
import Typography from "@material-ui/core/Typography";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from "react-google-maps";

const MapWithAMarker = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    options={{
      mapTypeId: "hybrid",
      streetViewControl: true
    }}
    zoom={props.zoom}
    ref={props.googleMapRef}
    onZoomChanged={e => props.onZoomChanged(e)}
    onCenterChanged={e => props.onCenterChanged(e)}
    center={{ lat: props.centerLat, lng: props.centerLng }}
    onClick={e => props.handleClick(e)}
  >
    <Marker position={{ lat: props.lat, lng: props.lng }} />
    {props.liveLocationLat && props.liveLocationLng && (
      <Circle
        center={{ lat: props.liveLocationLat, lng: props.liveLocationLng }}
        radius={props.liveLocationAccuracy || 0}
        strokeColor={"#FF0000"}
        strokeOpacity={0.8}
        strokeWeight={2}
        fillColor={"#FF0000"}
        fillOpacity={0.35}
      />
    )}
  </GoogleMap>
));

const FarmMapView = ({
  liveLocationLat,
  liveLocationLng,
  liveLocationAccuracy,
  lat,
  lng,
  centerLat,
  centerLng,
  zoom,
  handleClick,
  onZoomChanged,
  onCenterChanged,
  googleMapRef,
  ...props
}) => (
  <React.Fragment>
    {lat && lng ? (
      <MapWithAMarker
        isMarkerShown={true}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBmg2Zr_wrJ1HUeBgaPywgP6zAoL0szepY`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: "400px" }} />}
        mapElement={<div style={{ height: "100%" }} />}
        lat={lat}
        lng={lng}
        centerLat={centerLat}
        centerLng={centerLng}
        liveLocationLat={liveLocationLat}
        liveLocationLng={liveLocationLng}
        liveLocationAccuracy={liveLocationAccuracy}
        zoom={zoom}
        handleClick={handleClick}
        onZoomChanged={onZoomChanged}
        onCenterChanged={onCenterChanged}
        googleMapRef={googleMapRef}
      />
    ) : (
      <Typography gutterBottom variant="h6">
        Map currently not set
      </Typography>
    )}
  </React.Fragment>
);

export default FarmMapView;
