import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateTrialLocally } from "../../../actions/trials";
import {
  createTrialVariety,
  setTrialVarietyLocally
} from "../../../actions/trial_varieties";
import {
  setSelectedPlotBeds,
  toggleSelectedPlotBed,
  clearSelectedPlotBeds
} from "../../../actions/plot_beds";
import { createPlotBedLocation, filterPlotBedsByTrial } from "../../../helpers";
import Evaluation from "../Evaluation";
import PlotTrialVarieties from "../PlotTrialVarieties";
import PlotGrid from "../PlotGrid";
import SetVariety from "../SetVariety";
import { PlotDetails } from "./views";

import Grid from "@material-ui/core/Grid";

class PlotGridGrouping extends Component {
  constructor(props) {
    super(props);
    this.updateTrialLocally = this.updateTrialLocally.bind(this);
  }

  componentDidMount() {
    this.props.clearSelectedPlotBeds();
  }

  updateTrialLocally = name => e => {
    const { trial } = this.props.trials;
    this.props.updateTrialLocally(trial.id, name, e.target.value);
  };

  selectPlotBed = (rowIndex, colIndex) => {
    const { trial } = this.props.trials;
    const { trialVarieties } = this.props.trialVarieties;
    const { preSetVariety, plotBeds } = this.props.plotBeds;
    const filteredPlotBeds = filterPlotBedsByTrial(
      trial,
      trialVarieties,
      plotBeds
    );
    const locationId = createPlotBedLocation(rowIndex, colIndex);
    if (filteredPlotBeds[locationId]) {
      const plotsId = filteredPlotBeds[locationId].plotsId;
      this.props.setSelectedPlotBeds(plotsId);
      this.props.openEvaluation(true);
      this.props.setTrialVarietyLocally(plotsId);
    } else if (!preSetVariety) {
      this.props.toggleSelectedPlotBed(locationId, rowIndex, colIndex);
      this.props.openEvaluation(false);
    } else {
      this.props.clearSelectedPlotBeds();
      this.props.toggleSelectedPlotBed(locationId, rowIndex, colIndex);
      this.props.openEvaluation(false);
    }
  };

  render() {
    const { trial } = this.props.trials;
    const { trialVariety } = this.props.trialVarieties;
    const plotRows = parseInt(trial.plotRows) || 0;
    const plotCols = parseInt(trial.plotCols) || 0;
    const { showEvaluation, openEvaluation } = this.props;
    return (
      <Grid container spacing={2}>
        {showEvaluation && (
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Evaluation
              type={"trialVarieties"}
              openEvaluation={openEvaluation}
              recordId={trialVariety.id}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <PlotDetails
              updateTrialLocally={this.updateTrialLocally}
              plotCols={plotCols}
              plotRows={plotRows}
              plotType={trial.plotType}
            />
            <Grid item xs={12}>
              <PlotGrid selectPlotBed={this.selectPlotBed} />
            </Grid>
            <Grid item xs={12}>
              <SetVariety
                parent={trial}
                createVarietyConnection={this.props.createTrialVariety}
              />
            </Grid>
            <Grid item xs={12}>
              <PlotTrialVarieties openEvaluation={openEvaluation} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

PlotGridGrouping.propTypes = {
  openEvaluation: PropTypes.func.isRequired,
  showEvaluation: PropTypes.bool.isRequired
};
const mapStateToProps = ({ trials, trialVarieties, plotBeds }) => ({
  trials,
  trialVarieties,
  plotBeds
});

export default connect(mapStateToProps, {
  updateTrialLocally,
  createTrialVariety,
  setTrialVarietyLocally,
  setSelectedPlotBeds,
  toggleSelectedPlotBed,
  clearSelectedPlotBeds
})(PlotGridGrouping);
