import React, { Component } from "react";
import { Navbar } from "../containers";
import { VarietiesList } from "../containers/varieties";

class Varieties extends Component {
  render() {
    return (
      <Navbar currentPage="varieties">
        <VarietiesList />
      </Navbar>
    );
  }
}
export default Varieties;
