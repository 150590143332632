import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const PageHeader = ({ title, children, ...props }) => {
  const matches = useMediaQuery(json2mq({ maxWidth: 400 }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={props.half ? props.half : 8}>
        <Typography
          variant={matches ? "h4" : "h2"}
          gutterBottom
          className={props.classes.headerText}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={props.half ? 12 - props.half : 4}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  firstHalf: PropTypes.number
};

const styles = theme => ({
  headerText: {
    marginTop: theme.headerText.marginTop,
    paddingBottom: theme.headerText.paddingBottom,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  }
});

export default withStyles(styles)(PageHeader);
