import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCommodity } from "../actions/commodities";
import { fetchLabelsByCommodityId } from "../actions/labels";

import { Navbar } from "../containers";
import { CommodityEdit } from "../containers/commodities";

import Grid from "@material-ui/core/Grid";

class Commodity extends Component {
  componentDidMount() {
    this.props.fetchCommodity(this.props.match.params.id);
    //this.props.fetchLabelsByCommodityId(this.props.match.params.id);
  }

  render() {
    return (
      <div>
        <Navbar currentPage="commodities">
          <Grid container>
            <CommodityEdit id={this.props.match.params.id} />
          </Grid>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = ({ commodities }) => ({ commodities });

export default connect(mapStateToProps, {
  fetchCommodity,
  fetchLabelsByCommodityId
})(Commodity);
