import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  admin: state.user.admin,
  authenticated: state.user.authenticated
});

export const withAuth = (ComponentToProtect, ...props) =>
  connect(
    mapStateToProps,
    null
  )(
    class extends Component {
      render() {
        if (!this.props.authenticated) {
          return <Redirect push to="/signin" />;
        }
        return (
          <React.Fragment>
            <ComponentToProtect {...this.props} />
          </React.Fragment>
        );
      }
    }
  );

export const withAuthAndAdmin = (ComponentToProtect, ...props) =>
  connect(
    mapStateToProps,
    null
  )(
    class extends Component {
      render() {
        if (!this.props.authenticated) {
          return <Redirect push to="/signin" />;
        }
        if (!this.props.admin) {
          return <Redirect push to="/" />;
        }
        return (
          <React.Fragment>
            <ComponentToProtect {...this.props} />
          </React.Fragment>
        );
      }
    }
  );
