import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";
import { UPDATE_TRIAL_REGION_ID_LOCALLY } from "./trials";

export const UPDATE_REGION_LOCALLY = "UPDATE_REGION_LOCALLY";
export const UPDATE_REGION_SEARCH = "UPDATE_REGION_SEARCH";

export const FETCH_REGIONS = "FETCH_REGIONS";
export const FETCH_REGIONS_SUCCESS = "FETCH_REGIONS_SUCCESS";
export const FETCH_REGIONS_FAILURE = "FETCH_REGIONS_FAILURE";

export const FETCH_MORE_REGIONS = "FETCH_MORE_REGIONS";
export const FETCH_MORE_REGIONS_SUCCESS = "FETCH_MORE_REGIONS_SUCCESS";
export const FETCH_MORE_REGIONS_FAILURE = "FETCH_MORE_REGIONS_FAILURE";

export const FETCH_REGION = "FETCH_REGION";
export const FETCH_REGION_SUCCESS = "FETCH_REGION_SUCCESS";
export const FETCH_REGION_FAILURE = "FETCH_REGION_FAILURE";

export const FETCH_REGIONS_BY_ID = "FETCH_REGIONS_BY_ID";
export const FETCH_REGIONS_BY_ID_SUCCESS = "FETCH_REGIONS_BY_ID_SUCCESS";
export const FETCH_REGIONS_BY_ID_FAILURE = "FETCH_REGIONS_BY_ID_FAILURE";

export const CREATE_REGION = "CREATE_REGION";
export const CREATE_REGION_SUCCESS = "CREATE_REGION_SUCCESS";
export const CREATE_REGION_FAILURE = "CREATE_REGION_FAILURE";

export const UPDATE_REGION = "UPDATE_REGION";
export const UPDATE_REGION_SUCCESS = "UPDATE_REGION_SUCCESS";
export const UPDATE_REGION_FAILURE = "UPDATE_REGION_FAILURE";

export const DELETE_REGION = "DELETE_REGION";
export const DELETE_REGION_SUCCESS = "DELETE_REGION_SUCCESS";
export const DELETE_REGION_FAILURE = "DELETE_REGION_FAILURE";

export const updateSearch = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_REGION_SEARCH,
    value: value
  });

export const updateRegionLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_REGION_LOCALLY,
    key: key,
    value: value
  });

export const fetchRegions = (search = "") => (dispatch, getState) => {
  const { page } = getState().regions;
  return dispatch({
    [CALL_API]: {
      types: [FETCH_REGIONS, FETCH_REGIONS_SUCCESS, FETCH_REGIONS_FAILURE],
      endpoint: `/regions?page=${page}&search=${encodeURIComponent(search)}`,
      schema: Schemas.REGIONS,
      method: "get"
    }
  });
};

export const fetchMoreRegions = (search = "") => (dispatch, getState) => {
  const { page } = getState().regions;
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_MORE_REGIONS,
        (FETCH_MORE_REGIONS_SUCCESS: FETCH_MORE_REGIONS_FAILURE)
      ],
      endpoint: `/regions?search=${encodeURIComponent(search)}&page=${page +
        1}`,
      schema: Schemas.REGIONS,
      method: "get"
    }
  });
};

export const fetchRegion = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_REGION, FETCH_REGION_SUCCESS, FETCH_REGION_FAILURE],
      endpoint: `/regions/${id}`,
      schema: Schemas.REGION,
      method: "get"
    }
  });

export const fetchRegionsById = ids => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_REGIONS_BY_ID,
        FETCH_REGIONS_BY_ID_SUCCESS,
        FETCH_REGIONS_BY_ID_FAILURE
      ],
      endpoint: `/regions/by_ids?ids=${ids}`,
      schema: Schemas.REGIONS,
      method: "get"
    }
  });

export const createRegion = name => (dispatch, getState) =>
  dispatch(createRegionCall(name));

export const createRegionUpdateTrial = name => (dispatch, getState) => {
  const updateTrialCall = {
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created an Region successfully"
      },
      {
        type: UPDATE_TRIAL_REGION_ID_LOCALLY
      }
    ]
  };
  const apiCall = Object.assign(createRegionCall(name), updateTrialCall);
  return dispatch(apiCall);
};

const createRegionCall = name => ({
  [CALL_API]: {
    types: [CREATE_REGION, CREATE_REGION_SUCCESS, CREATE_REGION_FAILURE],
    endpoint: "/regions",
    schema: Schemas.REGION,
    method: "post",
    data: { region: { name: name } }
  },
  [AFTER_SUCCESS]: [
    {
      type: SET_NOTIFICATION,
      payload: "Created an Region successfully"
    },
    {
      type: UPDATE_TRIAL_REGION_ID_LOCALLY
    }
  ],
  [AFTER_FAILURE]: [
    {
      type: SET_NOTIFICATION,
      payload: "Failed to create Region"
    }
  ]
});

export const updateRegion = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_REGION, UPDATE_REGION_SUCCESS, UPDATE_REGION_FAILURE],
      endpoint: `/regions/${id}`,
      schema: Schemas.REGION,
      method: "patch",
      data: { region: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created an Region successfully"
      },
      {
        type: UPDATE_TRIAL_REGION_ID_LOCALLY
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Region"
      }
    ]
  });

export const deleteRegion = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_REGION, DELETE_REGION_SUCCESS, DELETE_REGION_FAILURE],
      endpoint: `/regions/${id}`,
      schema: Schemas.REGION,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted a Region successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete Region"
      }
    ]
  });
