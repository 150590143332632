import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";
import { fetchCommoditiesByVariety } from "./commodities";
import { fetchPlotBeds, removePlotBedsLocally } from "./plot_beds";

export const UPDATE_TRIAL_VARIETY_LOCALLY = "UPDATE_TRIAL_VARIETY_LOCALLY";
export const REMOVE_TRIAL_VARIETY_LOCALLY = "REMOVE_TRIAL_VARIETY_LOCALLY";
export const SET_TRIAL_VARIETY_LOCALLY = "SET_TRIAL_VARIETY_LOCALLY";

export const FETCH_TRIAL_VARIETIES = "FETCH_TRIAL_VARIETIES";
export const FETCH_TRIAL_VARIETIES_SUCCESS = "FETCH_TRIAL_VARIETIES_SUCCESS";
export const FETCH_TRIAL_VARIETIES_FAILURE = "FETCH_TRIAL_VARIETIES_FAILURE";

export const FETCH_TRIAL_VARIETIES_PAGINATION =
  "FETCH_TRIAL_VARIETIES_PAGINATION";
export const FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS =
  "FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS";
export const FETCH_TRIAL_VARIETIES_PAGINATION_FAILURE =
  "FETCH_TRIAL_VARIETIES_PAGINATION_FAILURE";

export const FETCH_TRIAL_VARIETY = "FETCH_TRIAL_VARIETY";
export const FETCH_TRIAL_VARIETY_SUCCESS = "FETCH_TRIAL_VARIETY_SUCCESS";
export const FETCH_TRIAL_VARIETY_FAILURE = "FETCH_TRIAL_VARIETY_FAILURE";

export const CREATE_TRIAL_VARIETY = "CREATE_TRIAL_VARIETY";
export const CREATE_TRIAL_VARIETY_SUCCESS = "CREATE_TRIAL_VARIETY_SUCCESS";
export const CREATE_TRIAL_VARIETY_FAILURE = "CREATE_TRIAL_VARIETY_FAILURE";

export const UPDATE_TRIAL_VARIETY = "UPDATE_TRIAL_VARIETY";
export const UPDATE_TRIAL_VARIETY_SUCCESS = "UPDATE_TRIAL_VARIETY_SUCCESS";
export const UPDATE_TRIAL_VARIETY_FAILURE = "UPDATE_TRIAL_VARIETY_FAILURE";

export const DELETE_TRIAL_VARIETY = "DELETE_TRIAL_VARIETY";
export const DELETE_TRIAL_VARIETY_SUCCESS = "DELETE_TRIAL_VARIETY_SUCCESS";
export const DELETE_TRIAL_VARIETY_FAILURE = "DELETE_TRIAL_VARIETY_FAILURE";

export const DELETE_IMAGE_TRIAL_VARIETY = "DELETE_IMAGE_TRIAL_VARIETY";
export const DELETE_IMAGE_TRIAL_VARIETY_SUCCESS =
  "DELETE_IMAGE_TRIAL_VARIETY_SUCCESS";
export const DELETE_IMAGE_TRIAL_VARIETY_FAILURE =
  "DELETE_IMAGE_TRIAL_VARIETY_FAILURE";

export const fetchTrialVarieties = trialId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_TRIAL_VARIETIES,
        FETCH_TRIAL_VARIETIES_SUCCESS,
        FETCH_TRIAL_VARIETIES_FAILURE
      ],
      method: "get",
      endpoint: `/trials/${trialId}/trial_varieties`,
      schema: Schemas.TRIAL_VARIETIES
    }
  });

export const fetchTrialVarietiesByVariety = varietyId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_TRIAL_VARIETIES,
        FETCH_TRIAL_VARIETIES_SUCCESS,
        FETCH_TRIAL_VARIETIES_FAILURE
      ],
      method: "get",
      endpoint: `/varieties/${varietyId}/trial_varieties`,
      schema: Schemas.TRIAL_VARIETIES
    }
  });

export const fetchTrialVarietiesByVarietyIds = varietyIds => (
  dispatch,
  getState
) => {
  return dispatch(paginationCallApiObject(varietyIds, 1)).then(
    success => {
      const meta = success["meta"];
      if (meta && meta["totalPages"] > meta["currentPage"]) {
        dispatch(
          fetchMoreTrialVarietiesByVarietyIds(
            varietyIds,
            meta["currentPage"] + 1
          )
        );
      }
    },
    error => {
      console.log("FETCH_TRIAL_VARIETIES_PAGINATION_FAILURE", error);
    }
  );
};

const fetchMoreTrialVarietiesByVarietyIds = (varietyIds, nextPage) => (
  dispatch,
  getState
) => {
  return dispatch(paginationCallApiObject(varietyIds, nextPage)).then(
    success => {
      const meta = success["meta"];
      if (meta && meta["totalPages"] > meta["currentPage"]) {
        dispatch(
          fetchMoreTrialVarietiesByVarietyIds(
            varietyIds,
            meta["currentPage"] + 1
          )
        );
      }
    },
    error => {
      console.log("FETCH_TRIAL_VARIETIES_PAGINATION_FAILURE", error);
    }
  );
};

const paginationCallApiObject = (varietyIds, currentPage) => ({
  [CALL_API]: {
    types: [
      FETCH_TRIAL_VARIETIES_PAGINATION,
      FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS,
      FETCH_TRIAL_VARIETIES_PAGINATION_FAILURE
    ],
    method: "get",
    endpoint: `/trial_varieties/by_variety_ids?ids=${varietyIds.join(
      ","
    )}&page=${currentPage}`,
    schema: Schemas.TRIAL_VARIETIES
  }
});

export const fetchTrialVariety = (trialId, trialVarietyId) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_TRIAL_VARIETY,
        FETCH_TRIAL_VARIETY_SUCCESS,
        FETCH_TRIAL_VARIETY_FAILURE
      ],
      method: "get",
      endpoint: `/trials/${trialId}/trial_varieties/${trialVarietyId}`,
      schema: Schemas.TRIAL_VARIETY
    }
  });

export const createTrialVariety = (trialId, varietyId) => (
  dispatch,
  getState
) => {
  const { selected } = getState().plotBeds;
  return dispatch({
    [CALL_API]: {
      types: [
        CREATE_TRIAL_VARIETY,
        CREATE_TRIAL_VARIETY_SUCCESS,
        CREATE_TRIAL_VARIETY_FAILURE
      ],
      method: "post",
      endpoint: `/trials/${trialId}/trial_varieties`,
      schema: Schemas.TRIAL_VARIETY,
      data: {
        trialVarieties: { varietyId: varietyId },
        newPlotBeds: Object.values(selected)
      }
    }
  })
    .then(() => {
      dispatch(fetchPlotBeds(trialId));
      dispatch(fetchTrialVarieties(trialId));
    })
    .catch(() => {
      dispatch(fetchPlotBeds(trialId));
    });
};

export const updateTrialVariety = (trialId, id, attributes) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        UPDATE_TRIAL_VARIETY,
        UPDATE_TRIAL_VARIETY_SUCCESS,
        UPDATE_TRIAL_VARIETY_FAILURE
      ],
      method: "patch",
      endpoint: `/trials/${trialId}/trial_varieties/${id}`,
      schema: Schemas.TRIAL_VARIETY,
      data: { trialVarieties: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Successfully saved the evaluation"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to save the evaluation"
      }
    ]
  });

export const deleteTrialVariety = (trialId, id) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_TRIAL_VARIETY,
        DELETE_TRIAL_VARIETY_SUCCESS,
        DELETE_TRIAL_VARIETY_FAILURE
      ],
      method: "delete",
      endpoint: `/trials/${trialId}/trial_varieties/${id}`,
      schema: Schemas.TRIAL_VARIETIES
    }
  }).then(() => {
    dispatch(removePlotBedsLocally(id));
    dispatch(removeTrialVarietyLocally(id));
  });

export const deleteFieldVariety = (trialId, id) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_TRIAL_VARIETY,
        DELETE_TRIAL_VARIETY_SUCCESS,
        DELETE_TRIAL_VARIETY_FAILURE
      ],
      method: "delete",
      endpoint: `/trials/${trialId}/trial_varieties/${id}`,
      schema: Schemas.TRIAL_VARIETIES
    }
  }).then(() => {
    dispatch(removeTrialVarietyLocally(id));
  });

export const removeTrialVarietyLocally = id => (dispatch, getState) =>
  dispatch({
    type: REMOVE_TRIAL_VARIETY_LOCALLY,
    id: id
  });

export const updateTrialVarietyLocally = (id, key, value) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_TRIAL_VARIETY_LOCALLY,
    id: id,
    key: key,
    value: value
  });

export const setTrialVarietyLocally = id => (dispatch, getState) => {
  const { trialVarieties } = getState().trialVarieties;
  const trialVariety = trialVarieties[id];
  if (trialVariety) {
    dispatch(
      fetchCommoditiesByVariety(
        "trialVarieties",
        trialVariety.varietyId,
        trialVariety.commodityId
      )
    );
  }
  return dispatch({
    type: SET_TRIAL_VARIETY_LOCALLY,
    id: id
  });
};

export const deleteTrialVarietyImage = (trialVarietyId, imageId) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_IMAGE_TRIAL_VARIETY,
        DELETE_IMAGE_TRIAL_VARIETY_SUCCESS,
        DELETE_IMAGE_TRIAL_VARIETY_FAILURE
      ],
      method: "post",
      endpoint: `/trial_varieties/${trialVarietyId}/image_delete`,
      schema: Schemas.TRIAL_VARIETY,
      data: {
        imageId: imageId
      }
    }
  });
