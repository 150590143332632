import {
  UPDATE_FIELD_VARIETY_LOCALLY,
  REMOVE_FIELD_VARIETY_LOCALLY,
  SET_FIELD_VARIETY_LOCALLY,
  FETCH_FIELD_VARIETIES,
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_FAILURE,
  FETCH_FIELD_VARIETIES_PAGINATION,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_FAILURE,
  FETCH_FIELD_VARIETY,
  FETCH_FIELD_VARIETY_SUCCESS,
  FETCH_FIELD_VARIETY_FAILURE,
  CREATE_FIELD_VARIETY,
  CREATE_FIELD_VARIETY_SUCCESS,
  CREATE_FIELD_VARIETY_FAILURE,
  UPDATE_FIELD_VARIETY,
  UPDATE_FIELD_VARIETY_SUCCESS,
  UPDATE_FIELD_VARIETY_FAILURE,
  DELETE_FIELD_VARIETY,
  DELETE_FIELD_VARIETY_SUCCESS,
  DELETE_FIELD_VARIETY_FAILURE,
  DELETE_IMAGE_FIELD_VARIETY,
  DELETE_IMAGE_FIELD_VARIETY_SUCCESS,
  DELETE_IMAGE_FIELD_VARIETY_FAILURE
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import {
  CREATE_TRIAL_SUCCESS,
  FETCH_TRIAL_SUCCESS,
  UPDATE_TRIAL_SUCCESS
} from "../actions/trials";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  fieldVarieties: {},
  fieldVariety: {},
  error: null,
  waiting: false,
  uploadingImage: false
};

export default function(state = INITIAL_STATE, action) {
  let fieldVarieties, fieldVariety, newFieldVariety;
  switch (action.type) {
    case CREATE_TRIAL_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
    case UPDATE_TRIAL_SUCCESS:
      fieldVarieties = combine(
        state.fieldVarieties,
        action.data.fieldVarieties
      );
      return { ...state, fieldVarieties: fieldVarieties };
    case UPDATE_FIELD_VARIETY_LOCALLY:
      newFieldVariety = state.fieldVariety;
      fieldVarieties = state.fieldVarieties;
      fieldVariety = fieldVarieties[action.id];
      fieldVariety[action.key] = action.value;
      fieldVarieties[action.id] = fieldVariety;
      if (newFieldVariety.id === fieldVariety.id) {
        newFieldVariety = fieldVariety;
      }
      return {
        ...state,
        fieldVarieties: fieldVarieties,
        fieldVariety: newFieldVariety
      };
    case SET_FIELD_VARIETY_LOCALLY:
      fieldVarieties = state.fieldVarieties;
      fieldVariety = fieldVarieties[action.id];
      return {
        ...state,
        fieldVariety: fieldVariety || {}
      };
    case REMOVE_FIELD_VARIETY_LOCALLY:
      fieldVarieties = state.fieldVarieties;
      delete fieldVarieties[action.id];
      return { ...state, fieldVarieties: fieldVarieties };
    case FETCH_FIELD_VARIETIES:
    case FETCH_FIELD_VARIETIES_PAGINATION:
      return { ...state, error: null, waiting: true };
    case FETCH_FIELD_VARIETIES_SUCCESS:
      fieldVarieties = combine(
        state.fieldVarieties,
        action.data.fieldVarieties
      );
      return { ...state, fieldVarieties: fieldVarieties, waiting: false };
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
      fieldVarieties = combine(
        state.fieldVarieties,
        action.data.fieldVarieties
      );
      let waiting = false;
      if (action.meta.totalPages > action.meta.currentPage) {
        waiting = true;
      }
      return { ...state, fieldVarieties: fieldVarieties, waiting: waiting };
    case FETCH_FIELD_VARIETIES_FAILURE:
    case FETCH_FIELD_VARIETIES_PAGINATION_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_FIELD_VARIETY:
      return { ...state, error: null };
    case FETCH_FIELD_VARIETY_SUCCESS:
      fieldVarieties = state.fieldVarieties;
      newFieldVariety = getSingleEntity(action.data.fieldVarieties);
      fieldVarieties[newFieldVariety.id] = newFieldVariety;

      fieldVariety = state.fieldVariety;
      if (fieldVariety.id === newFieldVariety.id) {
        fieldVariety = newFieldVariety;
      }
      return {
        ...state,
        fieldVariety: fieldVariety,
        fieldVarieties: fieldVarieties
      };
    case FETCH_FIELD_VARIETY_FAILURE:
      return { ...state, error: action.error };
    case CREATE_FIELD_VARIETY:
      return { ...state, error: null };
    case CREATE_FIELD_VARIETY_SUCCESS:
      fieldVariety = getSingleEntity(action.data.fieldVarieties);
      fieldVarieties = state.fieldVarieties;
      fieldVarieties[fieldVariety.id] = fieldVariety;
      return { ...state, fieldVarieties: fieldVarieties };
    case CREATE_FIELD_VARIETY_FAILURE:
      return { ...state, error: action.error };
    case DELETE_FIELD_VARIETY:
      return { ...state, error: null };
    case DELETE_FIELD_VARIETY_SUCCESS:
      fieldVarieties = state.fieldVarieties;
      let dataId = parseInt(action.data.id);
      delete fieldVarieties[dataId];
      return { ...state, fieldVarieties: fieldVarieties };
    case DELETE_FIELD_VARIETY_FAILURE:
      return { ...state, error: action.error };
    case UPDATE_FIELD_VARIETY:
      return { ...state, error: null };
    case UPDATE_FIELD_VARIETY_SUCCESS:
      fieldVariety = getSingleEntity(action.data.fieldVarieties);
      fieldVarieties = state.fieldVarieties;
      fieldVarieties[fieldVariety.id] = fieldVariety;
      return { ...state, fieldVarieties: fieldVarieties };
    case UPDATE_FIELD_VARIETY_FAILURE:
      if (action.status === 412) {
        fieldVariety = getSingleEntity(action.data.fieldVarieties);
        fieldVarieties = state.fieldVarieties;
        fieldVarieties[fieldVariety.id] = fieldVariety;
        return {
          ...state,
          error: action.error,
          fieldVarieties: fieldVarieties,
          fieldVariety: fieldVariety
        };
      }
      return { ...state, error: action.error };
    case DELETE_IMAGE_FIELD_VARIETY:
      return { ...state, error: null };
    case DELETE_IMAGE_FIELD_VARIETY_SUCCESS:
      fieldVarieties = state.fieldVarieties;
      newFieldVariety = getSingleEntity(action.data.fieldVarieties);
      fieldVarieties[newFieldVariety.id] = newFieldVariety;
      return {
        ...state,
        fieldVarieties: fieldVarieties
      };
    case DELETE_IMAGE_FIELD_VARIETY_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
