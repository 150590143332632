import React from "react";
import PropTypes from "prop-types";

import { dateStrToFriendlyFormat } from "../../../helpers";
import { CommentCard } from "../../../ui";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export const TrialHeader = ({ trial, hideList, color, ...props }) => (
  <Grid item xs={12}>
    <Card style={{ backgroundColor: color }}>
      <CardContent>
        <Typography variant="subtitle1">Trial {trial.name}</Typography>
        {!hideList["grower"] && (
          <Typography variant="body1">
            Grower: {trial.grower && trial.grower.name}
          </Typography>
        )}
        {!hideList["shipper"] && (
          <Typography variant="body1">
            Shipper: {trial.shipper && trial.shipper.name}
          </Typography>
        )}
        <Typography variant="body1">
          Region: {trial.region && trial.region.name}
        </Typography>
        <Typography variant="body1">
          City: {trial.area && trial.area.name}
        </Typography>
        <Typography variant="body1">
          Commodity: {trial.commodity && trial.commodity.name}
        </Typography>
        <Typography variant="body1">
          Cultural Practice: {trial.culturalPractice}
        </Typography>
        {!hideList["ranch"] && (
          <Typography variant="body1">
            Ranch: {trial.ranch && trial.ranch.name}
          </Typography>
        )}
        <Typography variant="body1">Lot: {trial.lot}</Typography>
        <Typography variant="body1">
          Wet Date: {trial.wetDate && dateStrToFriendlyFormat(trial.wetDate)}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export const SupplierCard = ({
  varietyName,
  hideList,
  maturityDate,
  supplierName,
  ...props
}) => (
  <Grid item xs={12}>
    <Card style={{ backgroundColor: props.color }}>
      <CardContent>
        <Typography variant="body1">Variety: {varietyName}</Typography>
        {!hideList["supplier"] && (
          <Typography variant="body1">Supplier: {supplierName}</Typography>
        )}
        <Typography
          variant="body1"
          style={maturityDate ? {} : { color: "red" }}
        >
          Maturity Date: {maturityDate && dateStrToFriendlyFormat(maturityDate)}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);
SupplierCard.propTypes = {
  hideList: PropTypes.object,
  maturityDate: PropTypes.string,
  supplierName: PropTypes.string
};

export const ReportCommentCards = ({
  color,
  comments,
  growerComments,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={12}>
      <CommentCard color={color} title={"Comments"} comments={comments} />
    </Grid>
    <Grid item xs={12}>
      <CommentCard
        color={color}
        title={"Grower Comments"}
        comments={growerComments}
      />
    </Grid>
  </React.Fragment>
);
ReportCommentCards.propTypes = {
  comments: PropTypes.string,
  growerComments: PropTypes.string
};

