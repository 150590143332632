import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  LOGOUT_USER,
  SET_TOKEN,
  CLEAR_TOKEN,
  SEND_PASSWORD_RESET_TOKEN,
  SEND_PASSWORD_RESET_TOKEN_SUCCESS,
  SEND_PASSWORD_RESET_TOKEN_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  FETCH_FILE_TOKEN,
  FETCH_FILE_TOKEN_SUCCESS,
  FETCH_FILE_TOKEN_FAILURE,
  UPDATE_LIVE_LOCATION
} from "../actions/users";

const INITIAL_STATE = {
  userId: null,
  token: null,
  admin: false,
  email: null,
  name: null,
  expiresAt: null,
  authenticated: false,
  error: null,
  loading: false,
  message: null,
  validatedToken: false,
  fileToken: null,
  liveLocation: {
    lat: null,
    lng: null,
    accuracy: 1000
  }
};

export default function(state = INITIAL_STATE, action) {
  const response = action.data;
  switch (action.type) {
    case SIGNIN_USER:
      return {
        ...state,
        userId: null,
        token: null,
        admin: false,
        email: null,
        name: null,
        expiresAt: null,
        authenticated: false,
        error: null,
        loading: true
      };
    case RESET_PASSWORD_SUCCESS:
    case SIGNIN_USER_SUCCESS:
      localStorage.setItem("jwtToken", JSON.stringify(response));
      return {
        ...state,
        userId: response.userId,
        token: response.idToken,
        admin: response.admin,
        email: response.email,
        name: response.name,
        expiresAt: response.expiresAt,
        authenticated: true,
        error: null,
        loading: false
      };
    case SIGNIN_USER_FAILURE:
      return {
        ...state,
        userId: null,
        token: null,
        admin: false,
        expiresAt: null,
        authenticated: false,
        error: action.error,
        loading: false
      };
    case LOGOUT_USER:
      return {
        ...state,
        userId: null,
        token: null,
        admin: false,
        email: null,
        name: null,
        expiresAt: null,
        authenticated: false,
        error: null,
        loading: false
      };
    case SET_TOKEN: // loading currentUser("me") from jwttoken in local/session storage storage,
      return {
        ...state,
        userId: action.payload.userId,
        token: action.payload.idToken,
        admin: action.payload.admin,
        email: action.payload.email,
        name: action.payload.name,
        expiresAt: action.payload.expiresAt,
        authenticated: true,
        error: null,
        loading: false
      };
    case SEND_PASSWORD_RESET_TOKEN:
      return {
        ...state,
        userId: null,
        token: null,
        expiresAt: null,
        authenticated: false,
        error: null,
        loading: true,
        message: null
      };
    case SEND_PASSWORD_RESET_TOKEN_SUCCESS:
      return { ...state, error: null, loading: false, message: action.message };
    case SEND_PASSWORD_RESET_TOKEN_FAILURE:
      return { ...state, error: action.error, loading: false, message: null };
    case RESET_PASSWORD_FAILURE:
      return { ...state, error: action.error, loading: false, message: null };
    case CLEAR_TOKEN:
      return {
        ...state,
        token: null,
        admin: false,
        email: null,
        name: null,
        error: "Session Expired",
        loading: false,
        authenticated: false,
        fileToken: null
      };
    case FETCH_FILE_TOKEN:
      return { ...state, fileToken: null, loading: true };
    case FETCH_FILE_TOKEN_SUCCESS:
      return { ...state, fileToken: action.data.fileToken, loading: false };
    case FETCH_FILE_TOKEN_FAILURE:
      return {
        ...state,
        error: "Authentication failed, was not able to download report",
        fileToken: null,
        loading: false
      };

    case UPDATE_LIVE_LOCATION:
      const lat = action.lat ? parseFloat(action.lat) : null;
      const lng = action.lng ? parseFloat(action.lng) : null;
      return {
        ...state,
        liveLocation: {
          lat: lat,
          lng: lng,
          accuracy: action.accuracy || 1000
        }
      };
    default:
      return state;
  }
}
