import React from "react";

import { dateStrToFriendlyFormat } from "../../../helpers";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const ColumnHeader = ({
  variety,
  plotNumber,
  supplier,
  record,
  ...props
}) => (
  <Grid item xs={12}>
    <Typography variant="subtitle1">{variety.name}</Typography>
    <Typography variant="body1">{plotNumber || "FV"}</Typography>
    <Typography variant="body1">{supplier && supplier.name}</Typography>
    <Typography variant="body1">
      Maturity Date: {dateStrToFriendlyFormat(record.maturityDate)}
    </Typography>
  </Grid>
);
