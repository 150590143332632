import {
  SEARCH_ALGOLIA_TRIALS,
  SEARCH_ALGOLIA_TRIALS_SUCCESS,
  SEARCH_ALGOLIA_TRIALS_FAILURE,
  SEARCH_MORE_ALGOLIA_TRIALS,
  SEARCH_MORE_ALGOLIA_TRIALS_SUCCESS,
  SEARCH_MORE_ALGOLIA_TRIALS_FAILURE,
  SEARCH_ALGOLIA_VARIETIES,
  SEARCH_ALGOLIA_VARIETIES_SUCCESS,
  SEARCH_ALGOLIA_VARIETIES_FAILURE,
  SEARCH_ALGOLIA_COMPANIES,
  SEARCH_ALGOLIA_COMPANIES_SUCCESS,
  SEARCH_ALGOLIA_COMPANIES_FAILURE
} from "../actions/algolia";
import { concat } from "lodash";

const INITIAL_STATE = {
  error: null,
  waiting: false,
  varieties: [],
  companies: [],
  trials: [],
  page: 0,
  pages: 0,
  totalHits: 0
};

export default function(state = INITIAL_STATE, action) {
  let trials;
  switch (action.type) {
    case SEARCH_ALGOLIA_TRIALS:
      return { ...state, waiting: true, error: null };
    case SEARCH_ALGOLIA_TRIALS_SUCCESS:
      trials = action.data.hits;
      return {
        ...state,
        trials: trials,
        totalHits: action.data.nbHits,
        page: 0,
        pages: action.data.nbPages,
        waiting: false
      };
    case SEARCH_ALGOLIA_TRIALS_FAILURE:
      return { ...state, waiting: false, error: action.error };
    case SEARCH_MORE_ALGOLIA_TRIALS:
      return { ...state, waiting: true, error: null };
    case SEARCH_MORE_ALGOLIA_TRIALS_SUCCESS:
      trials = concat(state.trials, action.data.hits);
      return {
        ...state,
        waiting: false,
        page: action.data.page,
        trials: trials,
        pages: action.data.nbPages,
        totalHits: action.data.nbHits
      };
    case SEARCH_MORE_ALGOLIA_TRIALS_FAILURE:
      return { ...state, waiting: false, error: null };
    case SEARCH_ALGOLIA_VARIETIES:
      return {
        ...state,
        waiting: true
      };
    case SEARCH_ALGOLIA_VARIETIES_SUCCESS:
      const varieties = action.data.hits;
      return {
        ...state,
        varieties: varieties,
        waiting: false
      };
    case SEARCH_ALGOLIA_VARIETIES_FAILURE:
      return {
        ...state,
        waiting: false
      };
    case SEARCH_ALGOLIA_COMPANIES:
      return { ...state, waiting: true, error: null };
    case SEARCH_ALGOLIA_COMPANIES_SUCCESS:
      const companies = action.data.hits;
      return { ...state, companies: companies, waiting: false };
    case SEARCH_ALGOLIA_COMPANIES_FAILURE:
      return { ...state, waiting: false };
    default:
      return state;
  }
}
