import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { View } from "./views";
import { LoadingDrawerDisplay } from "../../ui";

class EmailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [] //people
    };
  }

  sendEmail = () => {
    // all people not checked
    const { people } = this.props.people;
    const { checked } = this.state;
    const emailList = Object.values(people)
      .filter(person => checked.indexOf(person.id) === -1)
      .map(person => person.id);
    this.props.sendEmail(emailList);
  };

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      checked: newChecked
    });
  };

  render() {
    const { people, waiting } = this.props.people;
    if (waiting) {
      return (
        <LoadingDrawerDisplay
          showDrawer={this.props.showDrawer}
          closeDrawer={() => this.props.toggleDrawer(false)}
        />
      );
    }
    return (
      <View
        handleToggle={this.handleToggle}
        showDrawer={this.props.showDrawer}
        toggleDrawer={this.props.toggleDrawer}
        checked={this.state.checked}
        listOfPeople={Object.values(people)}
        sendEmail={this.sendEmail}
        sendOnDateUpdateTrial={this.props.sendOnDateUpdateTrial}
      />
    );
  }
}

EmailDrawer.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  sendOnDateUpdateTrial: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    people: state.people
  };
};

export default connect(
  mapStateToProps,
  null
)(EmailDrawer);
