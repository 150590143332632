import {
  CLEAR_VARIETY,
  UPDATE_VARIETY_LOCALLY,
  UPDATE_VARIETY_SEARCH,
  FETCH_VARIETIES,
  FETCH_VARIETIES_SUCCESS,
  FETCH_VARIETIES_FAILURE,
  CREATE_VARIETY,
  CREATE_VARIETY_SUCCESS,
  CREATE_VARIETY_FAILURE,
  FETCH_VARIETY,
  FETCH_VARIETY_SUCCESS,
  FETCH_VARIETY_FAILURE,
  UPDATE_VARIETY,
  UPDATE_VARIETY_SUCCESS,
  UPDATE_VARIETY_FAILURE,
  DELETE_VARIETY,
  DELETE_VARIETY_SUCCESS,
  DELETE_VARIETY_FAILURE,
  COMBINE_VARIETY,
  COMBINE_VARIETY_SUCCESS,
  COMBINE_VARIETY_FAILURE,
  REMOVE_VARIETY,
  REMOVE_VARIETY_SUCCESS,
  REMOVE_VARIETY_FAILURE
} from "../actions/varieties";
import {
  CREATE_TRIAL_SUCCESS,
  FETCH_TRIAL_SUCCESS,
  UPDATE_TRIAL_SUCCESS
} from "../actions/trials";
import {
  CREATE_NURSERY_SUCCESS,
  FETCH_NURSERY_SUCCESS,
  UPDATE_NURSERY_SUCCESS
} from "../actions/nurseries";
import {
  CREATE_FIELD_VARIETY_SUCCESS,
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  varieties: {},
  variety: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1,
  search: "",
  combineWaiting: false
};

export default function(state = INITIAL_STATE, action) {
  let variety, varieties;
  switch (action.type) {
    case CLEAR_VARIETY:
      return { ...state, variety: {} };
    case UPDATE_VARIETY_SEARCH:
      let search = action.value || "";
      return { ...state, search: search };
    case UPDATE_VARIETY_LOCALLY:
      variety = state.variety;
      variety[action.key] = action.value;
      return { ...state, variety: variety };
    case CREATE_NURSERY_SUCCESS:
    case CREATE_TRIAL_SUCCESS:
    case FETCH_NURSERY_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case CREATE_FIELD_VARIETY_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case UPDATE_NURSERY_SUCCESS:
    case UPDATE_TRIAL_SUCCESS:
      varieties = combine(state.varieties, action.data.varieties);
      return { ...state, varieties: varieties };
    case FETCH_VARIETIES:
      return { ...state, waiting: true, error: null, varieties: {} };
    case FETCH_VARIETIES_SUCCESS:
      return {
        ...state,
        varieties: action.data.varieties || {},
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_VARIETIES_FAILURE:
      return { ...state, waiting: false, error: action.error };
    case FETCH_VARIETY:
      return { ...state, error: null, waiting: true };
    case FETCH_VARIETY_SUCCESS:
      variety = getSingleEntity(action.data.varieties);
      return { ...state, variety: variety, error: null, waiting: false };
    case FETCH_VARIETY_FAILURE:
      return { ...state, variety: {}, error: action.error, waiting: false };
    case CREATE_VARIETY:
      return { ...state, error: action.error, waiting: true };
    case CREATE_VARIETY_SUCCESS:
      variety = getSingleEntity(action.data.varieties);
      varieties = state.varieties;
      varieties[variety.id] = variety;
      return {
        ...state,
        varieties: varieties,
        variety: variety,
        error: null,
        waiting: false
      };
    case CREATE_VARIETY_FAILURE:
      return { ...state, error: action.error, waiting: false };

    case UPDATE_VARIETY:
      return { ...state, error: null, waiting: true };
    case UPDATE_VARIETY_SUCCESS:
      variety = getSingleEntity(action.data.varieties);
      return { ...state, variety: variety, error: null, waiting: false };
    case UPDATE_VARIETY_FAILURE:
      if (action.status === 412) {
        variety = getSingleEntity(action.data.varieties);
        return {
          ...state,
          error: action.error,
          variety: variety,
          waiting: false
        };
      }
      return { ...state, error: action.error, waiting: false };
    case COMBINE_VARIETY:
      return { ...state, error: null, combineWaiting: true };
    case COMBINE_VARIETY_SUCCESS:
      variety = getSingleEntity(action.data.varieties);
      return { ...state, variety: variety, error: null, combineWaiting: false };
    case COMBINE_VARIETY_FAILURE:
      return { ...state, error: action.error, combineWaiting: false };
    case REMOVE_VARIETY:
      return { ...state, error: null };
    case REMOVE_VARIETY_SUCCESS:
      variety = state.variety;
      let removedVariety = getSingleEntity(action.data.varieties);
      variety.experimentalVarieties = variety.experimentalVarieties.filter(
        v => {
          return v.id !== removedVariety.id;
        }
      );
      return { ...state, variety: variety, error: null };
    case REMOVE_VARIETY_FAILURE:
      return { ...state, error: action.error };
    case DELETE_VARIETY:
      return { ...state, error: null, waiting: true };
    case DELETE_VARIETY_SUCCESS:
      variety = state.variety;
      if (variety.id === parseInt(action.data.id)) {
        variety = {};
      }
      varieties = state.varieties;
      delete varieties[action.data.id];
      return {
        ...state,
        varieties: varieties,
        variety: variety,
        error: null,
        waiting: false
      };
    case DELETE_VARIETY_FAILURE:
      return { ...state, error: action.error, waiting: false };

    default:
      return state;
  }
}
