import {
  FETCH_EVALUATIONS,
  FETCH_EVALUATIONS_SUCCESS,
  FETCH_EVALUATIONS_FAILURE,
  FETCH_EVALUATIONS_PAGINATION,
  FETCH_EVALUATIONS_PAGINATION_SUCCESS,
  FETCH_EVALUATIONS_PAGINATION_FAILURE,
  SAVE_EVALUATIONS,
  SAVE_EVALUATIONS_SUCCESS,
  SAVE_EVALUATIONS_FAILURE,
  UPDATE_EVALUATION_LOCALLY
} from "../actions/evaluations";
import {
  CREATE_TRIAL_SUCCESS,
  FETCH_TRIAL_SUCCESS,
  UPDATE_TRIAL_SUCCESS
} from "../actions/trials";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import { combine } from "./util";

const INITIAL_STATE = {
  evaluations: {},
  error: null,
  waiting: false
};

export default function(state = INITIAL_STATE, action) {
  let evaluations, evaluation;
  switch (action.type) {
    case CREATE_TRIAL_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case UPDATE_TRIAL_SUCCESS:
      evaluations = combine(state.evaluations, action.data.evaluations);
      return { ...state, evaluations: evaluations };
    case FETCH_EVALUATIONS_FAILURE:
    case FETCH_EVALUATIONS_PAGINATION_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_EVALUATIONS:
    case FETCH_EVALUATIONS_PAGINATION:
    case SAVE_EVALUATIONS:
      return { ...state, error: null, waiting: true };
    case FETCH_EVALUATIONS_SUCCESS:
      evaluations = combine(state.evaluations, action.data.evaluations);
      return {
        ...state,
        evaluations: evaluations,
        waiting: false
      };
    case SAVE_EVALUATIONS_SUCCESS:
      evaluations = combine(state.evaluations, action.data.evaluations);
      return {
        ...state,
        evaluations: evaluations,
        error: null,
        waiting: false
      };
    case FETCH_EVALUATIONS_PAGINATION_SUCCESS:
      evaluations = combine(state.evaluations, action.data.evaluations);
      let waiting = false;
      if (action.meta.totalPages > action.meta.currentPage) {
        waiting = true;
      }
      return {
        ...state,
        evaluations: evaluations,
        waiting: waiting
      };
    case SAVE_EVALUATIONS_FAILURE:
      if (action.status === 412) {
        evaluations = combine(state.evaluations, action.data.evaluations);
        return {
          ...state,
          error: action.error,
          waiting: false,
          evaluations: evaluations
        };
      }
      return { ...state, error: action.error, waiting: false };

    case UPDATE_EVALUATION_LOCALLY:
      evaluations = state.evaluations;
      evaluation = evaluations[action.id] || {};
      Object.keys(action.data || {}).forEach(key => {
        evaluation[key] = action.data[key];
      });
      evaluations[action.id] = evaluation;

      return { ...state, evaluations: evaluations };
    default:
      return state;
  }
}
