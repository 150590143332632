import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deletePersonalView,
  createPersonalView,
  setLocalPersonalView
} from "../../../actions/personal_views";
import { PersonalViewEditable, Connection } from "./views";
import { Grid } from "@material-ui/core";

class PersonalViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreate: false
    };
  }
  onCreate = () => {
    const { profile } = this.props.profiles;
    const { personalView } = this.props.personalViews;
    const companyId = personalView.company ? personalView.company.id : null;
    const commodityId = personalView.commodity
      ? personalView.commodity.id
      : null;
    const regionId = personalView.region ? personalView.region.id : null;
    this.props.createPersonalView({
      userId: profile.id,
      companyId: companyId,
      commodityId: commodityId,
      regionId: regionId
    });
    this.setState({ openCreate: false });
  };
  onOpen = () => {
    this.setState({ openCreate: true });
  };

  onDelete = personalView => () => {
    this.props.deletePersonalView(personalView);
  };

  setRecord = key => value => {
    this.props.setLocalPersonalView(key, value);
  };

  render() {
    const { openCreate } = this.state;
    const { personalViews, personalView, waiting } = this.props.personalViews;
    return (
      <Grid container spacing={2}>
        <PersonalViewEditable
          personalViews={Object.values(personalViews)}
          onDelete={this.onDelete}
        />
        <Connection
          waiting={waiting}
          onCreate={this.onCreate}
          openCreate={openCreate}
          onOpen={this.onOpen}
          personalView={personalView}
          setRecord={this.setRecord}
        />
      </Grid>
    );
  }
}

const mapStatetoProps = ({ profiles, personalViews }) => ({
  profiles,
  personalViews
});
export default connect(mapStatetoProps, {
  deletePersonalView,
  createPersonalView,
  setLocalPersonalView
})(PersonalViews);
