import React from "react";
import PropTypes from "prop-types";
import Evaluation from "../Evaluation";
import ConnectorFieldVariety from "../ConnectorFieldVariety";
import FieldVarietyChip from "../FieldVarietyChip";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const FieldVarietyChips = ({
  fieldVarieties,
  openEvaluation,
  ...props
}) => (
  <Grid item xs={12} sm={6}>
    {fieldVarieties.map(fieldVariety => (
      <FieldVarietyChip
        key={fieldVariety.id}
        openEvaluation={openEvaluation}
        fieldVariety={fieldVariety}
      />
    ))}
  </Grid>
);

FieldVarietyChips.propTypes = {
  fieldVarieties: PropTypes.array.isRequired,
  openEvaluation: PropTypes.func.isRequired
};

export const EvaluationWrapper = ({
  selectedFieldVariety,
  showEvaluation,
  openEvaluation,
  ...props
}) => (
  <Grid item xs={12} style={{ marginBottom: 5 }}>
    {showEvaluation && (
      <Evaluation
        type={"fieldVarieties"}
        openEvaluation={openEvaluation}
        recordId={selectedFieldVariety.id}
      >
        <Typography variant="h6">Field Variety Evaluation</Typography>
        <ConnectorFieldVariety fieldVariety={selectedFieldVariety} />
      </Evaluation>
    )}
  </Grid>
);

EvaluationWrapper.propTypes = {
  selectedFieldVariety: PropTypes.object.isRequired,
  openEvaluation: PropTypes.func.isRequired,
  showEvaluation: PropTypes.bool.isRequired
};

