import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3)
  }
});

export const View = withStyles(styles)(
  ({
    listOfPeople,
    showDrawer,
    toggleDrawer,
    handleToggle,
    sendEmail,
    sendOnDateUpdateTrial,
    ...props
  }) => (
    <Drawer
      anchor="bottom"
      open={showDrawer}
      onClose={() => toggleDrawer(false)}
    >
      <FormControl component="fieldset" className={props.classes.formControl}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                {listOfPeople.map(person => (
                  <TableRow key={person.id}>
                    <TableCell align={"left"}>
                      <Checkbox
                        checked={props.checked.indexOf(person.id) === -1}
                        tabIndex={-1}
                        disableRipple
                        onClick={handleToggle(person.id)}
                      />
                    </TableCell>
                    <TableCell>{person.name}</TableCell>
                    <TableCell>{person.email}</TableCell>
                    <TableCell>
                      {person.companies.map(company => company.name).join(", ")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl component="fieldset" className={props.classes.formControl}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  size="large"
                  variant="contained"
                  color="default"
                  onClick={() => toggleDrawer(false)}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={sendEmail}
                >
                  Send
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={sendOnDateUpdateTrial}
                >
                  Auto
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </Drawer>
  )
);

View.propsTypes = {
  listOfPeople: PropTypes.array.isRequired,
  sendEmail: PropTypes.func.isRequired,
  sendOnDateUpdateTrial: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired
};
