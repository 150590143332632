import React from "react";
import PropTypes from "prop-types";
import DeleteConfirmation from "../../DeleteConfirmation";
import { RegionSelect } from "../../regions";
import { CommoditySelect } from "../../commodities";
import { CompanySelect } from "../../companies";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button size="medium" variant="contained" onClick={confirmDelete} fullWidth>
    Delete
  </Button>
);

const styles = theme => ({
  selectWrapper: {
    marginTop: 5,
    marginBottom: 5
  }
});

export const PersonalViewEditable = withStyles(styles)(
  ({ personalViews, onDelete, ...props }) => (
    <React.Fragment>
      {personalViews.map(personalView => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={personalView.key}>
          <Card>
            <CardContent>
              <TextField
                label="Company"
                value={personalView.company ? personalView.company.name : ""}
                variant="outlined"
                fullWidth
                disabled
                className={props.classes.selectWrapper}
              />
              <TextField
                label="Commodity"
                value={
                  personalView.commodity ? personalView.commodity.name : ""
                }
                variant="outlined"
                fullWidth
                disabled
                className={props.classes.selectWrapper}
              />
              <TextField
                label="Region"
                value={personalView.region ? personalView.region.name : ""}
                variant="outlined"
                fullWidth
                disabled
                className={props.classes.selectWrapper}
              />
            </CardContent>
            <CardActions>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={8} md={5} lg={3}>
                  <DeleteConfirmation
                    onDelete={onDelete(personalView)}
                    key={personalView.key}
                  >
                    <DeleteButton {...props} />
                  </DeleteConfirmation>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </React.Fragment>
  )
);

export const Connection = withStyles(styles)(
  ({
    openCreate,
    onOpen,
    onCreate,
    personalView,
    setRecord,
    waiting,
    ...props
  }) => (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      {openCreate ? (
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} className={props.classes.selectWrapper}>
                <CompanySelect
                  company={personalView.company}
                  setCompany={setRecord("company")}
                />
              </Grid>
              <Grid item xs={12} className={props.classes.selectWrapper}>
                <CommoditySelect
                  commodity={personalView.commodity}
                  setCommodity={setRecord("commodity")}
                />
              </Grid>
              <Grid item xs={12} className={props.classes.selectWrapper}>
                <RegionSelect
                  region={personalView.region}
                  setRegion={setRecord("region")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12} sm={8} md={5} lg={3}>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={onCreate}
                  color="primary"
                  disabled={waiting}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <Button fullWidth onClick={onOpen} color="primary">
              Add New Connection
            </Button>
          </CardContent>
        </Card>
      )}
    </Grid>
  )
);

Connection.propTypes = {
  openCreate: PropTypes.bool.isRequired,
  waiting: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  setRecord: PropTypes.func.isRequired,
  personalView: PropTypes.object.isRequired
};
