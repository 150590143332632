import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPlotBedNumbers,
  createPlotBedLocation,
  filterPlotBedsByTrial
} from "../../../helpers";
import { PlotGridLayout } from "../../../ui";

class PlotGrid extends Component {
  constructor(props) {
    super(props);
    this.iconColor = this.iconColor.bind(this);
  }

  iconColor = locationId => {
    const { selected } = this.props.plotBeds;
    return selected[locationId] ? "selected" : "unselected";
  };

  setupPlots = () => {
    const { trial } = this.props.trials;
    const { trialVarieties } = this.props.trialVarieties;
    const { plotBeds } = this.props.plotBeds;
    const filteredPlotBeds = filterPlotBedsByTrial(
      trial,
      trialVarieties,
      plotBeds
    );
    const plotNumbers = getPlotBedNumbers(
      filteredPlotBeds,
      trial.plotType,
      trial.plotRows,
      trial.plotCols
    );
    const rows = [];
    for (
      let rowIndex = parseInt(trial.plotRows) - 1;
      rowIndex >= 0;
      rowIndex--
    ) {
      const cols = [];
      for (let colIndex = 0; colIndex < parseInt(trial.plotCols); colIndex++) {
        const locationId = createPlotBedLocation(rowIndex, colIndex);
        const plotNumber = plotNumbers[locationId];

        cols.push({
          key: locationId,
          rowIndex: rowIndex,
          colIndex: colIndex,
          color: this.iconColor(locationId),
          plotNumber: plotNumber
        });
      }
      rows.push(cols);
    }
    return rows;
  };

  render() {
    const rows = this.setupPlots();
    return (
      <PlotGridLayout rows={rows} selectPlotBed={this.props.selectPlotBed} />
    );
  }
}

PlotGrid.propTypes = {
  selectPlotBed: PropTypes.func.isRequired
};

const mapStateToProps = ({ plotBeds, trialVarieties, trials }) => ({
  plotBeds,
  trialVarieties,
  trials
});
export default connect(mapStateToProps, {})(PlotGrid);
