import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40
  }
});

export const IndexNewFieldDisplay = withStyles(styles)(
  ({ value, handleChange, onSave, label, ...props }) => (
    <Grid container spacing={2} className={props.classes.container}>
      <Grid item xs={8}>
        <TextField
          fullWidth
          value={value}
          onChange={handleChange}
          label={label}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            <CreateIcon />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
);

IndexNewFieldDisplay.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  label: PropTypes.string
};
