import {
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
  UPDATE_TAG_LOCALLY,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE
} from "../actions/tags";
import { FETCH_LABELS_SUCCESS, FETCH_LABEL_SUCCESS } from "../actions/labels";
import {
  CREATE_COMMODITY_SUCCESS,
  COMMODITY_ADD_LABEL_SUCCESS,
  COMMODITY_ADD_TAG_SUCCESS,
  COMMODITY_REMOVE_LABEL_SUCCESS,
  COMMODITY_REMOVE_TAG_SUCCESS,
  FETCH_COMMODITIES_DETAILS_SUCCESS,
  FETCH_COMMODITY_SUCCESS,
  UPDATE_COMMODITY_SUCCESS
} from "../actions/commodities";
import { FETCH_TRIAL_SUCCESS } from "../actions/trials";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS
} from "../actions/field_varieties";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  tags: {},
  error: null,
  waiting: false
};

export default function(state = INITIAL_STATE, action) {
  let tags, tag;
  switch (action.type) {
    case CREATE_COMMODITY_SUCCESS:
    case COMMODITY_ADD_LABEL_SUCCESS:
    case COMMODITY_ADD_TAG_SUCCESS:
    case COMMODITY_REMOVE_LABEL_SUCCESS:
    case COMMODITY_REMOVE_TAG_SUCCESS:
    case FETCH_COMMODITY_SUCCESS:
    case FETCH_COMMODITIES_DETAILS_SUCCESS:
    case FETCH_LABELS_SUCCESS:
    case FETCH_LABEL_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case UPDATE_COMMODITY_SUCCESS:
      tags = combine(state.tags, action.data.tags);
      return { ...state, tags: tags };
    case FETCH_TAGS:
      return { ...state, error: null, waiting: true };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.data.tags || {},
        waiting: false
      };
    case FETCH_TAGS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case CREATE_TAG:
      return { ...state, error: null, waiting: true };
    case CREATE_TAG_SUCCESS:
      tags = state.tags;
      tag = getSingleEntity(action.data.tags);
      tags[tag.id] = tag;
      return { ...state, tags: tags, waiting: false };
    case CREATE_TAG_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_TAG:
      return { ...state, error: null, waiting: true };
    case UPDATE_TAG_SUCCESS:
      tags = state.tags;
      tag = getSingleEntity(action.data.tags);
      tags[tag.id] = tag;
      return { ...state, waiting: false, tags: tags };
    case UPDATE_TAG_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_TAG_LOCALLY:
      tags = state.tags;
      tag = tags[action.id] || {};
      tag[action.key] = action.value;
      tags[action.id] = tag;
      return { ...state, tags: tags };
    case DELETE_TAG:
      return { ...state, waiting: true, error: null };
    case DELETE_TAG_SUCCESS:
      tags = state.tags;
      delete tags[action.data.id];
      return { ...state, tags: tags, waiting: false };
    case DELETE_TAG_FAILURE:
      return { ...state, error: action.error, waiting: false };
    default:
      return state;
  }
}
