import LogRocket from "logrocket";

export default store => next => action => {
  const userStore = store.getState().user;
  if (userStore.authenticated) {
    LogRocket.identify(userStore.userId, {
      userId: userStore.userId,
      email: userStore.email
    });
  }
  return next(action);
};
