import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchVarietiesWithExpiermentalVarieties } from "../actions/varieties";
import { fetchFieldVarietiesByVarietyIds } from "../actions/field_varieties";
import { fetchTrialVarietiesByVarietyIds } from "../actions/trial_varieties";
import { fetchTrialsByVarietyIds } from "../actions/trials";
import { fetchEvaluationsByVarietyIds } from "../actions/evaluations";

import { Filter, ReportSingle } from "../containers/reports";
import { LoadingSpinner } from "../ui";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false
    };
    this.toggleFilterDrawer = this.toggleFilterDrawer.bind(this);
  }

  componentDidMount() {
    const varietyId = this.props.match.params.variety_id;
    this.props.fetchVarietiesWithExpiermentalVarieties([varietyId]);
    this.props.fetchTrialVarietiesByVarietyIds([varietyId]);
    this.props.fetchFieldVarietiesByVarietyIds([varietyId]);
    this.props.fetchTrialsByVarietyIds([varietyId]);
    this.props.fetchEvaluationsByVarietyIds([varietyId]);
  }

  toggleFilterDrawer = input => () => {
    this.setState({ ...this.state, showFilters: input });
  };

  render() {
    const { waiting } = this.props.varieties;
    const { waiting: trialWaiting } = this.props.trials;
    const { waiting: fieldVarietyWaiting } = this.props.fieldVarieties;
    const { waiting: trialVarietyWaiting } = this.props.trialVarieties;
    const { waiting: evaluationsWaiting } = this.props.evaluations;

    if (
      waiting ||
      trialWaiting ||
      fieldVarietyWaiting ||
      trialVarietyWaiting ||
      evaluationsWaiting
    ) {
      return <LoadingSpinner />;
    }
    const mainVarietyIds = [parseInt(this.props.match.params.variety_id)];

    return (
      <Filter
        show={this.state.showFilters}
        toggleFilterDrawer={this.toggleFilterDrawer}
        mainVarietyIds={mainVarietyIds}
      >
        <ReportSingle
          varietyId={this.props.match.params.variety_id}
          toggleFilterDrawer={this.toggleFilterDrawer}
        />
      </Filter>
    );
  }
}

const mapStateToProps = ({
  trials,
  evaluations,
  varieties,
  fieldVarieties,
  trialVarieties
}) => ({
  trials,
  evaluations,
  varieties,
  fieldVarieties,
  trialVarieties
});
export default connect(mapStateToProps, {
  fetchVarietiesWithExpiermentalVarieties,
  fetchFieldVarietiesByVarietyIds,
  fetchTrialVarietiesByVarietyIds,
  fetchTrialsByVarietyIds,
  fetchEvaluationsByVarietyIds
})(Report);
