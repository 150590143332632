import {
  FETCH_AREAS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREAS_FAILURE,
  FETCH_MORE_AREAS,
  FETCH_MORE_AREAS_SUCCESS,
  FETCH_MORE_AREAS_FAILURE,
  CREATE_AREA,
  CREATE_AREA_SUCCESS,
  CREATE_AREA_FAILURE,
  FETCH_AREA,
  FETCH_AREA_SUCCESS,
  FETCH_AREA_FAILURE,
  UPDATE_AREA,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAILURE,
  UPDATE_AREA_LOCALLY,
  DELETE_AREA,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_FAILURE,
  UPDATE_AREA_SEARCH
} from "../actions/areas";
import { getSingleEntity } from "./util";

const INITIAL_STATE = {
  areas: {},
  area: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1,
  search: ""
};

export default function(state = INITIAL_STATE, action) {
  let area, areas;
  switch (action.type) {
    case UPDATE_AREA_SEARCH:
      let search = action.value || "";
      return { ...state, search: search };
    case UPDATE_AREA_LOCALLY:
      area = state.area;
      area[action.key] = action.value;
      return { ...state, area: area };
    case FETCH_AREAS:
      return { ...state, error: null, waiting: true };
    case FETCH_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.data.areas || {},
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_AREAS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_MORE_AREAS:
      return { ...state, error: null, waiting: true };
    case FETCH_MORE_AREAS_SUCCESS:
      areas = state.areas;
      let newAreas = action.data.areas || {};
      Object.keys(newAreas).forEach(key => {
        areas[key] = newAreas[key];
      });
      return {
        ...state,
        waiting: false,
        areas: areas,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_MORE_AREAS_FAILURE:
      return { ...state, waiting: false, error: action.error };
    case FETCH_AREA:
      return { ...state, area: {}, error: null, waiting: true };
    case FETCH_AREA_SUCCESS:
      area = getSingleEntity(action.data.area);
      return { ...state, area: area || {}, error: null, waiting: false };
    case FETCH_AREA_FAILURE:
      return { ...state, area: {}, error: action.error, waiting: false };

    case CREATE_AREA:
      return { ...state, area: {}, error: null, waiting: true };
    case CREATE_AREA_SUCCESS:
      area = getSingleEntity(action.data.area);
      areas = state.areas;
      areas[area.id] = area;
      return {
        ...state,
        areas: areas,
        area: area,
        error: null,
        waiting: false
      };
    case CREATE_AREA_FAILURE:
      return { ...state, error: action.error, waiting: false };

    case UPDATE_AREA:
      return { ...state, error: null, waiting: true };
    case UPDATE_AREA_SUCCESS:
      area = getSingleEntity(action.data.area);
      return { ...state, area: area, error: null, waiting: false };
    case UPDATE_AREA_FAILURE:
      if (action.status === 412) {
        area = getSingleEntity(action.data.area);
        return {
          ...state,
          error: action.error,
          waiting: false,
          area: area
        };
      }
      return { ...state, error: action.error, waiting: false };
    case DELETE_AREA:
      return { ...state, waiting: true, error: null };
    case DELETE_AREA_SUCCESS:
      areas = state.areas;
      delete areas[action.data.id];
      return { ...state, areas: areas, waiting: false, error: null };
    case DELETE_AREA_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
