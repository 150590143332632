import {
  FETCH_COMPANY_CATEGORIES,
  FETCH_COMPANY_CATEGORIES_SUCCESS,
  FETCH_COMPANY_CATEGORIES_FAILURE
} from '../actions/company_categories';

const INITIAL_STATE = {
  companyCategories: {},
  error: null,
  waiting: false
}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case FETCH_COMPANY_CATEGORIES:
      return { ...state,
        companyCategories: {},
        error: null,
        waiting: true
      };
    case FETCH_COMPANY_CATEGORIES_SUCCESS:
      return { ...state,
        companyCategories: action.data.companyCategory || {},
        error: null,
        waiting: false
      };
    case FETCH_COMPANY_CATEGORIES_FAILURE:
      return { ...state,
        companyCategories: {},
        error: action.error,
        waiting: false
      };
    default:
      return state;
  }
}