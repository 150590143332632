import React, { Component } from "react";
import { connect } from "react-redux";
import { LoadingSpinner } from "../../../ui";
import {
  commodityRemoveLabel,
  commodityToggleTagLocally,
  commodityRemoveTag,
  commodityAddTag
} from "../../../actions/commodities";
import { LabelCard } from "./views";

class CommodityLabelTagSelectors extends Component {
  removeLabel = () => {
    const { commodity } = this.props.commodities;
    this.props.commodityRemoveLabel(commodity.id, this.props.labelId);
  };
  toggleTag = tagId => () => {
    const { commodity } = this.props.commodities;
    if (commodity.tags.includes(tagId)) {
      this.props.commodityRemoveTag(commodity.id, tagId);
    } else {
      this.props.commodityAddTag(commodity.id, tagId);
    }
    this.props.commodityToggleTagLocally(commodity.id, tagId);
  };
  sortLabelTags = label => {
    const { tags } = this.props.tags;
    if (label.tags.length === 0) {
      return [];
    }
    return label.tags
      .map(tagId => tags[tagId] || {})
      .sort((tagA, tagB) => tagA.location - tagB.location);
  };
  render() {
    const { labels, waiting } = this.props.labels;

    if (waiting) {
      return <LoadingSpinner />;
    }

    const label = labels[this.props.labelId] || {};
    const { commodity } = this.props.commodities;
    const tags = this.sortLabelTags(label);
    return (
      <LabelCard
        commodityTags={commodity.tags}
        label={label}
        removeLabel={this.removeLabel}
        tags={tags}
        toggleTag={this.toggleTag}
      />
    );
  }
}

CommodityLabelTagSelectors.propTypes = {
  //labelId: PropTypes.number.isRequired
};

const mapStateToProps = ({ commodities, labels, tags }) => ({
  commodities,
  labels,
  tags
});

export default connect(mapStateToProps, {
  commodityRemoveLabel,
  commodityToggleTagLocally,
  commodityAddTag,
  commodityRemoveTag
})(CommodityLabelTagSelectors);
