import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "../containers";
import { CompanyEdit } from "../containers/companies";
import { LoadingSpinner } from "../ui";

import { fetchRegions } from "../actions/regions";
import { fetchAreas } from "../actions/areas";
import { fetchCompanyCategories } from "../actions/company_categories";
import { clearCompany } from "../actions/companies";
import { clearPeople } from "../actions/people";

import Grid from "@material-ui/core/Grid";

class CompanyNew extends Component {
  componentWillMount() {
    this.props.clearPeople();
    this.props.clearCompany();
    this.props.fetchRegions();
    this.props.fetchAreas();
    this.props.fetchCompanyCategories();
  }

  render() {
    const { areas, waiting: areaWaiting } = this.props.areas;
    const { regions, waiting: regionWaiting } = this.props.regions;
    const {
      companyCategories,
      waiting: companyCategoriesWaiting
    } = this.props.companyCategories;

    if (areaWaiting || regionWaiting || companyCategoriesWaiting) {
      return <LoadingSpinner />;
    }

    return (
      <Navbar currentPage="companies">
        <Grid container>
          <CompanyEdit
            areas={areas}
            regions={regions}
            companyCategories={companyCategories}
          />
        </Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ areas, regions, companies, companyCategories }) => ({
  areas,
  regions,
  companies,
  companyCategories
});

export default connect(
  mapStateToProps,
  {
    fetchAreas,
    fetchRegions,
    fetchCompanyCategories,
    clearCompany,
    clearPeople
  }
)(CompanyNew);
