import React from "react";
import PropTypes from "prop-types";
import { CircleIcon } from "../../../helpers";
import { PlotGrid } from "../../trials";
import { TrialReportHeader } from "../../../ui";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  dividerGrid: {
    marginBottom: 30,
    marginTop: 30
  },
  avatar: {
    marginRight: 4
  }
});

export const View = withStyles(styles)(
  ({
    trial,
    varieties,
    openEmailDrawerAction,
    downloadPDF,
    processingReport,
    children,
    ...props
  }) => (
    <Grid container spacing={2}>
      <TrialReportHeader
        title={"Plot Map"}
        trialName={trial.name}
        openEmailDrawerAction={openEmailDrawerAction}
        downloadPDF={downloadPDF}
        processingReport={processingReport}
      />
      {children}
      <Grid item xs={12} className={props.classes.dividerGrid}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography gutterBottom variant="h6">
          Varieties
        </Typography>
        <List>
          {varieties.map((variety, i) => (
            <ListItem key={i}>
              <Avatar className={props.classes.avatar}>
                <CircleIcon value={variety.plotNumber} />
              </Avatar>
              <ListItemText
                primary={variety.name}
                secondary={variety.supplier && variety.supplier.name}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PlotGrid selectPlotBed={() => {}} />
      </Grid>
    </Grid>
  )
);

View.propTypes = {
  trial: PropTypes.object.isRequired,
  varieties: PropTypes.array.isRequired,
  openEmailDrawerAction: PropTypes.func.isRequired,
  downloadPDF: PropTypes.func.isRequired
};
