import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAreas,
  deleteArea,
  fetchMoreAreas,
  createArea,
  updateSearch
} from "../../actions/areas";
import {
  ErrorPage,
  IndexHeaderButton,
  PageHeader,
  SimpleListName
} from "../../ui";
import IndexNewField from "../IndexNewField";

class AreasList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNew: false
    };
  }

  componentWillMount() {
    const { search } = this.props.areas;
    this.props.fetchAreas(search);
  }

  showMoreResults = () => {
    const { search } = this.props.areas;
    this.props.fetchMoreAreas(search);
  };

  toggleNew = () => {
    this.setState({ ...this.state, showNew: !this.state.showNew });
  };

  handleChange = e => {
    this.props.updateSearch(e.target.value);
    this.props.fetchAreas(e.target.value);
  };

  onSave = str => {
    this.props.createArea(str);
  };

  render() {
    const {
      areas,
      waiting,
      page,
      totalPages,
      error,
      search
    } = this.props.areas;
    if (error) {
      return <ErrorPage msg={error} />;
    }
    return (
      <React.Fragment>
        <PageHeader title={"Areas"} half={6}>
          <IndexHeaderButton create={this.toggleNew} />
        </PageHeader>
        {this.state.showNew && (
          <IndexNewField onSave={this.onSave} label={"Create Area"} />
        )}
        <SimpleListName
          waiting={waiting}
          baseUrl={"areas"}
          searchField={search}
          handleChange={this.handleChange}
          showMoreResults={this.showMoreResults}
          onDelete={this.props.deleteArea}
          rows={areas}
          page={page}
          totalPages={totalPages}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ areas }) => ({ areas });

export default connect(
  mapStateToProps,
  {
    fetchAreas,
    deleteArea,
    fetchMoreAreas,
    createArea,
    updateSearch
  }
)(AreasList);
