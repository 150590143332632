import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchRegions,
  deleteRegion,
  fetchMoreRegions,
  createRegion,
  updateSearch
} from "../../actions/regions";
import {
  ErrorPage,
  IndexHeaderButton,
  PageHeader,
  SimpleListName
} from "../../ui";
import IndexNewField from "../IndexNewField";

class RegionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNew: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.showMoreResults = this.showMoreResults.bind(this);
    this.toggleNew = this.toggleNew.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillMount() {
    const { search } = this.props.regions;
    this.props.fetchRegions(search);
  }

  showMoreResults = () => {
    const { search } = this.props.regions;
    this.props.fetchMoreRegions(search);
  };

  handleChange = e => {
    this.props.updateSearch(e.target.value);
    this.props.fetchRegions(e.target.value);
  };

  toggleNew = () => {
    this.setState({ ...this.state, showNew: !this.state.showNew });
  };

  onSave = str => {
    this.props.createRegion(str);
  };

  render() {
    const {
      regions,
      waiting,
      page,
      totalPages,
      error,
      search
    } = this.props.regions;
    if (error) {
      return <ErrorPage msg={error} />;
    }
    return (
      <React.Fragment>
        <PageHeader title={"Regions"} half={6}>
          <IndexHeaderButton create={this.toggleNew} />
        </PageHeader>
        {this.state.showNew && (
          <IndexNewField onSave={this.onSave} label={"Create Region"} />
        )}
        <SimpleListName
          waiting={waiting}
          baseUrl={"regions"}
          searchField={search}
          handleChange={this.handleChange}
          showMoreResults={this.showMoreResults}
          onDelete={this.props.deleteRegion}
          rows={regions}
          page={page}
          totalPages={totalPages}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    regions: state.regions
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRegions,
    deleteRegion,
    fetchMoreRegions,
    createRegion,
    updateSearch
  }
)(RegionsList);
