import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "../containers";
import { ProfileEdit } from "../containers/profiles";
import { clearProfile } from "../actions/profiles";

class ProfileNew extends Component {
  componentDidMount() {
    this.props.clearProfile();
  }

  render() {
    return (
      <Navbar currentPage="users">
        <ProfileEdit update={false} />
      </Navbar>
    );
  }
}
export default connect(
  null,
  {
    clearProfile
  }
)(ProfileNew);
