import React from "react";
import PropTypes from "prop-types";

import DeleteConfirmation from "../../DeleteConfirmation";
import CriteriumDragAndDropArea from "../CriteriumDragAndDropArea";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button
    size="medium"
    variant="contained"
    onClick={confirmDelete}
    className={props.classes.addSubCriterium}
  >
    <DeleteIcon />
  </Button>
);

const styles = theme => ({
  criteriumName: {
    marginBottom: 15
  },
  cardContainer: {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
    marginLeft: 10
  },
  addSubCriterium: {
    marginLeft: 6,
    marginBottom: 5
  },
  subCriteriaRow: {
    marginTop: 15,
    marginBottom: 5
  },
  subCriteriaItem: {
    marginRight: 10,
    marginLeft: 30
  }
});

export const View = withStyles(styles)(
  ({ criterium, handleChange, onDelete, addSubCriterium, ...props }) => (
    <Card className={props.classes.cardContainer}>
      <CardContent>
        <Grid container>
          <Grid item xs={8} sm={9}>
            <TextField
              fullWidth
              label="Criterium Name"
              variant="outlined"
              onChange={handleChange("name")}
              className={props.classes.criteriumName}
              value={criterium.name || ""}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <DeleteConfirmation onDelete={onDelete}>
                <DeleteButton {...props} />
              </DeleteConfirmation>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <CriteriumDragAndDropArea criteriumId={criterium.id} />
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container>
          <Grid item xs={3}>
            <Button
              size="medium"
              variant="outlined"
              className={props.classes.addSubCriterium}
              onClick={addSubCriterium}
            >
              Add Sub Criterium
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
);

View.propTypes = {
  criterium: PropTypes.object.isRequired
};
