import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchTrial } from "../actions/trials";

import { LoadingSpinner } from "../ui";
import { Navbar } from "../containers";
import { TrialEdit } from "../containers/trials";

class Trial extends Component {
  componentDidMount() {
    this.props.fetchTrial(this.props.match.params.id);
  }

  render() {
    const { waitingTrial } = this.props.trials;
    return (
      <Navbar currentPage="trials">
        {waitingTrial ? (
          <LoadingSpinner />
        ) : (
          <TrialEdit id={this.props.match.params.id} />
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = ({ trials }) => ({ trials });
export default connect(
  mapStateToProps,
  {
    fetchTrial
  }
)(Trial);
