import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExcelIcon from "@material-ui/icons/Description";

const TrialHeaderButtons = ({
  create,
  print,
  downloadExcel,
  processingReport,
  ...props
}) => {
  const matches = useMediaQuery(json2mq({ maxWidth: 400 }));

  return (
    <React.Fragment>
      <Button
        className={props.classes.printButton}
        size={matches ? "small" : "large"}
        variant="contained"
        color="secondary"
        onClick={print}
        disabled={processingReport}
      >
        <PrintIcon size={matches ? "small" : "large"} />
      </Button>
      <Button
        size={matches ? "small" : "large"}
        variant="contained"
        color="secondary"
        className={props.classes.printButton}
        onClick={downloadExcel}
        disabled={processingReport}
      >
        <ExcelIcon size={matches ? "small" : "large"} />
      </Button>
      <Button
        size={matches ? "small" : "large"}
        className={props.classes.button}
        variant="contained"
        color="secondary"
        onClick={create}
      >
        <AddIcon size={matches ? "small" : "large"} />
      </Button>
    </React.Fragment>
  );
};

TrialHeaderButtons.propTypes = {
  create: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired
};

const styles = theme => ({
  button: {
    marginTop: theme.addButton.marginTop
  },
  printButton: {
    marginTop: theme.addButton.marginTop,
    marginRight: 4,
    [theme.breakpoints.up("sm")]: {
      marginRight: 40
    }
  }
});

export default withStyles(styles)(TrialHeaderButtons);
