import React, { Component } from "react";
import DownshiftSingle from "../DownshiftSingle";
import { connect } from "react-redux";
import { searchAlgoliaVarieties } from "../../actions/algolia";

class VarietySelect extends Component {
  render() {
    const { varieties, waiting } = this.props.algolia;
    const results = varieties || {};

    const label = this.props.label || "Variety";
    const placeholder = this.props.placeholder || "Select Variety";
    const initialItem = this.props.variety || {};

    return (
      <DownshiftSingle
        backend={"algolia"}
        waiting={waiting}
        placeholder={placeholder}
        label={label}
        search={this.props.searchAlgoliaVarieties}
        results={results}
        initialItem={initialItem}
        selectedItemParent={this.props.setVariety}
      />
    );
  }
}

const mapStateToProps = ({ algolia }) => ({ algolia });
export default connect(
  mapStateToProps,
  {
    searchAlgoliaVarieties
  }
)(VarietySelect);
