import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export const PlotDetails = ({
  plotType,
  plotRows,
  plotCols,
  updateTrialLocally,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={6} md={6}>
      <TextField
        fullWidth
        label="Plot Rows"
        variant="outlined"
        type="number"
        value={plotRows}
        onChange={updateTrialLocally("plotRows")}
      />
    </Grid>
    <Grid item xs={6} md={6}>
      <TextField
        fullWidth
        label="Plot Cols"
        variant="outlined"
        value={plotCols}
        onChange={updateTrialLocally("plotCols")}
        type="number"
      />
    </Grid>
    <Grid item xs={12}>
      <FormGroup row style={{ marginLeft: 10 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={plotType === "normal"}
              onChange={updateTrialLocally("plotType")}
              value="normal"
            />
          }
          label="Normal"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={plotType === "snake"}
              onChange={updateTrialLocally("plotType")}
              value="snake"
            />
          }
          label="Snake"
        />
      </FormGroup>
    </Grid>
  </React.Fragment>
);

PlotDetails.propTypes = {
  plotType: PropTypes.string.isRequired,
  plotRows: PropTypes.number.isRequired,
  plotCols: PropTypes.number.isRequired,
  updateTrialLocally: PropTypes.func.isRequired
};
PlotDetails.defaultProps = {
  plotType: "snake"
};
