import React, { Component } from "react";
//import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SubCriteriumEdit from "./SubCriteriumEdit";
import { connect } from "react-redux";
import { updateSubcriteriumLocally } from "../../actions/subcriteria";

import Grid from "@material-ui/core/Grid";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "0 0 10px 0",
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? "rgba(208, 2, 27, 1)" : "rgba(255, 255, 255, 1)",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#D3D3D3" : "#fff",
  padding: grid
});

class CriteriumDragAndDropArea extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  getList = () => {
    const { criteria } = this.props.criteria;
    const { subcriteria } = this.props.subcriteria;
    if (!criteria[this.props.criteriumId]) {
      return [];
    }
    const list = criteria[this.props.criteriumId].subcriteria
      .map(id => subcriteria[id])
      .sort((subcriteriumA, subcriteriumB) => {
        return subcriteriumA.location - subcriteriumB.location;
      });

    return list;
  };

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const subcriteriumList = this.getList();

    const [removed] = subcriteriumList.splice(result.source.index, 1);
    subcriteriumList.splice(result.destination.index, 0, removed);

    subcriteriumList.forEach((subcriterium, index) => {
      this.props.updateSubcriteriumLocally(subcriterium.id, "location", index);
    });
  };

  render() {
    const { criteriumId } = this.props;
    const droppableId = `${criteriumId}-droppable`;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <Grid
              item
              xs={12}
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.getList().map((subcriterium, index) => (
                <Draggable
                  key={subcriterium.id}
                  draggableId={subcriterium.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Grid
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      container
                      spacing={0}
                    >
                      <SubCriteriumEdit
                        id={subcriterium.id}
                        criteriumId={criteriumId}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

CriteriumDragAndDropArea.propTypes = {
  //criteriumId: PropTypes.number,
  //subcriteriumIds: PropTypes.array.isRequired
};

const mapStateToProps = ({ criteria, subcriteria }) => ({
  criteria,
  subcriteria
});
export default connect(
  mapStateToProps,
  { updateSubcriteriumLocally }
)(CriteriumDragAndDropArea);
