import React from "react";
import TextField from "@material-ui/core/TextField";

export const reduxFormTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    error={touched && (error ? true : false)}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export const filterEmptyObjects = combinedTrialAndFieldVarieties => {
  if (combinedTrialAndFieldVarieties === null) {
    return [];
  }
  let filterEmptyObjects = combinedTrialAndFieldVarieties.filter(obj => {
    if (obj.comments !== null && obj.comments.length !== 0) {
      return true;
    }
    if (obj.growerComments !== null && obj.growerComments.length !== 0) {
      return true;
    }
    // if (obj.evaluations.length !== 0) {
    //   return true;
    // }
    if (obj.images.length !== 0) {
      return true;
    }
    return false;
  });
  return filterEmptyObjects;
};

export const camelToSnake = str => {
  return str
    .replace(/[\w]([A-Z])/g, function(m) {
      return m[0] + "_" + m[1];
    })
    .toLowerCase();
};

export const isTypeFieldVariety = typeStr =>
  typeStr === "fieldVarieties" ? true : false;

export const getRecord = (props, typeStr, recordId) => {
  const records = props[typeStr][typeStr];
  return records[recordId];
};

export const CircleIcon = props => <span {...props}>{props.value}</span>;

export const perPage = () => {
  return 10;
};

export const dateStrToFriendlyFormat = dateStr => {
  const dateLocale = new Date(dateStr);
  const dateLocaleStr = dateLocale.toLocaleString("en-US", {
    timeZone: "Europe/Helsinki"
  });
  const date = new Date(dateLocaleStr);
  return (
    getMonthName(date.getMonth()) +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear()
  );
};

export const dateStrToTightFormat = dateStr => {
  const dateLocale = new Date(dateStr);
  const dateLocaleStr = dateLocale.toLocaleString("en-US", {
    timeZone: "Europe/Helsinki"
  });
  const date = new Date(dateLocaleStr);
  const monthNum = date.getMonth() + 1;
  return monthNum + "/" + date.getDate() + "/" + date.getFullYear();
};

export const sortObjectByName = object => sortObjectByKey(object, "name");

export const sortObjectByKeyDesc = (object, key) =>
  Object.values(object).sort((a, b) => -1 * sortByKey(key, a, b));

export const sortObjectByKey = (object, key) =>
  Object.values(object).sort((a, b) => sortByKey(key, a, b));

const sortByKey = (key, a, b) => {
  if (a[key] === null) {
    return 1;
  }
  if (b[key] === null) {
    return -1;
  }
  if (a[key] < b[key]) {
    return -1;
  } else if (a[key] > b[key]) {
    return 1;
  } else {
    return 0;
  }
};

export const sortByKeyComparitor = (key, a, b, order) => {
  if (a[key] === null) {
    return swapOrder(1, order);
  }
  if (b[key] === null) {
    return swapOrder(-1, order);
  }
  if (key === "name") {
    return swapOrder(sortByName(a[key], b[key]), order);
  } else {
    if (a[key] < b[key]) {
      return swapOrder(-1, order);
    }
    if (a[key] > b[key]) {
      return swapOrder(1, order);
    }
    return swapOrder(sortByName(a["name"], b["name"]), order);
  }
};

export const sortByKeyComparitorExcludeYear = (key, a, b, order) => {
  if (a[key] === null) {
    return swapOrder(1, order);
  }
  if (b[key] === null) {
    return swapOrder(-1, order);
  }
  if (key.includes("date")) {
    const splitA = a[key].split("-");
    const splitB = b[key].split("-");
    if (splitA[1] < splitB[1]) {
      return swapOrder(-1, order);
    } else if (splitA[1] > splitB[1]) {
      return swapOrder(1, order);
    } else if (splitA[2] < splitB[2]) {
      return swapOrder(-1, order);
    } else if (splitA[2] > splitB[2]) {
      return swapOrder(1, order);
    } else {
      return sortByKeyComparitor(key, a, b, order);
    }
  } else {
    return sortByKeyComparitor(key, a, b, order);
  }
};

const sortByName = (valA, valB) => {
  const yearIdA = Number(valA.split("-")[0]);
  const yearA = Number(valA.split("-")[1]);
  const yearIdB = Number(valB.split("-")[0]);
  const yearB = Number(valB.split("-")[1]);
  if (yearA < yearB) {
    return -1;
  } else if (yearA > yearB) {
    return 1;
  } else if (yearIdA < yearIdB) {
    return -1;
  } else if (yearIdA > yearIdB) {
    return 1;
  } else {
    return 0;
  }
};

const swapOrder = (val, order) => {
  if (order === "asc") {
    return val * -1;
  }
  return val;
};

export const graphFormatDateStr = dateStr => {
  const date = new Date(dateStr);
  return [date.getMonth() + 1, date.getDate()].join("-");
};

export const getUnixEpoch = dateStr => {
  const date = new Date(dateStr);
  return (date.getTime() / 1000) | 0;
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.substr(1);

export const getMonthName = monthNum => {
  const months = new Array(12);
  months[0] = "January";
  months[1] = "February";
  months[2] = "March";
  months[3] = "April";
  months[4] = "May";
  months[5] = "June";
  months[6] = "July";
  months[7] = "August";
  months[8] = "September";
  months[9] = "October";
  months[10] = "November";
  months[11] = "December";
  return months[monthNum];
};

const totalDaysByMonth = monthNum => {
  const months = new Array(12);
  months[0] = 31;
  months[1] = 28; // if year divisable by 4, year % 4 === 0
  months[2] = 31;
  months[3] = 30;
  months[4] = 31;
  months[5] = 30;
  months[6] = 31;
  months[7] = 31;
  months[8] = 30;
  months[9] = 31;
  months[10] = 30;
  months[11] = 31;
  return months[monthNum];
};

const convertDateToDays = date => {
  let totalDays = 0;
  for (let i = 0; i < date.getMonth(); i++) {
    totalDays += totalDaysByMonth(i);
  }
  totalDays += date.getDate();
  return totalDays;
};

export const dateStrToNumber = dateStr => {
  const date = new Date(dateStr);
  return convertDateToDays(date);
};

export const lineOfBestFit = data => {
  let xSum = 0;
  let ySum = 0;
  data.forEach(point => {
    xSum += point.x; // will be a date
    ySum += point.y;
  });
  const xAverage = xSum / data.length;
  const yAverage = ySum / data.length;
  // calculate the diff sum
  let xyDiffProductSum = 0;
  let xDiffSquaredSum = 0;
  data.forEach(point => {
    const xDiff = point.x - xAverage;
    const yDiff = point.y - yAverage;

    const xyDiffProduct = xDiff * yDiff;
    const xDiffSquared = xDiff * xDiff;

    xyDiffProductSum += xyDiffProduct;
    xDiffSquaredSum += xDiffSquared;
  });
  const slope = xyDiffProductSum / xDiffSquaredSum;
  const yIntercept = yAverage - slope * xAverage;
  return {
    slope: slope,
    yIntercept: yIntercept
  };
};

export const pluralize = word => {
  if (PLURALS[word]) {
    return PLURALS[word];
  }
  return word + "s";
};

const PLURALS = {
  person: "people",
  company: "companies",
  commodity: "commodities"
};
