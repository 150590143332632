import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export const ProfileButtons = ({ onSave, ...props }) => (
  <Grid item xs={12}>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={false}
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

ProfileButtons.propTypes = {
  onSave: PropTypes.func.isRequired
};

const adminStyle = theme => ({
  linkStyle: {
    marginTop: 20,
    textDecoration: "none",
    cursor: "pointer",
    color: "black"
  }
});

export const AdminToggle = withStyles(adminStyle)(
  ({
    admin,
    hideExtraTrialInfo,
    handleChangeToggle,
    sendPasswordReset,
    ...props
  }) => (
    <Grid container>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={hideExtraTrialInfo}
                onChange={handleChangeToggle("hideExtraTrialInfo")}
                value={"HideExtraTrialInfo"}
                color="primary"
              />
            }
            label="Hide Extra Trial List Info."
          />
          <FormControlLabel
            control={
              <Switch
                checked={admin}
                onChange={handleChangeToggle("admin")}
                value="Admin"
                color="primary"
                // disabled={!admin}
              />
            }
            label="Admin"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <Typography
            variant="body1"
            gutterBottom
            className={props.classes.linkStyle}
            onClick={sendPasswordReset}
          >
            Send reset password link to the above email?
          </Typography>
        </FormGroup>
      </Grid>
    </Grid>
  )
);

AdminToggle.propTypes = {
  admin: PropTypes.bool.isRequired,
  sendPasswordReset: PropTypes.func.isRequired,
  handleChangeToggle: PropTypes.func.isRequired
};

const styles = theme => ({
  passwordContainer: {
    marginTop: 30,
    borderTop: "1px solid gray"
  },
  passwordInnerContainer: {
    marginTop: 30
  },
  headerText: theme.headerText
});

export const ProfileDetails = withStyles(styles)(
  ({ header, profile, handleChange, ...props }) => (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          gutterBottom
          className={props.classes.headerText}
        >
          {header}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="First Name"
          value={profile.firstName || ""}
          onChange={handleChange("firstName")}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Last Name"
          value={profile.lastName || ""}
          onChange={handleChange("lastName")}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Email"
          value={profile.email || ""}
          onChange={handleChange("email")}
          variant="outlined"
        />
      </Grid>
    </React.Fragment>
  )
);

ProfileDetails.propTypes = {
  profile: PropTypes.object.isRequired,
  header: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
