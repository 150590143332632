import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import decode from "jwt-decode";

import { setToken } from "./actions/users";

import PersonalizedTrials from "./pages/PersonalizedTrials";
import Trials from "./pages/Trials";
import Trial from "./pages/Trial";
import EvaluationReportPage from "./pages/EvaluationReportPage";
import PlotReportPage from "./pages/PlotReportPage";

import Area from "./pages/Area";
import Areas from "./pages/Areas";
import Region from "./pages/Region";
import Regions from "./pages/Regions";
import Ranch from "./pages/Ranch";
import Ranches from "./pages/Ranches";

import Commodities from "./pages/Commodities";
import Commodity from "./pages/Commodity";
import Labels from "./pages/Labels";
import Label from "./pages/Label";

import Varieties from "./pages/Varieties";
import VarietyNew from "./pages/VarietyNew";
import Variety from "./pages/Variety";
import VarietyCombine from "./pages/VarietyCombine";

import Nurseries from "./pages/Nurseries";
import Nursery from "./pages/Nursery";
import Reports from "./pages/Reports";
import Report from "./pages/Report";
import ReportCompare from "./pages/ReportCompare";
import Statistics from "./pages/Statistics";

import PersonNew from "./pages/PersonNew";
import People from "./pages/People";
import Person from "./pages/Person";
import Companies from "./pages/Companies";
import Company from "./pages/Company";
import CompanyNew from "./pages/CompanyNew";

import ProfileNew from "./pages/ProfileNew";
import Profile from "./pages/Profile";
import Profiles from "./pages/Profiles";

import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";

import NoMatch from "./pages/NoMatch";
import { Notification } from "./containers";

import { withAuth, withAuthAndAdmin } from "./Protectors";

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.props.loadToken();
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <React.Fragment>
            <Notification />
            <Switch>
              <Route exact path="/" component={withAuth(PersonalizedTrials)} />

              <Route
                exact
                path="/trials/plot_report/:id"
                component={withAuth(PlotReportPage)}
              />
              <Route
                exact
                path="/trials/evaluation_report/:id"
                component={withAuth(EvaluationReportPage)}
              />
              <Route exact path="/trials/:id" component={withAuth(Trial)} />
              <Route exact path="/trials" component={withAuth(Trials)} />

              <Route
                exact
                path="/areas/:id"
                component={withAuthAndAdmin(Area)}
              />
              <Route exact path="/areas" component={withAuthAndAdmin(Areas)} />
              <Route
                exact
                path="/regions/:id"
                component={withAuthAndAdmin(Region)}
              />
              <Route
                exact
                path="/regions"
                component={withAuthAndAdmin(Regions)}
              />
              <Route
                exact
                path="/ranches/:id"
                component={withAuthAndAdmin(Ranch)}
              />
              <Route
                exact
                path="/ranches"
                component={withAuthAndAdmin(Ranches)}
              />

              <Route
                exact
                path="/commodities/:id"
                component={withAuthAndAdmin(Commodity)}
              />
              <Route
                exact
                path="/commodities"
                component={withAuthAndAdmin(Commodities)}
              />
              <Route
                exact
                path="/diseases/:id"
                component={withAuthAndAdmin(Label)}
              />
              <Route
                exact
                path="/diseases"
                component={withAuthAndAdmin(Labels)}
              />
              <Route path="/varieties/new" component={withAuth(VarietyNew)} />
              <Route
                exact
                path="/varieties/:id/combine"
                component={withAuthAndAdmin(VarietyCombine)}
              />
              <Route path="/varieties/:id" component={withAuth(Variety)} />
              <Route path="/varieties" component={withAuth(Varieties)} />

              <Route
                exact
                path="/nurseries/:id"
                component={withAuthAndAdmin(Nursery)}
              />
              <Route
                exact
                path="/nurseries"
                component={withAuthAndAdmin(Nurseries)}
              />

              <Route
                exact
                path="/statistics"
                component={withAuth(Statistics)}
              />

              <Route
                path="/reports/single/:variety_id"
                component={withAuth(Report)}
              />
              <Route
                path="/reports/compare"
                component={withAuth(ReportCompare)}
              />
              <Route path="/reports" component={withAuth(Reports)} />

              <Route
                exact
                path="/companies/new"
                component={withAuthAndAdmin(CompanyNew)}
              />
              <Route
                exact
                path="/companies/:id"
                component={withAuthAndAdmin(Company)}
              />
              <Route
                exact
                path="/companies"
                component={withAuthAndAdmin(Companies)}
              />

              <Route path="/people/new" component={withAuth(PersonNew)} />
              <Route path="/people/:id" component={withAuth(Person)} />
              <Route path="/people" component={withAuth(People)} />

              <Route
                exact
                path="/profiles/new"
                component={withAuthAndAdmin(ProfileNew)}
              />
              <Route path="/profiles/:id" component={withAuth(Profile)} />
              <Route
                exact
                path="/profiles"
                component={withAuthAndAdmin(Profiles)}
              />

              <Route path="/signin" component={SignIn} />
              <Route path="/forgot_password" component={ForgotPassword} />
              <Route path="/password_reset/:token" component={PasswordReset} />
              <Route component={withAuth(NoMatch)} />
            </Switch>
          </React.Fragment>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    admin: state.user.admin,
    authenticated: state.user.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  const isTokenExpired = token => {
    try {
      const decoded = decode(token.idToken);
      if (decoded.exp > Date.now() / 1000) {
        // Checking if token is expired. N
        return false;
      } else return true;
    } catch (err) {
      return true;
    }
  };
  return {
    loadToken: () => {
      let token = localStorage.getItem("jwtToken");
      if (!token || token === "") {
        return;
      }
      try {
        token = JSON.parse(token);
      } catch (err) {
        localStorage.removeItem("jwtToken");
        return;
      }
      if (!isTokenExpired(token)) {
        dispatch(setToken(token));
      } else {
        localStorage.removeItem("jwtToken");
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
