import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const CommentCard = ({ title, comments, ...props }) => (
  <Card style={{ backgroundColor: props.color }}>
    <CardContent>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body1">{comments}</Typography>
    </CardContent>
  </Card>
);
export default CommentCard;
