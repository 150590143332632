import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class DeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  handleDelete = () => {
    this.setState({ confirmDelete: true });
  };

  handleClose = () => {
    this.setState({ confirmDelete: false });
  };

  onConfirm = () => {
    this.props.onDelete();
    this.handleClose();
  };

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        confirmDelete: this.handleDelete
      });
    });
    return (
      <React.Fragment>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.confirmDelete}
        >
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {this.props.question ||
                "Are you sure you want to delete the following?"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onConfirm} color="primary">
              Confirm
            </Button>
            <Button onClick={this.handleClose}>
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>
        {children}
      </React.Fragment>
    );
  }
}

DeleteConfirmation.propTypes = {
  onDelete: PropTypes.func.isRequired,
  question: PropTypes.string
};

export default DeleteConfirmation;
