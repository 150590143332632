import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const FullPageSpinner = ({ ...props }) => (
  <Grid item xs={12}>
    <Grid container direction="column" alignItems="center" justify="center">
      <Grid item xs={3}>
        <CircularProgress size={30} thickness={4} color={"primary"} />
      </Grid>
    </Grid>
  </Grid>
);

export default FullPageSpinner;
