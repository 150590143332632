import React from "react";
import { Link } from "react-router-dom";
import { HighlightAlogliaResult } from "../../../ui";
import { dateStrToTightFormat } from "../../../helpers";

import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const TrialHit = ({ hit, hitsStatuses, hideExtraTrialInfo, ...props }) => {
  return (
    <TableRow hover tabIndex={-1} key={hit.objectID}>
      <TableCell
        component="th"
        scope="row"
        className={props.classes.tableBodyCell}
        align="center"
      >
        <Link
          to={"/trials/" + hit.objectID}
          className={props.classes.linkStyle}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            className={props.classes.linkTypography}
          >
            <HighlightAlogliaResult
              attribute="name"
              hit={hit}
              highlight={hit._highlightResult}
            />
          </Typography>
        </Link>
      </TableCell>
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          <HighlightAlogliaResult
            attribute="commodity.name"
            hit={hit}
            highlight={hit._highlightResult}
          />
        </Typography>
      </TableCell>
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          {hit.wet_date && dateStrToTightFormat(hit.wet_date)}
        </Typography>
      </TableCell>
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          {hit.harvest_date && dateStrToTightFormat(hit.harvest_date)}
        </Typography>
      </TableCell>
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          <HighlightAlogliaResult
            attribute="grower.name"
            hit={hit}
            highlight={hit._highlightResult}
          />
        </Typography>
      </TableCell>
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          <HighlightAlogliaResult
            attribute="region.name"
            hit={hit}
            highlight={hit._highlightResult}
          />
        </Typography>
      </TableCell>
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          <HighlightAlogliaResult
            attribute="area.name"
            hit={hit}
            highlight={hit._highlightResult}
          />
        </Typography>
      </TableCell>
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          <HighlightAlogliaResult
            attribute="ranch.combined"
            hit={hit}
            highlight={hit._highlightResult}
          />
        </Typography>
      </TableCell>
      {!hideExtraTrialInfo && (
        <React.Fragment>
          <TableCell className={props.classes.tableBodyCell} align="center">
            {hitsStatuses[hit.objectID] &&
              hitsStatuses[hit.objectID].mapPrinted && <CheckCircleIcon />}
          </TableCell>
          <TableCell className={props.classes.tableBodyCell} align="center">
            {hitsStatuses[hit.objectID] &&
              hitsStatuses[hit.objectID].producerMapEmailed && (
                <CheckCircleIcon />
              )}
          </TableCell>
          <TableCell className={props.classes.tableBodyCell} align="center">
            {hitsStatuses[hit.objectID] &&
              (hitsStatuses[hit.objectID].growerMapEmailed ? (
                <CheckCircleIcon />
              ) : (
                hitsStatuses[hit.objectID].autoSendPlotReport && <CheckCircleIcon color="secondary" />
              ))}
          </TableCell>
          <TableCell className={props.classes.tableBodyCell} align="center">
            {hitsStatuses[hit.objectID] &&
              hitsStatuses[hit.objectID].cleaned && <CheckCircleIcon />}
          </TableCell>
          <TableCell className={props.classes.tableBodyCell} align="center">
            {hitsStatuses[hit.objectID] &&
              hitsStatuses[hit.objectID].evaluationReady && <CheckCircleIcon />}
          </TableCell>
          <TableCell className={props.classes.tableBodyCell} align="center">
            {hitsStatuses[hit.objectID] &&
              hitsStatuses[hit.objectID].producerEvaluationEmailed && (
                <CheckCircleIcon />
              )}
          </TableCell>
          <TableCell className={props.classes.tableBodyCell} align="center">
            {hitsStatuses[hit.objectID] && (
              hitsStatuses[hit.objectID].growerEvaluationEmailed ? (
                <CheckCircleIcon />
              ) : (
                hitsStatuses[hit.objectID].autoSendEvaluationReport && <CheckCircleIcon color="secondary" />
              ))}
          </TableCell>
        </React.Fragment>
      )}
      <TableCell className={props.classes.tableBodyCell} align="center">
        <Typography
          variant="body1"
          gutterBottom
          className={props.classes.cellTypography}
        >
          {hit.sales_team ? "Sales Trial" : "PD Trial"}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
const styles = theme => ({
  linkStyle: theme.linkStyle,
  "ais-Highlight-highlighted": {
    backgroundColor: "#FFFF00"
  },
  cellTypography: {
    fontSize: 12
  },
  linkTypography: {
    color: "black",
    fontSize: 12
  },
  tableBodyCell: {
    paddingLeft: 2,
    paddingRight: 2
  }
});

export default withStyles(styles)(TrialHit);
