import React from "react";
import PropTypes from "prop-types";

import TableRowSimple from "./TableRowSimple";
import TableHeaderSimple from "./TableHeaderSimple";
import { sortObjectByName } from "../../helpers";
import { DeleteConfirmation } from "../../containers";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Search from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";

const SimpleListName = ({
  baseUrl,
  rows,
  onDelete,
  handleChange,
  searchField,
  showMoreResults,
  ...props
}) => (
  <div className={props.classes.root}>
    <Grid container spacing={2} className={props.classes.searchContainer}>
      <Grid item xs={12}>
        <TextField
          id="input-with-icon-textfield"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          autoFocus={true}
          value={searchField}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Table>
        <TableHeaderSimple headers={["Name", ""]} />
        <TableBody>
          {sortObjectByName(rows).map(row => (
            <DeleteConfirmation onDelete={() => onDelete(row.id)} key={row.id}>
              <TableRowSimple link={`/${baseUrl}/` + row.id} name={row.name} />
            </DeleteConfirmation>
          ))}
        </TableBody>
      </Table>
    </Grid>
    <Grid item xs={12}>
      {props.waiting ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={props.classes.progressGrid}
        >
          <CircularProgress size={80} thickness={7} color={"primary"} />
        </Grid>
      ) : (
        <div>
          {!props.waiting && props.page < props.totalPages && (
            <Button color="primary" fullWidth onClick={showMoreResults}>
              Show more results
            </Button>
          )}
        </div>
      )}
    </Grid>
  </div>
);

SimpleListName.propTypes = {
  waiting: PropTypes.bool.isRequired,
  rows: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  searchField: PropTypes.string
};

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  searchContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  progressGrid: {
    marginTop: 30
  }
});

export default withStyles(styles)(SimpleListName);
