import React from "react";
import { connectHighlight } from "react-instantsearch-dom";
import { withStyles } from "@material-ui/core/styles";

const CustomHighlight = ({ highlight, attribute, hit, ...props }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit
  });
  if (
    attribute === "commodities" ||
    attribute === "company_categories" ||
    attribute === "regions" ||
    attribute === "companies" ||
    attribute === "growers" ||
    attribute === "suppliers" ||
    attribute === "shippers"
  ) {
    return (
      <span>
        {parsedHit.map((commodityPart, parsedHitIndex) => (
          <span key={parsedHitIndex}>
            {commodityPart.map((part, index) =>
              part.isHighlighted ? (
                <mark key={index}>{part.value}</mark>
              ) : (
                <span key={index}>{part.value}</span>
              )
            )}
            {parsedHitIndex !== parsedHit.length - 1 && <span>, </span>}
          </span>
        ))}
      </span>
    );
  }
  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <mark key={index}>{part.value}</mark>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  );
};

const styles = theme => ({
  mark: {
    backgroundColor: "#FFFF00"
  },
  "ais-Highlight-highlighted": {
    backgroundColor: "#FFFF00"
  }
});

export default connectHighlight(withStyles(styles)(CustomHighlight));
