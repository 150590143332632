import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../actions/users";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import "typeface-roboto";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

const drawerWidth = 160;

const styles = theme => ({
  children: {
    marginTop: 40,
    marginLeft: drawerWidth
  },
  title: {
    marginLeft: drawerWidth - 160,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  root: {
    backgroundColor: theme.palette.background.default
  },
  content: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginBottom: 40,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  "content-left": {
    marginLeft: -drawerWidth
  },
  "content-right": {
    marginRight: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  "contentShift-left": {
    marginLeft: 0
  },
  "contentShift-right": {
    marginRight: 0
  },
  typography: {},
  linkStyle: theme.linkStyle,
  headerLinkStyle: {
    textDecoration: "none",
    color: "white"
  },
  bigAvatar: {
    margin: 10,
    width: 50,
    height: 50,
    [theme.breakpoints.down(385)]: {
      display: "none"
    }
  },
  icon: {
    marginLeft: drawerWidth - 50,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  iconButton: {
    marginLeft: 10,
    marginTop: 10
  }
});

class Navbar extends Component {
  constructor(props) {
    super(props);
    //this.currentPage = props.currentPage;
    this.state = {
      open: false,
      anchor: "left"
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleChangeAnchor = event => {
    this.setState({
      anchor: event.target.value
    });
  };

  renderAdminNav(listItem) {
    const admin = this.props.admin;
    if (admin) {
      return listItem;
    }
    return;
  }

  render() {
    const { classes, theme } = this.props;
    const { anchor, open } = this.state;
    const { userId } = this.props.user;

    const drawer = (
      <Drawer
        variant="persistent"
        anchor={anchor}
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div>
          <IconButton
            onClick={this.handleDrawerClose}
            className={classes.iconButton}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <List>
          <ListItem button selected={this.props.currentPage === "trials"}>
            <Link to="/trials" className={classes.linkStyle}>
              <ListItemText primary="Trials" />
            </Link>
          </ListItem>
        </List>
        {this.renderAdminNav(<Divider />)}
        {this.renderAdminNav(
          <List>
            <ListItem
              button
              selected={this.props.currentPage === "commodities"}
            >
              <Link to="/commodities" className={classes.linkStyle}>
                <ListItemText primary="Commodities" />
              </Link>
            </ListItem>
          </List>
        )}
        {this.renderAdminNav(
          <List>
            <ListItem button selected={this.props.currentPage === "diseases"}>
              <Link to="/diseases" className={classes.linkStyle}>
                <ListItemText primary="Diseases" />
              </Link>
            </ListItem>
          </List>
        )}
        <Divider />
        {this.renderAdminNav(
          <List>
            <ListItem button selected={this.props.currentPage === "areas"}>
              <Link to="/areas" className={classes.linkStyle}>
                <ListItemText primary="Areas" />
              </Link>
            </ListItem>
            <ListItem button selected={this.props.currentPage === "regions"}>
              <Link to="/regions" className={classes.linkStyle}>
                <ListItemText primary="Regions" />
              </Link>
            </ListItem>
            <ListItem button selected={this.props.currentPage === "ranches"}>
              <Link to="/ranches" className={classes.linkStyle}>
                <ListItemText primary="Ranches" />
              </Link>
            </ListItem>
          </List>
        )}
        {this.renderAdminNav(<Divider />)}
        <List>
          <ListItem button selected={this.props.currentPage === "varieties"}>
            <Link to="/varieties" className={classes.linkStyle}>
              <ListItemText primary="Varieties" />
            </Link>
          </ListItem>
        </List>
        <Divider />
        {this.renderAdminNav(
          <List>
            <ListItem button selected={this.props.currentPage === "nurseries"}>
              <Link to="/nurseries" className={classes.linkStyle}>
                <ListItemText primary="Nurseries" />
              </Link>
            </ListItem>
          </List>
        )}
        <List>
          <ListItem button selected={this.props.currentPage === "statistics"}>
            <Link to="/statistics" className={classes.linkStyle}>
              <ListItemText primary="Days to Harvest" />
            </Link>
          </ListItem>
        </List>
        {this.renderAdminNav(
          <List>
            <ListItem button selected={this.props.currentPage === "reports"}>
              <Link to="/reports" className={classes.linkStyle}>
                <ListItemText primary="Reports" />
              </Link>
            </ListItem>
          </List>
        )}
        <Divider />
        <List>
          <ListItem button selected={this.props.currentPage === "people"}>
            <Link to="/people" className={classes.linkStyle}>
              <ListItemText primary="People" />
            </Link>
          </ListItem>
        </List>
        {this.renderAdminNav(
          <List>
            <ListItem button selected={this.props.currentPage === "companies"}>
              <Link to="/companies" className={classes.linkStyle}>
                <ListItemText primary="Companies" />
              </Link>
            </ListItem>
          </List>
        )}
        {this.renderAdminNav(
          <List>
            <ListItem button selected={this.props.currentPage === "users"}>
              <Link to={"/profiles"} className={classes.linkStyle}>
                <ListItemText primary="Users" />
              </Link>
            </ListItem>
          </List>
        )}
        <Divider />
        <List>
          <ListItem button selected={this.props.currentPage === "profile"}>
            <Link to={`/profiles/${userId}`} className={classes.linkStyle}>
              <ListItemText primary="My Profile" />
            </Link>
          </ListItem>
        </List>
        <List>
          <ListItem button onClick={() => this.props.logout()}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    );

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes[`appBarShift-${anchor}`]]: open
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <div
                className={classNames({
                  [classes["icon"]]: open
                })}
              >
                <Avatar
                  alt=""
                  src={
                    "https://holaday-assets.s3-us-west-1.amazonaws.com/logoBorder.jpg"
                  }
                  className={classes.bigAvatar}
                />
              </div>
              <div className={classNames({ [classes["title"]]: open })}>
                <Link to="/" className={classes.headerLinkStyle}>
                  <Typography variant="h5" color="inherit" noWrap>
                    Holaday Seed Company
                  </Typography>
                </Link>
              </div>
            </Toolbar>
          </AppBar>
          {drawer}
          <main
            className={classNames(
              classes.content,
              classes[`content-${anchor}`],
              {
                [classes.contentShift]: open,
                [classes[`contentShift-${anchor}`]]: open
              }
            )}
          >
            <div className={classes.drawerHeader} />
            <div className={classes.children}>{this.props.children}</div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    admin: state.user.admin,
    authenticatedUser:
      state.user.status === "authenticated" ? state.user.user : null,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      localStorage.removeItem("jwtToken");
      dispatch(logoutUser());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Navbar));
