import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "../containers";
import { TrialsList } from "../containers/trials";
import { LoadingSpinner } from "../ui";
import { fetchProfile } from "../actions/profiles";

class PersonalizedTrials extends Component {
  componentDidMount() {
    const { userId } = this.props.user;
    this.props.fetchProfile(userId);
  }

  render() {
    const { waiting, profile } = this.props.profiles;
    return (
      <Navbar currentPage="trials">
        {waiting ? (
          <LoadingSpinner />
        ) : (
          <TrialsList
            title={`${profile.name} Trials`}
            archived={true}
            personalized={true}
          />
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  profiles: state.profiles
});

export default connect(
  mapStateToProps,
  {
    fetchProfile
  }
)(PersonalizedTrials);
