import React, { Component } from "react";
import { connect } from "react-redux";
import { createRanchUpdateTrial, fetchRanches } from "../../actions/ranches";
import DownshiftSingle from "../DownshiftSingle";

class RanchSelect extends Component {
  render() {
    const { classes } = this.props;
    const { ranches, waiting } = this.props.ranches;
    const results = ranches || {};

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Ranch"}
        label={"Ranch"}
        classes={classes}
        search={this.props.fetchRanches}
        results={results}
        initialItem={this.props.ranch}
        createItem={this.props.createRanchUpdateTrial}
        selectedItemParent={this.props.setRanch}
      />
    );
  }
}

const mapStateToProps = ({ ranches }) => ({ ranches });
export default connect(
  mapStateToProps,
  {
    createRanchUpdateTrial,
    fetchRanches
  }
)(RanchSelect);
