import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

const SimpleAlgoliaSearchBox = ({
  currentRefinement,
  isSearchStalled,
  refine,
  handleChange,
  ...props
}) => (
  <Grid container spacing={2} className={props.classes.searchContainer}>
    <Grid item xs={12}>
      <TextField
        id="input-with-icon-textfield"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
        type="search"
        autoFocus={true}
        value={currentRefinement}
        onChange={event => handleChange(event.currentTarget.value, refine)}
      />
    </Grid>
  </Grid>
);

const styles = theme => ({
  searchContainer: {
    marginBottom: 20
  }
});

export default connectSearchBox(withStyles(styles)(SimpleAlgoliaSearchBox));
