import React from "react";
import PropTypes from "prop-types";
import { dateStrToFriendlyFormat } from "../../helpers";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  buttonGrid: {
    marginTop: 20
  },
  gridList: {
    width: "100%",
    height: theme.spacing(50)
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)"
  }
});

export const View = withStyles(styles)(
  ({ hideImages, deleteImage, images, ...props }) => (
    <div className={props.classes.root}>
      {images.length > 0 ? (
        <GridList cellHeight={180} className={props.classes.gridList}>
          {images.map(image => (
            <GridListTile key={image.id}>
              <img src={image.url} alt={image.id} />
              <GridListTileBar
                title={<span>{dateStrToFriendlyFormat(image.createdAt)}</span>}
                actionIcon={
                  <IconButton
                    className={props.classes.icon}
                    onClick={deleteImage(image.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      ) : (
        <Typography variant="h5" gutterBottom>
          No images
        </Typography>
      )}

      <Grid item xs={12} className={props.classes.buttonGrid}>
        <Button
          size="large"
          variant="contained"
          color="default"
          fullWidth
          onClick={hideImages}
        >
          Close
        </Button>
      </Grid>
    </div>
  )
);

View.propTypes = {
  deleteImage: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  hideImages: PropTypes.func.isRequired
};
