import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export const SubcriteriumSelect = ({
  criterium,
  choosenSubcriteriumId,
  shownSubcriteria,
  subcriteria,
  handleChange,
  ...props
}) => (
  <Grid item xs={4} md={3} key={criterium.id}>
    <InputLabel>{criterium.name}</InputLabel>
    <Select
      variant="outlined"
      fullWidth
      value={choosenSubcriteriumId}
      label={criterium.name}
      input={
        <OutlinedInput
          name="criteriumScoreId"
          labelWidth={65}
          id={`${criterium.id}`}
          onChange={handleChange(criterium.id)}
        />
      }
    >
      {shownSubcriteria.sort((subcriteriumIdA, subcriteriumIdB) => {
          if (!subcriteria[subcriteriumIdA]) {
            return 1;
          } else if (!subcriteria[subcriteriumIdB]) {
            return -1;
          }
          return (
            subcriteria[subcriteriumIdA].location -
            subcriteria[subcriteriumIdB].location
          );
        })
        .map(subcriteriumId => (
          <MenuItem key={subcriteriumId} value={subcriteriumId}>
            {subcriteria[subcriteriumId]
              ? subcriteria[subcriteriumId].name
              : `${criterium.id}-${subcriteriumId}`}
          </MenuItem>
        ))}
    </Select>
  </Grid>
);

SubcriteriumSelect.propTypes = {
  criterium: PropTypes.object.isRequired,
  subcriteria: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};
