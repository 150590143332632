import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signInUser } from "../actions/users";
import { reduxFormTextField } from "../helpers/utils";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.email || values.email.trim() === "") {
    errors.email = "Enter email";
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === "") {
    errors.password = "Enter password";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class SignInForm extends Component {
  // componentWillReceiveProps(nextProps) {
  // }

  validateAndSignInUser = values => {
    this.props.signInUser(values);
    //return dispatch(signInUser(values));
  };

  renderError() {
    if (this.props.errorMsg) {
      return (
        <Typography component="p" variant="subtitle2" color="error">
          <ErrorIcon color="error" />
          {this.props.errorMsg}
        </Typography>
      );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper} elevation={2}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="subtitle1" color="initial">
              Sign in
            </Typography>
            <form
              className={classes.form}
              onSubmit={this.props.handleSubmit(this.validateAndSignInUser)}
            >
              {this.renderError()}
              <FormControl margin="normal" required fullWidth>
                <Field
                  name="email"
                  component={reduxFormTextField}
                  label="Email"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Field
                  name="password"
                  component={reduxFormTextField}
                  label="Password"
                  type="password"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Link to="/forgot_password" className={classes.linkStyle}>
                  <Typography variant="body2" color="initial" gutterBottom>
                    Forgot your password?
                  </Typography>
                </Link>
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={this.props.submitting}
              >
                Sign in
              </Button>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up(400)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  typography: {},
  linkStyle: theme.linkStyle
});

SignInForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    errorMsg: state.user.error,
    authenticated: state.user.authenticated
  };
};

export default connect(
  mapStateToProps,
  {
    signInUser
  }
)(
  reduxForm({
    form: "SignInForm",
    validate
  })(withStyles(styles)(SignInForm))
);
