import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  sortByKeyComparitor,
  sortByKeyComparitorExcludeYear,
  getUnixEpoch,
  dateStrToNumber,
  camelToSnake
} from "../../../helpers/utils";
import {
  submitTrialList,
  submitTrialListExcel,
  checkStatisticalReportStatus,
  updateCheckReportStatus
} from "../../../actions/reports";
import {
  deleteTrial,
  createTrial,
  updateTrialSearch
} from "../../../actions/trials";
import {
  searchAlogliaTrials,
  searchMoreAlgoliaTrials,
  searchAllAlgoliaTrials
} from "../../../actions/algolia";
import { ErrorPage, PageHeader, ReportProcessing } from "../../../ui";
import TrialLoadMore from "./TrialLoadMore";
import TrialSearchBox from "./TrialSearchBox";
import TrialHeaderButtons from "./TrialHeaderButtons";

import { debounce } from "lodash";
import Typography from "@material-ui/core/Typography";

class TrialsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createdTrial: false,
      order: "desc",
      orderBy: "harvest_date",
      showProcessingReport: false,
      heightOfTable: 400
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.search();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidCatch() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ heightOfTable: window.innerHeight - 220 });
  }

  createTrial = () => {
    this.props.createTrial({});
    this.setState({ ...this.state, createdTrial: true });
  };

  printTrialList = () => {
    const filtersHash = this._buildFiltersHash();
    const tagFilters = this._buildTagFilters();
    const { searchField } = this.props.trials.search;
    this.props.submitTrialList(
      searchField,
      filtersHash,
      tagFilters,
      this.state.orderBy,
      this.state.order,
      this.props.personalized
    );
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  downloadExcel = () => {
    const filtersHash = this._buildFiltersHash();
    const tagFilters = this._buildTagFilters();
    const { searchField } = this.props.trials.search;
    this.props.submitTrialListExcel(
      searchField,
      filtersHash,
      tagFilters,
      this.props.personalized
    );
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  _buildFiltersHash = () => {
    const {
      excludeYear,
      archived,
      mexican,
      startEstHarvestDate,
      endEstHarvestDate,
      startWetDate,
      endWetDate
    } = this.props.trials.search;
    const filters = {};
    if (archived === true && this.props.archived) {
      filters["archived"] = true;
    }
    if(mexican === false){
      filters['mexican'] = false;
    }
    if (excludeYear) {
      if (startEstHarvestDate) {
        filters["startHarvestDateDays"] = dateStrToNumber(startEstHarvestDate);
      }
      if (endEstHarvestDate) {
        filters["endHarvestDateDays"] = dateStrToNumber(endEstHarvestDate);
      }
      if (startWetDate) {
        filters["startWetDateDays"] = dateStrToNumber(startWetDate);
      }
      if (endWetDate) {
        filters["endWetDateDays"] = dateStrToNumber(endWetDate);
      }
    } else {
      if (startEstHarvestDate) {
        filters["startHarvestDateUnix"] = getUnixEpoch(startEstHarvestDate);
      }
      if (endEstHarvestDate) {
        filters["endHarvestDateUnix"] = getUnixEpoch(endEstHarvestDate);
      }
      if (startWetDate) {
        filters["startWetDateUnix"] = getUnixEpoch(startWetDate);
      }
      if (endWetDate) {
        filters["endWetDateUnix"] = getUnixEpoch(endWetDate);
      }
    }
    return filters;
  };

  handleSearchChange = value => {
    this.props.updateTrialSearch("searchField", value).then(
      debounce(() => {
        this.search();
      }, 500)
    );
  };

  search = () => {
    const filters = this._buildFilters();
    const tagFilters = this._buildTagFilters();
    const { searchField } = this.props.trials.search;
    this.props.searchAlogliaTrials(searchField, filters, [tagFilters]);
  };

  loadMore = () => {
    const filters = this._buildFilters();
    const tagFilters = this._buildTagFilters();
    const { searchField } = this.props.trials.search;
    this.props.searchMoreAlgoliaTrials(searchField, filters, [tagFilters]);
  };

  loadAll = () => {
    const filters = this._buildFilters();
    const tagFilters = this._buildTagFilters();
    const { searchField } = this.props.trials.search;
    this.props.searchAllAlgoliaTrials(searchField, filters, [tagFilters]);
  };

  handleChange = name => moment => {
    this.props.updateTrialSearch(name, moment).then(() => {
      this.search();
    });
  };

  checkChange = type => e => {
    this.props.updateTrialSearch(type, e.target.checked).then(() => {
      this.search();
    });
  };

  _buildTagFilters = () => {
    const { profile } = this.props.profiles;
    const tagFilters = [];
    if (this.props.personalized && profile.personalViews) {
      profile.personalViews.forEach(pv => {
        if (
          !(
            pv.companyId == null &&
            pv.regionId == null &&
            pv.commodityId == null
          )
        ) {
          const key = `${pv.companyId || 0}-${pv.regionId ||
            0}-${pv.commodityId || 0}`;
          const fullSearchValue = `${key}`;
          tagFilters.push(fullSearchValue);
        }
      });
    }
    return tagFilters;
  };

  _buildFilters = () => {
    let filterArr = [];
    const filtersHash = this._buildFiltersHash();
    if (filtersHash["archived"] !== true) {
      filterArr.push("archived=0");
    }
    if(filtersHash['mexican'] === false) {
      filterArr.push("mexican=0")
    }
    Object.keys(filtersHash).forEach(key => {
      if (key.includes("start")) {
        filterArr.push(
          camelToSnake(`${key.replace("start", "")} >= ${filtersHash[key]}`)
        );
      } else if (key.includes("end")) {
        filterArr.push(
          camelToSnake(`${key.replace("end", "")} <= ${filtersHash[key]}`)
        );
      }
    });
    return filterArr.join(" AND ");
  };

  sortHandler = orderBy => () => {
    let order = "desc";
    if (this.state.orderBy === orderBy && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ ...this.state, orderBy: orderBy, order: order });
  };

  sortTable = trials => {
    const { excludeYear } = this.props.trials.search;
    const { orderBy, order } = this.state;
    let comparitor = (a, b) => sortByKeyComparitor(orderBy, a, b, order);
    if (excludeYear) {
      comparitor = (a, b) =>
        sortByKeyComparitorExcludeYear(orderBy, a, b, order);
    }
    return trials.sort(comparitor);
  };

  render() {
    const { trial, error, search } = this.props.trials;
    if (this.state.createdTrial && trial.id) {
      return <Redirect push to={`/trials/${trial.id}`} />;
    }

    if (error) {
      return <ErrorPage msg={error} />;
    }
    const { checkReportStatus } = this.props.reports;

    const tagFilters = this._buildTagFilters();
    if (tagFilters.length === 0 && this.props.personalized === true) {
      return (
        <React.Fragment>
          <PageHeader title={this.props.title} half={5}>
            <TrialHeaderButtons
              create={this.createTrial}
              print={this.printTrialList}
              downloadExcel={this.downloadExcel}
              processingReport={checkReportStatus}
            />
          </PageHeader>

          {this.state.showProcessingReport && (
            <ReportProcessing
              checkReportStatusCall={this.props.checkStatisticalReportStatus}
            />
          )}

          <Typography align="center" variant={"h2"} gutterBottom>
            Not Configured
          </Typography>
          <Typography align="center" variant={"h6"} gutterBottom>
            Please go to the My Profile page to configure
          </Typography>
        </React.Fragment>
      );
    }

    const { profile } = this.props.profiles;
    const { trials, page, pages, waiting } = this.props.algolia;
    const { trials: hitsStatuses } = this.props.trials;
    return (
      <React.Fragment>
        <PageHeader title={this.props.title} half={6}>
          <TrialHeaderButtons
            create={this.createTrial}
            print={this.printTrialList}
            downloadExcel={this.downloadExcel}
            processingReport={checkReportStatus}
          />
        </PageHeader>
        {this.state.showProcessingReport && (
          <ReportProcessing
            checkReportStatusCall={this.props.checkStatisticalReportStatus}
          />
        )}
        <TrialSearchBox
          state={search}
          checkChange={this.checkChange}
          handleChange={this.handleChange}
          handleSearchChange={this.handleSearchChange}
          archived={this.props.archived}
        />
        <TrialLoadMore
          hits={trials}
          hitsStatuses={hitsStatuses}
          loading={waiting}
          loadMore={this.loadMore}
          loadAll={this.loadAll}
          hasMore={page < pages - 1}
          heightOfTable={this.state.heightOfTable}
          currentPage={page}
          totalPages={pages}
          order={this.state.order}
          orderBy={this.state.orderBy}
          sortTable={this.sortTable}
          sortHandler={this.sortHandler}
          hideExtraTrialInfo={profile.hideExtraTrialInfo}
          handleSearchChange={this.handleSearchChange}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ algolia, profiles, reports, trials }) => ({
  algolia,
  trials,
  profiles,
  reports
});

export default connect(mapStateToProps, {
  deleteTrial,
  createTrial,
  updateTrialSearch,
  submitTrialList,
  submitTrialListExcel,
  checkStatisticalReportStatus,
  updateCheckReportStatus,
  searchAlogliaTrials,
  searchMoreAlgoliaTrials,
  searchAllAlgoliaTrials
})(TrialsList);
