import React from "react";
import PropTypes from "prop-types";

import { DatePicker } from "@material-ui/pickers";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const TrialSearchBox = ({
  handleChange,
  handleSearchChange,
  checkChange,
  state,
  ...props
}) => (
  <Grid container spacing={2} className={props.classes.searchContainer}>
    <Grid item xs={12} md={5} className={props.classes.searchField}>
      <TextField
        id="input-with-icon-textfield"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
        type="search"
        autoFocus={true}
        value={state.searchField}
        onChange={event => {
          handleSearchChange(event.currentTarget.value);
        }}
      />
    </Grid>
    <Grid item xs={12} sm={2} md={2}>
      <FormControlLabel
        control={
          <Switch
            checked={state.excludeYear}
            onChange={checkChange("excludeYear")}
            value="excludeYear"
            color="primary"
          />
        }
        label="Exclude Years"
      />
      <FormControlLabel
        control={
          <Switch
            checked={state.mexican}
            onChange={checkChange("mexican")}
            value="mexican"
            color="primary"
          />
        }
        label="Mexico"
      />
      {props.archived && (
        <FormControlLabel
          control={
            <Switch
              checked={state.archived}
              onChange={checkChange("archived")}
              value="archived"
              color="primary"
            />
          }
          label="Archived"
        />
      )}
    </Grid>
    <Grid item xs={12} sm={10} md={5}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={state.startWetDate || null}
            onChange={handleChange("startWetDate")}
            fullWidth
            clearable
            format={"MMMM Do, YYYY"}
            label="Start Wet Date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={state.endWetDate || null}
            onChange={handleChange("endWetDate")}
            fullWidth
            clearable
            format={"MMMM Do, YYYY"}
            label="End Wet Date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={state.startEstHarvestDate || null}
            onChange={handleChange("startEstHarvestDate")}
            fullWidth
            clearable
            format={"MMMM Do, YYYY"}
            label="Start Harvest Date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={state.endEstHarvestDate || null}
            onChange={handleChange("endEstHarvestDate")}
            fullWidth
            clearable
            format={"MMMM Do, YYYY"}
            label="End Harvest Date"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

TrialSearchBox.propTypes = {
  state: PropTypes.object.isRequired,
  checkChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

const styles = theme => ({
  searchContainer: {
    marginBottom: 20
  },
  searchField: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 70
    },
    marginBottom: 20
  }
});

export default withStyles(styles)(TrialSearchBox);
