import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { FilterDrawer } from "./views";

const lesserComparison = (maturityDate, date) => {
  if (isNaN(date)) {
    return false;
  }
  if (maturityDate.getMonth() < date.getMonth()) {
    return true;
  } else if (
    maturityDate.getMonth() === date.getMonth() &&
    maturityDate.getDate() < date.getDate()
  ) {
    return true;
  }
  return false;
};

const greaterComparison = (maturityDate, date) => {
  if (isNaN(date)) {
    return false;
  }
  if (maturityDate.getMonth() > date.getMonth()) {
    return true;
  } else if (
    maturityDate.getMonth() === date.getMonth() &&
    maturityDate.getDate() > date.getDate()
  ) {
    return true;
  }
  return false;
};

const momentToDateStr = momentObj => {
  if (momentObj === null || momentObj === undefined) {
    return "";
  }
  const dateObj = moment(momentObj._d);
  return dateObj.format("YYYY-MM-DD");
};

class Filter extends Component {
  constructor(props) {
    super(props);
    const { search } = props.reports;
    const regions = {};
    search.regions.forEach(region => {
      regions[region.id] = region;
    });
    const suppliers = {};
    search.suppliers.forEach(supplier => {
      suppliers[supplier.id] = supplier;
    });
    const growers = {};
    search.growers.forEach(grower => {
      growers[grower.id] = grower;
    });
    const shippers = {};
    search.shippers.forEach(shipper => {
      shippers[shipper.id] = shipper;
    });

    this.state = {
      grower: growers,
      shipper: shippers,
      area: {},
      commodity: {},
      region: regions,
      suppliers: suppliers,
      supplier: {},
      startDate: momentToDateStr(search.startWetDate),
      endDate: momentToDateStr(search.endWetDate),
      excludeYear: search.excludeYear,
      wetDateSearch: true,
      conventional: true,
      organic: true,
      growerLike: false,
      hide: {
        grower: false,
        shipper: false,
        ranch: false,
        supplier: false
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.notFiltered = this.notFiltered.bind(this);
    this.notFilteredRecord = this.notFilteredRecord.bind(this);
    this.toggleHide = this.toggleHide.bind(this);
  }

  toggleHide = type => () => {
    this.setState({
      ...this.state,
      hide: { ...this.state.hide, [type]: !this.state.hide[type] }
    });
  };

  handleChange = type => recordId => () => {
    const filteredList = this.state[type];
    if (filteredList[recordId]) {
      delete filteredList[recordId];
      this.setState({ ...this.state, [type]: filteredList });
    } else {
      filteredList[recordId] = true;
      this.setState({ ...this.state, [type]: filteredList });
    }
  };

  handleDateChange = dateType => moment => {
    let newDate = moment;
    if (moment === null) {
      newDate = "";
    }
    this.setState({ ...this.state, [dateType]: newDate });
  };

  toggleFilterChange = type => e => {
    this.setState({ ...this.state, [type]: e.target.checked });
  };

  updateSelected = type => records => {
    const recordObjs = {};
    records.forEach(record => {
      recordObjs[record.id] = record;
    });
    this.setState({ ...this.state, [type]: recordObjs });
  };

  notFiltered = trial => {
    try {
      if (
        Object.values(this.state["grower"]).length !== 0 &&
        !(trial.grower && this.state["grower"][trial.grower.id])
      ) {
        return false;
      } else if (
        Object.values(this.state["shipper"]).length !== 0 &&
        !(trial.shipper && this.state["shipper"][trial.shipper.id])
      ) {
        return false;
      } else if (
        Object.values(this.state["area"]).length !== 0 &&
        !(trial.area && this.state["area"][trial.area.id])
      ) {
        return false;
      } else if (
        trial.commodity &&
        this.state["commodity"][trial.commodity.id]
      ) {
        return false;
      } else if (
        Object.values(this.state["region"]).length !== 0 &&
        !(trial.region && this.state["region"][trial.region.id])
      ) {
        return false;
      } else if (
        !this.state["conventional"] &&
        trial.culturalPractice === "conventional"
      ) {
        return false;
      } else if (
        !this.state["organic"] &&
        trial.culturalPractice === "organic"
      ) {
        return false;
      }
      return true;
    } catch (err) {
      console.error(err, trial);
      return true;
    }
  };

  notFilteredRecord = record => {
    const { varieties } = this.props.varieties;
    const variety = varieties[record.varietyId] || {};

    const { companies } = this.props.companies;
    const supplier =
      variety.supplier && variety.supplier.id
        ? variety.supplier
        : companies[variety.supplier] || {};

    if (this.state["supplier"][supplier.id]) {
      return false;
    }
    if (this.state["growerLike"] && record.growerLike !== true) {
      return false;
    }

    // Filter for date
    let evalDate = record.maturityDate ? new Date(record.maturityDate) : null;
    if (this.state["wetDateSearch"]) {
      const { trials } = this.props.trials;
      const trial = trials[record.trialId] || {};
      evalDate = trial.wetDate ? new Date(trial.wetDate) : null;
    }

    if (isNaN(evalDate) || evalDate === null) {
      return false;
    }
    let startDate = new Date(this.state["startDate"]);
    let endDate = new Date(this.state["endDate"]);

    if (this.state["excludeYear"]) {
      if (lesserComparison(startDate, endDate)) {
        if (lesserComparison(evalDate, startDate)) {
          return false;
        }
        if (greaterComparison(evalDate, endDate)) {
          return false;
        }
      } else {
        if (
          lesserComparison(evalDate, startDate) &&
          greaterComparison(evalDate, endDate)
        ) {
          return false;
        }
      }
    } else {
      if (!isNaN(startDate) && evalDate < startDate) {
        return false;
      }
      if (!isNaN(endDate) && evalDate > endDate) {
        return false;
      }
    }
    return true;
  };

  listNonFilteredRecords = records => {
    const { trials } = this.props.trials;
    const filteredRecords = {};
    records.forEach(record => {
      const trial = trials[record.trialId];
      if (trial && this.notFiltered(trial) && this.notFilteredRecord(record)) {
        filteredRecords[record.id] = record;
      }
    });
    return filteredRecords;
  };

  columnRecords = records => {
    const { mainVarietyIds } = this.props;
    const selected = {};
    const { varieties } = this.props.varieties;
    Object.values(records).forEach(record => {
      const variety = varieties[record.variety] || {};
      if (mainVarietyIds.includes(variety.id)) {
        selected[record.id] = record;
      }
    });
    return Object.values(selected);
  };

  render() {
    const { trialVarieties } = this.props.trialVarieties;
    const trialVarietyRecords = this.columnRecords(trialVarieties);
    const filteredTrialVarieties = this.listNonFilteredRecords(
      trialVarietyRecords
    );

    const { fieldVarieties } = this.props.fieldVarieties;
    const fieldVarietyRecords = this.columnRecords(fieldVarieties);
    const fitleredFieldVarieties = this.listNonFilteredRecords(
      fieldVarietyRecords
    );

    const records = trialVarietyRecords.concat(fieldVarietyRecords);

    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        filteredTrialVarietyList: filteredTrialVarieties,
        filteredFieldVarietyList: fitleredFieldVarieties,
        hideList: this.state.hide,
        excludeYear: this.state.excludeYear,
        wetDateSearch: this.state.wetDateSearch
      });
    });
    return (
      <FilterDrawer
        show={this.props.show}
        filters={this.state}
        records={records}
        handleFilterCheckboxChange={this.handleChange}
        handleDateChange={this.handleDateChange}
        toggleFilterDrawer={this.props.toggleFilterDrawer}
        toggleFilterChange={this.toggleFilterChange}
        toggleHide={this.toggleHide}
        updateSelected={this.updateSelected}
      >
        {children}
      </FilterDrawer>
    );
  }
}

Filter.propTypes = {
  mainVarietyIds: PropTypes.array.isRequired,
  variety: PropTypes.object,
  children: PropTypes.node,
  show: PropTypes.bool,
  toggleFilterDrawer: PropTypes.func
};

const mapStateToProps = ({
  companies,
  fieldVarieties,
  reports,
  trials,
  trialVarieties,
  varieties
}) => ({
  companies,
  fieldVarieties,
  reports,
  trials,
  trialVarieties,
  varieties
});

export default connect(mapStateToProps, null)(Filter);
