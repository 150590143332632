import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DownshiftSingle from "../DownshiftSingle";
import { fetchRegions } from "../../actions/regions";

class RegionSelect extends Component {
  render() {
    const { regions, waiting } = this.props.regions;
    const results = regions || {};

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Region"}
        label={"Region"}
        search={this.props.fetchRegions}
        results={results}
        initialItem={this.props.region}
        createItem={this.props.createRegion}
        selectedItemParent={this.props.setRegion}
      />
    );
  }
}

RegionSelect.propTypes = {
  region: PropTypes.object,
  createRegion: PropTypes.func,
  setRegion: PropTypes.func.isRequired
};

const mapStateToProps = ({ regions }) => ({ regions });

export default connect(mapStateToProps, {
  fetchRegions
})(RegionSelect);
