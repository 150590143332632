import React, { Component } from "react";
import { Navbar } from "../containers";
import { TrialsList } from "../containers/trials";
import { LoadingSpinner } from "../ui";
import { connect } from "react-redux";
import { fetchProfile } from "../actions/profiles";

class Trials extends Component {
  componentDidMount() {
    const { userId } = this.props.user;
    this.props.fetchProfile(userId);
  }
  render() {
    const { waiting } = this.props.profiles;
    return (
      <Navbar currentPage="trials">
        {waiting ? (
          <LoadingSpinner />
        ) : (
          <TrialsList title={"Trials"} archived={true} />
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  profiles: state.profiles
});

export default connect(
  mapStateToProps,
  {
    fetchProfile
  }
)(Trials);
