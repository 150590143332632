import {
  CREATE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAILURE,
  UPDATE_REPORT_SEARCH,
  CHECK_REPORT_STATUS,
  CHECK_REPORT_STATUS_SUCCESS,
  CHECK_REPORT_STATUS_FAILURE,
  EMAIL_REPORT,
  EMAIL_REPORT_SUCCESS,
  EMAIL_REPORT_FAILURE,
  UPDATE_CHECK_REPORT_STATUS
} from "../actions/reports";

const INITIAL_STATE = {
  error: null,
  waiting: false,
  search: {
    searchField: "",
    excludeYear: false,
    startWetDate: null,
    endWetDate: null,
    regions: [],
    suppliers: [],
    growers: [],
    shippers: []
  },
  status: null,
  reportId: null,
  percentageLeft: 0,
  link: null,
  checkReportStatus: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_REPORT_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          [action.key]: action.value
        }
      };
    case EMAIL_REPORT:
      return { ...state, error: null, waiting: true };
    case EMAIL_REPORT_SUCCESS:
      return { ...state, waiting: false };
    case EMAIL_REPORT_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case CREATE_REPORT:
      return {
        ...state,
        error: null,
        waiting: true,
        percentageLeft: 0,
        checkReportStatus: true,
        status: null
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        waiting: false,
        reportId: action.data.id,
        status: action.data.status
      };
    case CREATE_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
        waiting: false,
        status: "failed"
      };
    case CHECK_REPORT_STATUS:
      return { ...state, waiting: true, error: null };
    case CHECK_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        waiting: false,
        status: action.data.status,
        percentageLeft: action.data.percentageLeft,
        link: action.data.link,
        error: action.data.error
      };
    case CHECK_REPORT_STATUS_FAILURE:
      return {
        ...state,
        waiting: false,
        status: "failed",
        error: action.error
      };
    case UPDATE_CHECK_REPORT_STATUS:
      return { ...state, checkReportStatus: action.value || false };
    default:
      return state;
  }
}
