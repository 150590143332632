import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { SupplierSelect } from "../../companies";

import { DatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";

export const SubVarieties = ({
  subVarieties,
  removeSubVariety,
  combineWaiting,
  ...props
}) => (
  <Grid item xs={12}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="subtitle1" gutterBottom>
              Experimental Varieties
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {combineWaiting ? (
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                <CircularProgress size={80} thickness={7} color={"primary"} />
              </Grid>
            </TableCell>
          </TableRow>
        ) : (
          subVarieties.map(subVariety => (
            <TableRow key={subVariety.id}>
              <TableCell>{subVariety.name}</TableCell>
              <TableCell align="right">
                <Button
                  size="small"
                  aria-label="Remove"
                  onClick={removeSubVariety(subVariety.id)}
                >
                  Remove From List
                </Button>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </Grid>
);
SubVarieties.propTypes = {
  subVarieties: PropTypes.array.isRequired,
  removeSubVariety: PropTypes.func.isRequired,
  combineWaiting: PropTypes.bool.isRequired
};

export const VarietyInputs = ({
  variety,
  supplier,
  title,
  setSupplier,
  handleChange,
  handleDateChange,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={12}>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <TextField
        fullWidth
        label="Commercial Name"
        defaultValue={variety.name || ""}
        onChange={handleChange("name")}
        variant="outlined"
      />
    </Grid>
    <Grid item xs={6}>
      <DatePicker
        value={variety.dateReceived}
        inputVariant="outlined"
        onChange={handleDateChange("dateReceived")}
        fullWidth
        clearable
        format={"MMMM Do, YYYY"}
        label="Date Received"
      />
    </Grid>
    <Grid item xs={6}>
      <SupplierSelect supplier={supplier} setSupplier={setSupplier} />
    </Grid>
    <Grid item xs={6}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="status-list">Status</InputLabel>
        <Select
          fullWidth
          label="Status"
          value={variety.status || ""}
          onChange={handleChange("status")}
          input={
            <OutlinedInput name="status" labelWidth={45} id="status-list" />
          }
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"screening"}>Screening</MenuItem>
          <MenuItem value={"product_development"}>Product Development</MenuItem>
          <MenuItem value={"sale_trials"}>Sale Trials</MenuItem>
          <MenuItem value={"commercial"}>Commercial</MenuItem>
          <MenuItem value={"drop"}>Drop</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <TextField
        fullWidth
        label="Resistance"
        defaultValue={variety.resistance || ""}
        onChange={handleChange("resistance")}
        variant="outlined"
      />
    </Grid>
  </React.Fragment>
);

VarietyInputs.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  setSupplier: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  variety: PropTypes.object.isRequired
};

const styles = theme => ({
  deleteButton: {
    backgroundColor: theme.palette.primary.dark
  }
});

export const VarietyButtons = withStyles(styles)(
  ({ update, varietyId, onSave, onDelete, ...props }) => (
    <React.Fragment>
      <Grid item xs={6} sm={3}>
        <Button
          size="large"
          component={Link}
          to="/varieties"
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
      </Grid>
      {update && (
        <Grid item xs={6} sm={3}>
          <Button
            fullWidth
            size="large"
            component={Link}
            to={"/varieties/" + varietyId + "/combine"}
            color="secondary"
            variant="contained"
          >
            Combine
          </Button>
        </Grid>
      )}
      <Grid item xs={6} sm={3}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
      {update && (
        <Grid item xs={6} sm={3}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.deleteButton}
            onClick={onDelete}
            fullWidth
          >
            Delete
          </Button>
        </Grid>
      )}
    </React.Fragment>
  )
);
VarietyButtons.propTypes = {
  varietyId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  update: PropTypes.bool
};
