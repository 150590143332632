import { CALL_API, Schemas } from '../middleware/api';

export const FETCH_GROWER = 'FETCH_GROWER';
export const FETCH_GROWER_SUCCESS = 'FETCH_GROWER_SUCCESS';
export const FETCH_GROWER_FAILURE = 'FETCH_GROWER_FAILURE';

export const FETCH_GROWERS = 'FETCH_GROWERS';
export const FETCH_GROWERS_SUCCESS = 'FETCH_GROWERS_SUCCESS';
export const FETCH_GROWERS_FAILURE = 'FETCH_GROWERS_FAILURE';

export const FETCH_SUB_GROWER = 'FETCH_SUB_GROWER';
export const FETCH_SUB_GROWER_SUCCESS = 'FETCH_SUB_GROWER_SUCCESS';
export const FETCH_SUB_GROWER_FAILURE = 'FETCH_SUB_GROWER_FAILURE';

export const FETCH_SUB_GROWERS = 'FETCH_SUB_GROWERS';
export const FETCH_SUB_GROWERS_SUCCESS = 'FETCH_SUB_GROWERS_SUCCESS';
export const FETCH_SUB_GROWERS_FAILURE = 'FETCH_SUB_GROWERS_FAILURE';

export const fetchGrower = (id) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [FETCH_GROWER, FETCH_GROWER_SUCCESS, FETCH_GROWER_FAILURE],
      endpoint: `/companies/${id}`,
      schema: Schemas.COMPANY,
      method: 'get'
    }
  })
)

export const fetchGrowers = (search="") => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_GROWERS, FETCH_GROWERS_SUCCESS, FETCH_GROWERS_FAILURE],
      endpoint: `/growers?search=${search}`,
      schema: Schemas.COMPANIES,
      method: 'get'
    }
  })
)

export const fetchGrowerByTrialId = () => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_GROWERS, FETCH_GROWERS_SUCCESS, FETCH_GROWERS_FAILURE ],
      endpoint: "/grower_by_trial",
      schema: Schemas.COMPANIES,
      method: 'get'
    }
  })
)

export const fetchSubGrower = (id) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [FETCH_SUB_GROWER, FETCH_SUB_GROWER_SUCCESS, FETCH_SUB_GROWER_FAILURE],
      endpoint: `/companies/${id}`,
      schema: Schemas.COMPANY,
      method: 'get'
    }
  })
)

export const fetchSubGrowers = (search = "") => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_SUB_GROWERS, FETCH_SUB_GROWERS_SUCCESS, FETCH_SUB_GROWERS_FAILURE],
      endpoint: `/growers?search=${search}`,
      schema: Schemas.COMPANIES,
      method: 'get'
    }
  })
)
