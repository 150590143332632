import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchNursery,
  updateNursery,
  updateNurseryLocally,
  createNursery
} from "../../../actions/nurseries";
import { createNurseVariety } from "../../../actions/nurse_varieties";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { NurseryEditableItems, NurseryButtons } from "./views";
import { NurseryPlotGrid, PlotNurseVarieties } from "../../nurseries";
import { SetVariety } from "../../trials";

class NurseryEdit extends Component {
  setRecord = recordKey => record => {
    this.props.updateNurseryLocally(this.props.id, recordKey, record);
    const id = record.id || null;
    const recordKeyId = recordKey + "Id";
    this.props.updateNurseryLocally(this.props.id, recordKeyId, id);
  };

  handleChange = name => e => {
    const value = name === "active" ? e.target.checked : e.target.value;
    this.props.updateNurseryLocally(this.props.id, name, value);
  };

  handleDateChange = name => momentObj => {
    if (momentObj === null) {
      this.props.updateNurseryLocally(this.props.id, name, null);
    } else {
      const dateObj = moment(momentObj._d);
      const dateStr = dateObj.format("YYYY-MM-DD");
      this.props.updateNurseryLocally(this.props.id, name, dateStr);
    }
  };

  onSave = () => {
    if (this.props.update) {
      this.props.updateNursery(this.props.id, this.props.nursery);
    } else {
      this.props.createNursery(this.props.nursery);
    }
  };

  render() {
    const title = this.props.update ? "Edit Nursery" : "Create Nursery";
    const { nursery } = this.props;
    return (
      <Grid container spacing={2}>
        <NurseryEditableItems
          title={title}
          setRecord={this.setRecord}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          nursery={nursery}
        />
        <Grid item xs={12}>
          <NurseryPlotGrid />
        </Grid>
        <Grid item xs={12}>
          <SetVariety
            parent={nursery}
            createVarietyConnection={this.props.createNurseVariety}
          />
        </Grid>
        <Grid item xs={12}>
          <PlotNurseVarieties />
        </Grid>
        <NurseryButtons onSave={this.onSave} />
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    nurseries: state.nurseries
  };
};

export default connect(mapStateToProps, {
  fetchNursery,
  updateNurseryLocally,
  updateNursery,
  createNursery,
  createNurseVariety
})(NurseryEdit);
