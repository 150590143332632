import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchRanch } from "../actions/ranches";

import { Navbar } from "../containers/";
import { RanchEdit } from "../containers/ranches";

import Grid from "@material-ui/core/Grid";

class Ranch extends Component {
  componentWillMount() {
    this.props.fetchRanch(this.props.match.params.id);
  }

  render() {
    const { ranch } = this.props.ranches;
    return (
      <Navbar currentPage="ranches">
        <Grid container>
          <RanchEdit ranch={ranch} id={this.props.match.params.id} />
        </Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    ranches: state.ranches
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRanch
  }
)(Ranch);
