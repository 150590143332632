import React, { Component } from "react";
import { connect } from "react-redux";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import ReportVarietyLoadMore from "./ReportVarietyLoadMore";
import ReportSearchBox from "./ReportSearchBox";
import { getUnixEpoch, dateStrToNumber } from "../../helpers/utils";

import { fetchRegions } from "../../actions/regions";
import { updateReportSearch } from "../../actions/reports";

import { IndexLinkButton, PageHeader } from "../../ui";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

class ReportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.generateSearchParam = this.generateSearchParam.bind(this);
  }

  componentWillMount() {
    this.props.fetchRegions();
  }

  handleChange = (value, refine) => {
    this.props.updateReportSearch("searchField", value);
    refine(value);
  };

  handleCheckChange = type => e => {
    this.props.updateReportSearch(type, e.target.checked);
  };

  handleDateChange = key => moment => {
    this.props.updateReportSearch(key, moment);
  };

  handleMultipleSelect = type => objs => {
    const objIds = objs.map(obj => ({ id: obj.objectID, name: obj.name }));
    this.props.updateReportSearch(type, objIds);
  };

  handleRegionSelect = objs => {
    const objIds = objs.map(obj => ({ id: obj.id, name: obj.name }));
    this.props.updateReportSearch("regions", objIds);
  };

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ ...this.state, checked: newChecked });
  };

  buildFacetFilters = () => {
    const { suppliers, shippers, growers, regions } = this.props.reports.search;
    let facetFilters = [];
    if (suppliers.length !== 0) {
      const suppliersArr = [];
      suppliers.forEach(supplier => {
        suppliersArr.push(`supplier_id:${supplier.id}`);
      });
      facetFilters.push(suppliersArr.join(" OR "));
    }
    if (shippers.length !== 0) {
      const shippersArr = [];
      shippers.forEach(shipper => {
        shippersArr.push(`shippers_id:${shipper.id}`);
      });
      facetFilters.push(shippersArr.join(" OR "));
    }
    if (growers.length !== 0) {
      const growersArr = [];
      growers.forEach(grower => {
        growersArr.push(`growers_id:${grower.id}`);
      });
      facetFilters.push(growersArr.join(" OR "));
    }
    if (regions.length !== 0) {
      const regionsArr = [];
      regions.forEach(region => {
        regionsArr.push(`regions_id:${region.id}`);
      });
      facetFilters.push(regionsArr.join(" OR "));
    }
    return facetFilters;
  };

  buildFilters = () => {
    const { startWetDate, endWetDate, excludeYear } = this.props.reports.search;
    let filters = [];
    if (excludeYear) {
      if (startWetDate) {
        filters.push(`end_wet_date_days >= ${dateStrToNumber(startWetDate)}`);
      }
      if (endWetDate) {
        filters.push(`start_wet_date_days <= ${dateStrToNumber(endWetDate)}`);
      }
    } else {
      if (startWetDate) {
        filters.push(`end_wet_date_unix >= ${getUnixEpoch(startWetDate)}`);
      }
      if (endWetDate) {
        filters.push(`start_wet_date_unix <= ${getUnixEpoch(endWetDate)}`);
      }
    }
    const facetFilters = this.buildFacetFilters().join(" AND ");
    if (facetFilters.length > 0) {
      filters.push(facetFilters);
    }
    const normalFilters = filters.join(" AND ");
    return normalFilters;
  };

  generateSearchParam = () => {
    const ids = this.state.checked.join(",");
    return `?variety_ids=${ids}`;
  };

  render() {
    const { checked } = this.state;
    const { search } = this.props.reports;
    const { regions } = this.props.regions;
    const filters = this.buildFilters();
    const searchParams = this.generateSearchParam(this.state.checked);
    return (
      <React.Fragment>
        <PageHeader title={"Reports"} half={6}>
          <IndexLinkButton
            disabled={checked.length < 2}
            linkUrl={{ pathname: "/reports/compare", search: searchParams }}
          />
        </PageHeader>
        <InstantSearch indexName={`Variety_${process.env.REACT_APP_NAME}`} searchClient={searchClient}>
          <Configure hitsPerPage={25} filters={filters} />
          <ReportSearchBox
            defaultRefinement={search.searchField}
            state={search}
            handleChange={this.handleChange}
            handleCheckChange={this.handleCheckChange}
            handleDateChange={this.handleDateChange}
            handleMultipleSelect={this.handleMultipleSelect}
            handleRegionSelect={this.handleRegionSelect}
          />
          <ReportVarietyLoadMore
            handleToggle={this.handleToggle}
            checked={checked}
            regions={regions}
          />
        </InstantSearch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    reports: state.reports,
    regions: state.regions
  };
};

export default connect(mapStateToProps, {
  fetchRegions,
  updateReportSearch
})(ReportsList);
