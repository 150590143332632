import React from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import ReportCombineHit from "./ReportCombineHit";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const ReportVarietyLoadMore = ({
  hits,
  hasMore,
  refine,
  regions,
  ...props
}) => (
  <React.Fragment>
    <div className={props.classes.tableRoot}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Supplier
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Commodity
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Growers
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Shippers
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" gutterBottom>
                Regions
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {hits.map(hit => (
            <ReportCombineHit
              key={hit.objectID}
              hit={hit}
              handleToggle={props.handleToggle}
              checked={props.checked}
              report={true}
              regions={regions}
            />
          ))}
        </TableBody>
      </Table>
    </div>
    <Grid item xs={12}>
      <Button
        color="primary"
        fullWidth
        onClick={refine}
        disabled={!hasMore}
        className={props.classes.button}
      >
        Show more results
      </Button>
    </Grid>
  </React.Fragment>
);

const styles = theme => ({
  button: {
    cursor: "pointer"
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  progressGrid: {
    marginTop: 40
  }
});

export default connectInfiniteHits(withStyles(styles)(ReportVarietyLoadMore));
