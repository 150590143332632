import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE_LOCALLY = "UPDATE_PROFILE_LOCALLY";

export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const FETCH_PROFILES = "FETCH_PROFILES";
export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS";
export const FETCH_PROFILES_FAILURE = "FETCH_PROFILES_FAILURE";

export const FETCH_MORE_PROFILES = "FETCH_MORE_PROFILES";
export const FETCH_MORE_PROFILES_SUCCESS = "FETCH_MORE_PROFILES_SUCCESS";
export const FETCH_MORE_PROFILES_FAILURE = "FETCH_MORE_PROFILES_FAILURE";

export const CREATE_PROFILE = "CREATE_PROFILE";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_FAILURE = "CREATE_PROFILE_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const DELETE_PROFILE = "DELETE_PROFILE";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_FAILURE = "DELETE_PROFILE_FAILURE";

export const SEND_PASSWORD_RESET = "SEND_PASSWORD_RESET";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";
export const SEND_PASSWORD_RESET_FAILURE = "SEND_PASSWORD_RESET_FAILURE";

export const clearProfile = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_PROFILE
  });

export const sendPasswordReset = userId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        SEND_PASSWORD_RESET,
        SEND_PASSWORD_RESET_SUCCESS,
        SEND_PASSWORD_RESET_FAILURE
      ],
      endpoint: `/users/${userId}/send_password_reset`,
      method: "post"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Sent password reset successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to send password reset"
      }
    ]
  });

export const updateProfileLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PROFILE_LOCALLY,
    key: key,
    value: value
  });

export const fetchProfile = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_PROFILE, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE],
      endpoint: `/users/${id}`,
      schema: Schemas.PROFILE,
      method: "get"
    }
  });

export const fetchProfiles = () => (dispatch, getState) => {
  const { page } = getState().profiles;
  return dispatch({
    [CALL_API]: {
      types: [FETCH_PROFILES, FETCH_PROFILES_SUCCESS, FETCH_PROFILES_FAILURE],
      endpoint: `/users?page=${page}`,
      schema: Schemas.PROFILES,
      method: "get"
    }
  });
};

export const fetchMoreProfiles = () => (dispatch, getState) => {
  const { page } = getState().profiles;
  return dispatch({
    [CALL_API]: {
      types: [FETCH_PROFILES, FETCH_PROFILES_SUCCESS, FETCH_PROFILES_FAILURE],
      endpoint: `/users?page=${page + 1}`,
      schema: Schemas.PROFILES,
      method: "get"
    }
  });
};

export const createProfile = attributes => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_PROFILE, CREATE_PROFILE_SUCCESS, CREATE_PROFILE_FAILURE],
      endpoint: "/users",
      schema: Schemas.PROFILE,
      method: "post",
      data: { user: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a Profile successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Profile"
      }
    ]
  });

export const updateProfile = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE],
      endpoint: `/users/${id}`,
      schema: Schemas.PROFILE,
      method: "patch",
      data: { user: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated Profile successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update Profile"
      }
    ]
  });

export const deleteProfile = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_PROFILE, DELETE_PROFILE_SUCCESS, DELETE_PROFILE_FAILURE],
      endpoint: `/users/${id}`,
      schema: Schemas.PROFILE,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted a Profile successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete Profile"
      }
    ]
  });
