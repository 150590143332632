import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DownshiftSingle from "../DownshiftSingle";
import { fetchAreas } from "../../actions/areas";

class AreaSelect extends Component {
  render() {
    const { areas, waiting } = this.props.areas;
    const results = areas || {};

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Area"}
        label={"Area"}
        search={this.props.fetchAreas}
        results={results}
        initialItem={this.props.area}
        createItem={this.props.createArea}
        selectedItemParent={this.props.setArea}
      />
    );
  }
}

AreaSelect.propTypes = {
  area: PropTypes.object,
  createArea: PropTypes.func,
  setArea: PropTypes.func.isRequired
};

const mapStateToProps = ({ areas }) => ({ areas });
export default connect(mapStateToProps, {
  fetchAreas
})(AreaSelect);
