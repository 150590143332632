import { CALL_API, Schemas } from '../middleware/api';

export const FETCH_COMPANY_CATEGORIES = 'FETCH_COMPANY_CATEGORIES';
export const FETCH_COMPANY_CATEGORIES_SUCCESS = 'FETCH_COMPANY_CATEGORIES_SUCCESS';
export const FETCH_COMPANY_CATEGORIES_FAILURE = 'FETCH_COMPANY_CATEGORIES_FAILURE';

export const fetchCompanyCategories = () => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_COMPANY_CATEGORIES, FETCH_COMPANY_CATEGORIES_SUCCESS, FETCH_COMPANY_CATEGORIES_FAILURE ],
      endpoint: "/company_categories",
      schema: Schemas.COMPANY_CATEGORIES,
      method: 'get'
    }
  })
)
