import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfile } from "../actions/profiles";
import { fetchPersonalViews } from "../actions/personal_views";
import { Navbar } from "../containers";
import { ProfileEdit } from "../containers/profiles";
import { LoadingSpinner } from "../ui";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "users"
    };
  }

  componentDidMount() {
    this.props.fetchProfile(this.props.match.params.id);
    this.props.fetchPersonalViews(this.props.match.params.id);
    this.setCurrentPage(this.props.match.params.id);
  }

  componentWillReceiveProps(newProps) {
    const newProfileId = newProps.match.params.id;
    if (newProfileId.toString() !== this.props.match.params.id.toString()) {
      this.props.fetchProfile(newProfileId);
      this.setCurrentPage(newProfileId);
    }
  }

  setCurrentPage = newProfileId => {
    const { userId } = this.props.user;
    const currentPage = userId === newProfileId ? "profile" : "users";
    if (this.state.currentPage !== currentPage) {
      this.setState({ currentPage: currentPage });
    }
  };

  render() {
    const { waiting } = this.props.profiles;
    return (
      <Navbar currentPage={this.state.currentPage}>
        {waiting ? (
          <LoadingSpinner />
        ) : (
          <ProfileEdit id={this.props.match.params.id} update={true} />
        )}
      </Navbar>
    );
  }
}

Profile.propTypes = {};

const mapStateToProps = state => {
  return {
    user: state.user,
    profiles: state.profiles
  };
};

export default connect(
  mapStateToProps,
  {
    fetchProfile,
    fetchPersonalViews
  }
)(Profile);
