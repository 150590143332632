import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const CLEAR_PERSON = "CLEAR_PERSON";
export const CLEAR_PEOPLE = "CLEAR_PEOPLE";
export const UPDATE_PERSON_LOCALLY = "UPDATE_PERSON_LOCALLY";
export const UPDATE_PERSON_SEARCH = "UPDATE_PERSON_SEARCH";

export const FETCH_PEOPLE = "FETCH_PEOPLE";
export const FETCH_PEOPLE_SUCCESS = "FETCH_PEOPLE_SUCCESS";
export const FETCH_PEOPLE_FAILURE = "FETCH_PEOPLE_FAILURE";

export const FETCH_PERSON = "FETCH_PERSON";
export const FETCH_PERSON_SUCCESS = "FETCH_PERSON_SUCCESS";
export const FETCH_PERSON_FAILURE = "FETCH_PERSON_FAILURE";

export const CREATE_PERSON = "CREATE_PERSON";
export const CREATE_PERSON_SUCCESS = "CREATE_PERSON_SUCCESS";
export const CREATE_PERSON_FAILURE = "CREATE_PERSON_FAILURE";

export const UPDATE_PERSON = "UPDATE_PERSON";
export const UPDATE_PERSON_SUCCESS = "UPDATE_PERSON_SUCCESS";
export const UPDATE_PERSON_FAILURE = "UPDATE_PERSON_FAILURE";

export const DELETE_PERSON = "DELETE_PERSON";
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS";
export const DELETE_PERSON_FAILURE = "DELETE_PERSON_FAILURE";

export const updateSearch = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PERSON_SEARCH,
    value: value
  });

export const clearPeople = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_PEOPLE
  });

export const clearPerson = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_PERSON
  });

export const updatePersonLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PERSON_LOCALLY,
    key: key,
    value: value
  });

export const fetchPeople = (search = "") => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_PEOPLE, FETCH_PEOPLE_SUCCESS, FETCH_PEOPLE_FAILURE],
      endpoint: `/people?search=${encodeURIComponent(search)}`,
      schema: Schemas.PEOPLE,
      method: "get"
    }
  });

// grower & subgrower and maybe by shipper
export const fetchPeopleAssociatedWith = (assocations = {}) => (
  dispatch,
  getState
) => {
  const params = [];
  if (assocations["regionId"]) {
    params.push(`region_id=${assocations["regionId"]}`);
  }
  if (assocations["commodityId"]) {
    params.push(`commodity_id=${assocations["commodityId"]}`);
  }
  if (assocations["growerIds"] && Array.isArray(assocations["growerIds"])) {
    params.push(`grower_ids=${assocations["growerIds"]}`);
  }
  if (
    assocations["subGrowerIds"] &&
    Array.isArray(assocations["subGrowerIds"])
  ) {
    params.push(`subgrower_ids=${assocations["subGrowerIds"]}`);
  }
  if (assocations["supplierIds"] && Array.isArray(assocations["supplierIds"])) {
    params.push(`supplier_ids=${assocations["supplierIds"].join(",")}`);
  }
  if (assocations["shipperIds"] && Array.isArray(assocations["shipperIds"])) {
    params.push(`shipper_ids=${assocations["shipperIds"].join(",")}`);
  }
  if (assocations["culturalPractice"] === "conventional") {
    params.push("cultural_practice=conventional");
  }
  if (assocations["culturalPractice"] === "organic") {
    params.push("cultural_practice=organic");
  }
  let url = `/people/associated`;
  if (params.length > 0) {
    url = url + "?" + params.join("&");
  }
  return dispatch({
    [CALL_API]: {
      types: [FETCH_PEOPLE, FETCH_PEOPLE_SUCCESS, FETCH_PEOPLE_FAILURE],
      endpoint: url,
      schema: Schemas.PEOPLE,
      method: "get"
    }
  });
};

export const fetchPerson = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_PERSON, FETCH_PERSON_SUCCESS, FETCH_PERSON_FAILURE],
      endpoint: `/people/${id}`,
      schema: Schemas.PERSON,
      method: "get"
    }
  });

export const createPerson = attributes => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_PERSON, CREATE_PERSON_SUCCESS, CREATE_PERSON_FAILURE],
      endpoint: `/people`,
      schema: Schemas.PERSON,
      method: "post",
      data: { person: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a Person successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Person"
      }
    ]
  });

export const updatePerson = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_PERSON, UPDATE_PERSON_SUCCESS, UPDATE_PERSON_FAILURE],
      endpoint: `/people/${id}`,
      schema: Schemas.PERSON,
      method: "patch",
      data: { person: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated Person successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update Person"
      }
    ]
  });

export const deletePerson = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_PERSON, DELETE_PERSON_SUCCESS, DELETE_PERSON_FAILURE],
      endpoint: `/people/${id}`,
      schema: Schemas.PERSON,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted a Person successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete Person"
      }
    ]
  });
