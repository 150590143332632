import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  getColor,
  graphFormatDateStr,
  dateStrToNumber,
  filterEmptyObjects,
  REPORT_TYPE_MULTIPLE_VARIETY,
  fetchEmailDrawerCompanies
} from "../../../helpers";
import {
  submitStatisticalReport,
  emailStatisticalReport,
  submitExcelReport,
  checkStatisticalReportStatus,
  updateCheckReportStatus
} from "../../../actions/reports";
import { fetchCompaniesById } from "../../../actions/companies";
import { fetchPeopleAssociatedWith } from "../../../actions/people";
import { FilterButton, Header, ReportColumns, NoEvaluations } from "./views";
import { ReportEmailDrawer } from "../index";
import { GraphView, Navbar } from "../../index";
import { ReportProcessing } from "../../../ui";
import { Grid } from "@material-ui/core";

class ReportMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailDrawer: false,
      showProcessingReport: false
    };
    this.trialVarietyColor = this.trialVarietyColor.bind(this);
    this.getVarietyName = this.getVarietyName.bind(this);
  }

  downloadPDF = () => {
    this.props.submitStatisticalReport(
      REPORT_TYPE_MULTIPLE_VARIETY,
      this.props.filteredFieldVarietyList,
      this.props.filteredTrialVarietyList,
      this.props.hideList,
      this.props.mainVarietyIds,
      { orderByWetDate: this.props.wetDateSearch }
    );
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  downloadExcel = () => {
    this.props.submitExcelReport(
      this.props.filteredFieldVarietyList,
      this.props.filteredTrialVarietyList,
      this.props.mainVarietyIds
    );
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  sendEmail = peopleIds => {
    this.props.emailStatisticalReport(
      REPORT_TYPE_MULTIPLE_VARIETY,
      this.props.filteredFieldVarietyList,
      this.props.filteredTrialVarietyList,
      this.props.hideList,
      this.props.mainVarietyIds,
      peopleIds,
      { orderByWetDate: this.props.wetDateSearch }
    );
    this.closeEmailDrawer();
  };

  fetchCompaniesForEmail = (
    varieties,
    filteredTrialAndFieldVarieties
  ) => () => {
    const { trials } = this.props.trials;
    const filteredTrials = [];
    filteredTrialAndFieldVarieties.forEach(obj => {
      if (trials[obj.trialId]) {
        filteredTrials.push(trials[obj.trialId]);
      }
    });
    fetchEmailDrawerCompanies(
      varieties,
      filteredTrials,
      this.props.fetchCompaniesById
    );
    this.setState({ ...this.state, showEmailDrawer: true });
  };

  closeEmailDrawer = () => {
    this.setState({ ...this.state, showEmailDrawer: false });
  };

  combineTrialAndFieldVarieties = () => {
    const trialVarieties = Object.values(this.props.filteredTrialVarietyList);
    const fieldVarieties = Object.values(this.props.filteredFieldVarietyList);
    const combinedTrialAndFieldVarieties = trialVarieties.concat(
      fieldVarieties
    );
    return combinedTrialAndFieldVarieties;
  };

  filterTrialAndFieldVarieties = () => {
    const trialAndFieldVarieties = this.combineTrialAndFieldVarieties();
    const mainVarieties = this.compileMainVarieties();
    const filteredTrialAndFieldVarieties = filterEmptyObjects(
      trialAndFieldVarieties
    ).filter(record => (mainVarieties[record.variety] ? true : false));
    return this.orderColumns(filteredTrialAndFieldVarieties);
  };

  createDate = str => new Date(str);

  orderColumns = records => {
    const { trials } = this.props.trials;
    let convertDate = this.props.excludeYear
      ? dateStrToNumber
      : this.createDate;
    const orderByWetDate = this.props.wetDateSearch;
    return records.sort((a, b) => {
      let dateA = null;
      let dateB = null;
      if (orderByWetDate) {
        const trialA = trials[a.trialId] || {};
        dateA = convertDate(trialA.wetDate);
        const trialB = trials[b.trialId] || {};
        dateB = convertDate(trialB.wetDate);
      } else {
        dateA = convertDate(a.maturityDate);
        dateB = convertDate(b.maturityDate);
      }
      if (dateA > dateB) {
        return 1;
      }
      if (dateA < dateB) {
        return -1;
      }
      return 0;
    });
  };

  createPoint = record => {
    if (record.maturityDate && record.score) {
      return {
        x: graphFormatDateStr(record.maturityDate),
        y: record.score
      };
    }
    return null;
  };

  compileMainVarieties = () => {
    const { varieties } = this.props.varieties;
    const mainVarieties = {};
    this.props.mainVarietyIds.forEach(varietyId => {
      const variety = varieties[varietyId];
      if (variety !== undefined) {
        mainVarieties[variety.id] = variety;
      }
    });
    return mainVarieties;
  };

  compileMainVarietiesSubVarieties = () => {
    const { varieties } = this.props.varieties;
    const mainVarieties = this.compileMainVarieties();
    const subVarietiesByMainVariety = {};
    Object.values(mainVarieties).forEach(variety => {
      subVarietiesByMainVariety[variety.id] = variety.name;
      Object.values(varieties).forEach(v => {
        if (v.mainVarietyId === variety.id) {
          subVarietiesByMainVariety[v.id] = variety.name;
        }
      });
    });
    return subVarietiesByMainVariety;
  };

  seperateDataByVariety = () => {
    const subVarietiesByMainVariety = this.compileMainVarietiesSubVarieties();
    const seperatedVarieties = {};
    const records = this.filterTrialAndFieldVarieties();
    records
      .filter(record =>
        subVarietiesByMainVariety[record.variety] ? true : false
      )
      .forEach(record => {
        const mainVarietyKey = subVarietiesByMainVariety[record.variety];
        if (!seperatedVarieties[mainVarietyKey]) {
          seperatedVarieties[mainVarietyKey] = [];
        }
        const point = this.createPoint(record);
        if (point) {
          seperatedVarieties[mainVarietyKey].push(point);
        }
      });
    return seperatedVarieties;
  };

  compileData = () => {
    const varietyData = this.seperateDataByVariety();
    const data = Object.keys(varietyData).map(varietyName => {
      const trialVarietyPoints = varietyData[varietyName];
      return {
        id: varietyName,
        data: trialVarietyPoints
      };
    });
    return data;
  };

  trialVarietyColor = mainVarietyBySubVarieties => (
    trialVariety,
    graphData
  ) => {
    let color = null;
    graphData.forEach((graphGrouping, index) => {
      const varietyName = mainVarietyBySubVarieties[trialVariety.variety];
      if (graphGrouping.id === varietyName) {
        color = getColor(index);
      }
    });
    return color + "4D";
  };

  getVarietyName = mainVarietyBySubVarieties => trialVariety => {
    let varietyName = "";
    if (trialVariety && trialVariety.variety) {
      varietyName = mainVarietyBySubVarieties[trialVariety.variety];
    }
    return varietyName;
  };

  render() {
    const mainVarieties = this.compileMainVarieties();
    const filteredTrialAndFieldVarieties = this.filterTrialAndFieldVarieties();
    const mainVarietyBySubVarieties = this.compileMainVarietiesSubVarieties();
    const graphData = this.compileData();
    const { showProcessingReport, showEmailDrawer } = this.state;
    const { status } = this.props.reports;
    let processingReport = showProcessingReport;
    if (status === "completed" || status === "failed") {
      processingReport = false;
    }
    return (
      <Navbar currentPage={"reports"}>
        <Grid container spacing={2}>
          <Header
            varieties={Object.values(mainVarieties)}
            fetchCompaniesForEmail={this.fetchCompaniesForEmail(
              Object.values(mainVarieties),
              filteredTrialAndFieldVarieties
            )}
            downloadExcel={this.downloadExcel}
            downloadPDF={this.downloadPDF}
            processingReport={processingReport}
          />
          <FilterButton toggleFilterDrawer={this.props.toggleFilterDrawer} />
          {showProcessingReport && (
            <ReportProcessing
              checkReportStatusCall={this.props.checkStatisticalReportStatus}
            />
          )}

          {filteredTrialAndFieldVarieties.length === 0 ? (
            <NoEvaluations />
          ) : (
            <React.Fragment>
              <GraphView data={graphData} />
              <ReportColumns
                filteredTrialAndFieldVarieties={filteredTrialAndFieldVarieties}
                hideList={this.props.hideList}
                getVarietyName={this.getVarietyName(mainVarietyBySubVarieties)}
                trialVarietyColor={this.trialVarietyColor(
                  mainVarietyBySubVarieties
                )}
                graphData={graphData}
              />
            </React.Fragment>
          )}
        </Grid>
        <ReportEmailDrawer
          sendEmail={this.sendEmail}
          showDrawer={showEmailDrawer}
          closeDrawer={this.closeEmailDrawer}
        />
      </Navbar>
    );
  }
}

ReportMultiple.propTypes = {
  varieties: PropTypes.object,
  toggleFilterDrawer: PropTypes.func,
  hideList: PropTypes.object.isRequired,
  filteredTrialVarietyList: PropTypes.object.isRequired,
  wetDateSearch: PropTypes.bool,
  mainVarietyIds: PropTypes.array
};

const mapStateToProps = ({ reports, trials, trialVarieties, varieties }) => ({
  reports,
  trials,
  trialVarieties,
  varieties
});

export default connect(mapStateToProps, {
  fetchPeopleAssociatedWith,
  submitStatisticalReport,
  checkStatisticalReportStatus,
  emailStatisticalReport,
  submitExcelReport,
  updateCheckReportStatus,
  fetchCompaniesById
})(ReportMultiple);
