import {
  FETCH_COMMODITY,
  FETCH_COMMODITY_SUCCESS,
  FETCH_COMMODITY_FAILURE,
  FETCH_COMMODITIES_DETAILS,
  FETCH_COMMODITIES_DETAILS_SUCCESS,
  FETCH_COMMODITIES_DETAILS_FAILURE,
  FETCH_MORE_COMMODITIES,
  FETCH_MORE_COMMODITIES_SUCCESS,
  FETCH_MORE_COMMODITIES_FAILURE,
  FETCH_COMMODITIES_CLEAR,
  FETCH_COMMODITIES_CLEAR_SUCCESS,
  FETCH_COMMODITIES_CLEAR_FAILURE,
  COMMODITY_ADD_LABEL,
  COMMODITY_ADD_LABEL_SUCCESS,
  COMMODITY_ADD_LABEL_FAILURE,
  COMMODITY_ADD_TAG,
  COMMODITY_ADD_TAG_SUCCESS,
  COMMODITY_ADD_TAG_FAILURE,
  COMMODITY_REMOVE_LABEL,
  COMMODITY_REMOVE_LABEL_SUCCESS,
  COMMODITY_REMOVE_LABEL_FAILURE,
  COMMODITY_REMOVE_TAG,
  COMMODITY_REMOVE_TAG_SUCCESS,
  COMMODITY_REMOVE_TAG_FAILURE,
  CREATE_COMMODITY,
  CREATE_COMMODITY_SUCCESS,
  CREATE_COMMODITY_FAILURE,
  CLEAR_COMMODITY,
  CLEAR_COMMODITIES,
  UPDATE_COMMODITY,
  UPDATE_COMMODITY_SUCCESS,
  UPDATE_COMMODITY_FAILURE,
  DELETE_COMMODITY,
  DELETE_COMMODITY_SUCCESS,
  DELETE_COMMODITY_FAILURE,
  COMMODITY_TOGGLE_TAG_LOCALLY,
  UPDATE_COMMODITY_LOCALLY,
  DELETE_COMMODITY_CRITERIUM_LOCALLY,
  ADD_COMMODITY_SUBCRITERIUM_LOCALLY,
  SET_COMMODITY_LOCALLY
} from "../actions/commodities";
import {
  CREATE_TRIAL_SUCCESS,
  FETCH_TRIAL_SUCCESS,
  UPDATE_TRIAL_SUCCESS
} from "../actions/trials";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS,
  CREATE_FIELD_VARIETY_SUCCESS
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  commodity: {},
  commodities: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1
};

export default function(state = INITIAL_STATE, action) {
  let commodity, commodities;
  switch (action.type) {
    case FETCH_COMMODITY:
      return { ...state, waiting: true, error: null };
    case FETCH_COMMODITY_SUCCESS:
      commodity = getSingleEntity(action.data.commodity) || {};
      commodities = state.commodities;
      commodities[commodity.id] = commodity;
      return {
        ...state,
        commodity: commodity,
        commodities: commodities,
        waiting: false
      };
    case FETCH_COMMODITY_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_COMMODITIES_DETAILS:
      return { ...state, waiting: true, error: null };
    case FETCH_COMMODITIES_DETAILS_SUCCESS:
      commodities = combine(state.commodities, action.data.commodities);
      return { ...state, commodities: commodities, waiting: false };
    case FETCH_COMMODITIES_DETAILS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case CREATE_TRIAL_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case CREATE_FIELD_VARIETY_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
    case UPDATE_TRIAL_SUCCESS:
      commodities = combine(state.commodities, action.data.commodity);
      return { ...state, commodities: commodities };
    case UPDATE_COMMODITY_LOCALLY:
      commodity = state.commodity;
      commodity[action.key] = action.value;
      return { ...state, commodity: commodity || {} };
    case DELETE_COMMODITY_CRITERIUM_LOCALLY:
      commodity = state.commodity;
      let criteria = commodity.criteria || [];
      commodity.criteria = criteria.filter(value => {
        return value !== action.value;
      });
      return { ...state, commodity: commodity || {} };
    case ADD_COMMODITY_SUBCRITERIUM_LOCALLY:
      commodity = state.commodity;
      criteria = commodity.criteria || [];
      let criterium = getSingleEntity(action.data.criteria);
      criteria.push(criterium.id);
      commodity.criteria = criteria;
      return { ...state, commodity: commodity || {} };
    case SET_COMMODITY_LOCALLY:
      commodities = state.commodities;
      commodity = commodities[action.id] || {};
      return { ...state, commodity: commodity || {} };
    case FETCH_MORE_COMMODITIES:
      return { ...state, error: null, waiting: true };
    case FETCH_MORE_COMMODITIES_SUCCESS:
      commodities = combine(state.commodities, action.data.commodities);
      return {
        ...state,
        commodities: commodities,
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_MORE_COMMODITIES_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_COMMODITIES_CLEAR:
      return { ...state, error: null, waiting: true };
    case FETCH_COMMODITIES_CLEAR_SUCCESS:
      return {
        ...state,
        waiting: false,
        commodities: action.data.commodities || {},
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_COMMODITIES_CLEAR_FAILURE:
      return { ...state, waiting: false, error: action.error };
    case CLEAR_COMMODITY:
      return { ...state, commodity: {} };
    case CLEAR_COMMODITIES:
      return { ...state, commodities: {} };
    case CREATE_COMMODITY:
      return { ...state, commodity: {}, error: null, waiting: true };
    case CREATE_COMMODITY_SUCCESS:
      commodity = getSingleEntity(action.data.commodity);
      return {
        ...state,
        commodity: commodity || {},
        error: null,
        waiting: false
      };
    case CREATE_COMMODITY_FAILURE:
      return { ...state, commodity: {}, error: action.error, waiting: false };
    case UPDATE_COMMODITY:
      return { ...state, error: null, waiting: true };
    case UPDATE_COMMODITY_SUCCESS:
      commodity = getSingleEntity(action.data.commodity);
      return {
        ...state,
        commodity: commodity || {},
        error: null,
        waiting: false
      };
    case UPDATE_COMMODITY_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case DELETE_COMMODITY:
      return { ...state, waiting: true, error: null };
    case DELETE_COMMODITY_SUCCESS:
      commodities = state.commodities;
      delete commodities[action.data.id];
      return {
        ...state,
        commodities: commodities || {},
        waiting: false,
        error: null
      };
    case DELETE_COMMODITY_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case COMMODITY_ADD_LABEL:
      return { ...state, waiting: true };
    case COMMODITY_ADD_LABEL_SUCCESS:
      return {
        ...state,
        commodity: getSingleEntity(action.data.commodity) || {},
        waiting: false
      };
    case COMMODITY_ADD_LABEL_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case COMMODITY_ADD_TAG:
      return { ...state, waiting: false, error: null };
    case COMMODITY_ADD_TAG_SUCCESS:
      return {
        ...state,
        commodity: getSingleEntity(action.data.commodity) || {},
        waiting: false
      };
    case COMMODITY_ADD_TAG_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case COMMODITY_REMOVE_LABEL:
      return { ...state, waiting: true, error: null };
    case COMMODITY_REMOVE_LABEL_SUCCESS:
      return {
        ...state,
        commodity: getSingleEntity(action.data.commodity) || {},
        waiting: false
      };
    case COMMODITY_REMOVE_LABEL_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case COMMODITY_REMOVE_TAG:
      return { ...state, error: action.error, waiting: false };
    case COMMODITY_REMOVE_TAG_SUCCESS:
      commodities = state.commodities;
      commodity = getSingleEntity(action.data.commodity) || {};
      if (commodity.id) {
        commodities[commodity] = commodity;
      }
      return {
        ...state,
        commodity: commodity,
        commodities: commodities,
        waiting: false
      };
    case COMMODITY_REMOVE_TAG_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case COMMODITY_TOGGLE_TAG_LOCALLY:
      commodity = state.commodity;
      if (commodity.tags.includes(action.tagId)) {
        commodity.tags = commodity.tags.filter(tagId => action.tagId !== tagId);
      } else {
        commodity.tags.push(action.tagId);
      }
      return { ...state, commodity: commodity };
    default:
      return state;
  }
}
