const colors = [
  "#ffd700",
  "#b9ceeb",
  "#148680",
  "#78145a",
  "#ff0522",
  "#6497b1",
  "#f37735",
  "#000046",
  "#ff6289",
  "#368155",
  "#2A363B",
  "#A8A7A7",
  "#A7226E",
  "#F26B38",
  "#45ADA8",
  "#E5FCC2",
  "#6C5B7B",
  "#F8B195"
];

export const getAllColors = () => colors;

export const getColor = i => colors[i % colors.length];
