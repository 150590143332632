import React, { Component } from "react";

import Downshift from "downshift";
import deburr from "lodash/deburr";
import keycode from "keycode";
import debounce from "lodash/debounce";

import { HighlightAlogliaResult } from "../ui";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

class DownshiftSingle extends Component {
  constructor(props) {
    super(props);
    const name = this.props.initialItem
      ? this.props.initialItem.name || ""
      : "";
    this.state = {
      inputValue: name,
      selectedItem: this.props.initialItem,
      initialItem: this.props.initialItem
    };
    this.renderInput = this.renderInput.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.itemToString = this.itemToString.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialItem !== this.state.initialItem &&
      this.props.backend !== "algolia"
    ) {
      this.setState({
        selectedItem: nextProps.initialItem,
        inputValue: nextProps.initialItem.name
          ? nextProps.initialItem.name
          : this.state.inputValue,
        initialItem: nextProps.initialItem
      });
    }
  }

  handleKeyDown = event => {
    const { inputValue } = this.state;
    if (inputValue && inputValue.length && keycode(event) === "backspace") {
      this.setState(
        {
          selectedItem: {},
          inputValue: inputValue
        },
        () => {
          this.props.selectedItemParent(this.state.selectedItem);
        }
      );
    }
  };

  debouncedApiCall = debounce(value => {
    this.props.search(this.state.inputValue);
  }, 1000);

  handleInputChange = event => {
    this.setState(
      {
        inputValue: event.target.value
      },
      () => {
        this.debouncedApiCall(this.state.inputValue);
      }
    );
  };

  handleChange = item => {
    const { inputValue } = this.state;
    this.setState(
      {
        inputValue: item ? item.name : inputValue,
        selectedItem: item
      },
      () => {
        this.props.selectedItemParent(this.state.selectedItem);
      }
    );
  };

  renderInput(inputProps) {
    const { InputProps, classes, ref, createItem, ...other } = inputProps;
    let endAdornment = null;
    if (this.props.createItem) {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            aria-label="Create"
            onClick={() => this.props.createItem(this.state.inputValue)}
          >
            <AddIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    return (
      <TextField
        variant="outlined"
        fullWidth
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          endAdornment: endAdornment,
          ...InputProps
        }}
        {...other}
      />
    );
  }

  renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  }) {
    const isHighlighted = highlightedIndex === index;
    const suggestionId =
      this.props.backend === "algolia" ? suggestion.objectID : suggestion.id;
    const isSelected = (selectedItem || "") === suggestionId;
    return (
      <ListItem
        {...itemProps}
        key={suggestionId}
        selected={isHighlighted}
        component="div"
        style={{
          backgroundColor: "FFFFFF",
          fontWeight: isSelected ? 500 : 400
        }}
      >
        {this.props.backend === "algolia" ? (
          <ListItemText
            primary={
              <HighlightAlogliaResult hit={suggestion} attribute={"name"} />
            }
            secondary={
              <React.Fragment>
                <HighlightAlogliaResult
                  hit={suggestion}
                  attribute={"experimental_varieties"}
                />
                <HighlightAlogliaResult
                  hit={suggestion}
                  attribute={"commodities"}
                />
                <HighlightAlogliaResult
                  hit={suggestion}
                  attribute={"company.name"}
                />
              </React.Fragment>
            }
          />
        ) : (
          <ListItemText primary={suggestion.name} />
        )}
      </ListItem>
    );
  }

  itemToString(item) {
    if (item && item.name) {
      return item.name;
    }
    return "";
  }

  getSuggestions(value, results) {
    const adjustedValue = value || "";
    const inputValue = deburr(adjustedValue.trim()).toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : Object.values(results);
  }

  render() {
    const { classes, waiting } = this.props;
    const { inputValue, selectedItem } = this.state;

    return (
      <Downshift
        id="downshift-obj"
        selectedItem={selectedItem}
        inputValue={inputValue}
        itemToString={this.itemToString}
        onChange={this.handleChange}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue: inputValue2,
          isOpen,
          selectedItem: selectedItem2,
          itemToString: itemToString2
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              classes,
              InputProps: getInputProps({
                placeholder: this.props.placeholder,
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown
              }),
              label: this.props.label
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} elevation={2} square>
                  {waiting ? (
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justify="center"
                      className={classes.progressGrid}
                    >
                      <CircularProgress
                        size={40}
                        thickness={4}
                        color={"primary"}
                      />
                    </Grid>
                  ) : (
                    <div>
                      {this.getSuggestions(inputValue, this.props.results).map(
                        (suggestion, index) =>
                          this.renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion }),
                            highlightedIndex,
                            selectedItem: selectedItem2
                          })
                      )}
                    </div>
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 10,
    backgroundColor: "white",
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    overflow: "auto",
    maxHeight: 250
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  inputRoot: {
    flexWrap: "wrap",
    padding: 0
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  [theme.breakpoints.down("xs")]: {
    inputInput: {
      width: "40%"
    }
  },
  divider: {
    height: theme.spacing(2)
  },
  progressGrid: {
    marginTop: 10,
    marginBottom: 10
  }
});

export default withStyles(styles)(DownshiftSingle);
