import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchArea, updateArea, updateAreaLocally } from "../../actions/areas";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

class AreaEdit extends Component {
  handleChange = e => {
    this.props.updateAreaLocally(this.props.id, "name", e.target.value);
  };

  onSave = _ => {
    this.props.updateArea(this.props.id, this.props.area);
  };

  render() {
    const name = this.props.area.name || "";
    const { classes } = this.props;
    return (
      <Grid container spacing={2} className={classes.headerContainer}>
        {!this.props.create && (
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Edit Area
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField fullWidth value={name} onChange={this.handleChange} />
        </Grid>
        <Grid item xs={3}>
          <Button component={Link} to="/areas" variant="contained">
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.onSave()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
}
const styles = theme => ({
  headerContainer: {
    marginTop: 20
  }
});

const mapStateToProps = ({ areas }) => ({ areas });

export default connect(mapStateToProps, {
  fetchArea,
  updateArea,
  updateAreaLocally
})(withStyles(styles)(AreaEdit));
