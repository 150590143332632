import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, StatsTable } from "../containers";
import { AreaSelect } from "../containers/areas";
import { CommoditySelect } from "../containers/commodities";
import { RegionSelect } from "../containers/regions";
import { VarietySelect } from "../containers/varieties";
import {
  fetchCommodityStatistics,
  setErrorNotification
} from "../actions";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";

class Statistics extends Component {
  state = {
    selectedArea: null,
    selectedCommodity: null,
    selectedRegion: null,
    selectedVariety: null,
    startDate: null,
    endDate: null,
    varietyDateType: "wet",
    culturalPractice: 'conventional',
  };
  setAlogilaObject = key => object => {
    if (object && object.objectID) {
      this.setState({ ...this.state, [key]: object });
    } else {
      this.setState({ ...this.state, [key]: {} });
    }
  };
  setActiveRecordObject = key => object => {
    if (object && object.id) {
      this.setState({ ...this.state, [key]: object });
    } else {
      this.setState({ ...this.state, [key]: {} });
    }
  };
  handleDateChange = name => momentObj => {
    if (momentObj === null) {
      this.setState({ ...this.state, [name]: null });
    } else {
      const dateObj = moment(momentObj._d);
      const dateStr = dateObj.format("YYYY-MM-DD");
      this.setState({ ...this.state, [name]: dateStr });
    }
  };
  handleChange = name => e => {
    this.setState({ ...this.state, [name]: e.target.value });
  };

  search = () => {
    const {
      selectedCommodity,
      selectedRegion,
      selectedArea,
      selectedVariety,
      startDate,
      endDate,
      varietyDateType,
      culturalPractice
    } = this.state;
    if (selectedCommodity === null) {
      return this.props.setErrorNotification("No commodity selected");
    }
    if (selectedRegion === null){
      return this.props.setErrorNotification("No region selected");
    }
    if (startDate === null) {
      return this.props.setErrorNotification("No start date selected");
    }
    if (endDate === null){
      return this.props.setErrorNotification("No end date selected");
    }
    const extraCriteria = {
      areaId: (selectedArea || {}).id,
      regionId: (selectedRegion || {}).id,
      varietyId: (selectedVariety || {}).id,
    };

    this.props.fetchCommodityStatistics({
      commodityId: selectedCommodity.id,
      startDate,
      endDate,
      extraCriteria,
      varietyDateType,
      culturalPractice
    });

  };

  render() {
    const { startDate, endDate, varietyDateType, culturalPractice } = this.state;
    const { waiting } = this.props.statistics;
    return (
      <Navbar currentPage="statistics">
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Typography variant="h2" gutterBottom>
              Days to Harvest
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} style={{ paddingLeft: 40, paddingBottom: 30 }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="VarietyDateType"
                name="varietyDateType"
                value={varietyDateType}
                onChange={() => {
                  this.handleChange("varietyDateType");
                  this.search();
                }}
              >
                <FormControlLabel
                  value={"wet"}
                  control={<Radio color="primary" />}
                  label="Wet Date"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={"transplant"}
                  control={<Radio color="primary" />}
                  label="Transplant Date"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={7} md={5}>
            <CommoditySelect
              label="Commodity"
              placeholder="Choose Commodity"
              setCommodity={this.setActiveRecordObject("selectedCommodity")}
            />
          </Grid>
          <Grid item xs={1} md={3} />
          <Grid item xs={4} md={3}>
            <RegionSelect
              label="Region"
              placeholder="Choose Region"
              setRegion={this.setActiveRecordObject("selectedRegion")}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <AreaSelect
              label="Area"
              placeholder="Choose Area"
              setArea={this.setActiveRecordObject("selectedArea")}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <VarietySelect
              label="Variety"
              placeholder="Choose Variety"
              setVariety={this.setActiveRecordObject("selectedVariety")}
            />
          </Grid>
          <Grid item xs={1} md={3} />
          <Grid item xs={4} md={3}>
            <DatePicker
              value={startDate}
              inputVariant="outlined"
              onChange={this.handleDateChange("startDate")}
              fullWidth
              clearable
              format={"MMMM Do"}
              label={"Start Date"}
              views={["month", "date"]}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <DatePicker
              value={endDate}
              inputVariant="outlined"
              onChange={this.handleDateChange("endDate")}
              fullWidth
              clearable
              format={"MMMM Do"}
              label={"End Date"}
              views={["month", "date"]}
            />
          </Grid>
          <Grid item xs={4} md={3} style={{ paddingLeft: 40, paddingBottom: 30 }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="culturalPractice"
                name="culturalPractice"
                value={culturalPractice}
                onChange={this.handleChange("culturalPractice")}
              >
                <FormControlLabel
                  value={"conventional"}
                  control={<Radio color="primary" />}
                  label="Convential"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={"organic"}
                  control={<Radio color="primary" />}
                  label="Organic"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={9} style={{ marginTop: 20 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.search}
              disabled={waiting}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 30 }}>
            <StatsTable dateType={varietyDateType}
                        dayGroupings={3}
                        startDate={new Date(startDate)}
                        endDate={new Date(endDate)}
            />
          </Grid>
        </Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ statistics }) => ({ statistics });
export default connect(mapStateToProps, {
  fetchCommodityStatistics,
  setErrorNotification
})(Statistics);
