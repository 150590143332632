import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { reduxFormTextField } from "../helpers";

import { resetPassword } from "../actions/users";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

const styles = theme => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up(400)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  typography: {}
});

function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.password || values.password.trim() === "") {
    errors.password = "Enter password";
    hasErrors = true;
  }
  if (
    !values.passwordConfirmation ||
    values.passwordConfirmation.trim() === ""
  ) {
    errors.passwordConfirmation = "Enter password confirmation";
    hasErrors = true;
  }
  if (
    hasErrors === false &&
    values.password.trim() !== values.passwordConfirmation.trim()
  ) {
    errors.passwordConfirmation = "Passwords do not match";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class PasswordReset extends Component {
  sendRequest = values => {
    this.props.resetPassword(values, this.props.match.params.token);
  };

  renderError() {
    if (this.props.errorMsg) {
      return (
        <Typography component="p" variant="subtitle2" color="error">
          <ErrorIcon color="error" />
          {this.props.errorMsg}
        </Typography>
      );
    }
  }

  render() {
    if (this.props.authenticated) {
      return <Redirect push to="/" />;
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={this.props.classes.layout}>
          <Paper className={this.props.classes.paper} elevation={2}>
            <Avatar className={this.props.classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="subtitle1">
              Reset Password
            </Typography>
            <Typography variant="overline" gutterBottom></Typography>
            <form
              className={this.props.classes.form}
              onSubmit={this.props.handleSubmit(this.sendRequest)}
            >
              {this.renderError()}
              <FormControl margin="normal" required fullWidth>
                <Field
                  name="password"
                  component={reduxFormTextField}
                  label="Password"
                  type="password"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <Field
                  name="passwordConfirmation"
                  component={reduxFormTextField}
                  label="Password Confirmation"
                  type="password"
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.submit}
                disabled={this.props.submitting}
              >
                Submit
              </Button>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.user.authenticated,
    errorMsg: state.user.error
  };
};

export default connect(
  mapStateToProps,
  {
    resetPassword
  }
)(
  reduxForm({
    form: "PasswordReset",
    validate
  })(withStyles(styles)(PasswordReset))
);
