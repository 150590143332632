import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { getAllColors } from "../../helpers";
import { ResponsiveBar } from "@nivo/bar";

class StatsGraph extends Component {
  state = {
    height: 220
  };

  updateDimensions() {
    if (window.innerHeight < 400) {
      this.setState({ height: 220 });
    } else {
      const height = Math.round(window.innerHeight / 1.7);
      this.setState({ height: height });
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  differenceInDays = (date1, date2) => {
    const differenceInTimes = date1.getTime() - date2.getTime();
    return differenceInTimes / (1000 * 3600 * 24);
  };

  compileGraphData = () => {
    const { data } = this.props.statistics;
    const byDays = {}
    data
      .filter(record => record.maturityDate ? true : false)
      .forEach(record => {
        const maturityDate = new Date(record.maturityDate);
        const wetDate = new Date(record.wetDate);
        const days = this.differenceInDays(maturityDate, wetDate);
        byDays[days] = (byDays[days] || 0) + 1;
      })
    return Object.keys(byDays).map(days => {
      const count = byDays[days];
      return {
        days: days,
        tillHarvestDate: count
      }
    })
  };

  averageDaysToHarvest = () => {
    const { data } = this.props.statistics;
    const daysArr = data
      .filter(record => (record.maturityDate ? true : false))
      .map(record => {
        const maturityDate = new Date(record.maturityDate);
        const wetDate = new Date(record.wetDate);
        const days = this.differenceInDays(maturityDate, wetDate);
        return days;
      });
    if (daysArr.length === 0) {
      return null;
    }
    return (
      daysArr.reduce((total, num) => total + num) / daysArr.length
    ).toFixed(2);
  };

  render() {
    const { height } = this.state;
    const { data } = this.props.statistics;
    const graphData = this.compileGraphData();
    const daysToHarvest = this.averageDaysToHarvest();
    return (
      <Grid container spacing={2}>
        {data.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Average Days To Harvest {daysToHarvest}
            </Typography>
          </Grid>
        ): (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Not enough data for chosen conditions
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} style={{ height: height }}>
          <ResponsiveBar
            data={graphData}
            keys={['tillHarvestDate']}
            indexBy="days"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={getAllColors()}
            axisTop={null}
            axisRight={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ statistics }) => ({ statistics });

export default connect(mapStateToProps, {})(StatsGraph);
