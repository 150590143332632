import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchVariety } from "../actions/varieties";

import { Navbar } from "../containers";
import { VarietyEdit } from "../containers/varieties";

import Grid from "@material-ui/core/Grid";

class Variety extends Component {
  componentDidMount() {
    this.props.fetchVariety(this.props.match.params.id);
  }

  render() {
    return (
      <Navbar currentPage="varieties">
        <Grid container>
          <VarietyEdit id={this.props.match.params.id} update={true} />
        </Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    varieties: state.varieties
  };
};

export default connect(
  mapStateToProps,
  {
    fetchVariety
  }
)(Variety);
