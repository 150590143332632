import {
  FETCH_GROWER,
  FETCH_GROWER_SUCCESS,
  FETCH_GROWER_FAILURE,
  FETCH_GROWERS,
  FETCH_GROWERS_SUCCESS,
  FETCH_GROWERS_FAILURE,
  FETCH_SUB_GROWER,
  FETCH_SUB_GROWER_SUCCESS,
  FETCH_SUB_GROWER_FAILURE,
  FETCH_SUB_GROWERS,
  FETCH_SUB_GROWERS_SUCCESS,
  FETCH_SUB_GROWERS_FAILURE
} from "../actions/growers";
import { getSingleEntity } from "./util";

const INITIAL_STATE = {
  growers: {},
  grower: {},
  error: null,
  waiting: false,
  subGrowers: {},
  subGrower: {},
  waitingSubGrower: false,
  page: 1,
  totalPages: 1
};

export default function(state = INITIAL_STATE, action) {
  let company;
  switch (action.type) {
    case FETCH_GROWERS:
      return { ...state, error: null, growers: {}, waiting: true };
    case FETCH_GROWERS_SUCCESS:
      return {
        ...state,
        growers: action.data.companies || {},
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_GROWERS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_GROWER:
      return { ...state, grower: {}, error: null, waiting: true };
    case FETCH_GROWER_SUCCESS:
      company = getSingleEntity(action.data.company);
      return { ...state, grower: company, error: null, waiting: false };
    case FETCH_GROWER_FAILURE:
      return { ...state, grower: {}, error: action.error, waiting: false };
    case FETCH_SUB_GROWERS:
      return { ...state, subGrowers: {}, error: null, waiting: true };
    case FETCH_SUB_GROWERS_SUCCESS:
      let subGrowers = state.subGrowers;
      let newSubGrowers = action.data.companies || {};
      Object.keys(newSubGrowers).forEach(key => {
        subGrowers[key] = newSubGrowers[key];
      });
      return {
        ...state,
        subGrowers: subGrowers,
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_SUB_GROWERS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_SUB_GROWER:
      return { ...state, subGrower: {}, error: null, waitingSubGrower: true };
    case FETCH_SUB_GROWER_SUCCESS:
      company = getSingleEntity(action.data.company);
      return {
        ...state,
        subGrower: company,
        error: null,
        waitingSubGrower: false
      };
    case FETCH_SUB_GROWER_FAILURE:
      return {
        ...state,
        subGrower: {},
        error: action.error,
        waitingSubGrower: false
      };

    default:
      return state;
  }
}
