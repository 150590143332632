import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deleteSubcriterium,
  updateSubcriteriumLocally
} from "../../../actions/subcriteria";
import { deleteCriteriumSubcriteriumLocally } from "../../../actions/criteria";
import { View } from "./views";

class SubCriteriumEdit extends Component {
  computeWeightedScore = (score, weight) => {
    if (score && weight) {
      return (score * weight).toFixed(2);
    }
    return 0;
  };

  handleChange = name => e => {
    this.props.updateSubcriteriumLocally(this.props.id, name, e.target.value);
  };

  onDelete = () => {
    this.props.deleteSubcriterium(this.props.id, this.props.criteriumId);
    this.props.deleteCriteriumSubcriteriumLocally(
      this.props.criteriumId,
      this.props.id
    );
  };

  render() {
    const { subcriteria } = this.props.subcriteria;
    const subcriterium = subcriteria[this.props.id] || {};
    return (
      <View
        subcriterium={subcriterium}
        handleChange={this.handleChange}
        onDelete={this.onDelete}
        computeWeightedScore={this.computeWeightedScore}
      />
    );
  }
}

const mapStateToProps = ({ subcriteria }) => ({ subcriteria });

export default connect(
  mapStateToProps,
  {
    deleteSubcriterium,
    updateSubcriteriumLocally,
    deleteCriteriumSubcriteriumLocally
  }
)(SubCriteriumEdit);
