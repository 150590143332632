import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  linkStyle: theme.linkStyle
});

const TableRowSimple = ({ key, link, name, confirmDelete, ...props }) => (
  <TableRow key={key}>
    <TableCell component="th" scope="row">
      <Link to={link} className={props.classes.linkStyle}>
        <Typography
          variant="body2"
          gutterBottom
          className={props.classes.linkStyle}
        >
          {name}
        </Typography>
      </Link>
    </TableCell>
    <TableCell align="right">
      <Button size="small" aria-label="Delete" onClick={confirmDelete}>
        <DeleteIcon />
      </Button>
    </TableCell>
  </TableRow>
);

TableRowSimple.propTypes = {
  confirmDelete: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default withStyles(styles)(TableRowSimple);
