import {
  FETCH_PERSONAL_VIEWS,
  FETCH_PERSONAL_VIEWS_SUCCESS,
  FETCH_PERSONAL_VIEWS_FAILURE,
  CREATE_PERSONAL_VIEW,
  CREATE_PERSONAL_VIEW_SUCCESS,
  CREATE_PERSONAL_VIEW_FAILURE,
  DELETE_PERSONAL_VIEW,
  DELETE_PERSONAL_VIEW_SUCCESS,
  DELETE_PERSONAL_VIEW_FAILURE,
  SET_LOCAL_PERSONAL_VIEW
} from "../actions/personal_views";
import {
  FETCH_PROFILE_SUCCESS,
  CREATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_SUCCESS
} from "../actions/profiles";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  personalViews: {},
  personalView: {},
  error: null,
  waiting: false
};

export default function(state = INITIAL_STATE, action) {
  let personalViews, personalView;
  switch (action.type) {
    case CREATE_PROFILE_SUCCESS:
    case FETCH_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      personalViews = combine(state.personalViews, action.data.personalViews);
      return { ...state, personalViews: personalViews };
    case FETCH_PERSONAL_VIEWS:
      return { ...state, personalViews: {}, error: null, waiting: true };
    case FETCH_PERSONAL_VIEWS_SUCCESS:
      return {
        ...state,
        personalViews: action.data.personalViews || {},
        waiting: false
      };
    case FETCH_PERSONAL_VIEWS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case CREATE_PERSONAL_VIEW:
      return { ...state, error: null, waiting: true };
    case CREATE_PERSONAL_VIEW_SUCCESS:
      personalView = getSingleEntity(action.data.personalView);
      personalViews = state.personalViews;
      personalViews[personalView.key] = personalView;
      return {
        ...state,
        personalViews: personalViews,
        personalView: {},
        waiting: false
      };
    case CREATE_PERSONAL_VIEW_FAILURE:
      return {
        ...state,
        waiting: false,
        error: action.error
      };
    case SET_LOCAL_PERSONAL_VIEW:
      return {
        ...state,
        personalView: {
          ...state.personalView,
          [action.key]: action.value
        }
      };
    case DELETE_PERSONAL_VIEW:
      return {
        ...state,
        error: null,
        waiting: true
      };
    case DELETE_PERSONAL_VIEW_SUCCESS:
      personalViews = state.personalViews;
      delete personalViews[action.data.id];
      return {
        ...state,
        waiting: false,
        personalViews: personalViews
      };
    case DELETE_PERSONAL_VIEW_FAILURE:
      return {
        ...state,
        waiting: false,
        error: action.error
      };
    default:
      return state;
  }
}
