import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchNursery } from "../actions/nurseries";
import { fetchNurseVarieties } from "../actions/nurse_varieties";
import { fetchNurseryPlotBeds } from "../actions/plot_beds";

import { LoadingSpinner } from "../ui";
import { Navbar } from "../containers";
import { NurseryEdit } from "../containers/nurseries";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

class Nursery extends Component {
  componentDidMount() {
    this.props.fetchNursery(this.props.match.params.id);
  }

  renderContent() {
    const { nursery, waiting } = this.props.nurseries;

    if (waiting || !nursery.id) {
      return <LoadingSpinner />;
    }

    return (
      <NurseryEdit
        id={this.props.match.params.id}
        update={true}
        nursery={nursery}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Navbar currentPage="nurseries">
        <Grid container>
          <div className={classes.content}>{this.renderContent()}</div>
        </Grid>
      </Navbar>
    );
  }
}

const styles = theme => ({
  content: {
    marginTop: theme.content.marginTop
  }
});

const mapStateToProps = ({ nurseries }) => ({ nurseries });
export default connect(mapStateToProps, {
  fetchNursery,
  fetchNurseryPlotBeds,
  fetchNurseVarieties
})(withStyles(styles)(Nursery));
