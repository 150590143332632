import {
  SET_NOTIFICATION,
  CLOSE_NOTIFICATION
} from '../actions/notifications';

const INITIAL_STATE = {
  message: '',
  open: false,
  variant: 'info'
}

export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case SET_NOTIFICATION:
      let message = action.payload;
      let variant = 'info';
      if(action.error){
        message = action.error
        variant = 'error';
      }
      return { ...state, message: message, variant: variant, open: true };
    case CLOSE_NOTIFICATION:
      return { ...state, message: '', open: false, error: false };
    default:
      return state;
  }
}