import {
  FETCH_TRIALS,
  FETCH_TRIALS_SUCCESS,
  FETCH_TRIALS_FAILURE,
  FETCH_TRIALS_PAGINATION,
  FETCH_TRIALS_PAGINATION_SUCCESS,
  FETCH_TRIALS_PAGINATION_FAILURE,
  FETCH_TRIAL,
  FETCH_TRIAL_SUCCESS,
  FETCH_TRIAL_FAILURE,
  FETCH_TRIALS_STATUS,
  FETCH_TRIALS_STATUS_SUCCESS,
  FETCH_TRIALS_STATUS_FAILURE,
  CREATE_TRIAL,
  CREATE_TRIAL_SUCCESS,
  CREATE_TRIAL_FAILURE,
  UPDATE_TRIAL,
  UPDATE_TRIAL_SUCCESS,
  UPDATE_TRIAL_FAILURE,
  UPDATE_TRIAL_LOCALLY,
  UPDATE_TRIAL_AREA_ID_LOCALLY,
  UPDATE_TRIAL_RANCH_ID_LOCALLY,
  UPDATE_TRIAL_REGION_ID_LOCALLY,
  UPDATE_TRIAL_COMMODITY_ID_LOCALLY,
  DELETE_TRIAL,
  DELETE_TRIAL_SUCCESS,
  DELETE_TRIAL_FAILURE,
  UPDATE_TRIAL_SEARCH
} from "../actions/trials";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import { combine, getSingleEntity } from "./util";
import {
  FETCH_EST_HARVEST_DATE_SUCCESS,
} from '../actions/statistics';

const INITIAL_STATE = {
  trials: {},
  trial: {},
  error: null,
  waiting: false,
  waitingTrial: false,
  page: 1,
  totalPages: 1,
  search: {
    searchField: "",
    excludeYear: false,
    archived: false,
    mexican: true,
    startWetDate: "",
    endWetDate: "",
    startEstHarvestDate: "",
    endEstHarvestDate: ""
  }
};

export default function(state = INITIAL_STATE, action) {
  let trial, trials, newTrials;
  switch (action.type) {
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
      trials = combine(state.trials, action.data.trials);
      return { ...state, trials: trials };
    case FETCH_TRIALS_SUCCESS:
      trials = combine(state.trials, action.data.trials);
      return { ...state, trials: trials, error: null, waiting: false };
    case FETCH_TRIALS_PAGINATION_SUCCESS:
      trials = combine(state.trials, action.data.trials);
      let waiting = false;
      if (action.meta.totalPages > action.meta.currentPage) {
        waiting = true;
      }
      return { ...state, trials: trials, waiting: waiting };
    case UPDATE_TRIAL_SEARCH:
      return {
        ...state,
        search: { ...state.search, [action.key]: action.value }
      };
    case UPDATE_TRIAL_COMMODITY_ID_LOCALLY:
      let commodity = getSingleEntity(action.data.commodity);
      trial = state.trial;
      trial.commodity = commodity;
      trial.commodityId = commodity.id;
      trials = state.trials;
      trials[trial.id] = trial;
      return { ...state, trial: trial, trials: trials };
    case UPDATE_TRIAL_RANCH_ID_LOCALLY:
      let ranch = getSingleEntity(action.data.ranch);
      trial = state.trial;
      trial.ranch = ranch;
      trial.ranchId = ranch.id;
      trials = state.trials;
      trials[trial.id] = trial;
      return { ...state, trial: trial, trials: trials };
    case UPDATE_TRIAL_REGION_ID_LOCALLY:
      let region = getSingleEntity(action.data.region);
      trial = state.trial;
      trial.region = region;
      trial.regionId = region.id;
      trials = state.trials;
      trials[trial.id] = trial;
      return { ...state, trial: trial, trials: trials };
    case UPDATE_TRIAL_AREA_ID_LOCALLY:
      let area = getSingleEntity(action.data.area);
      trial = state.trial;
      trial.area = area;
      trial.areaId = area.id;
      trials = state.trials;
      trials[trial.id] = trial;
      return { ...state, trial: trial, trials: trials };
    case UPDATE_TRIAL_LOCALLY:
      trial = state.trial;
      trial[action.key] = action.value;
      return { ...state, trial: trial };
    case FETCH_TRIAL:
      return { ...state, trial: {}, error: null, waitingTrial: true };
    case FETCH_TRIAL_SUCCESS:
      trial = getSingleEntity(action.data.trial);
      trials = state.trials;
      trials[trial.id] = trial;
      return {
        ...state,
        trial: trial,
        trials: trials,
        error: null,
        waitingTrial: false
      };
    case FETCH_TRIAL_FAILURE:
      return { ...state, trial: {}, error: action.error, waitingTrial: false };
    case FETCH_TRIALS_STATUS:
    case FETCH_TRIALS:
    case FETCH_TRIALS_PAGINATION:
      return { ...state, error: null, waiting: true };
    case FETCH_TRIALS_STATUS_SUCCESS:
      trials = state.trials;
      newTrials = action.data.trials || {};
      Object.keys(newTrials).forEach(key => {
        trials[key] = newTrials[key];
      });
      return { ...state, trials: trials, waiting: false };
    case FETCH_TRIALS_STATUS_FAILURE:
    case FETCH_TRIALS_FAILURE:
    case FETCH_TRIALS_PAGINATION_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case CREATE_TRIAL:
      return { ...state, trial: {}, error: null, waiting: false };
    case CREATE_TRIAL_SUCCESS:
      trial = getSingleEntity(action.data.trial);
      trials = state.trials;
      trials[trial.id] = trial;
      return {
        ...state,
        trial: trial,
        trials: trials,
        error: null,
        waiting: false
      };
    case CREATE_TRIAL_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_TRIAL:
      return { ...state, error: null, waiting: false };
    case UPDATE_TRIAL_SUCCESS:
      trial = getSingleEntity(action.data.trial);
      trials = state.trials;
      trials[trial.id] = trial;
      return {
        ...state,
        trial: trial,
        trials: trials,
        error: null,
        waiting: false
      };
    case UPDATE_TRIAL_FAILURE:
      if (action.status === 412) {
        trial = getSingleEntity(action.data.trial);
        trials = state.trials;
        trials[trial.id] = trial;
        return {
          ...state,
          error: action.error,
          trial: trial,
          trials: trials,
          waiting: false
        };
      }
      return { ...state, error: action.error, waiting: false };
    case DELETE_TRIAL:
      return { ...state, error: null, waiting: true };
    case DELETE_TRIAL_SUCCESS:
      trials = state.trials;
      delete trials[action.data.id];
      return { ...state, trials: trials, error: null, waiting: false };
    case DELETE_TRIAL_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_EST_HARVEST_DATE_SUCCESS:
      trial = state.trial;
      trial['tempEstimatedHarvestDate'] = action.data.tempEstimatedHarvestDate
      return { ...state, trial: trial }
    default:
      return state;
  }
}
