import React, { Component } from "react";
import configureStore from "../store/configureStore.js";
import Router from "../Router";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const store = configureStore();
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "rgba(0, 0, 0, 1)",
      main: "rgba(3, 67, 144, 1)",
      dark: "rgba(208, 2, 27, 1)",
      contrastText: "rgba(255, 255, 255, 1)"
    },
    secondary: {
      light: "rgba(208, 2, 27, 1)",
      main: "rgba(155, 155, 155, 1)",
      dark: "rgba(208, 2, 27, 1)",
      contrastText: "#fff"
    }
  },
  content: {
    marginTop: 20
  },
  addButton: {
    marginTop: 25
  },
  headerText: {
    marginTop: 20,
    paddingBottom: 10
  },
  searchBar: {
    marginTop: 25
  },
  linkStyle: {
    textDecoration: "none",
    color: "black"
  },
  linkTypography: {
    color: "black"
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router />
          </MuiPickersUtilsProvider>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
