import React, { Component } from "react";
import DownshiftMultiple from "../DownshiftMultiple";
import { connect } from "react-redux";
import { fetchRegions } from "../../actions/regions";

class RegionsMultipleSelect extends Component {
  render() {
    const { regions, waiting } = this.props.regions;
    const results = regions || {};
    const initialItems = this.props.initialItems || [];

    return (
      <DownshiftMultiple
        waiting={waiting}
        placeholder={"Select Regions"}
        label={"Regions"}
        search={this.props.fetchRegions}
        results={results}
        initialItems={initialItems}
        selectedItemsParent={this.props.setRegions}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    regions: state.regions
  };
};
export default connect(
  mapStateToProps,
  {
    fetchRegions
  }
)(RegionsMultipleSelect);
