import { SET_NOTIFICATION } from './notifications';
import { CALL_API, Schemas, AFTER_SUCCESS, AFTER_FAILURE } from '../middleware/api';
import { ADD_COMMODITY_SUBCRITERIUM_LOCALLY } from './commodities';

export const UPDATE_CRITERIUM_LOCALLY = 'UPDATE_CRITERIUM_LOCALLY';
export const DELETE_CRITERIUM_SUBCRITERIUM_LOCALLY = 'DELETE_CRITERIUM_SUBCRITERIUM_LOCALLY';
export const ADD_CRITERIUM_SUBCRITERIUM_LOCALLY = 'ADD_CRITERIUM_SUBCRITERIUM_LOCALLY';

export const FETCH_CRITERIUM = 'FETCH_CRITERIUM';
export const FETCH_CRITERIUM_SUCCESS = 'FETCH_CRITERIUM_SUCCESS';
export const FETCH_CRITERIUM_FAILURE = 'FETCH_CRITERIUM_FAILURE';

export const CREATE_CRITERIUM = 'CREATE_CRITERIUM';
export const CREATE_CRITERIUM_SUCCESS = 'CREATE_CRITERIUM_SUCCESS';
export const CREATE_CRITERIUM_FAILURE = 'CREATE_CRITERIUM_FAILURE';

export const UPDATE_CRITERIUM = 'UPDATE_CRITERIUM';
export const UPDATE_CRITERIUM_SUCCESS = 'UPDATE_CRITERIUM_SUCCESS';
export const UPDATE_CRITERIUM_FAILURE = 'UPDATE_CRITERIUM_FAILURE';

export const DELETE_CRITERIUM = 'DELETE_CRITERIUM';
export const DELETE_CRITERIUM_SUCCESS = 'DELETE_CRITERIUM_SUCCESS';
export const DELETE_CRITERIUM_FAILURE = 'DELETE_CRITERIUM_FAILURE';

export const deleteCriteriumSubcriteriumLocally = (id, value) => (dispatch, getState) => (
  dispatch({
    type: DELETE_CRITERIUM_SUBCRITERIUM_LOCALLY,
    id: id,
    value: value
  })
)

export const updateCriteriumLocally = (id, key, value) => (dispatch, getState) => (
  dispatch({
    type: UPDATE_CRITERIUM_LOCALLY,
    id: id,
    key: key,
    value: value
  })
)

export const fetchCriterium = (id, commodityId) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_CRITERIUM, FETCH_CRITERIUM_SUCCESS, FETCH_CRITERIUM_FAILURE ],
      endpoint: `/commodities/${commodityId}/criteria/${id}`,
      schema: Schemas.CRITERIUM,
      method: 'get'
    }
  })
)

export const createCriterium = (commodityId, attributes) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ CREATE_CRITERIUM, CREATE_CRITERIUM_SUCCESS, CREATE_CRITERIUM_FAILURE ],
      endpoint: `/commodities/${commodityId}/criteria`,
      schema: Schemas.CRITERIUM,
      method: 'post',
      data: { criterium: attributes }
    },
    [AFTER_SUCCESS]: [{
      type: ADD_COMMODITY_SUBCRITERIUM_LOCALLY,
      id: commodityId
    }, {
      type: SET_NOTIFICATION,
      payload: 'Created a Criterium successfully'
    }],
    [AFTER_FAILURE]: [{
      type: SET_NOTIFICATION,
      payload: 'Failed to create Criterium'
    }]
  })
)

export const updateCriterium = (id, commodityId, attributes) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ UPDATE_CRITERIUM, UPDATE_CRITERIUM_SUCCESS, UPDATE_CRITERIUM_FAILURE ],
      endpoint: `/commodities/${commodityId}/criteria/${id}`,
      schema: Schemas.CRITERIUM,
      method: 'patch',
      data: { criterium: attributes }
    }
  })
)

export const deleteCriterium = (id, commodityId) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ DELETE_CRITERIUM, DELETE_CRITERIUM_SUCCESS, DELETE_CRITERIUM_FAILURE ],
      endpoint: `/commodities/${commodityId}/criteria/${id}`,
      schema: Schemas.CRITERIUM,
      method: 'delete'
    },
    [AFTER_SUCCESS]: [{
      type: SET_NOTIFICATION,
      payload: 'Deleted the Criterium successfully'
    }],
    [AFTER_FAILURE]: [{
      type: SET_NOTIFICATION,
      payload: 'Failed to delete the Criterium'
    }]
  })
)
