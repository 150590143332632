import React from "react";
import PropTypes from "prop-types";

import EvaluationReportColumn from "../EvaluationReportColumn";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  headerText: {
    marginTop: theme.headerText.marginTop
  },
  topButton: {
    marginTop: 25
  },
  columnGrid: {
    marginTop: 30
  },
  root: {
    flexGrow: 1
  }
});

export const EvaluationReportColumns = withStyles(styles)(
  ({ trialAndFieldVarieties, showGrowerComments, plotNumbers, ...props }) => (
    <React.Fragment>
      {trialAndFieldVarieties.map((record, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          className={props.classes.columnGrid}
        >
          <EvaluationReportColumn
            plotNumber={plotNumbers[record.id]}
            record={record}
            showGrowerComments={showGrowerComments}
          />
        </Grid>
      ))}
    </React.Fragment>
  )
);

EvaluationReportColumns.propTypes = {
  trialAndFieldVarieties: PropTypes.array.isRequired,
  plotNumbers: PropTypes.object.isRequired,
  showGrowerComments: PropTypes.bool.isRequired
};
