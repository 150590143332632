import {
  CREATE_LABEL,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_FAILURE,
  FETCH_LABELS,
  FETCH_LABELS_SUCCESS,
  FETCH_LABELS_FAILURE,
  SEARCH_LABELS,
  SEARCH_LABELS_SUCCESS,
  SEARCH_LABELS_FAILURE,
  FETCH_LABEL,
  FETCH_LABEL_SUCCESS,
  FETCH_LABEL_FAILURE,
  UPDATE_LABEL,
  UPDATE_LABEL_SUCCESS,
  UPDATE_LABEL_FAILURE,
  UPDATE_LABEL_LOCALLY,
  DELETE_LABEL,
  DELETE_LABEL_SUCCESS,
  DELETE_LABEL_FAILURE
} from "../actions/labels";
import { CREATE_TAG_SUCCESS, DELETE_TAG_SUCCESS } from "../actions/tags";
import { combine } from "./util";
import {
  CREATE_COMMODITY_SUCCESS,
  COMMODITY_ADD_LABEL_SUCCESS,
  COMMODITY_ADD_TAG_SUCCESS,
  COMMODITY_REMOVE_LABEL_SUCCESS,
  COMMODITY_REMOVE_TAG_SUCCESS,
  FETCH_COMMODITIES_DETAILS_SUCCESS,
  FETCH_COMMODITY_SUCCESS,
  UPDATE_COMMODITY_SUCCESS
} from "../actions/commodities";
import { FETCH_TRIAL_SUCCESS } from "../actions/trials";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS
} from "../actions/field_varieties";

import { remove } from "lodash";

const INITIAL_STATE = {
  labels: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1
};

export default function(state = INITIAL_STATE, action) {
  let labels, label;
  switch (action.type) {
    case COMMODITY_ADD_LABEL_SUCCESS:
    case COMMODITY_ADD_TAG_SUCCESS:
    case COMMODITY_REMOVE_LABEL_SUCCESS:
    case COMMODITY_REMOVE_TAG_SUCCESS:
    case CREATE_COMMODITY_SUCCESS:
    case FETCH_COMMODITY_SUCCESS:
    case FETCH_COMMODITIES_DETAILS_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case UPDATE_COMMODITY_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case CREATE_TAG_SUCCESS:
      labels = combine(state.labels, action.data.labels);
      return { ...state, labels: labels };
    case FETCH_LABELS_SUCCESS:
    case CREATE_LABEL_SUCCESS:
    case FETCH_LABEL_SUCCESS:
    case UPDATE_LABEL_SUCCESS:
      labels = combine(state.labels, action.data.labels);
      return { ...state, labels: labels, waiting: false };
    case SEARCH_LABELS_SUCCESS:
      labels = combine({}, action.data.labels);
      return { ...state, labels: labels, waiting: false };
    case UPDATE_LABEL_LOCALLY:
      labels = state.labels;
      label = labels[action.id];
      label[action.key] = action.value;
      labels[action.id] = label;
      return { ...state, labels: labels };
    case CREATE_LABEL:
    case DELETE_LABEL:
    case FETCH_LABELS:
    case FETCH_LABEL:
    case SEARCH_LABELS:
    case UPDATE_LABEL:
      return { ...state, waiting: true, error: null };
    case DELETE_LABEL_SUCCESS:
      labels = state.labels;
      delete labels[action.data.id];
      return { ...state, waiting: false, labels: labels };
    case CREATE_LABEL_FAILURE:
    case DELETE_LABEL_FAILURE:
    case FETCH_LABELS_FAILURE:
    case FETCH_LABEL_FAILURE:
    case SEARCH_LABELS_FAILURE:
    case UPDATE_LABEL_FAILURE:
      return { ...state, waiting: false, error: action.error };
    case DELETE_TAG_SUCCESS:
      labels = state.labels;
      label = labels[action.extra.labelId];
      const removeTagId = parseInt(action.data.id);
      label.tags = remove(label.tags, tagId => removeTagId !== tagId);
      labels[action.extra.labelId] = label;
      return { ...state, labels: labels };
    default:
      return state;
  }
}
