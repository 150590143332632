import React, { Component } from "react";
import DownshiftMultiple from "../DownshiftMultiple";
import { connect } from "react-redux";

import { searchAlgoliaCompanies } from "../../actions/algolia";

class CompaniesMultipleSelect extends Component {
  search = str => {
    this.props.searchAlgoliaCompanies(str, this.props.companyCategory);
  };

  render() {
    const { companies, waiting } = this.props.algolia;
    let results = companies || {};
    const initialItems = this.props.initialItems || [];
    const placeholder = this.props.placeholder || "Select Companies";
    const label = this.props.label || "Companies";

    return (
      <DownshiftMultiple
        backend={"algolia"}
        waiting={waiting}
        placeholder={placeholder}
        label={label}
        search={this.search}
        results={results}
        initialItems={initialItems}
        selectedItemsParent={this.props.setCompanies}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    algolia: state.algolia
  };
};
export default connect(
  mapStateToProps,
  {
    searchAlgoliaCompanies
  }
)(CompaniesMultipleSelect);
