import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";

class MultiEvaluationReportCard extends Component {
  combineLabels = () => {
    const labelNames = {};
    const { labels } = this.props.labels;
    const { multiEvaluations } = this.props;
    multiEvaluations.forEach(multiEval => {
      const labelName = (labels[multiEval.label] || {}).name;
      const labelId = multiEval.label;
      labelNames[labelId] = labelName;
    });
    return labelNames;
  };

  tagNames = labelId => {
    const { tags } = this.props.tags;
    const { multiEvaluations } = this.props;
    const tagNames = {};
    multiEvaluations
      .filter(multiEval =>
        multiEval.archived
          ? multiEval.archivedLabelId === parseInt(labelId)
          : multiEval.label === parseInt(labelId)
      )
      .forEach(multiEval => {
        const tagName = (tags[multiEval.tag] || {}).name;
        const tagLocation = (tags[multiEval.tag] || {}).location;
        tagNames[tagName] = tagLocation;
      });
    return Object.keys(tagNames).sort((a, b) => {
      const aLoc = tagNames[a];
      const bLoc = tagNames[b];
      if (aLoc < bLoc) {
        return -1;
      }
      if (aLoc > bLoc) {
        return 1;
      }
      return 0;
    });
  };

  render() {
    const { color, classes } = this.props;
    const combinedLabels = this.combineLabels();
    return (
      <Card style={{ backgroundColor: color }}>
        <CardContent>
          <Typography variant="subtitle1">Diseases</Typography>
          {Object.keys(combinedLabels).map(labelId => {
            const labelName = combinedLabels[labelId];
            return (
              <ListItem key={labelId} className={classes.listItem}>
                <ListItemText
                  className={classes.listItemText}
                  secondary={`${labelName} : ${this.tagNames(labelId).join(
                    ", "
                  )}`}
                />
              </ListItem>
            );
          })}
        </CardContent>
      </Card>
    );
  }
}

MultiEvaluationReportCard.propTypes = {
  multiEvaluations: PropTypes.array.isRequired
};

const mapStateToProps = ({ labels, tags }) => ({
  labels,
  tags
});

const styles = theme => ({
  listItem: {
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  listItemText: {
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  }
});

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(MultiEvaluationReportCard));
