export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const setNotification = message => (dispatch, getState) =>
  dispatch({
    type: SET_NOTIFICATION,
    payload: message
  });

export const setErrorNotification = message => (dispatch, getState) =>
  dispatch({
    type: SET_NOTIFICATION,
    error: message
  });

export const closeNotification = () => (dispatch, getState) =>
  dispatch({
    type: CLOSE_NOTIFICATION
  });
