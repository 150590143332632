import { CALL_API } from "../middleware/api";

export const FETCH_STATISTICS = "FETCH_STATISTICS";
export const FETCH_STATISTICS_SUCCESS = "FETCH_STATISTICS_SUCCESS";
export const FETCH_STATISTICS_FAILURE = "FETCH_STATISTICS_FAILURE";
export const FETCH_EST_HARVEST_DATE = 'FETCH_EST_HARVEST_DATE';
export const FETCH_EST_HARVEST_DATE_SUCCESS = 'FETCH_EST_HARVEST_DATE_SUCCESS';
export const FETCH_EST_HARVEST_DATE_FAILURE = 'FETCH_EST_HARVEST_DATE_FAILURE';

export const fetchCommodityStatistics = ({
  commodityId,
  startDate,
  endDate,
  extraCriteria,
  varietyDateType,
  culturalPractice
}) => (dispatch, getState) => {
  let url = `/statistics/commodity?commodity_id=${commodityId}&date_type=${varietyDateType}&start_date=${startDate}&end_date=${endDate}&cultural_practice=${culturalPractice}`;
  url = addExtraCriteriaToUrl(url, extraCriteria);
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_STATISTICS,
        FETCH_STATISTICS_SUCCESS,
        FETCH_STATISTICS_FAILURE
      ],
      endpoint: url,
      method: "get"
    }
  });
};

export const fetchEstHarvestDate = ({
  commodityId,
  searchDate,
  dateType,
  culturalPractice,
}) => (dispatch, getState) => {
  let url = `/statistics/est_harvest_date?commodity_id=${commodityId}&search_date=${searchDate}&date_type=${dateType}&cultural_practice=${culturalPractice}`;
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_EST_HARVEST_DATE,
        FETCH_EST_HARVEST_DATE_SUCCESS,
        FETCH_EST_HARVEST_DATE_FAILURE
      ],
      endpoint: url,
      method: "get"
    }
  });
};

const addExtraCriteriaToUrl = (url, extraCriteria) => {
  if (extraCriteria["areaId"]) {
    url += `&area_id=${extraCriteria["areaId"]}`;
  }
  if (extraCriteria["regionId"]) {
    url += `&region_id=${extraCriteria["regionId"]}`;
  }
  if (extraCriteria['varietyId']) {
    url += `&varietyId=${extraCriteria["varietyId"]}`;
  }
  return url;
};
