import React, { Component } from "react";
import DownshiftSingle from "../DownshiftSingle";
import { connect } from "react-redux";
import { sortObjectByKeyDesc } from "../../helpers/utils";
import { fetchCompanies } from "../../actions/companies";

class CompanySelect extends Component {
  render() {
    const { companies, waiting } = this.props.companies;
    let results = companies || {};
    results = sortObjectByKeyDesc(results, "rank");

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Company"}
        label={"Company"}
        search={this.props.fetchCompanies}
        results={results}
        initialItem={this.props.company}
        selectedItemParent={this.props.setCompany}
      />
    );
  }
}

const mapStateToProps = ({ companies }) => ({ companies });
export default connect(
  mapStateToProps,
  {
    fetchCompanies
  }
)(CompanySelect);
