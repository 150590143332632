import { combineReducers } from "redux";
import AlgoliaReducer from "./algolia";
import AreasReducer from "./areas";
import CommoditiesReducer from "./commodities";
import CompaniesReducer from "./companies";
import CompanyCategoriesReducer from "./company_categories";
import CriteriaReducer from "./criteria";
import EvaluationsReducer from "./evaluations";
import FieldVarietiesReducer from "./field_varieties";
import GrowersReducer from "./growers";
import LabelsReducer from "./labels";
import MultiEvaluationsReducer from "./multi_evaluations";
import NotificationReducer from "./notifications";
import NurseriesReducer from "./nurseries";
import NurseVarietiesReducer from "./nurse_varieties";
import PeopleReducer from "./people";
import PersonalViewsReducer from "./personal_views";
import PlotBedsReducer from "./plot_beds";
import ProfilesReducer from "./profiles";
import RanchesReducer from "./ranches";
import RegionsReducer from "./regions";
import ReportsReducer from "./reports";
import SuppliersReducer from "./suppliers";
import TagsReducer from "./tags";
import TrialsReducer from "./trials";
import TrialVarietiesReducer from "./trial_varieties";
import ShippersReducer from "./shippers";
import StatisticsReducer from "./statistics";
import SubcriteriaReducer from "./subcriteria";
import UserReducer from "./users";
import VarietiesReducer from "./varieties";

import { reducer as formReducer } from "redux-form";
import { reducer as refreshReducer } from "redux-refresh-token";

export default combineReducers({
  algolia: AlgoliaReducer,
  areas: AreasReducer,
  companies: CompaniesReducer,
  companyCategories: CompanyCategoriesReducer,
  commodities: CommoditiesReducer,
  criteria: CriteriaReducer,
  evaluations: EvaluationsReducer,
  fieldVarieties: FieldVarietiesReducer,
  growers: GrowersReducer,
  labels: LabelsReducer,
  multiEvaluations: MultiEvaluationsReducer,
  people: PeopleReducer,
  profiles: ProfilesReducer,
  personalViews: PersonalViewsReducer,
  plotBeds: PlotBedsReducer,
  regions: RegionsReducer,
  ranches: RanchesReducer,
  shippers: ShippersReducer,
  statistics: StatisticsReducer,
  subcriteria: SubcriteriaReducer,
  suppliers: SuppliersReducer,
  tags: TagsReducer,
  trials: TrialsReducer,
  trialVarieties: TrialVarietiesReducer,
  reports: ReportsReducer,
  notification: NotificationReducer,
  nurseries: NurseriesReducer,
  nurseVarieties: NurseVarietiesReducer,
  varieties: VarietiesReducer,

  form: formReducer, // <-- redux-form

  user: UserReducer,
  tokenRefresh: refreshReducer
});
