import {
  UPDATE_NURSERY_LOCALLY,
  FETCH_NURSERIES,
  FETCH_NURSERIES_SUCCESS,
  FETCH_NURSERIES_FAILURE,
  FETCH_MORE_NURSERIES,
  FETCH_MORE_NURSERIES_SUCCESS,
  FETCH_MORE_NURSERIES_FAILURE,
  FETCH_NURSERY,
  FETCH_NURSERY_SUCCESS,
  FETCH_NURSERY_FAILURE,
  CREATE_NURSERY,
  CREATE_NURSERY_SUCCESS,
  CREATE_NURSERY_FAILURE,
  UPDATE_NURSERY,
  UPDATE_NURSERY_SUCCESS,
  UPDATE_NURSERY_FAILURE,
  DELETE_NURSERY,
  DELETE_NURSERY_SUCCESS,
  DELETE_NURSERY_FAILURE,
  UPDATE_NURSERY_SEARCH,
  UPDATE_NURSERY_SHOW_INACTIVE
} from "../actions/nurseries";

import { getSingleEntity } from "./util";

const INITIAL_STATE = {
  nurseries: {},
  nursery: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1,
  search: "",
  showInactive: false
};

export default function(state = INITIAL_STATE, action) {
  let nursery, nurseries;
  switch (action.type) {
    case UPDATE_NURSERY_SHOW_INACTIVE:
      let inactive = action.value || false;
      return { ...state, showInactive: inactive };
    case UPDATE_NURSERY_SEARCH:
      let search = action.value || "";
      return { ...state, search: search };
    case FETCH_NURSERIES:
      return { ...state, nurseries: {}, error: null };
    case FETCH_NURSERIES_SUCCESS:
      return {
        ...state,
        nurseries: action.data.nurseries || {},
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_NURSERIES_FAILURE:
      return { ...state, error: action.error };
    case FETCH_MORE_NURSERIES:
      return { ...state, error: null };
    case FETCH_MORE_NURSERIES_SUCCESS:
      nurseries = state.nurseries;
      let newNurseries = action.data.nurseries || {};
      Object.keys(newNurseries).forEach(key => {
        nurseries[key] = newNurseries[key];
      });
      return {
        ...state,
        nurseries: nurseries,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_MORE_NURSERIES_FAILURE:
      return { ...state, error: action.error };
    case FETCH_NURSERY:
      return { ...state, nursery: {}, error: null };
    case FETCH_NURSERY_SUCCESS:
      nursery = getSingleEntity(action.data.nursery);
      return { ...state, nursery: nursery };
    case FETCH_NURSERY_FAILURE:
      return { ...state, error: action.error };
    case CREATE_NURSERY:
      return { ...state, error: null, waiting: true };
    case CREATE_NURSERY_SUCCESS:
      nursery = getSingleEntity(action.data.nursery);
      return { ...state, nursery: nursery, waiting: false };
    case CREATE_NURSERY_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_NURSERY_LOCALLY:
      nursery = state.nursery;
      nursery[action.key] = action.value;
      return { ...state, nursery: nursery };
    case UPDATE_NURSERY:
      return { ...state, error: null, waiting: true };
    case UPDATE_NURSERY_SUCCESS:
      nursery = getSingleEntity(action.data.nursery);
      return { ...state, nursery: nursery, waiting: false };
    case UPDATE_NURSERY_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case DELETE_NURSERY:
      return { ...state, error: null, waiting: true };
    case DELETE_NURSERY_SUCCESS:
      nurseries = state.nurseries;
      delete nurseries[action.data.id];
      return { ...state, nurseries: nurseries, error: null, waiting: false };
    case DELETE_NURSERY_FAILURE:
      return { ...state, error: action.error, waiting: false };
    default:
      return state;
  }
}
