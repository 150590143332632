import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import App from './pages/App';
import { unregister } from './serviceWorker';

unregister();
render(
  <App />,
  document.getElementById('root')
)