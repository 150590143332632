import React from "react";
import Webcam from "react-webcam";
import { shake } from "react-animations";
import { StyleSheet, css } from "aphrodite";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const videoConstraints = {
  facingMode: "environment"
};

const styles = theme => ({
  cameraFade: {
    opacity: 0.2
  }
});

const animationStyle = StyleSheet.create({
  headShake: {
    animationName: shake,
    animationDuration: "1s"
  }
});

export const WebcamCaptureDisplay = withStyles(styles)(
  ({ capture, setRef, hideCamera, shouldShake, uploadingImage, ...props }) => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div
          className={shouldShake ? css(animationStyle.headShake) : undefined}
        >
          {uploadingImage && !shouldShake ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={3}>
                <CircularProgress size={60} thickness={7} color={"primary"} />
              </Grid>
            </Grid>
          ) : (
            <Webcam
              audio={false}
              ref={setRef}
              width={"100%"}
              height={"100%"}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="large"
          variant="contained"
          color="default"
          fullWidth
          onClick={hideCamera}
        >
          Hide Camera
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={uploadingImage}
          onClick={capture}
        >
          Take Picture
        </Button>
      </Grid>
    </Grid>
  )
);
