import React, { Component } from "react";
import { connect } from "react-redux";
import { updateRanch, updateRanchLocally } from "../../../actions/ranches";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

class RanchEdit extends Component {
  handleChange = e => {
    this.props.updateRanchLocally(this.props.id, "name", e.target.value);
  };

  onSave = () => {
    this.props.updateRanch(this.props.id, this.props.ranch);
  };

  render() {
    const name = this.props.ranch.name || "";
    const { classes } = this.props;
    return (
      <Grid container spacing={2} className={classes.headerContainer}>
        {!this.props.create && (
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Edit Ranch
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField fullWidth value={name} onChange={this.handleChange} />
        </Grid>
        <Grid item xs={3}>
          <Button component={Link} to="/ranches" variant="contained">
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={this.onSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  headerContainer: {
    marginTop: 20
  }
});

const mapStateToProps = ({ ranches }) => ({ ranches });

export default connect(
  mapStateToProps,
  {
    updateRanch,
    updateRanchLocally
  }
)(withStyles(styles)(RanchEdit));
