import React from "react";
import PropTypes from "prop-types";

import { ScrollableImageCard } from "../../../ui";

import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import InputLabel from "@material-ui/core/InputLabel";

export const Evaluations = ({ evaluations, ...props }) => (
  <React.Fragment>
    {evaluations.map(e => (
      <ListItem
        key={e.id}
        style={{
          paddingLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          padding: 0
        }}
      >
        <ListItemText
          secondary={`${e.archivedCriteriumName} : ${e.archivedSubcriteriumName}`}
        />
      </ListItem>
    ))}
  </React.Fragment>
);

Evaluations.propTypes = {
  evaluations: PropTypes.array.isRequired
};

export const MultiEvaluations = ({ labels, ...props }) => (
  <React.Fragment>
    {Object.keys(labels).map((labelName, i) => (
      <ListItem
        key={i}
        style={{
          paddingLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          padding: 0
        }}
      >
        <ListItemText
          secondary={`${labelName} : ${labels[labelName].join(", ")}`}
        />
      </ListItem>
    ))}
  </React.Fragment>
);

export const Images = ({ images, ...props }) => (
  <Grid item xs={12}>
    <ScrollableImageCard images={images} />
  </Grid>
);

const styles = theme => ({
  scoreGrid: {
    marginTop: 10,
    marginBottom: 10
  },
  comments: {
    marginTop: 10,
    marginBottom: 10
  },
  likeButton: {
    marginRight: 10
  }
});

export const View = withStyles(styles)(
  ({ evaluations, weightedScore, record, ...props }) => (
    <React.Fragment>
      <Grid item xs={12} className={props.classes.scoreGrid}>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          Weighted Score: {weightedScore}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <InputLabel>Maturity Date</InputLabel>
        <TextField
          fullWidth
          disabled
          type="date"
          variant="outlined"
          value={record.maturityDate || ""}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} className={props.classes.comments}>
        <TextField
          fullWidth
          rows={4}
          disabled
          variant="outlined"
          multiline={true}
          label="Comments"
          placeholder="Comments ..."
          value={record.comments || ""}
        />
      </Grid>
      <Grid item xs={12} className={props.classes.comments}>
        <TextField
          fullWidth
          variant="outlined"
          disabled
          rows={4}
          multiline={true}
          label="Grower Comments"
          placeholder="Grower Comments ..."
          value={record.growerComments || ""}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="textPrimary" gutterBottom>
              Grower's Overall Feeling
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={props.classes.likeButton}
              size="small"
              disabled={true}
              aria-label="Dislike"
              style={{
                color:
                  record.growerLike === false
                    ? "rgba(208, 2, 27, 1)"
                    : "default"
              }}
            >
              <ThumbDownIcon />
            </Button>
            <Button
              className={props.classes.likeButton}
              size="small"
              disabled={true}
              aria-label="Like"
              style={{
                color:
                  record.growerLike === true ? "rgba(208, 2, 27, 1)" : "default"
              }}
            >
              <ThumbUpIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
);

View.propTypes = {
  weightedScore: PropTypes.string.isRequired
};
