import React, { Component } from "react";
import { RegionsList } from "../containers/regions";
import { Navbar } from "../containers";

class Regions extends Component {
  render() {
    return (
      <Navbar currentPage="regions">
        <RegionsList />
      </Navbar>
    );
  }
}

export default Regions;
