import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deleteProfile,
  fetchProfiles,
  fetchMoreProfiles
} from "../../../actions/profiles";
import { View } from "./views";
import { ErrorPage, IndexLinkButton, PageHeader } from "../../../ui";

class ProfilesList extends Component {
  componentWillMount() {
    this.props.fetchProfiles();
  }

  showMoreResults = () => {
    this.props.fetchMoreProfiles();
  };

  render() {
    const { profiles, waiting, error, page, totalPages } = this.props.profiles;
    if (error) {
      return <ErrorPage msg={error} />;
    }
    return (
      <React.Fragment>
        <PageHeader title={"Profiles"} half={7}>
          <IndexLinkButton linkUrl="/profiles/new" />
        </PageHeader>
        <View
          profiles={profiles}
          onDelete={this.props.deleteProfile}
          showMoreResults={this.showMoreResults}
          waiting={waiting}
          page={page}
          totalPages={totalPages}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ profiles }) => ({ profiles });
export default connect(
  mapStateToProps,
  {
    deleteProfile,
    fetchProfiles,
    fetchMoreProfiles
  }
)(ProfilesList);
