import React from "react";

import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

const ReportScoreCard = ({
  trialVarietyScore,
  fieldVarietyScore,
  fieldVarietyName,
  ...props
}) => {
  return (
    <Card style={{ backgroundColor: props.color }}>
      <CardContent>
        <Typography variant="subtitle1">Scores</Typography>
        <Typography variant="body1">
          Trial Variety: {trialVarietyScore}
        </Typography>
        <Typography variant="body1">
          {fieldVarietyName
            ? `${fieldVarietyName} : ${fieldVarietyScore}`
            : "No associated Field Variety"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReportScoreCard;
