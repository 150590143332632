import {
  FETCH_REGIONS,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_FAILURE,
  FETCH_MORE_REGIONS,
  FETCH_MORE_REGIONS_SUCCESS,
  FETCH_MORE_REGIONS_FAILURE,
  FETCH_REGIONS_BY_ID,
  FETCH_REGIONS_BY_ID_SUCCESS,
  FETCH_REGIONS_BY_ID_FAILURE,
  CREATE_REGION,
  CREATE_REGION_SUCCESS,
  CREATE_REGION_FAILURE,
  FETCH_REGION,
  FETCH_REGION_SUCCESS,
  FETCH_REGION_FAILURE,
  UPDATE_REGION,
  UPDATE_REGION_SUCCESS,
  UPDATE_REGION_FAILURE,
  UPDATE_REGION_LOCALLY,
  DELETE_REGION,
  DELETE_REGION_SUCCESS,
  DELETE_REGION_FAILURE,
  UPDATE_REGION_SEARCH
} from "../actions/regions";
import { getSingleEntity } from "./util";

const INITIAL_STATE = {
  regions: {},
  regionsById: {},
  region: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1,
  search: ""
};

export default function(state = INITIAL_STATE, action) {
  let region, regions;
  switch (action.type) {
    case UPDATE_REGION_SEARCH:
      let search = action.value || "";
      return { ...state, search: search };
    case UPDATE_REGION_LOCALLY:
      region = state.region;
      region[action.key] = action.value;
      return { ...state, region: region };
    case FETCH_REGIONS:
      return { ...state, error: null, waiting: true };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.data.regions || {},
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_REGIONS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_MORE_REGIONS:
      return { ...state, error: null, waiting: true };
    case FETCH_MORE_REGIONS_SUCCESS:
      regions = state.regions;
      let newRegions = action.data.regions || {};
      Object.keys(newRegions).forEach(key => {
        regions[key] = newRegions[key];
      });
      return {
        ...state,
        waiting: false,
        regions: regions,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_MORE_REGIONS_FAILURE:
      return { ...state, waiting: false, error: action.error };
    case FETCH_REGIONS_BY_ID:
      return { ...state, regionsById: {}, error: null, waiting: true };
    case FETCH_REGIONS_BY_ID_SUCCESS:
      return {
        ...state,
        regionsById: action.data.region || {},
        error: null,
        waiting: false
      };
    case FETCH_REGIONS_BY_ID_FAILURE:
      return { ...state, regionsById: {}, error: action.error, waiting: false };
    case FETCH_REGION:
      return { ...state, region: {}, error: null, waiting: true };
    case FETCH_REGION_SUCCESS:
      region = getSingleEntity(action.data.region);
      return { ...state, region: region || {}, error: null, waiting: false };
    case FETCH_REGION_FAILURE:
      return { ...state, region: {}, error: action.error, waiting: false };
    case CREATE_REGION:
      return { ...state, region: {}, error: null, waiting: true };
    case CREATE_REGION_SUCCESS:
      region = getSingleEntity(action.data.region);
      regions = state.regions;
      regions[region.id] = region;
      return {
        ...state,
        regions: regions || {},
        region: region || {},
        error: null,
        waiting: false
      };
    case CREATE_REGION_FAILURE:
      return { ...state, region: {}, error: action.error, waiting: false };

    case UPDATE_REGION:
      return { ...state, region: {}, error: null, waiting: true };
    case UPDATE_REGION_SUCCESS:
      region = getSingleEntity(action.data.region);
      return { ...state, region: region || {}, error: null, waiting: false };
    case UPDATE_REGION_FAILURE:
      if (action.status === 412) {
        region = getSingleEntity(action.data.region);
        return {
          ...state,
          error: action.error,
          waiting: false,
          region: region
        };
      }
      return { ...state, error: action.error, waiting: false };
    case DELETE_REGION:
      return { ...state, error: null, waiting: true };
    case DELETE_REGION_SUCCESS:
      regions = state.regions;
      delete regions[action.data.id];
      return { ...state, regions: regions, error: null, waiting: false };
    case DELETE_REGION_FAILURE:
      return { ...state, error: action.error, waiting: false };

    default:
      return state;
  }
}
