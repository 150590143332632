import React from "react";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const TableHeaderSimple = ({ headers, ...props }) => (
  <TableHead>
    <TableRow>
      {headers.map((header, index) => (
        <TableCell key={index}>
          <Typography variant="body2" gutterBottom>
            {header}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

TableHeaderSimple.propTypes = {
  headers: PropTypes.array.isRequired
};

export default TableHeaderSimple;
