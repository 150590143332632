import React, { Component } from "react";
import { AreasList } from "../containers/areas";
import { Navbar } from "../containers";

class Areas extends Component {
  render() {
    return (
      <Navbar currentPage="areas">
        <AreasList />
      </Navbar>
    );
  }
}

export default Areas;
