import React from "react";
import { Link } from "react-router-dom";
import { CustomHighlight } from "../../ui";

import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const VarietyHit = ({ hit, ...props }) => {
  return (
    <TableRow key={hit.objectID}>
      <TableCell component="th" scope="row">
        {props.checkbox ? (
          <Typography variant="subtitle1" gutterBottom>
            <CustomHighlight attribute="name" hit={hit} />
          </Typography>
        ) : (
          <Link
            to={"/varieties/" + hit.objectID}
            className={props.classes.linkStyle}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              className={props.classes.linkTypography}
            >
              <CustomHighlight attribute="name" hit={hit} />
            </Typography>
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" gutterBottom>
          <CustomHighlight attribute="company.name" hit={hit} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" gutterBottom>
          <CustomHighlight attribute="commodities" hit={hit} />
        </Typography>
      </TableCell>
    </TableRow>
  );
};
const styles = theme => ({
  linkStyle: theme.linkStyle,
  linkTypography: theme.linkTypography,
  "ais-Highlight-highlighted": {
    backgroundColor: "#FFFF00"
  }
});

export default withStyles(styles)(VarietyHit);
