import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";

export const GraphViewDisplay = ({
  data,
  tooltip,
  height,
  layers,
  colors,
  ...props
}) => (
  <Grid item xs={12} style={{ height: height }}>
    <ResponsiveScatterPlot
      data={data}
      symbolSize={18}
      indexBy="date"
      yScale={{
        type: "linear",
        min: 0,
        max: 10
      }}
      xScale={{
        type: "time",
        format: "%m-%d",
        precision: "day"
      }}
      axisBottom={{
        tickRotation: -40,
        format: "%b %d"
      }}
      margin={{
        top: props.marginHeight, //need to change this depending on number in compare
        right: 10,
        bottom: 45,
        left: 36
      }}
      colors={colors}
      enableGridX={false}
      tooltip={tooltip}
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          translateY: -1 * props.marginHeight, //need to change this depending on number in compare
          translateX: 0,
          itemWidth: 10,
          itemHeight: 20,
          dataFrom: "keys",
          symbolSize: 12,
          symbolShape: "circle"
        }
      ]}
      layers={layers}
    />
  </Grid>
);

GraphViewDisplay.propTypes = {
  data: PropTypes.array,
  tooltip: PropTypes.func,
  height: PropTypes.number,
  marginHeight: PropTypes.number,
  layers: PropTypes.array,
  colors: PropTypes.array
};
GraphViewDisplay.defaultProps = {
  marginHeight: 50
};
