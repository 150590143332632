import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";
import PrintIcon from "@material-ui/icons/Print";
import ExcelIcon from "@material-ui/icons/Description";

const TrialReportHeader = ({
  title,
  trialName,
  openEmailDrawerAction,
  downloadPDF,
  downloadExcel,
  processingReport,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={downloadExcel ? 6 : 8}>
      <Typography variant="h2" className={props.classes.headerText}>
        {title}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {trialName}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button
          size="large"
          variant="contained"
          color="primary"
          className={props.classes.topButton}
          onClick={openEmailDrawerAction}
        >
          <EmailIcon />
        </Button>
      </Grid>
    </Grid>
    {downloadExcel && (
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            onClick={downloadExcel}
            disabled={processingReport}
          >
            <ExcelIcon />
          </Button>
        </Grid>
      </Grid>
    )}
    <Grid item xs={2}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button
          size="large"
          variant="contained"
          color="primary"
          className={props.classes.topButton}
          onClick={downloadPDF}
          disabled={processingReport}
        >
          <PrintIcon />
        </Button>
      </Grid>
    </Grid>
  </React.Fragment>
);

TrialReportHeader.propTypes = {
  title: PropTypes.string.isRequired,
  trialName: PropTypes.string,
  openEmailDrawerAction: PropTypes.func.isRequired,
  downloadPDF: PropTypes.func.isRequired
};

const styles = theme => ({
  headerText: {
    marginTop: theme.headerText.marginTop
  },
  topButton: {
    marginTop: 25
  }
});

export default withStyles(styles)(TrialReportHeader);
