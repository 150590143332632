import React from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { Link } from "react-router-dom";
import { CustomHighlight } from "../../../ui";
import DeleteConfirmation from "../../DeleteConfirmation";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

const DeleteButton = ({ confirmDelete }) => (
  <Button size="small" aria-label="Delete" onClick={confirmDelete}>
    <DeleteIcon />
  </Button>
);

const companyHitStyles = theme => ({
  linkStyle: theme.linkStyle,
  linkTypography: theme.linkTypography,
  "ais-Highlight-highlighted": {
    backgroundColor: "#FFFF00"
  }
});

const CompanyHit = withStyles(companyHitStyles)(
  ({ hit, onDelete, ...props }) => (
    <TableRow key={hit.objectID}>
      <TableCell component="th" scope="row">
        <Link
          to={"/companies/" + hit.objectID}
          className={props.classes.linkStyle}
        >
          <Typography variant="body1" gutterBottom>
            <CustomHighlight attribute="name" hit={hit} />
          </Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography variant="body1" gutterBottom>
          <CustomHighlight attribute="company_categories" hit={hit} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" gutterBottom>
          <CustomHighlight attribute="regions" hit={hit} />
        </Typography>
      </TableCell>
      <TableCell align={"right"}>
        <DeleteConfirmation onDelete={() => onDelete(hit.objectID)}>
          <DeleteButton />
        </DeleteConfirmation>
      </TableCell>
    </TableRow>
  )
);

const companyLoadMoreStyles = theme => ({
  button: {
    cursor: "pointer"
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  progressGrid: {
    marginTop: 40
  }
});

export const CompanyLoadMore = connectInfiniteHits(
  withStyles(companyLoadMoreStyles)(
    ({ hits, hasMore, refine, onDelete, ...props }) => (
      <Grid container spacing={2}>
        <Grid item xs={12} className={props.classes.tableRoot}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Categories
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Regions
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {hits.map(hit => (
                <CompanyHit key={hit.objectID} hit={hit} onDelete={onDelete} />
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            onClick={refine}
            disabled={!hasMore}
            className={props.classes.button}
          >
            Show more results
          </Button>
        </Grid>
      </Grid>
    )
  )
);
