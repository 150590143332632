import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Evaluations, MultiEvaluations, Images, View } from "./views";
import { getRecord } from "../../../helpers";

class ArchivedEvaluationScore extends Component {
  getEvaluations = () => {
    const { evaluations } = this.props.evaluations;
    return this.props.evaluationIds.map(eId => evaluations[eId] || {}) || [];
  };
  calcWeightedScore = () => {
    let total = 0;
    this.getEvaluations().forEach(e => {
      total += parseFloat(e.archivedScore);
    });
    return total.toFixed(1);
  };
  orderEvaluations = () =>
    this.getEvaluations().sort((evalObjA, evalObjB) => {
      if (
        evalObjA.archivedCriteriumLocation < evalObjB.archivedCriteriumLocation
      ) {
        return -1;
      } else if (
        evalObjA.archivedCriteriumLocation > evalObjB.archivedCriteriumLocation
      ) {
        return 1;
      }
      return 0;
    });
  getMultiEvaluations = () => {
    const { multiEvaluations } = this.props.multiEvaluations;
    return (
      this.props.multiEvaluationIds.map(id => multiEvaluations[id] || {}) || []
    );
  };
  compileMultiEvaluations = () => {
    const multiEvaluations = this.getMultiEvaluations();
    const labels = {};
    multiEvaluations.forEach(multiEvaluation => {
      if (!labels[multiEvaluation.archivedLabelName]) {
        labels[multiEvaluation.archivedLabelName] = [];
      }
      labels[multiEvaluation.archivedLabelName].push(
        multiEvaluation.archivedTagName
      );
    });
    return labels;
  };

  render() {
    const weightedScore = this.calcWeightedScore();
    const evaluations = this.orderEvaluations();
    const labels = this.compileMultiEvaluations();
    const record = getRecord(this.props, this.props.type, this.props.recordId);
    return (
      <React.Fragment>
        <Evaluations evaluations={evaluations} />
        <MultiEvaluations labels={labels} />
        <View weightedScore={weightedScore} record={record} />
        <Images images={record.images} />
      </React.Fragment>
    );
  }
}

ArchivedEvaluationScore.propTypes = {
  evaluationIds: PropTypes.array.isRequired,
  multiEvaluationIds: PropTypes.array
};

const mapStateToProps = ({
  evaluations,
  multiEvaluations,
  trialVarieties,
  fieldVarieties
}) => ({
  evaluations,
  multiEvaluations,
  trialVarieties,
  fieldVarieties
});

export default connect(mapStateToProps, null)(ArchivedEvaluationScore);
