import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const IndexHeaderButton = ({ create, ...props }) => (
  <Button
    size={"large"}
    className={props.classes.button}
    variant="contained"
    color="secondary"
    onClick={create}
  >
    <AddIcon size={"large"} />
  </Button>
);

IndexHeaderButton.propTypes = {
  create: PropTypes.func.isRequired
};

const styles = theme => ({
  button: {
    marginTop: theme.addButton.marginTop
  }
});

export default withStyles(styles)(IndexHeaderButton);
