import React, { Component } from "react";

import { Navbar } from "../containers";
import { LabelsList } from "../containers/labels";

class Labels extends Component {
  render() {
    return (
      <Navbar currentPage="labels">
        <LabelsList />
      </Navbar>
    );
  }
}
export default Labels;
