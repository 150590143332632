import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DrawerColumnDisplay } from "./views";

class DrawerColumn extends Component {
  recordList = (records, type) => {
    const { trials } = this.props.trials;
    const list = {};
    records.forEach(record => {
      const trial = trials[record.trialId] || {};
      const obj = trial[type] || {};
      if (obj && obj.id) {
        list[obj.id] = obj;
      }
    });
    // run the values to show each of the growers
    return Object.values(list);
  };

  render() {
    const records = this.recordList(this.props.records, this.props.type);
    return (
      <DrawerColumnDisplay
        filters={this.props.filters}
        handleChange={this.props.handleFilterCheckboxChange}
        records={records}
      />
    );
  }
}

DrawerColumn.propTypes = {
  filters: PropTypes.object,
  type: PropTypes.string,
  records: PropTypes.array,
  handleFilterCheckboxChange: PropTypes.func
};

const mapStateToProps = ({ trials }) => ({ trials });
export default connect(mapStateToProps, null)(DrawerColumn);
