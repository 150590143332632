import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const FETCH_PERSONAL_VIEWS = "FETCH_PERSONAL_VIEWS";
export const FETCH_PERSONAL_VIEWS_SUCCESS = "FETCH_PERSONAL_VIEWS_SUCCESS";
export const FETCH_PERSONAL_VIEWS_FAILURE = "FETCH_PERSONAL_VIEWS_FAILURE";

export const CREATE_PERSONAL_VIEW = "CREATE_PERSONAL_VIEW";
export const CREATE_PERSONAL_VIEW_SUCCESS = "CREATE_PERSONAL_VIEW_SUCCESS";
export const CREATE_PERSONAL_VIEW_FAILURE = "CREATE_PERSONAL_VIEW_FAILURE";

export const SET_LOCAL_PERSONAL_VIEW = "SET_LOCAL_PERSONAL_VIEW";

export const DELETE_PERSONAL_VIEW = "DELETE_PERSONAL_VIEW";
export const DELETE_PERSONAL_VIEW_SUCCESS = "DELETE_PERSONAL_VIEW_SUCCESS";
export const DELETE_PERSONAL_VIEW_FAILURE = "DELETE_PERSONAL_VIEW_FAILURE";

export const fetchPersonalViews = userId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_PERSONAL_VIEWS,
        FETCH_PERSONAL_VIEWS_SUCCESS,
        FETCH_PERSONAL_VIEWS_FAILURE
      ],
      endpoint: `/personal_views?user_id=${userId}`,
      schema: Schemas.PERSONAL_VIEWS,
      method: "get"
    }
  });

export const createPersonalView = attributes => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        CREATE_PERSONAL_VIEW,
        CREATE_PERSONAL_VIEW_SUCCESS,
        CREATE_PERSONAL_VIEW_FAILURE
      ],
      endpoint: "/personal_views",
      schema: Schemas.PERSONAL_VIEW,
      method: "post",
      data: { personalView: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created connection successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create connection"
      }
    ]
  });

export const setLocalPersonalView = (key, value) => (dispatch, getState) =>
  dispatch({
    type: SET_LOCAL_PERSONAL_VIEW,
    key: key,
    value: value
  });

export const deletePersonalView = personalView => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_PERSONAL_VIEW,
        DELETE_PERSONAL_VIEW_SUCCESS,
        DELETE_PERSONAL_VIEW_FAILURE
      ],
      endpoint: `/personal_views/${personalView.key}`,
      schema: Schemas.PERSONAL_VIEW,
      method: "delete"
    }
  });
