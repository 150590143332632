import React, { Component } from "react";
import DownshiftSingle from "../DownshiftSingle";
import { connect } from "react-redux";
import { fetchSupplier, fetchSuppliers } from "../../actions/suppliers";

class SupplierSelect extends Component {
  render() {
    const { suppliers, waiting } = this.props.suppliers;
    const results = suppliers || {};

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Supplier"}
        label={"Supplier"}
        search={this.props.fetchSuppliers}
        results={results}
        initialItem={this.props.supplier}
        selectedItemParent={this.props.setSupplier}
      />
    );
  }
}

const mapStateToProps = ({ suppliers }) => ({ suppliers });
export default connect(
  mapStateToProps,
  {
    fetchSupplier,
    fetchSuppliers
  }
)(SupplierSelect);
