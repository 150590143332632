import React, { Component } from "react";
import { Navbar } from "../containers";
import { PeopleList } from "../containers/people";

class People extends Component {
  render() {
    return (
      <Navbar currentPage="people">
        <PeopleList />
      </Navbar>
    );
  }
}

export default People;
