import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchRanches,
  deleteRanch,
  fetchMoreRanches,
  createRanch,
  updateSearch
} from "../../actions/ranches";
import {
  ErrorPage,
  IndexHeaderButton,
  PageHeader,
  SimpleListName
} from "../../ui";
import IndexNewField from "../IndexNewField";

class RanchesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNew: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.showMoreResults = this.showMoreResults.bind(this);
    this.toggleNew = this.toggleNew.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillMount() {
    const { search } = this.props.ranches;
    this.props.fetchRanches(search);
  }

  showMoreResults = () => {
    const { search } = this.props.ranches;
    this.props.fetchMoreRanches(search);
  };

  toggleNew = () => {
    this.setState({ ...this.state, showNew: !this.state.showNew });
  };

  handleChange = e => {
    this.props.updateSearch(e.target.value);
    this.props.fetchRanches(e.target.value);
  };

  onSave = str => {
    this.props.createRanch(str);
  };

  render() {
    const {
      ranches,
      waiting,
      page,
      totalPages,
      error,
      search
    } = this.props.ranches;
    if (error) {
      return <ErrorPage msg={error} />;
    }
    return (
      <React.Fragment>
        <PageHeader title={"Ranches"} half={6}>
          <IndexHeaderButton create={this.toggleNew} />
        </PageHeader>
        {this.state.showNew && (
          <IndexNewField onSave={this.onSave} label={"Create Ranch"} />
        )}
        <SimpleListName
          waiting={waiting}
          baseUrl={"ranches"}
          searchField={search}
          handleChange={this.handleChange}
          showMoreResults={this.showMoreResults}
          onDelete={this.props.deleteRanch}
          rows={ranches}
          page={page}
          totalPages={totalPages}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ranches: state.ranches
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRanches,
    deleteRanch,
    createRanch,
    fetchMoreRanches,
    updateSearch
  }
)(RanchesList);
