import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { View } from "./views";

class DrawerSupplierColumn extends Component {
  recordList = () => {
    const { records } = this.props;
    const { varieties } = this.props.varieties;
    const { companies } = this.props.companies;
    const suppliers = {};
    records.forEach(record => {
      const variety = varieties[record.varietyId] || {};
      const supplier =
        variety.supplier && variety.supplier.id
          ? variety.supplier
          : companies[variety.supplier] || {};
      if (supplier && supplier.id) {
        suppliers[supplier.id] = supplier;
      }
    });
    return Object.values(suppliers);
  };

  render() {
    const { filters, handleFilterCheckboxChange } = this.props;
    const records = this.recordList();
    return (
      <View
        filters={filters}
        handleChange={handleFilterCheckboxChange}
        records={records}
      />
    );
  }
}

DrawerSupplierColumn.propTypes = {
  filters: PropTypes.object,
  records: PropTypes.array,
  handleFilterCheckboxChange: PropTypes.func
};

const mapStateToProps = ({ companies, varieties }) => ({
  companies,
  varieties
});
export default connect(mapStateToProps, null)(DrawerSupplierColumn);
