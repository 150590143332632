import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

import axios from "axios";
import { camelizeKeys } from "humps";
export const API_ROOT = process.env.REACT_APP_BASE_URL;

export const FETCH_LABELS = "FETCH_LABELS";
export const FETCH_LABELS_SUCCESS = "FETCH_LABELS_SUCCESS";
export const FETCH_LABELS_FAILURE = "FETCH_LABELS_FAILURE";

export const SEARCH_LABELS = "SEARCH_LABELS";
export const SEARCH_LABELS_SUCCESS = "SEARCH_LABELS_SUCCESS";
export const SEARCH_LABELS_FAILURE = "SEARCH_LABELS_FAILURE";

export const FETCH_LABEL = "FETCH_LABEL";
export const FETCH_LABEL_SUCCESS = "FETCH_LABEL_SUCCESS";
export const FETCH_LABEL_FAILURE = "FETCH_LABEL_FAILURE";

export const CREATE_LABEL = "CREATE_LABEL";
export const CREATE_LABEL_SUCCESS = "CREATE_LABEL_SUCCESS";
export const CREATE_LABEL_FAILURE = "CREATE_LABEL_FAILURE";

export const UPDATE_LABEL = "UPDATE_LABEL";
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS";
export const UPDATE_LABEL_FAILURE = "UPDATE_LABEL_FAILURE";
export const UPDATE_LABEL_LOCALLY = "UPDATE_LABEL_LOCALLY";

export const DELETE_LABEL = "DELETE_LABEL";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const DELETE_LABEL_FAILURE = "DELETE_LABEL_FAILURE";

export const updateLabelLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_LABEL_LOCALLY,
    id: id,
    key: key,
    value: value
  });

export const fetchLabels = (search = "") => (dispatch, getState) => {
  let types = [FETCH_LABELS, FETCH_LABELS_SUCCESS, FETCH_LABELS_FAILURE];
  if (search.length !== 0) {
    types = [SEARCH_LABELS, SEARCH_LABELS_SUCCESS, SEARCH_LABELS_FAILURE];
  }
  dispatch({
    [CALL_API]: {
      types: types,
      endpoint: `/labels?search=${encodeURIComponent(search)}`,
      schema: Schemas.LABELS,
      method: "get"
    }
  });
};

export const fetchLabelsByCommodityId = commodityId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_LABELS, FETCH_LABELS_SUCCESS, FETCH_LABELS_FAILURE],
      endpoint: `/labels/by_commodity_id?commodity_id=${commodityId}`,
      schema: Schemas.LABELS,
      method: "get"
    }
  });

export const fetchLabel = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_LABEL, FETCH_LABEL_SUCCESS, FETCH_LABEL_FAILURE],
      endpoint: `/labels/${id}`,
      schema: Schemas.LABEL,
      method: "get"
    }
  });

export const createLabel = name => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_LABEL
    });
    const { token } = getState().user;
    const request = {
      url: `${API_ROOT}/labels`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`
      },
      data: {
        label: { name: name }
      }
    };
    let response = await axios(request);
    const { data } = response;
    if (!(response.status >= 200 && response.status < 300)) {
      throw new Error(data);
    }
    dispatch({
      type: CREATE_LABEL_SUCCESS,
      data: camelizeKeys(data)
    });
    return data;
  } catch (error) {
    dispatch({
      type: CREATE_LABEL_FAILURE,
      error: error.message
    });
    return null;
  }
};

export const updateLabel = (id, label, tags) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_LABEL, UPDATE_LABEL_SUCCESS, UPDATE_LABEL_FAILURE],
      endpoint: `/labels/${id}`,
      schema: Schemas.LABEL,
      method: "patch",
      data: {
        label: label,
        tags: Object.values(tags)
      }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated the Label successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update the Label"
      }
    ]
  });

export const deleteLabel = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_LABEL, DELETE_LABEL_SUCCESS, DELETE_LABEL_FAILURE],
      endpoint: `/labels/${id}`,
      schema: Schemas.LABEL,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted the Label successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete the Label"
      }
    ]
  });
