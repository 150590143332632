import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { View } from "./views";

class SetVariety extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variety: {}
    };
    this.setVariety = this.setVariety.bind(this);
    this.createVarietyConnection = this.createVarietyConnection.bind(this);
  }

  setVariety = variety => {
    if (variety) {
      this.setState({ ...this.state, variety: variety });
    }
  };

  createVarietyConnection = () => {
    this.props.createVarietyConnection(
      this.props.parent.id,
      this.state.variety.objectID
    );
  };

  render() {
    const { selected, preSetVariety } = this.props.plotBeds;
    const selectedCount = Object.keys(selected).length;
    if (selectedCount > 0 && !preSetVariety) {
      return (
        <View
          setVariety={this.setVariety}
          createVarietyConnection={this.createVarietyConnection}
        />
      );
    }
    return <div />;
  }
}

SetVariety.propTypes = {
  trial: PropTypes.object
};
const mapStateToProps = ({ plotBeds }) => ({ plotBeds });
export default connect(
  mapStateToProps,
  {}
)(SetVariety);
