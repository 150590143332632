import {
  FETCH_RANCHES,
  FETCH_RANCHES_SUCCESS,
  FETCH_RANCHES_FAILURE,
  FETCH_MORE_RANCHES,
  FETCH_MORE_RANCHES_SUCCESS,
  FETCH_MORE_RANCHES_FAILURE,
  CREATE_RANCH,
  CREATE_RANCH_SUCCESS,
  CREATE_RANCH_FAILURE,
  FETCH_RANCH,
  FETCH_RANCH_SUCCESS,
  FETCH_RANCH_FAILURE,
  UPDATE_RANCH,
  UPDATE_RANCH_SUCCESS,
  UPDATE_RANCH_FAILURE,
  UPDATE_RANCH_LOCALLY,
  DELETE_RANCH,
  DELETE_RANCH_SUCCESS,
  DELETE_RANCH_FAILURE,
  UPDATE_RANCH_SEARCH
} from "../actions/ranches";
import { getSingleEntity } from "./util";

const INITIAL_STATE = {
  ranches: {},
  ranch: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1,
  search: ""
};

export default function(state = INITIAL_STATE, action) {
  let ranch, ranches;
  switch (action.type) {
    case UPDATE_RANCH_SEARCH:
      let search = action.value || "";
      return { ...state, search: search };
    case UPDATE_RANCH_LOCALLY:
      ranch = state.ranch;
      ranch[action.key] = action.value;
      return { ...state, ranch: ranch };
    case FETCH_RANCHES:
      return { ...state, error: null, waiting: true };
    case FETCH_RANCHES_SUCCESS:
      return {
        ...state,
        ranches: action.data.ranches || {},
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_RANCHES_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_MORE_RANCHES:
      return { ...state, error: null, waiting: true };
    case FETCH_MORE_RANCHES_SUCCESS:
      ranches = state.ranches;
      let newRanches = action.data.ranches || {};
      Object.keys(newRanches).forEach(key => {
        ranches[key] = newRanches[key];
      });
      return {
        ...state,
        waiting: false,
        ranches: ranches,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_MORE_RANCHES_FAILURE:
      return { ...state, waiting: false, error: action.error };
    case FETCH_RANCH:
      return { ...state, ranch: {}, error: null, waiting: true };
    case FETCH_RANCH_SUCCESS:
      ranch = getSingleEntity(action.data.ranch);
      return { ...state, ranch: ranch || {}, error: null, waiting: false };
    case FETCH_RANCH_FAILURE:
      return { ...state, ranch: {}, error: action.error, waiting: false };
    case CREATE_RANCH:
      return { ...state, ranch: {}, error: null, waiting: true };
    case CREATE_RANCH_SUCCESS:
      ranch = getSingleEntity(action.data.ranch);
      ranches = state.ranches;
      ranches[ranch.id] = ranch;
      return {
        ...state,
        ranches: ranches,
        ranch: ranch,
        error: null,
        waiting: false
      };
    case CREATE_RANCH_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_RANCH:
      return { ...state, error: null, waiting: false };
    case UPDATE_RANCH_SUCCESS:
      ranch = getSingleEntity(action.data.ranch);
      return { ...state, ranch: ranch, error: null, waiting: false };
    case UPDATE_RANCH_FAILURE:
      if (action.status === 412) {
        ranch = getSingleEntity(action.data.ranch);
        return {
          ...state,
          error: action.error,
          waiting: false,
          ranch: ranch
        };
      }
      return { ...state, error: action.error, waiting: false };
    case DELETE_RANCH:
      return { ...state, waiting: true, error: null };
    case DELETE_RANCH_SUCCESS:
      ranches = state.ranches;
      delete ranches[action.data.id];
      return { ...state, ranches: ranches, waiting: false, error: null };
    case DELETE_RANCH_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
