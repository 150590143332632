import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  fetchCommoditiesClear,
  deleteCommodity,
  fetchMoreCommodities,
  createCommodity
} from "../../actions/commodities";
import { IndexHeaderButton, PageHeader, SimpleListName } from "../../ui";
import IndexNewField from "../IndexNewField";

class CommoditiesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: "",
      showNew: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.showMoreResults = this.showMoreResults.bind(this);
    this.toggleNew = this.toggleNew.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillMount() {
    this.props.fetchCommoditiesClear(this.state.searchField);
  }

  toggleNew = () => {
    this.setState({ ...this.state, showNew: !this.state.showNew });
  };

  showMoreResults = () => {
    this.props.fetchMoreCommodities(this.state.searchField);
  };

  handleChange = e => {
    this.setState({ ...this.state, searchField: e.target.value }, () => {
      this.props.fetchCommoditiesClear(this.state.searchField);
    });
  };

  onSave = str => {
    this.props.createCommodity(str);
    this.setState({
      fireRedirect: true
    });
  };

  render() {
    const {
      commodities,
      commodity,
      waiting,
      page,
      totalPages
    } = this.props.commodities;

    const { fireRedirect } = this.state;
    if (commodity.id && !waiting && fireRedirect) {
      return <Redirect push to={`/commodities/${commodity.id}`} />;
    }

    return (
      <React.Fragment>
        <PageHeader title={"Commodities"} half={6}>
          <IndexHeaderButton create={this.toggleNew} />
        </PageHeader>
        {this.state.showNew && (
          <IndexNewField onSave={this.onSave} label={"Create Commodity"} />
        )}
        <SimpleListName
          waiting={waiting}
          baseUrl={"commodities"}
          searchField={this.state.searchField}
          handleChange={this.handleChange}
          showMoreResults={this.showMoreResults}
          onDelete={this.props.deleteCommodity}
          rows={commodities}
          page={page}
          totalPages={totalPages}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.commodities
  };
};

export default connect(
  mapStateToProps,
  {
    fetchCommoditiesClear,
    deleteCommodity,
    fetchMoreCommodities,
    createCommodity
  }
)(CommoditiesList);
