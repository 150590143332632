import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import _ from 'lodash';

class StatsTable extends Component {
  // date1 maturityDate, endDate
  differenceInDays = (date1, date2) => {
    const differenceInTimes = date1.getTime() - date2.getTime();
    return differenceInTimes / (1000 * 3600 * 24);
  };

  dateToKey = (date) => `${date.getMonth()}-${date.getDay()}`;

  dayGroupings = (byMaturityDates) => {
    const { dayGroupings, startDate, endDate } = this.props;
    const rows = []
    for(const initialDate = new Date(Number(startDate)); initialDate < endDate; initialDate.setDate(initialDate.getDate() + dayGroupings) ){
      let grouping = [];
      const groupEndDate = new Date(Number(initialDate));
      groupEndDate.setDate(groupEndDate.getDate() + dayGroupings)
      for(const iDate = new Date(Number(initialDate)); iDate < groupEndDate; iDate.setDate(iDate.getDate() + 1) ){
        const key = this.dateToKey(iDate)
        if(byMaturityDates[key]){
          grouping = grouping.concat(byMaturityDates[key])
        }
      }
      const average = Math.round(_.mean(grouping));

      rows.push({
        startDate: initialDate.toDateString(),
        endDate: groupEndDate.toDateString(),
        average: average,
        total: grouping.length
      })
    }
    return rows;
  };

  compileData = (data) => {
    const { dateType } = this.props;
    const byMaturityDates = {}
    data
      .filter(record => record.maturityDate ? true : false)
      .forEach(record => {
        const maturityDate = new Date(record.maturityDate);
        const endDate = new Date(record[`${dateType}Date`]);
        const days = this.differenceInDays(maturityDate, endDate);
        const key = this.dateToKey(endDate);
        if(byMaturityDates[key] === undefined){
          byMaturityDates[key] = []
        }
        byMaturityDates[key].push(days)
      })
    return this.dayGroupings(byMaturityDates);
  }

  render(){
    const { data } = this.props.statistics;

    if(data.length === 0){
      return (
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Not enough data for chosen conditions
          </Typography>
        </Grid>
      )
    }

    const rows = this.compileData(data);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={this.props.classes.tableRoot}>
          <Table aria-label="stats table">
            <TableHead>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell># Trials</TableCell>
                <TableCell>Days to Harvest</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow>
                  <TableCell>{row['startDate']}</TableCell>
                  <TableCell>{row['endDate']}</TableCell>
                  <TableCell>{row['total']}</TableCell>
                  <TableCell>{row['average']}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
});

const mapStateToProps = ({ statistics }) => ({ statistics });
export default connect(mapStateToProps, {})(withStyles(styles)(StatsTable));

