import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DownshiftSingle from "../DownshiftSingle";
import { sortObjectByKeyDesc } from "../../helpers";
import { fetchCommoditiesClear } from "../../actions/commodities";

class CommoditySelect extends Component {
  render() {
    const { commodities, waiting } = this.props.commodities;
    let results = commodities || {};
    results = sortObjectByKeyDesc(results, "rank");

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Commodity"}
        label={"Commodity"}
        search={this.props.fetchCommoditiesClear}
        results={results}
        initialItem={this.props.commodity}
        createItem={this.props.createCommodity}
        selectedItemParent={this.props.setCommodity}
      />
    );
  }
}

CommoditySelect.propTypes = {
  commodity: PropTypes.object,
  createCommodity: PropTypes.func,
  setCommodity: PropTypes.func.isRequired
};

const mapStateToProps = ({ commodities }) => ({ commodities });

export default connect(
  mapStateToProps,
  {
    fetchCommoditiesClear
  }
)(CommoditySelect);
