import React, { Component } from "react";
import { connect } from "react-redux";
import { clearCommodity } from "../actions/commodities";
import { FieldVarietyGrouping, PlotGridGrouping } from "./trials";
import Grid from "@material-ui/core/Grid";

class ToggleEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plotGridEvaluation: false,
      fieldVarietyEvaluation: false
    };
    this.closePlotGrid = this.closePlotGrid.bind(this);
    this.closeFieldVariety = this.closeFieldVariety.bind(this);
  }

  closePlotGrid = open => {
    if (open) {
      this.setState(
        {
          ...this.state,
          fieldVarietyEvaluation: true,
          plotGridEvaluation: false
        },
        () => {
          this.props.clearCommodity();
        }
      );
    } else {
      this.setState({ ...this.state, fieldVarietyEvaluation: false });
    }
  };

  closeFieldVariety = open => {
    if (open) {
      this.setState(
        {
          fieldVarietyEvaluation: false,
          plotGridEvaluation: true
        },
        () => {
          this.props.clearCommodity();
        }
      );
    } else {
      this.setState({
        plotGridEvaluation: false
      });
    }
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FieldVarietyGrouping
            openEvaluation={this.closePlotGrid}
            showEvaluation={this.state.fieldVarietyEvaluation}
          />
        </Grid>
        <Grid item xs={12}>
          <PlotGridGrouping
            openEvaluation={this.closeFieldVariety}
            showEvaluation={this.state.plotGridEvaluation}
          />
        </Grid>
      </Grid>
    );
  }
}
export default connect(
  null,
  {
    clearCommodity
  }
)(ToggleEvaluation);
