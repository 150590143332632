import React from "react";
import PropTypes from "prop-types";

import { DrawerColumn, DrawerSupplierColumn } from "../../reports";
import { SearchFilter } from "../../companies";

import { DatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  filterList: {
    width: "auto"
  },
  filterButton: {
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(3)
  },
  dateFilter: {
    marginTop: 10
  }
});

export const FilterDrawer = withStyles(styles)(
  ({ children, filters, records, ...props }) => (
    <div>
      <Drawer
        anchor="top"
        open={props.show}
        onClose={props.toggleFilterDrawer(false)}
      >
        <div className={props.classes.filterList}>
          <FormControl
            component="fieldset"
            className={props.classes.formControl}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Growers</FormLabel>
                <FormGroup>
                  <SearchFilter
                    records={records}
                    updateSelected={props.updateSelected("grower")}
                    initialResults={Object.values(filters["grower"])}
                    placeholder={"Select Growers"}
                    type={"grower"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Shippers</FormLabel>
                <FormGroup>
                  <SearchFilter
                    records={records}
                    initialResults={Object.values(filters["shipper"])}
                    updateSelected={props.updateSelected("shipper")}
                    type={"shipper"}
                    placeholder={"Select Shippers"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Areas</FormLabel>
                <FormGroup>
                  <SearchFilter
                    records={records}
                    initialResults={Object.values(filters["area"])}
                    updateSelected={props.updateSelected("area")}
                    type={"area"}
                    placeholder={"Select Areas"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Regions</FormLabel>
                <FormGroup>
                  <SearchFilter
                    records={records}
                    initialResults={Object.values(filters["region"])}
                    updateSelected={props.updateSelected("region")}
                    type={"region"}
                    placeholder={"Select Regions"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Commodities</FormLabel>
                <FormGroup>
                  <DrawerColumn
                    type={"commodity"}
                    records={records}
                    filters={filters["commodity"]}
                    handleFilterCheckboxChange={props.handleFilterCheckboxChange(
                      "commodity"
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Suppliers</FormLabel>
                <FormGroup>
                  <DrawerSupplierColumn
                    filters={filters["supplier"]}
                    records={records}
                    handleFilterCheckboxChange={props.handleFilterCheckboxChange(
                      "supplier"
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Cultural Practice</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters["organic"]}
                      onChange={props.toggleFilterChange("organic")}
                      value="organic"
                      color="primary"
                    />
                  }
                  label="Organic"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters["conventional"]}
                      onChange={props.toggleFilterChange("conventional")}
                      value="conventional"
                      color="primary"
                    />
                  }
                  label="Cultural Practice"
                />
                <FormLabel component="legend">Grower's Feeling</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters["growerLike"]}
                      onChange={props.toggleFilterChange("growerLike")}
                      value="growerLike"
                      color="primary"
                    />
                  }
                  label="Like"
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <FormLabel component="legend">Date Range</FormLabel>
                <DatePicker
                  value={filters["startDate"] || null}
                  onChange={props.handleDateChange("startDate")}
                  fullWidth
                  clearable
                  format={"MMMM Do, YYYY"}
                  label="Start Date"
                />
                <DatePicker
                  value={filters["endDate"] || null}
                  onChange={props.handleDateChange("endDate")}
                  fullWidth
                  clearable
                  format={"MMMM Do, YYYY"}
                  label="End Date"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters["excludeYear"]}
                      onChange={props.toggleFilterChange("excludeYear")}
                      value="excludeYear"
                      color="primary"
                    />
                  }
                  label="Exclude Years"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters["wetDateSearch"]}
                      onChange={props.toggleFilterChange("wetDateSearch")}
                      value="wetDateSearch"
                      color="primary"
                    />
                  }
                  label="Date Search by Wet Date"
                />
              </Grid>
            </Grid>
          </FormControl>
          <Divider />
          <FormControl
            component="fieldset"
            className={props.classes.formControl}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel component="legend">Displayed Information</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    key={"grower"}
                    control={
                      <Checkbox
                        checked={!filters["hide"]["grower"]}
                        onChange={props.toggleHide("grower")}
                      />
                    }
                    label={"Grower"}
                  />
                  <FormControlLabel
                    key={"shipper"}
                    control={
                      <Checkbox
                        checked={!filters["hide"]["shipper"]}
                        onChange={props.toggleHide("shipper")}
                      />
                    }
                    label={"Shipper"}
                  />
                  <FormControlLabel
                    key={"ranch"}
                    control={
                      <Checkbox
                        checked={!filters["hide"]["ranch"]}
                        onChange={props.toggleHide("ranch")}
                      />
                    }
                    label={"Ranch"}
                  />
                  <FormControlLabel
                    key={"supplier"}
                    control={
                      <Checkbox
                        checked={!filters["hide"]["supplier"]}
                        onChange={props.toggleHide("supplier")}
                      />
                    }
                    label={"Supplier"}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </FormControl>
          <Divider />
          <FormControl
            component="fieldset"
            className={props.classes.formControl}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={props.toggleFilterDrawer(false)}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </div>
      </Drawer>
      {children}
    </div>
  )
);

FilterDrawer.propTypes = {
  records: PropTypes.array,
  filters: PropTypes.object.isRequired,
  children: PropTypes.node,
  show: PropTypes.bool,
  handleFilterCheckboxChange: PropTypes.func,
  toggleFilterDrawer: PropTypes.func,
  toggleHide: PropTypes.func.isRequired
};
