import React from "react";
import TableHeaderSimple from "../../../ui/SimpleListName/TableHeaderSimple";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 40
  },
  searchContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  progressGrid: {
    marginTop: 30
  }
});

export const SearchField = withStyles(styles)(
  ({ searchField, handleChange, ...props }) => (
    <Grid item xs={12}>
      <TextField
        id="input-with-icon-textfield"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
        autoFocus={true}
        value={searchField}
        onChange={handleChange}
      />
    </Grid>
  )
);

export const LabelTable = withStyles(styles)(
  ({ addLabel, labels, searchField, ...props }) => (
    <Grid container spacing={2} className={props.classes.root}>
      <Grid item xs={12}>
        {searchField.length !== 0 && Object.values(labels).length === 0 ? (
          <Typography variant="h5" gutterBottom>
            No Results
          </Typography>
        ) : (
          <Table>
            <TableHeaderSimple headers={["Name", ""]} />
            <TableBody>
              {Object.values(labels)
                .sort((a, b) => {
                  if (a.name > b.name) {
                    return 1;
                  } else if (a.name < b.name) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .map((label, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2" gutterBottom>
                        {label.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        aria-label="Delete"
                        onClick={addLabel(label.id)}
                      >
                        <AddIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  )
);
