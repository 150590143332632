import {
  FETCH_SUBCRITERIA,
  FETCH_SUBCRITERIA_SUCCESS,
  FETCH_SUBCRITERIA_FAILURE,
  FETCH_SUBCRITERIUM,
  FETCH_SUBCRITERIUM_SUCCESS,
  FETCH_SUBCRITERIUM_FAILURE,
  CREATE_SUBCRITERIUM,
  CREATE_SUBCRITERIUM_SUCCESS,
  CREATE_SUBCRITERIUM_FAILURE,
  CLEAR_SUBCRITERIA,
  UPDATE_SUBCRITERIUM_LOCALLY,
  UPDATE_SUBCRITERIUM,
  UPDATE_SUBCRITERIUM_SUCCESS,
  UPDATE_SUBCRITERIUM_FAILURE,
  DELETE_SUBCRITERIUM,
  DELETE_SUBCRITERIUM_SUCCESS,
  DELETE_SUBCRITERIUM_FAILURE
} from "../actions/subcriteria";
import {
  CREATE_COMMODITY_SUCCESS,
  COMMODITY_ADD_LABEL_SUCCESS,
  COMMODITY_ADD_TAG_SUCCESS,
  COMMODITY_REMOVE_LABEL_SUCCESS,
  COMMODITY_REMOVE_TAG_SUCCESS,
  FETCH_COMMODITY_SUCCESS,
  FETCH_COMMODITIES_DETAILS_SUCCESS,
  UPDATE_COMMODITY_SUCCESS
} from "../actions/commodities";
import { FETCH_CRITERIUM_SUCCESS } from "../actions/criteria";
import { FETCH_TRIAL_SUCCESS } from "../actions/trials";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS,
  CREATE_FIELD_VARIETY_SUCCESS
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import {
  FETCH_EVALUATIONS_PAGINATION_SUCCESS,
  FETCH_EVALUATIONS_SUCCESS
} from "../actions/evaluations";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  subcriteria: {},
  subcriterium: {},
  error: null,
  waiting: false
};

const computeWeightedScore = (score, weight) => {
  if (score && weight) {
    const tempTotal = (parseFloat(score) * parseFloat(weight)).toFixed(2);
    return parseFloat(tempTotal);
  }
  return 0;
};

export default function(state = INITIAL_STATE, action) {
  let subcriterium, subcriteria;
  switch (action.type) {
    case CREATE_COMMODITY_SUCCESS:
    case UPDATE_COMMODITY_SUCCESS:
    case COMMODITY_ADD_LABEL_SUCCESS:
    case COMMODITY_ADD_TAG_SUCCESS:
    case COMMODITY_REMOVE_LABEL_SUCCESS:
    case COMMODITY_REMOVE_TAG_SUCCESS:
    case FETCH_COMMODITY_SUCCESS:
    case FETCH_COMMODITIES_DETAILS_SUCCESS:
    case FETCH_CRITERIUM_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_EVALUATIONS_SUCCESS:
    case FETCH_EVALUATIONS_PAGINATION_SUCCESS:
    case CREATE_FIELD_VARIETY_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
      subcriteria = combine(state.subcriteria, action.data.subcriteria);
      return { ...state, subcriteria: subcriteria };
    case CLEAR_SUBCRITERIA:
      return { ...state, INITIAL_STATE };
    case UPDATE_SUBCRITERIUM_LOCALLY:
      subcriteria = state.subcriteria;
      subcriterium = subcriteria[action.id] || {};
      subcriterium[action.key] = action.value;
      if (action.key === "score" || action.key === "weight") {
        subcriterium.weightedScore = computeWeightedScore(
          subcriterium.score,
          subcriterium.weight
        );
      }
      subcriteria[action.id] = subcriterium;
      return { ...state, subcriteria: subcriteria };

    case FETCH_SUBCRITERIA:
      return { ...state, error: null, waiting: true };
    case FETCH_SUBCRITERIA_SUCCESS:
      subcriteria = combine(state.subcriteria, action.data.subcriteria);
      return {
        ...state,
        subcriteria: subcriteria,
        error: null,
        waiting: false
      };
    case FETCH_SUBCRITERIA_FAILURE:
      return { ...state, error: action.error, waiting: false };

    case FETCH_SUBCRITERIUM:
      return { ...state, error: null, waiting: true };
    case FETCH_SUBCRITERIUM_SUCCESS:
      subcriteria = state.subcriteria;
      subcriterium = getSingleEntity(action.data.subcriteria);
      subcriteria[subcriterium.id] = subcriterium;
      return {
        ...state,
        subcriteria: subcriteria,
        error: null,
        waiting: false
      };
    case FETCH_SUBCRITERIUM_FAILURE:
      return { ...state, error: action.error, waiting: false };

    case CREATE_SUBCRITERIUM:
      return { ...state, error: null, waiting: true };
    case CREATE_SUBCRITERIUM_SUCCESS:
      subcriteria = state.subcriteria;
      subcriterium = getSingleEntity(action.data.subcriteria);
      subcriteria[subcriterium.id] = subcriterium;
      return {
        ...state,
        subcriteria: subcriteria,
        error: null,
        waiting: false
      };
    case CREATE_SUBCRITERIUM_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_SUBCRITERIUM:
      return { ...state, waiting: true, error: null };
    case UPDATE_SUBCRITERIUM_SUCCESS:
      subcriteria = state.subcriteria;
      subcriterium = getSingleEntity(action.data.subcriteria);
      subcriteria[subcriterium.id] = subcriterium;
      return {
        ...state,
        subcriteria: subcriteria,
        error: null,
        waiting: false
      };
    case UPDATE_SUBCRITERIUM_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case DELETE_SUBCRITERIUM:
      return { ...state, error: null, waiting: true };
    case DELETE_SUBCRITERIUM_SUCCESS:
      subcriteria = state.subcriteria;
      delete subcriteria[action.data.id];
      return {
        ...state,
        subcriteria: subcriteria,
        error: null,
        waiting: false
      };
    case DELETE_SUBCRITERIUM_FAILURE:
      return { ...state, error: action.error, waiting: false };
    default:
      return state;
  }
}
