import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPerson } from "../actions/people";

import { Navbar } from "../containers";
import { PersonEdit } from "../containers/people";
import { LoadingSpinner } from "../ui";

import Grid from "@material-ui/core/Grid";

class Person extends Component {
  componentDidMount() {
    this.props.fetchPerson(this.props.match.params.id);
  }

  render() {
    const { waiting } = this.props.people;
    return (
      <Navbar currentPage="people">
        <Grid container>
          {waiting ? (
            <LoadingSpinner />
          ) : (
            <PersonEdit id={this.props.match.params.id} update={true} />
          )}
        </Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    people: state.people
  };
};

export default connect(
  mapStateToProps,
  {
    fetchPerson
  }
)(Person);
