import React from "react";
import { CustomHighlight } from "../../ui";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

const VarietyHit = ({ hit, handleToggle, checked, ...props }) => {
  return (
    <TableRow key={hit.objectID}>
      <TableCell component="th" scope="row">
        {props.report ? (
          <Link
            to={"/reports/single/" + hit.objectID}
            className={props.classes.linkStyle}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              className={props.classes.linkTypography}
            >
              <CustomHighlight attribute="name" hit={hit} key={hit.objectID} />
            </Typography>
          </Link>
        ) : (
          <Typography variant="subtitle1" gutterBottom>
            <CustomHighlight attribute="name" hit={hit} key={hit.objectID} />
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" gutterBottom>
          <CustomHighlight attribute="company.name" hit={hit} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" gutterBottom>
          <CustomHighlight attribute="commodities" hit={hit} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Checkbox
          checked={checked.indexOf(hit.objectID) !== -1}
          tabIndex={-1}
          disableRipple
          onClick={handleToggle(hit.objectID)}
        />
      </TableCell>
    </TableRow>
  );
};
const styles = theme => ({
  linkStyle: theme.linkStyle,
  linkTypography: theme.linkTypography,
  "ais-Highlight-highlighted": {
    backgroundColor: "#FFFF00"
  }
});

export default withStyles(styles)(VarietyHit);
