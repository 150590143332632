import React, { Component } from "react";
import { connect } from "react-redux";
import DownshiftSingle from "../DownshiftSingle";
import { fetchGrower, fetchGrowers } from "../../actions/growers";

class GrowerSelect extends Component {
  render() {
    const { classes } = this.props;
    const { growers, waiting } = this.props.growers;
    const results = growers || {};

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Grower"}
        label={"Grower"}
        classes={classes}
        search={this.props.fetchGrowers}
        results={results}
        initialItem={this.props.grower}
        selectedItemParent={this.props.setGrower}
      />
    );
  }
}

const mapStateToProps = ({ growers }) => ({ growers });

export default connect(
  mapStateToProps,
  {
    fetchGrower,
    fetchGrowers
  }
)(GrowerSelect);
