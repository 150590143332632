import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CommoditySelect } from "../../commodities";

import { DatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";

export const NurseryButtons = ({ onSave, ...props }) => (
  <React.Fragment>
    <Grid item xs={3}>
      <Button component={Link} to="/nurseries" variant="contained">
        Cancel
      </Button>
    </Grid>
    <Grid item xs={3}>
      <Button variant="contained" color="primary" onClick={onSave}>
        Save
      </Button>
    </Grid>
  </React.Fragment>
);

NurseryButtons.propTypes = {
  onSave: PropTypes.func.isRequired
};

const styles = theme => ({
  activeGrid: {
    padding: 10
  },
  plotTypesFormGroup: {
    marginLeft: 5
  }
});

export const NurseryEditableItems = withStyles(styles)(
  ({
    nursery,
    handleChange,
    handleDateChange,
    setRecord,
    title,
    createNurseVariety,
    ...props
  }) => (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={nursery.name || ""}
              onChange={handleChange("name")}
            />
          </Grid>
          <Grid item xs={6}>
            <CommoditySelect
              commodity={nursery.commodity}
              setCommodity={setRecord("commodity")}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              value={nursery.wetDate}
              inputVariant="outlined"
              onChange={handleDateChange("wetDate")}
              fullWidth
              clearable
              format={"MMMM Do, YYYY"}
              label="Wet Date"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              value={nursery.estTransferDate}
              inputVariant="outlined"
              onChange={handleDateChange("estTransferDate")}
              fullWidth
              clearable
              format={"MMMM Do, YYYY"}
              label="Est. Transfer Date"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row className={props.classes.activeGrid}>
              <FormControlLabel
                control={
                  <Switch
                    checked={nursery.active}
                    onChange={handleChange("active")}
                    value="Active"
                    color="primary"
                  />
                }
                label="Active"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline={true}
              variant="outlined"
              placeholder="Comments ..."
              label="Comments"
              rows={4}
              value={nursery.comments || ""}
              onChange={handleChange("comments")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Plot Rows"
              variant="outlined"
              value={nursery.plotRows || 0}
              onChange={handleChange("plotRows")}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Plot Cols"
              variant="outlined"
              value={nursery.plotCols || 0}
              onChange={handleChange("plotCols")}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row className={props.classes.plotTypesFormGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nursery.plotType === "normal"}
                    onChange={handleChange("plotType")}
                    value="normal"
                  />
                }
                label="Normal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nursery.plotType === "snake"}
                    onChange={handleChange("plotType")}
                    value="snake"
                  />
                }
                label="Snake"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
);

NurseryEditableItems.propTypes = {
  nursery: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setRecord: PropTypes.func.isRequired
};
