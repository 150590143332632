import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";
import { UPDATE_TRIAL_RANCH_ID_LOCALLY } from "./trials";

export const UPDATE_RANCH_LOCALLY = "UPDATE_RANCH_LOCALLY";

export const FETCH_RANCHES = "FETCH_RANCHES";
export const FETCH_RANCHES_SUCCESS = "FETCH_RANCHES_SUCCESS";
export const FETCH_RANCHES_FAILURE = "FETCH_RANCHES_FAILURE";

export const FETCH_MORE_RANCHES = "FETCH_MORE_RANCHES";
export const FETCH_MORE_RANCHES_SUCCESS = "FETCH_MORE_RANCHES_SUCCESS";
export const FETCH_MORE_RANCHES_FAILURE = "FETCH_MORE_RANCHES_FAILURE";

export const FETCH_RANCH = "FETCH_RANCH";
export const FETCH_RANCH_SUCCESS = "FETCH_RANCH_SUCCESS";
export const FETCH_RANCH_FAILURE = "FETCH_RANCH_FAILURE";

export const CREATE_RANCH = "CREATE_RANCH";
export const CREATE_RANCH_SUCCESS = "CREATE_RANCH_SUCCESS";
export const CREATE_RANCH_FAILURE = "CREATE_RANCH_FAILURE";

export const UPDATE_RANCH = "UPDATE_RANCH";
export const UPDATE_RANCH_SUCCESS = "UPDATE_RANCH_SUCCESS";
export const UPDATE_RANCH_FAILURE = "UPDATE_RANCH_FAILURE";

export const DELETE_RANCH = "DELETE_RANCH";
export const DELETE_RANCH_SUCCESS = "DELETE_RANCH_SUCCESS";
export const DELETE_RANCH_FAILURE = "DELETE_RANCH_FAILURE";

export const UPDATE_RANCH_SEARCH = "UPDATE_RANCH_SEARCH";

export const updateSearch = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_RANCH_SEARCH,
    value: value
  });

export const updateRanchLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_RANCH_LOCALLY,
    key: key,
    value: value
  });

export const fetchRanches = (search = "") => (dispatch, getState) => {
  const { page } = getState().ranches;
  return dispatch({
    [CALL_API]: {
      types: [FETCH_RANCHES, FETCH_RANCHES_SUCCESS, FETCH_RANCHES_FAILURE],
      endpoint: `/ranches?page=${page}&search=${search}`,
      schema: Schemas.RANCHES,
      method: "get"
    }
  });
};

export const fetchMoreRanches = (search = "") => (dispatch, getState) => {
  const { page } = getState().ranches;
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_MORE_RANCHES,
        FETCH_MORE_RANCHES_SUCCESS,
        FETCH_MORE_RANCHES_FAILURE
      ],
      endpoint: `/ranches?search=${search}&page=${page + 1}`,
      schema: Schemas.RANCHES,
      method: "get"
    }
  });
};

export const fetchRanch = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_RANCH, FETCH_RANCH_SUCCESS, FETCH_RANCH_FAILURE],
      endpoint: `/ranches/${id}`,
      schema: Schemas.RANCH,
      method: "get"
    }
  });

export const createRanch = name => (dispatch, getState) =>
  dispatch(createRanchCall(name));

export const createRanchUpdateTrial = name => (dispatch, getState) => {
  const updateTrialCall = {
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created an Ranch successfully"
      },
      {
        type: UPDATE_TRIAL_RANCH_ID_LOCALLY
      }
    ]
  };
  const apiCall = Object.assign(createRanchCall(name), updateTrialCall);
  return dispatch(apiCall);
};

const createRanchCall = name => ({
  [CALL_API]: {
    types: [CREATE_RANCH, CREATE_RANCH_SUCCESS, CREATE_RANCH_FAILURE],
    endpoint: "/ranches",
    schema: Schemas.RANCH,
    method: "post",
    data: { ranch: { name: name } }
  },
  [AFTER_SUCCESS]: [
    {
      type: SET_NOTIFICATION,
      payload: "Created a Ranch successfully"
    }
  ],
  [AFTER_FAILURE]: [
    {
      type: SET_NOTIFICATION,
      payload: "Failed to create Ranch"
    }
  ]
});

export const updateRanch = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_RANCH, UPDATE_RANCH_SUCCESS, UPDATE_RANCH_FAILURE],
      endpoint: `/ranches/${id}`,
      schema: Schemas.RANCH,
      method: "patch",
      data: { ranch: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated Ranch successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update Ranch"
      }
    ]
  });

export const deleteRanch = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_RANCH, DELETE_RANCH_SUCCESS, DELETE_RANCH_FAILURE],
      endpoint: `/ranches/${id}`,
      schema: Schemas.RANCH,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted a Ranch successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete Ranch"
      }
    ]
  });
