import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  fetchNurseries,
  deleteNursery,
  fetchMoreNurseries,
  createNursery,
  updateSearch,
  updateShowInactive
} from "../../../actions/nurseries";
import IndexNewField from "../../IndexNewField";
import { ErrorPage, IndexHeaderButton, PageHeader } from "../../../ui";
import { NurseryRows, NurseryTable, TableSearchHeader } from "./views";

class NurseriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNew: false,
      showInactive: false
    };
  }

  componentWillMount() {
    const { search, showInactive } = this.props.nurseries;
    this.props.fetchNurseries(search, showInactive);
  }

  toggleNew = () => {
    this.setState({ ...this.state, showNew: !this.state.showNew });
  };

  onSave = str => {
    this.props.createNursery(str);
    this.setState({
      fireRedirect: true
    });
  };

  handleChange = e => {
    const { showInactive } = this.props.nurseries;
    this.props.updateSearch(e.target.value, showInactive);
    this.props.fetchNurseries(e.target.value, showInactive);
  };

  toggleInactive = e => {
    const { search } = this.props.nurseries;
    this.props.updateShowInactive(!this.state.showInactive);
    this.props.fetchNurseries(search, !this.state.showInactive);
  };

  showMoreResults = () => {
    const { search, showInactive } = this.props.nurseries;
    this.props.fetchMoreNurseries(search, showInactive);
  };

  render() {
    const {
      nurseries,
      nursery,
      waiting,
      page,
      totalPages,
      error,
      search,
      showInactive
    } = this.props.nurseries;

    const { fireRedirect } = this.state;
    if (nursery.id && !waiting && fireRedirect) {
      return <Redirect push to={`/nurseries/${nursery.id}`} />;
    }

    if (error) {
      return <ErrorPage msg={error} />;
    }

    return (
      <React.Fragment>
        <PageHeader title={"Nurseries"} half={7}>
          <IndexHeaderButton create={this.toggleNew} />
        </PageHeader>
        {this.state.showNew && (
          <IndexNewField onSave={this.onSave} label={"Create Nursery"} />
        )}
        <div style={{ flexGrow: 1 }}>
          <TableSearchHeader
            searchField={search}
            handleChange={this.handleChange}
            showInactive={showInactive}
            toggleInactive={this.toggleInactive}
          />
          <NurseryTable
            waiting={waiting}
            showMoreResults={this.showMoreResults}
            page={page}
            totalPages={totalPages}
          >
            <NurseryRows
              nurseries={nurseries}
              onDelete={this.props.deleteNursery}
            />
          </NurseryTable>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    nurseries: state.nurseries
  };
};

export default connect(
  mapStateToProps,
  {
    fetchNurseries,
    deleteNursery,
    fetchMoreNurseries,
    createNursery,
    updateSearch,
    updateShowInactive
  }
)(NurseriesList);
