import React, { Component } from "react";
import DownshiftMultiple from "../DownshiftMultiple";
import { connect } from "react-redux";
import { sortObjectByKeyDesc } from "../../helpers/utils";
import { fetchPeople } from "../../actions/people";

class PeopleMultipleSelect extends Component {
  render() {
    const { people, waiting } = this.props.people;
    let results = people || {};
    results = sortObjectByKeyDesc(results, "rank");
    const initialItems = this.props.initialItems || [];

    return (
      <DownshiftMultiple
        waiting={waiting}
        placeholder={"Select People"}
        label={"People"}
        search={this.props.fetchPeople}
        results={results}
        initialItems={initialItems}
        selectedItemsParent={this.props.setPeople}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    people: state.people
  };
};
export default connect(
  mapStateToProps,
  {
    fetchPeople
  }
)(PeopleMultipleSelect);
