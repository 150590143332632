import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deleteTrialVariety,
  setTrialVarietyLocally
} from "../../actions/trial_varieties";
import { clearSelectedPlotBeds } from "../../actions/plot_beds";
import PlotVarieties from "../PlotVarieties";

class PlotTrialVarieties extends Component {
  constructor(props) {
    super(props);
    this.deleteTrialVariety = this.deleteTrialVariety.bind(this);
    this.isCompleted = this.isCompleted.bind(this);
  }

  deleteTrialVariety = trial => trialVariety => {
    this.props.deleteTrialVariety(trial.id, trialVariety.id);
    this.props.openEvaluation(false);
    this.props.clearSelectedPlotBeds();
  };

  afterSetSelectedPlotBeds = () => {
    this.props.openEvaluation(true);
  };

  isCompleted = trialVariety => {
    // has comments || // maturity date
    if (!trialVariety.comments || !trialVariety.maturityDate) {
      return false;
    }
    // has commodityId
    if (!trialVariety.commodityId) {
      return false;
    }
    return true;
  };

  render() {
    const { trial } = this.props.trials;
    return (
      <PlotVarieties
        varietyType={"trialVarieties"}
        plotsType={"TrialVariety"}
        mainTypeSingle={"trial"}
        mainTypePlural={"trials"}
        isCompleted={this.isCompleted}
        afterSetSelectedPlotBeds={this.afterSetSelectedPlotBeds}
        deletePlotVarietyConnector={this.deleteTrialVariety(trial)}
        setVarietyConnectorLocally={this.props.setTrialVarietyLocally}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    trials: state.trials,
    trialVarieties: state.trialVarieties,
    evaluations: state.evaluations,
    commodities: state.commodities
  };
};
export default connect(mapStateToProps, {
  deleteTrialVariety,
  setTrialVarietyLocally,
  clearSelectedPlotBeds
})(PlotTrialVarieties);
