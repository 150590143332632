import React from "react";
import PropTypes from "prop-types";

import TrialModal from "../TrialModal";
import EvaluationCommodity from "../../EvaluationCommodity";
import ArchivedEvaluationScore from "../ArchivedEvaluationScore";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";

export const EvaluationCommoditiesLoading = ({ ...props }) => (
  <Grid container>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={3}>
              <CircularProgress size={50} thickness={5} color={"primary"} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export const VarietyNoAssociatedCommodities = ({ ...props }) => (
  <Grid container>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            No commodities are associated with this variety.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

const evaluationScoringStyles = theme => ({
  searchContainer: {
    marginBottom: 20
  },
  linkStyle: theme.linkStyle,
  commodityContainer: {
    marginTop: 20
  },
  commoditySelect: {
    marginTop: 20
  },
  likeButton: {
    marginRight: 10
  }
});

export const EvaluationScoring = withStyles(evaluationScoringStyles)(
  ({ archive, record, type, onCommoditySelected, commodities, ...props }) => (
    <React.Fragment>
      {(
        <React.Fragment>
          <Select
            fullWidth
            label="Commodity"
            onChange={onCommoditySelected}
            value={record.commodityId || ""}
            className={props.classes.commoditySelect}
          >
            {commodities.map((commodity, index) => (
              <MenuItem key={index} value={commodity.id}>
                {commodity.name}
              </MenuItem>
            ))}
          </Select>
          {record.commodityId ? (
            <React.Fragment>
              <EvaluationCommodity recordId={record.id} type={type} />
              <TrialModal recordId={record.id} type={type} />
            </React.Fragment>
          ) : (
            <Typography variant="body1" gutterBottom>
              Must select a Commodity before evaluating
            </Typography>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
);

EvaluationScoring.propTypes = {
  archive: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  onCommoditySelected: PropTypes.func.isRequired,
  commodities: PropTypes.array.isRequired
};

export const View = ({
  trial,
  variety,
  onSave,
  onCancel,
  children,
  ...props
}) => (
  <Grid container style={{ marginTop: 0 }}>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {variety.name}
          </Typography>
          {children}
        </CardContent>
        {!trial.archive && <Buttons onSave={onSave} onCancel={onCancel} />}
      </Card>
    </Grid>
  </Grid>
);

View.propTypes = {
  variety: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export const Buttons = ({ onSave, onCancel, ...props }) => (
  <CardActions>
    <Button
      size="small"
      color="primary"
      variant="contained"
      onClick={onSave}
      fullWidth
    >
      Save
    </Button>
    <Button
      size="small"
      color="default"
      variant="contained"
      onClick={onCancel}
      fullWidth
    >
      Close
    </Button>
  </CardActions>
);
