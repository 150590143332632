import React, { Component } from "react";
import { Navbar } from "../containers";
import { ReportsList } from "../containers/reports";

class Reports extends Component {
  render() {
    return (
      <Navbar currentPage="reports">
        <ReportsList />
      </Navbar>
    );
  }
}

export default Reports;
