import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ForgotPasswordForm } from "../containers";

class ForgotPassword extends Component {
  render() {
    if (this.props.authenticated) {
      return <Redirect push to="/" />;
    }
    return <ForgotPasswordForm />;
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.user.authenticated
  };
};

export default connect(
  mapStateToProps,
  null
)(ForgotPassword);
