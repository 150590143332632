import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchRegion } from "../actions/regions";

import { Navbar } from "../containers";
import { RegionEdit } from "../containers/regions";

import Grid from "@material-ui/core/Grid";

class Region extends Component {
  componentWillMount() {
    this.props.fetchRegion(this.props.match.params.id);
  }

  render() {
    const { region } = this.props.regions;

    return (
      <Navbar currentPage="regions">
        <Grid container>
          <RegionEdit region={region} id={this.props.match.params.id} />
        </Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    regions: state.regions
  };
};

export default connect(
  mapStateToProps,
  {
    fetchRegion
  }
)(Region);
