import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const CLEAR_VARIETY = "CLEAR_VARIETY";
export const UPDATE_VARIETY_LOCALLY = "UPDATE_VARIETY_LOCALLY";
export const UPDATE_VARIETY_SEARCH = "UPDATE_VARIETY_SEARCH";

export const FETCH_VARIETIES = "FETCH_VARIETIES";
export const FETCH_VARIETIES_SUCCESS = "FETCH_VARIETIES_SUCCESS";
export const FETCH_VARIETIES_FAILURE = "FETCH_VARIETIES_FAILURE";

export const FETCH_VARIETY = "FETCH_VARIETY";
export const FETCH_VARIETY_SUCCESS = "FETCH_VARIETY_SUCCESS";
export const FETCH_VARIETY_FAILURE = "FETCH_VARIETY_FAILURE";

export const CREATE_VARIETY = "CREATE_VARIETY";
export const CREATE_VARIETY_SUCCESS = "CREATE_VARIETY_SUCCESS";
export const CREATE_VARIETY_FAILURE = "CREATE_VARIETY_FAILURE";

export const UPDATE_VARIETY = "UPDATE_VARIETY";
export const UPDATE_VARIETY_SUCCESS = "UPDATE_VARIETY_SUCCESS";
export const UPDATE_VARIETY_FAILURE = "UPDATE_VARIETY_FAILURE";

export const DELETE_VARIETY = "DELETE_VARIETY";
export const DELETE_VARIETY_SUCCESS = "DELETE_VARIETY_SUCCESS";
export const DELETE_VARIETY_FAILURE = "DELETE_VARIETY_FAILURE";

export const COMBINE_VARIETY = "COMBINE_VARIETY";
export const COMBINE_VARIETY_SUCCESS = "COMBINE_VARIETY_SUCCESS";
export const COMBINE_VARIETY_FAILURE = "COMBINE_VARIETY_FAILURE";

export const REMOVE_VARIETY = "REMOVE_VARIETY";
export const REMOVE_VARIETY_SUCCESS = "REMOVE_VARIETY_SUCCESS";
export const REMOVE_VARIETY_FAILURE = "REMOVE_VARIETY_FAILURE";

export const updateSearch = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_VARIETY_SEARCH,
    value: value
  });

export const clearVariety = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_VARIETY
  });

export const updateVarietyLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_VARIETY_LOCALLY,
    key: key,
    value: value
  });

export const fetchVariety = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_VARIETY, FETCH_VARIETY_SUCCESS, FETCH_VARIETY_FAILURE],
      endpoint: `/varieties/${id}`,
      schema: Schemas.VARIETY_EDIT,
      method: "get"
    }
  });

export const fetchVarietiesWithExpiermentalVarieties = ids => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_VARIETIES,
        FETCH_VARIETIES_SUCCESS,
        FETCH_VARIETIES_FAILURE
      ],
      endpoint: `/varieties/all_expiermentals?ids=${ids.join(",")}`,
      schema: Schemas.VARIETIES,
      method: "get"
    }
  });

export const createVariety = attributes => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_VARIETY, CREATE_VARIETY_SUCCESS, CREATE_VARIETY_FAILURE],
      endpoint: "/varieties",
      schema: Schemas.VARIETY_EDIT,
      method: "post",
      data: { variety: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a Variety successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Variety"
      }
    ]
  });

export const updateVariety = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_VARIETY, UPDATE_VARIETY_SUCCESS, UPDATE_VARIETY_FAILURE],
      endpoint: `/varieties/${id}`,
      schema: Schemas.VARIETY_EDIT,
      method: "patch",
      data: { variety: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated a Variety successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update Variety"
      }
    ]
  });

export const deleteVariety = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_VARIETY, DELETE_VARIETY_SUCCESS, DELETE_VARIETY_FAILURE],
      endpoint: `/varieties/${id}`,
      schema: Schemas.VARIETY,
      method: "delete",
      data: {
        varietyId: id
      }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted a Variety successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete Variety"
      }
    ]
  });

export const combineVariety = (id, varieties) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        COMBINE_VARIETY,
        COMBINE_VARIETY_SUCCESS,
        COMBINE_VARIETY_FAILURE
      ],
      endpoint: `/varieties/${id}/combine`,
      schema: Schemas.VARIETY,
      method: "post",
      data: { varieties: varieties }
    }
  });

export const removeVariety = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [REMOVE_VARIETY, REMOVE_VARIETY_SUCCESS, REMOVE_VARIETY_FAILURE],
      endpoint: `/varieties/${id}/remove`,
      schema: Schemas.VARIETY,
      method: "post"
    }
  });
