import {
  FETCH_PLOT_BEDS,
  FETCH_PLOT_BEDS_SUCCESS,
  FETCH_PLOT_BEDS_FAILURE,
  CREATE_PLOT_BED_LOCALLY,
  REMOVE_PLOT_BEDS_LOCALLY,
  REMOVE_PLOT_BEDS_LOCALLY_BY_LOCATION,
  SET_SELECTED_PLOT_BEDS,
  TOGGLE_SELECTED_PLOT_BED,
  CLEAR_SELECTED_PLOT_BEDS
} from "../actions/plot_beds";
import {
  CREATE_TRIAL_SUCCESS,
  FETCH_TRIAL_SUCCESS,
  UPDATE_TRIAL_SUCCESS
} from "../actions/trials";
import {
  CREATE_TRIAL_VARIETY_SUCCESS,
  CREATE_TRIAL_VARIETY_FAILURE
} from "../actions/trial_varieties";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import {
  CREATE_NURSERY_SUCCESS,
  FETCH_NURSERY_SUCCESS,
  UPDATE_NURSERY_SUCCESS
} from "../actions/nurseries";
import { CREATE_NURSE_VARIETY_SUCCESS } from "../actions/nurse_varieties";
import { combine } from "./util";

const INITIAL_STATE = {
  plotBeds: {},
  error: null,
  waiting: false,
  selected: {},
  variety: null,
  preSetVariety: null
};

export default function(state = INITIAL_STATE, action) {
  let plotBeds, selected;
  switch (action.type) {
    case CREATE_TRIAL_VARIETY_FAILURE:
    case CREATE_TRIAL_VARIETY_SUCCESS:
    case CREATE_NURSE_VARIETY_SUCCESS:
      return { ...state, selected: {}, preSetVariety: null };
    case CREATE_NURSERY_SUCCESS:
    case CREATE_TRIAL_SUCCESS:
    case FETCH_NURSERY_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
    case UPDATE_NURSERY_SUCCESS:
    case UPDATE_TRIAL_SUCCESS:
      plotBeds = combine(state.plotBeds, action.data.plotBeds);
      return { ...state, plotBeds: plotBeds };
    case CREATE_PLOT_BED_LOCALLY:
      plotBeds = state.plotBeds;
      plotBeds[action.key] = action.attributes;
      return { ...state, plotBeds: plotBeds };
    case REMOVE_PLOT_BEDS_LOCALLY:
      plotBeds = state.plotBeds;
      Object.keys(plotBeds).forEach((key, index) => {
        let plotBed = plotBeds[key];
        if (plotBed.plotsId === action.plotsId) {
          delete plotBeds[key];
        }
      });
      return { ...state, plotBeds: plotBeds, selected: {} };
    case REMOVE_PLOT_BEDS_LOCALLY_BY_LOCATION:
      plotBeds = state.plotBeds;
      Object.keys(plotBeds).forEach((key, index) => {
        let plotBed = plotBeds[key];
        if (plotBed.row >= action.maxRow || plotBed.col >= action.maxCol) {
          delete plotBeds[key];
        }
      });
      return { ...state, plotBeds: plotBeds, selected: {} };
    case FETCH_PLOT_BEDS:
      return { ...state, plotBeds: {}, error: null, waiting: true };
    case FETCH_PLOT_BEDS_SUCCESS:
      plotBeds = combine(state.plotBeds, action.data.plotBeds);
      return { ...state, plotBeds: plotBeds, error: null, waiting: false };
    case FETCH_PLOT_BEDS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case CLEAR_SELECTED_PLOT_BEDS:
      return { ...state, selected: {}, preSetVariety: false };
    case SET_SELECTED_PLOT_BEDS:
      selected = {};
      Object.values(state.plotBeds).forEach(plotBed => {
        if (plotBed.plotsId === action.plotsId) {
          selected[plotBed.key] = plotBed;
        }
      });
      return { ...state, selected: selected, preSetVariety: true };
    case TOGGLE_SELECTED_PLOT_BED:
      selected = state.selected;
      if (selected[action.locationId]) {
        delete selected[action.locationId];
      } else {
        selected[action.locationId] = { row: action.row, col: action.col }; // { row: row, col: col}
      }
      return { ...state, selected: selected, preSetVariety: false };
    default:
      return state;
  }
}
