import {
  CREATE_CRITERIUM,
  CREATE_CRITERIUM_SUCCESS,
  CREATE_CRITERIUM_FAILURE,
  UPDATE_CRITERIUM_LOCALLY,
  DELETE_CRITERIUM_SUBCRITERIUM_LOCALLY,
  ADD_CRITERIUM_SUBCRITERIUM_LOCALLY,
  FETCH_CRITERIUM,
  FETCH_CRITERIUM_SUCCESS,
  FETCH_CRITERIUM_FAILURE,
  UPDATE_CRITERIUM,
  UPDATE_CRITERIUM_SUCCESS,
  UPDATE_CRITERIUM_FAILURE,
  DELETE_CRITERIUM,
  DELETE_CRITERIUM_SUCCESS,
  DELETE_CRITERIUM_FAILURE
} from "../actions/criteria";
import {
  CREATE_COMMODITY_SUCCESS,
  COMMODITY_ADD_LABEL_SUCCESS,
  COMMODITY_ADD_TAG_SUCCESS,
  COMMODITY_REMOVE_LABEL_SUCCESS,
  COMMODITY_REMOVE_TAG_SUCCESS,
  FETCH_COMMODITY_SUCCESS,
  FETCH_COMMODITIES_DETAILS_SUCCESS,
  UPDATE_COMMODITY_SUCCESS
} from "../actions/commodities";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS,
  CREATE_FIELD_VARIETY_SUCCESS
} from "../actions/field_varieties";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import { FETCH_TRIAL_SUCCESS } from "../actions/trials";
import {
  FETCH_EVALUATIONS_PAGINATION_SUCCESS,
  FETCH_EVALUATIONS_SUCCESS
} from "../actions/evaluations";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  criteria: {},
  criterium: {},
  error: null,
  waiting: false
};

export default function(state = INITIAL_STATE, action) {
  let criterium, criteria, subcriteria;
  switch (action.type) {
    case CREATE_COMMODITY_SUCCESS:
    case UPDATE_COMMODITY_SUCCESS:
    case COMMODITY_ADD_LABEL_SUCCESS:
    case COMMODITY_ADD_TAG_SUCCESS:
    case COMMODITY_REMOVE_LABEL_SUCCESS:
    case COMMODITY_REMOVE_TAG_SUCCESS:
    case FETCH_COMMODITY_SUCCESS:
    case FETCH_COMMODITIES_DETAILS_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case CREATE_FIELD_VARIETY_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_EVALUATIONS_SUCCESS:
    case FETCH_EVALUATIONS_PAGINATION_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
      criteria = combine(state.criteria, action.data.criteria);
      return { ...state, criteria: criteria };
    case DELETE_CRITERIUM_SUBCRITERIUM_LOCALLY:
      criteria = state.criteria;
      criterium = criteria[action.id] || {};
      subcriteria = criterium.subcriteria || [];
      criterium.subcriteria = subcriteria.filter(value => {
        return value !== action.value;
      });
      criteria[action.id] = criterium;
      return { ...state, criteria: criteria };
    case ADD_CRITERIUM_SUBCRITERIUM_LOCALLY:
      criteria = state.criteria;
      criterium = criteria[action.id] || {};
      subcriteria = criterium.subcriteria || [];
      let subcriteriumObj = action.data.subcriteria;
      let subcriterium = subcriteriumObj[Object.keys(subcriteriumObj)[0]];
      subcriteria.push(subcriterium.id);
      criterium.subcriteria = subcriteria;
      criteria[action.id] = criterium;
      return { ...state, criteria: criteria };
    case UPDATE_CRITERIUM_LOCALLY:
      criteria = state.criteria;
      criterium = criteria[action.id] || {};
      criterium[action.key] = action.value;
      criteria[action.id] = criterium;
      return { ...state, criteria: criteria };
    case FETCH_CRITERIUM:
    case CREATE_CRITERIUM:
    case DELETE_CRITERIUM:
    case UPDATE_CRITERIUM:
      return { ...state, error: null, waiting: true };
    case FETCH_CRITERIUM_SUCCESS:
    case CREATE_CRITERIUM_SUCCESS:
    case UPDATE_CRITERIUM_SUCCESS:
      criteria = state.criteria;
      criterium = getSingleEntity(action.data.criteria);
      criteria[criterium.id] = criterium;
      return { ...state, criteria: criteria, error: null, waiting: false };
    case CREATE_CRITERIUM_FAILURE:
    case DELETE_CRITERIUM_FAILURE:
    case FETCH_CRITERIUM_FAILURE:
    case UPDATE_CRITERIUM_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case DELETE_CRITERIUM_SUCCESS:
      criteria = state.criteria;
      delete criteria[action.data.id];
      return { ...state, criteria: criteria, error: null, waiting: false };
    default:
      return state;
  }
}
