import React from "react";
import { withStyles } from "@material-ui/core/styles";

const getHighlightInfo = (hit, attribute) => {
  const highlightProperty = "_highlightResult";
  if (!hit[highlightProperty]) {
    return [];
  }
  if (attribute.includes(".")) {
    const keys = attribute.split(".");
    const firstHalf = hit[highlightProperty][keys[0]];
    if (firstHalf === undefined) {
      return [];
    } else if (Array.isArray(firstHalf)) {
      return firstHalf.map(obj => obj[keys[1]]);
    } else {
      return firstHalf[keys[1]];
    }
  }
  return hit[highlightProperty][attribute];
};

const parseHit = (hit, attribute) => {
  const highlightInfo = getHighlightInfo(hit, attribute);
  if (Array.isArray(highlightInfo)) {
    const combinedHighlightInfo = [];
    highlightInfo.forEach(obj =>
      combinedHighlightInfo.concat(convertHighlightInfo(obj))
    );
    return combinedHighlightInfo;
  }
  return convertHighlightInfo(highlightInfo);
};

const convertHighlightInfo = highlightInfo => {
  if (highlightInfo === undefined || highlightInfo.value === undefined) {
    return [];
  }
  if (!highlightInfo.value.includes("<em>")) {
    return [{ isHighlighted: false, value: highlightInfo.value }];
  }
  const firstHalf = highlightInfo.value.split("<em>");
  const results = [];
  const firstElement = firstHalf.shift();
  if (firstElement.length !== 0) {
    results.push({ isHighlighted: false, value: firstElement });
  }
  firstHalf.forEach(str => {
    const splitStr = str.split("</em>");
    results.push({
      isHighlighted: true,
      value: splitStr[0]
    });
    if (splitStr[1]) {
      results.push({
        isHighlighted: false,
        value: splitStr[1]
      });
    }
  });
  return results;
};

const HighlightAlogliaResult = ({ hit, attribute, ...props }) => {
  const parsedHit = parseHit(hit, attribute);
  if (
    attribute === "commodities" ||
    attribute === "company_categories" ||
    attribute === "regions" ||
    attribute === "companies" ||
    attribute === "growers" ||
    attribute === "suppliers" ||
    attribute === "shippers"
  ) {
    return (
      <span>
        {parsedHit.map((commodityPart, parsedHitIndex) => (
          <span key={parsedHitIndex}>
            {commodityPart.map((part, index) =>
              part.isHighlighted ? (
                <mark key={index}>{part.value}</mark>
              ) : (
                <span key={index}>{part.value}</span>
              )
            )}
            {parsedHitIndex !== parsedHit.length - 1 && <span>, </span>}
          </span>
        ))}
      </span>
    );
  }
  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <mark key={index} className={props.classes.mark}>
            {part.value}
          </mark>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  );
};

const styles = theme => ({
  mark: {
    backgroundColor: "#FFFF00"
  }
});

export default withStyles(styles)(HighlightAlogliaResult);
