import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  CommentCard,
  EvaluationReportCard,
  ReportScoreCard,
  ScrollableImageCard,
  MultiEvaluationReportCard
} from "../../../ui";
import { ColumnHeader } from "./views";
import { Grid } from "@material-ui/core";

class EvaluationReportColumn extends Component {
  filterEvaluationsByCommodityId = (commodityId, evaluationKeys) => {
    const { evaluations } = this.props.evaluations;
    return evaluationKeys
      .map(key => evaluations[key])
      .filter(
        evaluation =>
          evaluation.commodityId === commodityId || evaluation.archived
      );
  };

  filterMultiEvaluations = (multiEvalId, multiEvaluationKeys) => {
    const { multiEvaluations } = this.props.multiEvaluations;
    return multiEvaluationKeys
      .map(key => multiEvaluations[key])
      .filter(multiEvaluation => multiEvaluation.multiEvalId === multiEvalId);
  };

  getFieldVariety = record => {
    const { fieldVarieties } = this.props.fieldVarieties;
    let fieldVariety = fieldVarieties[record.fieldVarietyId] || {};
    if (!Object.keys(record).includes("fieldVarietyId")) {
      fieldVariety = record;
    }
    return fieldVariety;
  };

  getRecordVariety = record => {
    const { varieties } = this.props.varieties;
    return varieties[record.variety] || {};
  };

  getCompany = companyId => {
    const { companies } = this.props.companies;
    return companies[companyId] || {};
  };

  render() {
    const { plotNumber, record, showGrowerComments } = this.props;
    const variety = this.getRecordVariety(record);
    const supplier = this.getCompany(variety.companyId);
    const fieldVariety = this.getFieldVariety(record);
    const fieldVarietyVariety = this.getRecordVariety(fieldVariety);
    const evaluations = this.filterEvaluationsByCommodityId(
      record.commodityId,
      record.evaluations
    );
    const multiEvaluations = this.filterMultiEvaluations(
      record.id,
      record.multiEvaluations
    );
    return (
      <Grid container spacing={2}>
        <ColumnHeader
          record={record}
          plotNumber={plotNumber}
          variety={variety}
          supplier={supplier}
        />
        <Grid item xs={12}>
          <EvaluationReportCard evaluations={evaluations} />
        </Grid>
        <Grid item xs={12}>
          <MultiEvaluationReportCard multiEvaluations={multiEvaluations} />
        </Grid>
        <Grid item xs={12}>
          <CommentCard title={"Comments"} comments={record.comments} />
        </Grid>
        {showGrowerComments && (
          <Grid item xs={12}>
            <CommentCard
              title={"Grower Comments"}
              comments={record.growerComments}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ReportScoreCard
            trialVarietyScore={record.score}
            fieldVarietyScore={fieldVariety.score}
            fieldVarietyName={fieldVarietyVariety.name}
          />
        </Grid>
        <Grid item xs={12}>
          <ScrollableImageCard images={record.images} />
        </Grid>
      </Grid>
    );
  }
}

EvaluationReportColumn.propTypes = {
  plotNumber: PropTypes.number,
  record: PropTypes.object.isRequired,
  showGrowerComments: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  companies,
  evaluations,
  fieldVarieties,
  multiEvaluations,
  varieties
}) => ({
  companies,
  evaluations,
  fieldVarieties,
  multiEvaluations,
  varieties
});
export default connect(mapStateToProps, {})(EvaluationReportColumn);
