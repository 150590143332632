import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  linkStyle: theme.linkStyle
});

export const MapButtons = withStyles(styles)(
  ({
    lat,
    lng,
    handleChange,
    refreshGeoLocation,
    toggleLock,
    locked,
    ...props
  }) => (
    <React.Fragment>
      <Grid item xs={4} md={3}>
        <TextField
          fullWidth
          label="Latitude"
          variant="outlined"
          value={lat}
          onChange={handleChange("lat")}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <TextField
          fullWidth
          label="Longitude"
          variant="outlined"
          value={lng}
          onChange={handleChange("lng")}
        />
      </Grid>
      <Grid item xs={4} md={2}>
        <Button
          size="large"
          variant="contained"
          color="default"
          fullWidth
          onClick={refreshGeoLocation}
        >
          Live
        </Button>
      </Grid>
      <Grid item xs={6} md={2}>
        <Button
          size="large"
          variant="contained"
          color="default"
          fullWidth
          onClick={toggleLock}
        >
          {locked ? "Unlock" : "Locked"}
        </Button>
      </Grid>
      <Grid item xs={6} md={2}>
        <a
          href={`http://maps.google.com/?q=${lat},${lng}`}
          className={props.classes.linkStyle}
        >
          <Button size="large" variant="contained" color="default" fullWidth>
            Directions
          </Button>
        </a>
      </Grid>
    </React.Fragment>
  )
);
