import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";

export const PersonDetails = ({
  title,
  person,
  handleChange,
  handleBooleanChange,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={12}>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <TextField
        fullWidth
        label="First Name"
        value={person.firstName || ""}
        onChange={handleChange("firstName")}
        variant="outlined"
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        fullWidth
        value={person.lastName || ""}
        onChange={handleChange("lastName")}
        label="Last Name"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={4}>
      <TextField
        fullWidth
        label="Position"
        value={person.position || ""}
        onChange={handleChange("position")}
        variant="outlined"
      />
    </Grid>
    <Grid item xs={4}>
      <TextField
        fullWidth
        value={person.phoneNumber || ""}
        onChange={handleChange("phoneNumber")}
        label="Phone Number"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={4}>
      <TextField
        fullWidth
        value={person.mobileNumber || ""}
        onChange={handleChange("mobileNumber")}
        label="Mobile Number"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        fullWidth
        value={person.website || ""}
        onChange={handleChange("website")}
        label="Website"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        fullWidth
        value={person.email || ""}
        onChange={handleChange("email")}
        label="Email"
        variant="outlined"
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Cultural Practice</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={person.organic}
                onChange={handleBooleanChange("organic")}
                value="organic"
              />
            }
            label="Organic"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={person.conventional}
                onChange={handleBooleanChange("conventional")}
                value="conventional"
              />
            }
            label="Cultural Practice"
          />
        </FormGroup>
      </FormControl>
    </Grid>
  </React.Fragment>
);

PersonDetails.propTypes = {
  title: PropTypes.string,
  person: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBooleanChange: PropTypes.func.isRequired
};

// PersonDetails.defaultProps = {
//   update: false
// };

const styles = theme => ({
  headerContainer: {
    marginTop: 20
  },
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  chip: {
    margin: theme.spacing(2, 4)
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  divider: {
    height: theme.spacing(4)
  }
});

export const PersonButtons = withStyles(styles)(({ onSave, ...props }) => (
  <React.Fragment>
    <Grid item xs={3}>
      <Button component={Link} to="/people" variant="contained">
        Cancel
      </Button>
    </Grid>
    <Grid item xs={3}>
      <Button variant="contained" color="primary" onClick={onSave}>
        Save
      </Button>
    </Grid>
  </React.Fragment>
));

PersonButtons.propTypes = {
  onSave: PropTypes.func.isRequired
};
