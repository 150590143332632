import React from "react";
import DeleteConfirmation from "../../DeleteConfirmation";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button size="medium" variant="contained" onClick={confirmDelete}>
    Remove Label
  </Button>
);

const styles = theme => ({
  root: { padding: 20, marginTop: 20, marginBottom: 20 },
  checkboxLabel: { fontWeight: "400" }
});

export const LabelCard = withStyles(styles)(
  ({ commodityTags, label, removeLabel, toggleTag, tags, ...props }) => (
    <Card className={props.classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">{label.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              {tags.map((tag, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={commodityTags.includes(tag.id)}
                      onChange={toggleTag(tag.id)}
                      value={tag.id}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="h5"
                      className={props.classes.checkboxLabel}
                    >
                      {tag.name}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <DeleteConfirmation onDelete={removeLabel}>
              <DeleteButton {...props} />
            </DeleteConfirmation>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
);
