import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isTypeFieldVariety } from "../helpers";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class WeightedScoreEvaluation extends Component {
  _calculateWeightedScore = () => {
    const { evaluations } = this.props.evaluations;
    const { commodity } = this.props.commodities;
    const { subcriteria } = this.props.subcriteria;

    let score = 0;
    const criteriaIds = commodity.criteria || [];
    criteriaIds.forEach(criteriumId => {
      const evaluationKey = `${this.props.recordId}-${
        commodity.id
      }-${criteriumId}`;
      const evaluation = evaluations[evaluationKey] || {};
      const subcriterium = subcriteria[evaluation.subcriteriumId];
      if (subcriterium) {
        score = score + subcriterium.weightedScore;
      }
    });
    return score.toFixed(1);
  };

  render() {
    const { trial } = this.props.trials;
    let weightedScore = undefined;
    if(trial.archive){
      if(isTypeFieldVariety(this.props.type)){
        const { fieldVariety } = this.props.fieldVarieties;
        weightedScore = fieldVariety.score;
      } else {
        const { trialVariety } = this.props.trialVarieties;
        weightedScore = trialVariety.score;
      }
    } else {
      weightedScore = this._calculateWeightedScore();
    }

    return (
      <Grid item xs={12}>
        <Typography variant="body2" color="textPrimary" gutterBottom>
          Weighted Score: {weightedScore}
        </Typography>
      </Grid>
    );
  }
}

WeightedScoreEvaluation.propTypes = {
  recordId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  trials: state.trials,
  fieldVarieties: state.fieldVarieties,
  trialVarieties: state.trialVarieties,
  evaluations: state.evaluations,
  commodities: state.commodities,
  subcriteria: state.subcriteria,
});

export default connect(
  mapStateToProps,
  {}
)(WeightedScoreEvaluation);
