import {
  FETCH_PEOPLE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,
  CREATE_PERSON,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_FAILURE,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_FAILURE,
  UPDATE_PERSON,
  UPDATE_PERSON_SUCCESS,
  UPDATE_PERSON_FAILURE,
  UPDATE_PERSON_LOCALLY,
  CLEAR_PERSON,
  CLEAR_PEOPLE,
  DELETE_PERSON,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_FAILURE,
  UPDATE_PERSON_SEARCH
} from "../actions/people";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  people: {},
  person: {},
  peopleById: {},
  error: null,
  waiting: false,
  page: 1,
  search: ""
};

export default function(state = INITIAL_STATE, action) {
  let person, people;
  switch (action.type) {
    case UPDATE_PERSON_SEARCH:
      let search = action.value || "";
      return { ...state, search: search };
    case CLEAR_PERSON:
      return { ...state, person: {} };
    case CLEAR_PEOPLE:
      return { ...state, people: {}, peopleById: {} };
    case UPDATE_PERSON_LOCALLY:
      person = state.person;
      person[action.key] = action.value;
      return { ...state, person: person };
    case FETCH_PEOPLE:
      return { ...state, people: {}, error: null, waiting: true };
    case FETCH_PEOPLE_SUCCESS:
      people = combine({}, action.data.people);
      return {
        ...state,
        people: people,
        error: null,
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_PEOPLE_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_PERSON:
      return { ...state, person: {}, error: null, waiting: true };
    case FETCH_PERSON_SUCCESS:
      person = getSingleEntity(action.data.person);
      return { ...state, person: person || {}, error: null, waiting: false };
    case FETCH_PERSON_FAILURE:
      return { ...state, person: {}, error: action.error, waiting: false };
    case CREATE_PERSON:
      return { ...state, error: null };
    case CREATE_PERSON_SUCCESS:
      person = getSingleEntity(action.data.person);
      return { ...state, person: person || {}, error: null };
    case CREATE_PERSON_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_PERSON:
      return { ...state, error: null };
    case UPDATE_PERSON_SUCCESS:
      person = getSingleEntity(action.data.person);
      return { ...state, person: person, error: null };
    case UPDATE_PERSON_FAILURE:
      if (action.status === 412) {
        person = getSingleEntity(action.data.person);
        return {
          ...state,
          error: action.error,
          person: person
        };
      }
      return { ...state, error: action.error };
    case DELETE_PERSON:
      return { ...state, error: null, waiting: true };
    case DELETE_PERSON_SUCCESS:
      people = state.people;
      delete people[action.data.id];
      return { ...state, people: people, error: null, waiting: false };
    case DELETE_PERSON_FAILURE:
      return { ...state, error: action.error, waiting: false };

    default:
      return state;
  }
}
