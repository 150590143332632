import algoliasearch from "algoliasearch/lite";
import { fetchTrialStatus } from "./trials";

export const SEARCH_ALGOLIA_TRIALS = "SEARCH_ALGOLIA_TRIALS";
export const SEARCH_ALGOLIA_TRIALS_SUCCESS = "SEARCH_ALGOLIA_TRIALS_SUCCESS";
export const SEARCH_ALGOLIA_TRIALS_FAILURE = "SEARCH_ALGOLIA_TRIALS_FAILURE";

export const SEARCH_MORE_ALGOLIA_TRIALS = "SEARCH_MORE_ALGOLIA_TRIALS";
export const SEARCH_MORE_ALGOLIA_TRIALS_SUCCESS =
  "SEARCH_MORE_ALGOLIA_TRIALS_SUCCESS";
export const SEARCH_MORE_ALGOLIA_TRIALS_FAILURE =
  "SEARCH_MORE_ALGOLIA_TRIALS_FAILURE";

export const SEARCH_ALGOLIA_VARIETIES = "SEARCH_ALGOLIA_VARIETIES";
export const SEARCH_ALGOLIA_VARIETIES_SUCCESS =
  "SEARCH_ALGOLIA_VARIETIES_SUCCESS";
export const SEARCH_ALGOLIA_VARIETIES_FAILURE =
  "SEARCH_ALGOLIA_VARIETIES_FAILURE";

export const SEARCH_ALGOLIA_COMPANIES = "SEARCH_ALGOLIA_COMPANIES";
export const SEARCH_ALGOLIA_COMPANIES_SUCCESS =
  "SEARCH_ALGOLIA_COMPANIES_SUCCESS";
export const SEARCH_ALGOLIA_COMPANIES_FAILURE =
  "SEARCH_ALGOLIA_COMPANIES_FAILURE";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

export const searchAlogliaTrials = (searchStr, filters, tagFilters) => (
  dispatch,
  getState
) => {
  const index = searchClient.initIndex(`Trial_${process.env.REACT_APP_NAME}`);
  dispatch({
    type: SEARCH_ALGOLIA_TRIALS
  });
  index
    .search({
      query: searchStr,
      filters: filters,
      tagFilters: tagFilters,
      page: 0
    })
    .then(
      data => {
        dispatch(fetchTrialStatus(data.hits.map(obj => obj.objectID)));
        dispatch({
          type: SEARCH_ALGOLIA_TRIALS_SUCCESS,
          data: data
        });
      },
      error =>
        dispatch({
          type: SEARCH_ALGOLIA_TRIALS_FAILURE,
          error: error
        })
    );
};

export const searchMoreAlgoliaTrials = (searchStr, filters, tagFilters) => (
  dispatch,
  getState
) => {
  const index = searchClient.initIndex(`Trial_${process.env.REACT_APP_NAME}`);
  dispatch({
    type: SEARCH_MORE_ALGOLIA_TRIALS
  });
  const { page } = getState().algolia;
  return index
    .search({
      query: searchStr,
      filters: filters,
      tagFilters: tagFilters,
      page: page + 1
    })
    .then(
      data => {
        // dispatch fetch trials
        dispatch(fetchTrialStatus(data.hits.map(obj => obj.objectID)));
        dispatch({
          type: SEARCH_MORE_ALGOLIA_TRIALS_SUCCESS,
          data: data
        });
      },
      error =>
        dispatch({
          type: SEARCH_MORE_ALGOLIA_TRIALS_FAILURE,
          error: error
        })
    );
};

export const searchAllAlgoliaTrials = (searchStr, filters, tagFilters) => (
  dispatch,
  getState
) => {
  dispatch(algoliaLoadAllTrialsHelper(searchStr, filters, tagFilters)).then(
    data => {
      const { page, pages } = getState().algolia;
      if (page < pages - 1) {
        dispatch(searchAllAlgoliaTrials(searchStr, filters, tagFilters));
      }
    }
  );
};

const algoliaLoadAllTrialsHelper = (searchStr, filters, tagFilters) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: SEARCH_MORE_ALGOLIA_TRIALS
    });
    const { page } = getState().algolia;
    const index = searchClient.initIndex(`Trial_${process.env.REACT_APP_NAME}`);
    index
      .search({
        query: searchStr,
        filters: filters,
        tagFilters: tagFilters,
        page: page + 1
      })
      .then(
        data => {
          dispatch(fetchTrialStatus(data.hits.map(obj => obj.objectID)));
          dispatch({
            type: SEARCH_MORE_ALGOLIA_TRIALS_SUCCESS,
            data: data
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: SEARCH_MORE_ALGOLIA_TRIALS_FAILURE,
            error: error
          });
          reject(error);
        }
      );
  });
};

export const searchAlgoliaVarieties = searchStr => (dispatch, getState) => {
  const index = searchClient.initIndex(`Variety_${process.env.REACT_APP_NAME}`);
  dispatch({
    type: SEARCH_ALGOLIA_VARIETIES
  });
  index.search(searchStr).then(
    data =>
      dispatch({
        type: SEARCH_ALGOLIA_VARIETIES_SUCCESS,
        data: data
      }),
    error =>
      dispatch({
        type: SEARCH_ALGOLIA_VARIETIES_FAILURE,
        error: error
      })
  );
};

export const searchAlgoliaCompanies = (searchStr, type) => (
  dispatch,
  getState
) => {
  const index = searchClient.initIndex(`Company_${process.env.REACT_APP_NAME}`);
  dispatch({
    type: SEARCH_ALGOLIA_COMPANIES
  });
  let filter = "";
  if (type) {
    filter = `company_categories:${type}`;
  }
  index.search({ query: searchStr, filters: filter }).then(
    data =>
      dispatch({
        type: SEARCH_ALGOLIA_COMPANIES_SUCCESS,
        data: data
      }),
    error =>
      dispatch({
        type: SEARCH_ALGOLIA_COMPANIES_FAILURE,
        error: error
      })
  );
};
