import { CALL_API, Schemas } from '../middleware/api';

export const FETCH_PLOT_BEDS = 'FETCH_PLOT_BEDS';
export const FETCH_PLOT_BEDS_SUCCESS = 'FETCH_PLOT_BEDS_SUCCESS';
export const FETCH_PLOT_BEDS_FAILURE = 'FETCH_PLOT_BEDS_FAILURE';

export const CREATE_PLOT_BED_LOCALLY = 'CREATE_PLOT_BED_LOCALLY';
export const REMOVE_PLOT_BEDS_LOCALLY = 'REMOVE_PLOT_BEDS_LOCALLY';
export const REMOVE_PLOT_BEDS_LOCALLY_BY_LOCATION = 'REMOVE_PLOT_BEDS_LOCALLY_BY_LOCATION';

export const SET_SELECTED_PLOT_BEDS = 'SET_SELECTED_PLOT_BEDS';
export const TOGGLE_SELECTED_PLOT_BED = 'TOGGLE_SELECTED_PLOT_BED';
export const CLEAR_SELECTED_PLOT_BEDS = 'CLEAR_SELECTED_PLOT_BEDS';

export const fetchPlotBeds = (trialId) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_PLOT_BEDS, FETCH_PLOT_BEDS_SUCCESS, FETCH_PLOT_BEDS_FAILURE ],
      method: 'get',
      endpoint: `/plot_beds?trial_id=${trialId}`,
      schema: Schemas.PLOT_BEDS
    }
  })
)

export const fetchNurseryPlotBeds = (nurseryId) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_PLOT_BEDS, FETCH_PLOT_BEDS_SUCCESS, FETCH_PLOT_BEDS_FAILURE ],
      method: 'get',
      endpoint: `/plot_beds?nursery_id=${nurseryId}`,
      schema: Schemas.PLOT_BEDS
    }
  })
)

export const createPlotBedLocally = (key, attributes) => (dispatch, getState) => (
  dispatch({
    type: CREATE_PLOT_BED_LOCALLY,
    key: key,
    attributes: attributes
  })
)

export const removePlotBedsLocally = (plotsId) => (dispatch, getState) => (
  dispatch(  {
    type: REMOVE_PLOT_BEDS_LOCALLY,
    plotsId: plotsId
  })
)

export const removePlotBedsLocallyByLocation = (maxRow, maxCol) => (dispatch, getState) => (
  dispatch({
    type: REMOVE_PLOT_BEDS_LOCALLY_BY_LOCATION,
    maxRow: maxRow,
    maxCol: maxCol
  })
)

export const clearSelectedPlotBeds = () => (dispatch, getState) => (
  dispatch({
    type: CLEAR_SELECTED_PLOT_BEDS
  })
)

export const setSelectedPlotBeds = (plotsId) => (dispatch, getState) => (
  dispatch({
    type: SET_SELECTED_PLOT_BEDS,
    plotsId: plotsId
  })
)

export const toggleSelectedPlotBed = (locationId, rowIndex, colIndex) => (dispatch, getState) => (
  dispatch({
    type: TOGGLE_SELECTED_PLOT_BED,
    locationId: locationId,
    row: rowIndex,
    col: colIndex
  })
)