import {
  CREATE_NURSE_VARIETY,
  CREATE_NURSE_VARIETY_SUCCESS,
  CREATE_NURSE_VARIETY_FAILURE,
  FETCH_NURSE_VARIETIES,
  FETCH_NURSE_VARIETIES_SUCCESS,
  FETCH_NURSE_VARIETIES_FAILURE,
  SET_NURSE_VARIETY_LOCALLY,
  REMOVE_NURSE_VARIETY_LOCALLY,
  DELETE_NURSE_VARIETY,
  DELETE_NURSE_VARIETY_SUCCESS,
  DELETE_NURSE_VARIETY_FAILURE
} from "../actions/nurse_varieties";
import {
  FETCH_NURSERY_SUCCESS,
  CREATE_NURSERY_SUCCESS,
  UPDATE_NURSERY_SUCCESS
} from "../actions/nurseries";
import { combine } from "./util";

const INITIAL_STATE = {
  nurseVarieties: {},
  nurseVariety: {},
  error: null,
  waiting: false
};

export default function(state = INITIAL_STATE, action) {
  let nurseVarieties, nurseries;
  switch (action.type) {
    case CREATE_NURSE_VARIETY_SUCCESS:
    case CREATE_NURSERY_SUCCESS:
    case FETCH_NURSERY_SUCCESS:
    case FETCH_NURSE_VARIETIES_SUCCESS:
    case UPDATE_NURSERY_SUCCESS:
      nurseries = combine(state.nurseVarieties, action.data.nurseVarieties);
      return { ...state, nurseries: nurseries };
    case CREATE_NURSE_VARIETY:
    case DELETE_NURSE_VARIETY:
    case FETCH_NURSE_VARIETIES:
      return { ...state, error: null };
    case DELETE_NURSE_VARIETY_SUCCESS:
      nurseVarieties = state.nurseVarieties;
      delete nurseVarieties[action.data.id];
      return { ...state, nurseVarieties: nurseVarieties };
    case CREATE_NURSE_VARIETY_FAILURE:
    case DELETE_NURSE_VARIETY_FAILURE:
    case FETCH_NURSE_VARIETIES_FAILURE:
      return { ...state, error: action.error };
    case SET_NURSE_VARIETY_LOCALLY:
      return { ...state, nurseVariety: state.nurseVarieties[action.id] || {} };
    case REMOVE_NURSE_VARIETY_LOCALLY:
      nurseVarieties = state.nurseVarieties;
      delete nurseVarieties[action.id];
      return { ...state, nurseVarieties: nurseVarieties };
    default:
      return state;
  }
}
