export const filterPlotBedsByTrial = (trial, trialVarieties, plotBeds) => {
  const filteredTrialVarieties = {};
  Object.values(trialVarieties).forEach(trialVariety => {
    if (trialVariety.trialId === trial.id) {
      filteredTrialVarieties[trialVariety.id] = trialVariety;
    }
  });
  const filteredPlotBeds = {};
  Object.values(plotBeds).forEach(plotBed => {
    if (
      filteredTrialVarieties[plotBed.plotsId] &&
      plotBed.plotsType === "TrialVariety"
    )
      filteredPlotBeds[plotBed.key] = plotBed;
  });
  return filteredPlotBeds;
};

export const createPlotBedLocation = (row, col) => `${row}-${col}`;

export const getPlotBedNumbers = (plotBeds, plotType, rows, cols) => {
  const content = createPlotBedObjects(plotBeds, plotType, rows, cols);
  return content["grid"];
};

export const getVarietyPlotNumber = (plotBeds, plotType, rows, cols) => {
  const content = createPlotBedObjects(plotBeds, plotType, rows, cols);
  return content["trialVarieties"];
};

const createPlotBedObjects = (plotBeds, plotType, rows, cols) => {
  let plotNumberCounter = 1;
  const trialVarieties = {};
  const grid = {};
  for (let rowIndex = 0; rowIndex < parseInt(rows); rowIndex++) {
    let colLoop = normalColLoop(cols);
    if (plotType === "snake" && rowIndex % 2 !== 0) {
      colLoop = snakeColLoop(cols);
    }
    let colLoopNext = colLoop.next();
    while (!colLoopNext.done) {
      let colIndex = colLoopNext.value;

      const location = createPlotBedLocation(rowIndex, colIndex);
      let plotNumber = plotNumberCounter;
      let plotsId = null;
      if (plotBeds[location] && plotBeds[location].plotsId) {
        plotsId = plotBeds[location].plotsId;
      }
      if (plotsId) {
        if (trialVarieties[plotsId]) {
          plotNumber = trialVarieties[plotsId];
        } else {
          trialVarieties[plotsId] = plotNumber;
          plotNumberCounter += 1;
        }
        grid[location] = plotNumber;
      } else {
        // plotNumberCounter += 1
      }
      // grid[location] = plotNumber;

      colLoopNext = colLoop.next();
    }
  }
  return { trialVarieties: trialVarieties, grid: grid };
};

function* snakeColLoop(cols) {
  for (let colIndex = parseInt(cols) - 1; colIndex >= 0; colIndex--) {
    yield colIndex;
  }
}

function* normalColLoop(cols) {
  for (let colIndex = 0; colIndex < parseInt(cols); colIndex++) {
    yield colIndex;
  }
}
