import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CriteriumEdit from "./CriteriumEdit";
import { connect } from "react-redux";
import { updateCriteriumLocally } from "../../actions/criteria";

import Grid from "@material-ui/core/Grid";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: 0,

  // change background colour if dragging
  background: isDragging ? "rgba(208, 2, 27, 1)" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#D3D3D3" : "#fafafa",
  padding: 0
});

class CommodityDragAndDropArea extends Component {
  getList = () => {
    const { commodity } = this.props.commodities;
    const { criteria } = this.props.criteria;
    if (!commodity.criteria) {
      return [];
    }
    const list = commodity.criteria
      .map(id => criteria[id])
      .filter(criterium => criterium)
      .sort((criteriumA, criteriumB) => {
        return criteriumA.location - criteriumB.location;
      });
    return list;
  };

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const criteriumList = this.getList();
    const [removed] = criteriumList.splice(result.source.index, 1);
    criteriumList.splice(result.destination.index, 0, removed);

    criteriumList.forEach((criterium, index) => {
      this.props.updateCriteriumLocally(criterium.id, "location", index);
    });
  };
  render() {
    const { commodityId } = this.props;
    const droppableId = `commodity-droppable`;
    const list = this.getList();
    return (
      <Grid container spacing={2}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
              <Grid
                container
                spacing={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {list.map((criterium, index) => {
                  return (
                    <Draggable
                      key={criterium.id}
                      draggableId={criterium.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Grid
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          item
                          xs={12}
                        >
                          <CriteriumEdit
                            id={criterium.id}
                            commodityId={commodityId}
                          />
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    );
  }
}
const mapStateToProps = ({ commodities, criteria }) => ({
  criteria,
  commodities
});
export default connect(
  mapStateToProps,
  { updateCriteriumLocally }
)(CommodityDragAndDropArea);
