import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILURE = "CREATE_TAG_FAILURE";

export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAILURE = "UPDATE_TAG_FAILURE";
export const UPDATE_TAG_LOCALLY = "UPDATE_TAG_LOCALLY";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "DELETE_TAG_FAILURE";

export const fetchTags = labelId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_TAGS, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE],
      endpoint: `/labels/${labelId}/tags`,
      schema: Schemas.TAGS,
      method: "get"
    }
  });

export const createTag = (labelId, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_TAG, CREATE_TAG_SUCCESS, CREATE_TAG_FAILURE],
      endpoint: `/labels/${labelId}/tags`,
      schema: Schemas.TAG,
      method: "post",
      data: { tag: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a Tag successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Tag"
      }
    ]
  });

export const updateTag = (labelId, id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_TAG, UPDATE_TAG_SUCCESS, UPDATE_TAG_FAILURE],
      endpoint: `/labels/${labelId}/tags/${id}`,
      schema: Schemas.TAG,
      method: "patch",
      data: { tag: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated the Tag successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update the Tag"
      }
    ]
  });

export const updateTagLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_TAG_LOCALLY,
    id: id,
    key: key,
    value: value
  });

export const deleteTag = (labelId, id) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_TAG, DELETE_TAG_SUCCESS, DELETE_TAG_FAILURE],
      endpoint: `/labels/${labelId}/tags/${id}`,
      schema: Schemas.TAG,
      method: "delete",
      extra: { labelId: labelId }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted the Tag successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete the Tag"
      }
    ]
  });
