import React, { Component } from "react";
import PropTypes from "prop-types";
import { IndexNewFieldDisplay } from "./views";

class IndexNewField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }

  handleChange = e => {
    this.setState({
      name: e.target.value
    });
  };

  onSave = () => {
    this.props.onSave(this.state.name);
    this.setState({ name: "" });
  };

  render() {
    const { name } = this.state;
    return (
      <IndexNewFieldDisplay
        onSave={this.onSave}
        value={name}
        handleChange={this.handleChange}
        label={this.props.label}
      />
    );
  }
}

IndexNewField.propTypes = {
  onSave: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default IndexNewField;
