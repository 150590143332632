import React from "react";
import PropTypes from "prop-types";

import LoadingSpinner from "./LoadingSpinner";

import Drawer from "@material-ui/core/Drawer";

const LoadingDrawerDisplay = ({ showDrawer, closeDrawer, ...props }) => (
  <Drawer anchor="bottom" open={showDrawer} onClose={closeDrawer}>
    <LoadingSpinner />
  </Drawer>
);

LoadingDrawerDisplay.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired
};

export default LoadingDrawerDisplay;
