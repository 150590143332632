import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { sortObjectByName, dateStrToFriendlyFormat } from "../../../helpers";
import DeleteConfirmation from "../../DeleteConfirmation";

import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Search from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export const TableSearchHeader = ({
  searchField,
  handleChange,
  showInactive,
  toggleInactive,
  ...props
}) => (
  <Grid container spacing={2} style={{ marginBottom: 20 }}>
    <Grid item sm={8} xs={10}>
      <TextField
        id="input-with-icon-textfield"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
        autoFocus={true}
        value={searchField}
        onChange={handleChange}
      />
    </Grid>
    <Grid item sm={4} xs={2}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={showInactive}
              onChange={toggleInactive}
              value="active"
              color="primary"
            />
          }
          label="Include Inactive"
        />
      </Grid>
    </Grid>
  </Grid>
);

const DeleteButton = ({ confirmDelete }) => (
  <Button size="small" aria-label="Delete" onClick={confirmDelete}>
    <DeleteIcon />
  </Button>
);

const rowStyle = theme => ({
  linkStyle: theme.linkStyle
});

export const NurseryRows = withStyles(rowStyle)(
  ({ nurseries, onDelete, ...props }) =>
    sortObjectByName(nurseries).map(nursery => (
      <TableRow key={nursery.id}>
        <TableCell component="th" scope="row">
          <Link
            to={"/nurseries/" + nursery.id}
            className={props.classes.linkStyle}
          >
            <Typography variant="body1" gutterBottom>
              {nursery.name}
            </Typography>
          </Link>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {nursery.commodityName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {dateStrToFriendlyFormat(nursery.wetDate)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {dateStrToFriendlyFormat(nursery.estTransferDate)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {nursery.active ? "active" : "inactive"}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <DeleteConfirmation onDelete={() => onDelete(nursery.id)}>
            <DeleteButton />
          </DeleteConfirmation>
        </TableCell>
      </TableRow>
    ))
);

NurseryRows.propTypes = {
  nurseries: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  searchContainer: {
    marginBottom: 20
  },
  linkStyle: theme.linkStyle,
  progressGrid: {
    marginTop: 40
  }
});

export const NurseryTable = withStyles(styles)(
  ({ waiting, showMoreResults, page, totalPages, children, ...props }) => (
    <div className={props.classes.root}>
      <Grid container spacing={2}>
        <div className={props.classes.tableRoot}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Commodity
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Wet Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Est. Transfer Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    State
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </div>
        <Grid item xs={12}>
          {waiting ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={props.classes.progressGrid}
            >
              <CircularProgress size={80} thickness={7} color={"primary"} />
            </Grid>
          ) : (
            <div>
              {page < totalPages && (
                <Button color="primary" fullWidth onClick={showMoreResults}>
                  Show more results
                </Button>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
);

NurseryTable.propTypes = {
  waiting: PropTypes.bool.isRequired,
  showMoreResults: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};
