import { CALL_API, Schemas } from '../middleware/api';

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE';

export const FETCH_SUPPLIER = 'FETCH_SUPPLIER';
export const FETCH_SUPPLIER_SUCCESS = 'FETCH_SUPPLIER_SUCCESS';
export const FETCH_SUPPLIER_FAILURE = 'FETCH_SUPPLIER_FAILURE';

export const fetchSupplier = (id) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [FETCH_SUPPLIER, FETCH_SUPPLIER_SUCCESS, FETCH_SUPPLIER_FAILURE],
      endpoint: `/companies/${id}`,
      schema: Schemas.COMPANY,
      method: 'get'
    }
  })
)

export const fetchSuppliers = (search = "") => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_SUPPLIERS, FETCH_SUPPLIERS_SUCCESS, FETCH_SUPPLIERS_FAILURE ],
      endpoint: `/suppliers?search=${search}`,
      schema: Schemas.COMPANIES,
      method: 'get'
    }
  })
)
