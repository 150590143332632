import React, { Component } from "react";
import { Navbar } from "../containers";
import { CompaniesList } from "../containers/companies";

class Companies extends Component {
  render() {
    return (
      <Navbar currentPage="companies">
        <CompaniesList />
      </Navbar>
    );
  }
}
export default Companies;
