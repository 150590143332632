import React, { Component } from "react";
import DownshiftMultiple from "../DownshiftMultiple";
import { connect } from "react-redux";
import { fetchCommoditiesClear } from "../../actions/commodities";

class CommoditiesMultipleSelect extends Component {
  render() {
    const { commodities, waiting } = this.props.commodities;
    const results = commodities || {};
    const initialItems = this.props.initialItems || [];

    return (
      <DownshiftMultiple
        waiting={waiting}
        placeholder={"Select Commodities"}
        label={this.props.label}
        search={this.props.fetchCommoditiesClear}
        results={results}
        initialItems={initialItems}
        selectedItemsParent={this.props.setCommodities}
      />
    );
  }
}

CommoditiesMultipleSelect.defaultProps = {
  label: "Commodities"
};

const mapStateToProps = ({ commodities }) => ({ commodities });

export default connect(mapStateToProps, {
  fetchCommoditiesClear
})(CommoditiesMultipleSelect);
