import React from "react";
import PropTypes from "prop-types";

import TrialImages from "../../TrialImages";
import ImageUpload from "../../ImageUpload";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CollectionIcon from "@material-ui/icons/Collections";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden"
  },
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none"
  }
});

export const View = withStyles(styles)(
  ({ record, type, toggle, showCamera, showImages, ...props }) => (
    <Grid container spacing={2}>
      <Modal open={showCamera || showImages}>
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: `translate(-50%, -50%)`
          }}
          className={props.classes.paper}
        >
          {showCamera && (
            <ImageUpload
              type={type}
              recordId={record.id}
              hideCamera={toggle("camera")}
            />
          )}
          {showImages && (
            <TrialImages
              type={type}
              recordId={record.id}
              hideImages={toggle("images")}
            />
          )}
        </div>
      </Modal>
      <Grid item xs={6} style={{ padding: 0, paddingRight: 4 }}>
        <Button
          size="large"
          variant="contained"
          color="default"
          fullWidth
          onClick={toggle("camera")}
        >
          <AddAPhotoIcon />
        </Button>
      </Grid>
      <Grid item xs={6} style={{ padding: 0, paddingLeft: 4 }}>
        <Button
          size="large"
          variant="contained"
          color="default"
          fullWidth
          onClick={toggle("images")}
        >
          <CollectionIcon />
        </Button>
      </Grid>
    </Grid>
  )
);

View.propTypes = {
  type: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  showImages: PropTypes.bool.isRequired,
  showCamera: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};
