import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchVarietiesWithExpiermentalVarieties } from "../actions/varieties";
import { fetchTrialVarietiesByVarietyIds } from "../actions/trial_varieties";
import { fetchFieldVarietiesByVarietyIds } from "../actions/field_varieties";
import { fetchTrialsByVarietyIds } from "../actions/trials";
import { fetchEvaluationsByVarietyIds } from "../actions/evaluations";

import { Filter, ReportMultiple } from "../containers/reports";
import { LoadingSpinner } from "../ui";

class ReportCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false
    };
    this.toggleFilterDrawer = this.toggleFilterDrawer.bind(this);
  }

  componentDidMount() {
    const varietyIds = this.getVarietyIds();
    if (varietyIds.length > 0) {
      this.props.fetchVarietiesWithExpiermentalVarieties(varietyIds);
      this.props.fetchTrialVarietiesByVarietyIds(varietyIds);
      this.props.fetchFieldVarietiesByVarietyIds(varietyIds);
      this.props.fetchTrialsByVarietyIds(varietyIds);
      this.props.fetchEvaluationsByVarietyIds(varietyIds);
    }
  }

  getVarietyIds = () => {
    let params = new URLSearchParams(this.props.location.search);
    const varietyIdsStr = params.get("variety_ids");
    if (varietyIdsStr) {
      return varietyIdsStr.split(",").map(idStr => parseInt(idStr));
    }
    return [];
  };

  toggleFilterDrawer = input => () => {
    this.setState({ ...this.state, showFilters: input });
  };

  render() {
    const { waiting } = this.props.varieties;
    const { waiting: trialWaiting } = this.props.trials;
    const { waiting: fieldVarietyWaiting } = this.props.fieldVarieties;
    const { waiting: trialVarietyWaiting } = this.props.trialVarieties;
    const { waiting: evaluationsWaiting } = this.props.evaluations;

    if (
      waiting ||
      trialWaiting ||
      fieldVarietyWaiting ||
      trialVarietyWaiting ||
      evaluationsWaiting
    ) {
      return <LoadingSpinner />;
    }
    const mainVarietyIds = this.getVarietyIds();
    return (
      <Filter
        show={this.state.showFilters}
        toggleFilterDrawer={this.toggleFilterDrawer}
        mainVarietyIds={mainVarietyIds}
      >
        <ReportMultiple
          mainVarietyIds={mainVarietyIds}
          toggleFilterDrawer={this.toggleFilterDrawer}
        />
      </Filter>
    );
  }
}

const mapStateToProps = ({
  evaluations,
  fieldVarieties,
  trials,
  trialVarieties,
  varieties
}) => ({ evaluations, fieldVarieties, trials, trialVarieties, varieties });

export default connect(mapStateToProps, {
  fetchVarietiesWithExpiermentalVarieties,
  fetchTrialVarietiesByVarietyIds,
  fetchFieldVarietiesByVarietyIds,
  fetchTrialsByVarietyIds,
  fetchEvaluationsByVarietyIds
})(ReportCompare);
