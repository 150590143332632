import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  updatePerson,
  createPerson,
  updatePersonLocally
} from "../../../actions/people";
import { CommoditiesMultipleSelect } from "../../commodities";
import { CompaniesMultipleSelect } from "../../companies";
import { RegionsMultipleSelect } from "../../regions";
import { PersonDetails, PersonButtons } from "./views";
import { LoadingSpinner } from "../../../ui";
import { Grid } from "@material-ui/core";

class PersonEdit extends Component {
  constructor(props) {
    super(props);
    let header = "Create";
    if (props.update) {
      header = "Update";
    }
    this.state = {
      header: header,
      created: false
    };
  }

  handleChange = name => e => {
    const { person } = this.props.people;
    this.props.updatePersonLocally(person.id, name, e.target.value);
  };

  handleBooleanChange = name => e => {
    const { person } = this.props.people;
    this.props.updatePersonLocally(person.id, name, e.target.checked);
  };

  onSave = () => {
    const { person } = this.props.people;
    if (this.props.update) {
      this.props.updatePerson(this.props.id, person);
    } else {
      this.props.createPerson(person);
      this.setState({ ...this.state, created: true });
    }
  };

  setCommodities = commodities => {
    const { person } = this.props.people;
    const commodityIds = commodities.map(commodity => commodity.id);
    this.props.updatePersonLocally(person.id, "commodityIds", commodityIds);
    this.props.updatePersonLocally(person.id, "commodities", commodities);
  };

  setCompanies = companies => {
    const { person } = this.props.people;
    const companyIds = companies.map(company => company.id || company.objectID);
    this.props.updatePersonLocally(person.id, "companyIds", companyIds);
    this.props.updatePersonLocally(person.id, "companies", companies);
  };

  setRegions = regions => {
    const { person } = this.props.people;
    const regionIds = regions.map(region => region.id);
    this.props.updatePersonLocally(person.id, "regionIds", regionIds);
    this.props.updatePersonLocally(person.id, "regions", regions);
  };

  render() {
    const { person, error } = this.props.people;
    const title = this.state.header + " Person";

    if (this.state.created && !person.id && !error) {
      return <LoadingSpinner />;
    }
    if (this.state.created && person.id) {
      return <Redirect push to="/people" />;
    }

    return (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <PersonDetails
          title={title}
          person={person}
          handleChange={this.handleChange}
          handleBooleanChange={this.handleBooleanChange}
        />
        <Grid item xs={12}>
          <CompaniesMultipleSelect
            initialItems={person.companies}
            setCompanies={this.setCompanies}
          />
        </Grid>
        <Grid item xs={12}>
          <RegionsMultipleSelect
            initialItems={person.regions}
            setRegions={this.setRegions}
          />
        </Grid>
        <Grid item xs={12}>
          <CommoditiesMultipleSelect
            initialItems={person.commodities}
            setCommodities={this.setCommodities}
          />
        </Grid>
        <PersonButtons onSave={this.onSave} />
      </Grid>
    );
  }
}

PersonEdit.defaultProps = {
  update: false
};

const mapStateToProps = state => {
  return {
    people: state.people
  };
};

export default connect(
  mapStateToProps,
  {
    createPerson,
    updatePerson,
    updatePersonLocally
  }
)(PersonEdit);
