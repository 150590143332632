import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "../containers";
import { PersonEdit } from "../containers/people";
import { clearCompanies } from "../actions/companies";
import { clearPerson } from "../actions/people";

class PersonNew extends Component {
  componentWillMount() {
    this.props.clearPerson();
    this.props.clearCompanies();
  }

  render() {
    return (
      <Navbar currentPage="people">
        <PersonEdit />
      </Navbar>
    );
  }
}

export default connect(
  null,
  {
    clearPerson,
    clearCompanies
  }
)(PersonNew);
