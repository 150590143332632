import React, { Component } from "react";
import { connect } from "react-redux";

import { clearVariety } from "../actions/varieties";

import { Navbar } from "../containers";
import { VarietyEdit } from "../containers/varieties";

import Grid from "@material-ui/core/Grid";

class VarietyNew extends Component {
  componentWillMount() {
    this.props.clearVariety();
  }

  render() {
    return (
      <Navbar currentPage="varieties">
        <Grid container>
          <VarietyEdit />
        </Grid>
      </Navbar>
    );
  }
}

export default connect(
  null,
  {
    clearVariety
  }
)(VarietyNew);
