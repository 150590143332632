import React, { Component } from "react";
import PropTypes from "prop-types";

import Downshift from "downshift";
import deburr from "lodash/deburr";
import keycode from "keycode";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

class DownshiftMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selectedItem: this.props.initialItems,
      initialItem: this.props.initialItems
    };
    this.renderInput = this.renderInput.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.itemToString = this.itemToString.bind(this);
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          ...InputProps
        }}
        {...other}
      />
    );
  }

  renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || "") === suggestion.id;

    return (
      <MenuItem
        {...itemProps}
        key={index}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  }

  getSuggestions(value, results) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : Object.values(results);
  }

  itemToString(item) {
    if (item && item.name) {
      return item.name;
    }
    return "";
  }

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (
      selectedItem.length &&
      !inputValue.length &&
      keycode(event) === "backspace"
    ) {
      this.setState(
        {
          selectedItem: selectedItem.slice(0, selectedItem.length - 1)
        },
        () => {
          this.props.selectedItemsParent(this.state.selectedItem);
        }
      );
    }
  };

  handleInputChange = event => {
    this.setState(
      {
        inputValue: event.target.value
      },
      () => {
        this.props.search(this.state.inputValue);
      }
    );
  };

  handleChange = item => {
    let { selectedItem } = this.state;

    if (selectedItem.indexOf(item) === -1) {
      selectedItem = [...selectedItem, item];
    }
    this.setState(
      {
        inputValue: "",
        selectedItem
      },
      () => {
        this.props.selectedItemsParent(this.state.selectedItem);
      }
    );
  };

  handleDelete = item => () => {
    this.setState(
      state => {
        const selectedItem = [...state.selectedItem];
        selectedItem.splice(selectedItem.indexOf(item), 1);
        return { selectedItem };
      },
      () => {
        this.props.selectedItemsParent(this.state.selectedItem);
      }
    );
  };

  render() {
    const { classes, waiting } = this.props;
    const { inputValue, selectedItem } = this.state;
    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
        itemToString={this.itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          itemToString: itemToString2,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                startAdornment: Object.values(selectedItem).map(
                  (item, index) => {
                    return (
                      <Chip
                        key={index}
                        tabIndex={-1}
                        label={item.name}
                        className={classes.chip}
                        onDelete={this.handleDelete(item)}
                      />
                    );
                  }
                ),
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown,
                placeholder: this.props.placeholder
              }),
              label: this.props.label
            })}
            {isOpen ? (
              <Paper className={classes.paper} elevation={2} square>
                {waiting ? (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.progressGrid}
                  >
                    <CircularProgress
                      size={40}
                      thickness={4}
                      color={"primary"}
                    />
                  </Grid>
                ) : (
                  <div>
                    {this.getSuggestions(inputValue2, this.props.results).map(
                      (suggestion, index) =>
                        this.renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion }),
                          highlightedIndex,
                          selectedItem: selectedItem2
                        })
                    )}
                  </div>
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    overflow: "auto",
    maxHeight: 250
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  progressGrid: {
    marginTop: 10,
    marginBottom: 10
  }
});

export default withStyles(styles)(DownshiftMultiple);
