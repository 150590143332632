import React, { Component } from "react";
import { connect } from "react-redux";
import { updateRegion, updateRegionLocally } from "../../actions/regions";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from "@material-ui/core";

class RegionEdit extends Component {
  handleChange = name => e => {
    this.props.updateRegionLocally(this.props.id, name, e.target.value);
  };

  checkChange = e => {
    this.props.updateRegionLocally(this.props.id, 'mexican', e.target.checked);
  };

  onSave = () => {
    this.props.updateRegion(this.props.id, this.props.region);
  };

  render() {
    const name = this.props.region.name || "";
    const mexican = this.props.region.mexican || false;
    const { classes } = this.props;
    return (
      <Grid container spacing={2} className={classes.headerContainer}>
        {!this.props.create && (
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Edit Region
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField fullWidth value={name} onChange={this.handleChange('name')} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={ mexican }
                onChange={this.checkChange}
                color="primary"
                name="mexican"
              />
            }
            label="Mexico"
          />
        </Grid>
        <Grid item xs={3}>
          <Button component={Link} to="/regions" variant="contained">
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={this.onSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  headerContainer: {
    marginTop: 20
  }
});

const mapStateToProps = ({ regions }) => ({ regions });

export default connect(
  mapStateToProps,
  {
    updateRegion,
    updateRegionLocally
  }
)(withStyles(styles)(RegionEdit));
