import React, { Component } from "react";
import { connect } from "react-redux";

import PlotVarieties from "../PlotVarieties";
import {
  setNurseVarietyLocally,
  deleteNurseVariety
} from "../../actions/nurse_varieties";

class PlotNurseVarieties extends Component {
  deleteNurseVariety = nursery => nurseVariety => {
    this.props.deleteNurseVariety(nursery.id, nurseVariety.id);
  };

  render() {
    const { nursery } = this.props.nurseries;
    return (
      <PlotVarieties
        varietyType={"nurseVarieties"}
        plotsType={"NurseVariety"}
        mainTypeSingle={"nursery"}
        mainTypePlural={"nurseries"}
        deletePlotVarietyConnector={this.deleteNurseVariety(nursery)}
        setVarietyConnectorLocally={this.props.setNurseVarietyLocally}
      />
    );
  }
}

const mapStateToProps = ({ nurseries, nurseVarieties }) => ({
  nurseries,
  nurseVarieties
});
export default connect(mapStateToProps, {
  setNurseVarietyLocally,
  deleteNurseVariety
})(PlotNurseVarieties);
