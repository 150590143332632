import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PlotChipRows } from "./views";
import { getVarietyPlotNumber } from "../../helpers/plotBed";
import { setSelectedPlotBeds } from "../../actions/plot_beds";

class PlotVarieties extends Component {
  filterAndSortVarietyConnectors = () => {
    const varietyConnectors = this.props[this.props.varietyType][
      this.props.varietyType
    ];
    const filteredVarietyConnectors = this.filterVarietyConnectors(
      varietyConnectors
    );
    const sortedVarietyConnectors = this.sortConnectors(
      filteredVarietyConnectors
    );
    return sortedVarietyConnectors;
  };

  filterPlotBedsByMainType = () => {
    const parentRecord = this.props[this.props.mainTypePlural][
      this.props.mainTypeSingle
    ];
    const varietyConnectors = this.props[this.props.varietyType][
      this.props.varietyType
    ];
    const { plotBeds } = this.props.plotBeds;
    const filteredVarietyConnectors = {};
    Object.values(varietyConnectors).forEach(varietyConnector => {
      const varietyConnectorParentId =
        varietyConnector[`${this.props.mainTypeSingle}Id`];
      if (varietyConnectorParentId === parentRecord.id) {
        filteredVarietyConnectors[varietyConnector.id] = varietyConnector;
      }
    });
    const filteredPlotBeds = {};
    Object.values(plotBeds).forEach(plotBed => {
      if (
        filteredVarietyConnectors[plotBed.plotsId] &&
        plotBed.plotsType === this.props.plotsType
      )
        filteredPlotBeds[plotBed.key] = plotBed;
    });
    return filteredPlotBeds;
  };

  filterVarietyConnectors = varietyConnectors => {
    const plotBeds = this.filterPlotBedsByMainType();
    const record = this.props[this.props.mainTypePlural][
      this.props.mainTypeSingle
    ];
    const plotBedNumbers = getVarietyPlotNumber(
      plotBeds,
      record.plotType,
      record.plotRows,
      record.plotCols
    );
    let allConnectors = [];
    Object.values(varietyConnectors).forEach(varietyConnector => {
      if (this.filterEvaluation(varietyConnector)) {
        const locationId = plotBedNumbers[varietyConnector.id];
        if (locationId) {
          allConnectors.push(varietyConnector);
        }
      }
    });
    return allConnectors;
  };

  filterEvaluation = varietyConnector =>
    varietyConnector.varietyId ? true : false;

  sortConnectors = varietyConnectors => {
    const plotBeds = this.filterPlotBedsByMainType();
    const record = this.props[this.props.mainTypePlural][
      this.props.mainTypeSingle
    ];
    const plotBedNumbers = getVarietyPlotNumber(
      plotBeds,
      record.plotType,
      record.plotRows,
      record.plotCols
    );
    return varietyConnectors.sort(
      (varietyConnectorA, varietyConnectorB) =>
        plotBedNumbers[varietyConnectorA.id] -
        plotBedNumbers[varietyConnectorB.id]
    );
  };

  setSelectedPlotBeds = varietyConnectorId => {
    this.props.setSelectedPlotBeds(varietyConnectorId);
    this.props.setVarietyConnectorLocally(varietyConnectorId);
    if (this.props.afterSetSelectedPlotBeds) {
      this.props.afterSetSelectedPlotBeds();
    }
  };

  getVariety = varietyId => {
    const { varieties } = this.props.varieties;
    return varieties[varietyId] || {};
  };

  render() {
    const record = this.props[this.props.mainTypePlural][
      this.props.mainTypeSingle
    ];
    const plotBeds = this.filterPlotBedsByMainType();
    const plotBedNumbers = getVarietyPlotNumber(
      plotBeds,
      record.plotType,
      parseInt(record.plotRows),
      parseInt(record.plotCols)
    );
    const varietyConnectors = this.filterAndSortVarietyConnectors();
    return (
      <PlotChipRows
        getVariety={this.getVariety}
        isCompleted={this.props.isCompleted}
        plotBedNumbers={plotBedNumbers}
        varietyConnectors={varietyConnectors}
        setSelectedPlotBeds={this.setSelectedPlotBeds}
        deletePlotVarietyConnector={this.props.deletePlotVarietyConnector}
      />
    );
  }
}

PlotVarieties.propTypes = {
  mainTypeSingle: PropTypes.string.isRequired,
  mainTypePlural: PropTypes.string.isRequired,
  plotsType: PropTypes.string.isRequired,
  varietyType: PropTypes.string.isRequired,
  deletePlotVarietyConnector: PropTypes.func.isRequired,
  setVarietyConnectorLocally: PropTypes.func.isRequired,
  isCompleted: PropTypes.func
};

const mapStateToProps = ({
  plotBeds,
  trials,
  trialVarieties,
  nurseVarieties,
  nurseries,
  varieties
}) => ({
  plotBeds,
  trials,
  trialVarieties,
  nurseVarieties,
  nurseries,
  varieties
});

export default connect(mapStateToProps, {
  setSelectedPlotBeds
})(PlotVarieties);
