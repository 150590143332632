import React, { Component } from "react";
import { Navbar } from "../containers";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  content: {
    marginTop: theme.content.marginTop
  }
});

class NoMatch extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Navbar currentPage="">
        <Grid container>
          <div className={classes.content}>
            <Typography variant="h5" gutterBottom>
              Route not found
            </Typography>
          </div>
        </Grid>
      </Navbar>
    );
  }
}

export default withStyles(styles)(NoMatch);
