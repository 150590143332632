import React from "react";
import PropTypes from "prop-types";

import TrialHit from "./TrialHit";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

const TrialLoadMore = ({
  hits,
  hitsStatuses,
  hasMore,
  loading,
  currentPage,
  totalPages,
  loadMore,
  loadAll,
  heightOfTable,
  order,
  orderBy,
  sortTable,
  sortHandler,
  hideExtraTrialInfo,
  ...props
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <div
          style={{
            marginTop: 0,
            width: "100%",
            overflow: "auto",
            height: heightOfTable
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sortDirection={order}
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Tooltip title="Sort by Name" enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={order}
                      onClick={sortHandler("name")}
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Name
                      </Typography>
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={props.classes.cellTypography}
                  >
                    Commodity
                  </Typography>
                </TableCell>
                <TableCell
                  sortDirection={order}
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Tooltip title="Sort by Wet Date" enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === "wet_date"}
                      direction={order}
                      onClick={sortHandler("wet_date")}
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Wet Date
                      </Typography>
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  sortDirection={order}
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Tooltip title="Sort by Est. Harvest Date" enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === "harvest_date"}
                      direction={order}
                      onClick={sortHandler("harvest_date")}
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Est. Harvest Date
                      </Typography>
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={props.classes.cellTypography}
                  >
                    Grower
                  </Typography>
                </TableCell>
                <TableCell
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={props.classes.cellTypography}
                  >
                    Region
                  </Typography>
                </TableCell>
                <TableCell
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={props.classes.cellTypography}
                  >
                    Area
                  </Typography>
                </TableCell>
                <TableCell
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={props.classes.cellTypography}
                  >
                    Ranch
                  </Typography>
                </TableCell>
                {!hideExtraTrialInfo && (
                  <React.Fragment>
                    <TableCell
                      className={props.classes.tableHeaderCell}
                      align="center"
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Map Printed
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={props.classes.tableHeaderCell}
                      align="center"
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Producer Map Emailed
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={props.classes.tableHeaderCell}
                      align="center"
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Grower Map Emailed
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={props.classes.tableHeaderCell}
                      align="center"
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Cleaned
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={props.classes.tableHeaderCell}
                      align="center"
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Evaluation Ready
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={props.classes.tableHeaderCell}
                      align="center"
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Producer Evaluation Emailed
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={props.classes.tableHeaderCell}
                      align="center"
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        className={props.classes.cellTypography}
                      >
                        Grower Evaluation Emailed
                      </Typography>
                    </TableCell>
                  </React.Fragment>
                )}
                <TableCell
                  className={props.classes.tableHeaderCell}
                  align="center"
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    className={props.classes.cellTypography}
                  >
                    Trial Type
                  </Typography>
                </TableCell>
                <TableCell
                  className={props.classes.tableHeaderCell}
                  align="center"
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortTable(hits).map(hit => (
                <TrialHit
                  key={hit.objectID}
                  hit={hit}
                  hitsStatuses={hitsStatuses}
                  hideExtraTrialInfo={hideExtraTrialInfo}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
      {loading ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={props.classes.progressGrid}
        >
          <CircularProgress size={80} thickness={7} color={"primary"} />
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            <Button
              color="primary"
              fullWidth
              onClick={loadMore}
              disabled={!hasMore}
              className={props.classes.button}
            >
              Show more results
            </Button>
          </Grid>
          <Grid item xs={12}>
            {hasMore && (
              <Button
                color="secondary"
                fullWidth
                onClick={loadAll}
                disabled={!hasMore}
                className={props.classes.button}
              >
                Load Rest of {totalPages - currentPage - 1} Pages
              </Button>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

TrialLoadMore.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  sortTable: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired
};

const styles = theme => ({
  button: {
    cursor: "pointer"
  },
  cellTypography: {
    fontSize: 12
  },
  root: {
    flexGrow: 1
  },
  tableHeaderCell: {
    padding: 2,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.default
  },
  tableBodyCell: {
    padding: 2
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflow: "auto"
    // overflowX: "auto"
  },
  tableBodyRoot: {
    marginTop: 0,
    width: "100%",
    overflow: "auto",
    height: 500
  },
  searchContainer: {
    marginBottom: 20
  },
  searchField: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 70
    },
    marginBottom: 20
  },
  progressGrid: {
    marginTop: 40
  },
  linkStyle: theme.linkStyle
});

export default withStyles(styles)(TrialLoadMore);
