import { CALL_API, Schemas } from '../middleware/api';

export const FETCH_SHIPPER = 'FETCH_SHIPPER';
export const FETCH_SHIPPER_SUCCESS = 'FETCH_SHIPPER_SUCCESS';
export const FETCH_SHIPPER_FAILURE = 'FETCH_SHIPPER_FAILURE';

export const FETCH_SHIPPERS = 'FETCH_SHIPPERS';
export const FETCH_SHIPPERS_SUCCESS = 'FETCH_SHIPPERS_SUCCESS';
export const FETCH_SHIPPERS_FAILURE = 'FETCH_SHIPPERS_FAILURE';

export const fetchShipper = (id) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [FETCH_SHIPPER, FETCH_SHIPPER_SUCCESS, FETCH_SHIPPER_FAILURE],
      endpoint: `/companies/${id}`,
      schema: Schemas.COMPANY,
      method: 'get'
    }
  })
)

export const fetchShippers = (search="") => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ FETCH_SHIPPERS, FETCH_SHIPPERS_SUCCESS, FETCH_SHIPPERS_FAILURE],
      endpoint: `/shippers?search=${search}`,
      schema: Schemas.COMPANIES,
      method: 'get'
    }
  })
)