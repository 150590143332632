import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updateTrialVariety } from "../../../actions/trial_varieties";
import { getVarietyPlotNumber, filterPlotBedsByTrial } from "../../../helpers";
import { Row } from "./views";

class ConnectorFieldVariety extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: {}
    };
    this.updateTrialVariety = this.updateTrialVariety.bind(this);
  }

  availableTrialVarieties = () => {
    const { trial } = this.props.trials;
    const { trialVarieties } = this.props.trialVarieties;
    return Object.values(trialVarieties).filter(trialVariety =>
      (!trialVariety.fieldVarietyId ||
        trialVariety.fieldVarietyId === this.props.fieldVariety.id) &&
      trialVariety.trialId === trial.id
        ? true
        : false
    );
  };

  updateTrialVariety = trialVariety => () => {
    trialVariety.fieldVarietyId = trialVariety.fieldVarietyId
      ? null
      : this.props.fieldVariety.id;
    this.props.updateTrialVariety(
      trialVariety.trialId,
      trialVariety.id,
      trialVariety
    );
  };

  generatePlotNumbers = () => {
    const { trial } = this.props.trials;
    const { trialVarieties } = this.props.trialVarieties;
    const { plotBeds } = this.props.plotBeds;
    const filteredPlotBeds = filterPlotBedsByTrial(
      trial,
      trialVarieties,
      plotBeds
    );
    const plotBedNumbers = getVarietyPlotNumber(
      filteredPlotBeds,
      trial.plotType,
      trial.plotRows,
      trial.plotCols
    );
    return plotBedNumbers;
  };

  render() {
    const { trial } = this.props.trials;
    const { varieties } = this.props.varieties;
    const trialVarieties = this.availableTrialVarieties();
    const plotBedNumbers = this.generatePlotNumbers();
    return (
      <Row
        archived={trial.archive}
        fieldVarietyId={this.props.fieldVariety.id}
        trialVarieties={trialVarieties}
        updateTrialVariety={this.updateTrialVariety}
        plotBedNumbers={plotBedNumbers}
        varieties={varieties}
      />
    );
  }
}

ConnectorFieldVariety.propTypes = {
  fieldVariety: PropTypes.object.isRequired
};

const mapStateToProps = ({ plotBeds, trials, trialVarieties, varieties }) => ({
  plotBeds,
  trials,
  trialVarieties,
  varieties
});

export default connect(mapStateToProps, {
  updateTrialVariety
})(ConnectorFieldVariety);
