import { CALL_API, Schemas } from "../middleware/api";

export const FETCH_MULTI_EVALUATIONS = "FETCH_MULTI_EVALUATIONS";
export const FETCH_MULTI_EVALUATIONS_SUCCESS =
  "FETCH_MULTI_EVALUATIONS_SUCCESS";
export const FETCH_MULTI_EVALUATIONS_FAILURE =
  "FETCH_MULTI_EVALUATIONS_FAILURE";

export const SAVE_MULTI_EVALUATIONS = "SAVE_MULTI_EVALUATIONS";
export const SAVE_MULTI_EVALUATIONS_SUCCESS = "SAVE_MULTI_EVALUATIONS_SUCCESS";
export const SAVE_MULTI_EVALUATIONS_FAILURE = "SAVE_MULTI_EVALUATIONS_FAILURE";

export const UPDATE_MULTI_EVALUATION_LOCALLY =
  "UPDATE_MULTI_EVALUATION_LOCALLY";

export const fetchMultiEvaluations = (type, parentId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_MULTI_EVALUATIONS,
        FETCH_MULTI_EVALUATIONS_SUCCESS,
        FETCH_MULTI_EVALUATIONS_FAILURE
      ],
      method: "get",
      endpoint: generateUrl(type, parentId),
      schema: Schemas.MULTI_EVALUATIONS
    }
  });

export const toggleMultiEvaluationLocally = (recordId, labelId, tagIds) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_MULTI_EVALUATION_LOCALLY,
    recordId: recordId,
    labelId: labelId,
    tagIds: tagIds
  });

export const saveMultiEvaluations = (type, parentId) => (
  dispatch,
  getState
) => {
  const { multiEvaluations } = getState().multiEvaluations;
  const parentMultiEvaluations = {}
  Object.values(multiEvaluations).forEach(multiEvaluation => {
    if(multiEvaluation.multiEvalId === parentId){
      parentMultiEvaluations[multiEvaluation.key] = multiEvaluation;
    }
  })
  return dispatch({
    [CALL_API]: {
      types: [
        SAVE_MULTI_EVALUATIONS,
        SAVE_MULTI_EVALUATIONS_SUCCESS,
        SAVE_MULTI_EVALUATIONS_FAILURE
      ],
      method: "post",
      endpoint: generateUrl(type, parentId),
      schema: Schemas.MULTI_EVALUATIONS,
      data: { multi_evaluations: Object.values(parentMultiEvaluations) },
      extra: { parentId: parentId }
    }
  });
};

const generateUrl = (type, parentId) => {
  let url = "/multi_evaluations";
  if (type === "fieldVarieties") {
    url += `?field_variety_id=${parentId}`;
  } else {
    url += `?trial_variety_id=${parentId}`;
  }
  return url;
};
