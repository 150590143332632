import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isTypeFieldVariety, getRecord } from "../../helpers";
import { updateFieldVarietyLocally } from "../../actions/field_varieties";
import { updateTrialVarietyLocally } from "../../actions/trial_varieties";

import { FullPageSpinner } from "../../ui";
import {
  EvaluationData,
  GrowerFeelings,
  SubcriteriumEvaluationSorted
} from "./views";
import MultiEvaluation from "../MultiEvaluation";
import WeightedScoreEvaluation from "../WeightedScoreEvaluation";
import Grid from "@material-ui/core/Grid";

class EvaluationCommodity extends Component {
  _updateRecordLocally = (name, value) => {
    if (isTypeFieldVariety(this.props.type)) {
      this.props.updateFieldVarietyLocally(this.props.recordId, name, value);
    } else {
      this.props.updateTrialVarietyLocally(this.props.recordId, name, value);
    }
  };

  handleChange = name => e => {
    this._updateRecordLocally(name, e.target.value);
  };
  handleNonEventChange = (name, value) => e => {
    this._updateRecordLocally(name, value);
  };

  render() {
    const { commodities, waiting } = this.props.commodities;
    const { criteria } = this.props.criteria;
    const record = getRecord(this.props, this.props.type, this.props.recordId);

    let criteriaObjs = [];
    let labelIds = [];
    let commodity = commodities[record.commodityId];
    if (commodity) {
      criteriaObjs = commodity.criteria.map(
        criteriumId => criteria[criteriumId]
      );
      labelIds = commodity.labels || [];
    } else {
      commodity = {};
    }

    return (
      <Grid container spacing={2} style={{ marginTop: 2 }}>
        {waiting ? (
          <FullPageSpinner />
        ) : (
          <SubcriteriumEvaluationSorted
            type={this.props.type}
            commodityId={record.commodityId}
            criteria={criteriaObjs}
          />
        )}
        {labelIds.map((labelId, index) => (
          <MultiEvaluation
            key={index}
            type={this.props.type}
            commodityId={commodity.id}
            labelId={labelId}
          />
        ))}
        <EvaluationData handleChange={this.handleChange} record={record} />
        <GrowerFeelings
          growerLike={record.growerLike}
          handleNonEventChange={this.handleNonEventChange}
        />
        <WeightedScoreEvaluation recordId={record.id} type={this.props.type} />
      </Grid>
    );
  }
}

EvaluationCommodity.propTypes = {
  type: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired
};

const mapStateToProps = ({
  commodities,
  criteria,
  fieldVarieties,
  subcriteria,
  trialVarieties
}) => ({ commodities, criteria, fieldVarieties, subcriteria, trialVarieties });

export default connect(mapStateToProps, {
  updateFieldVarietyLocally,
  updateTrialVarietyLocally
})(EvaluationCommodity);
