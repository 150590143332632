import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchArea } from "../actions/areas";

import { Navbar } from "../containers";
import { AreaEdit } from "../containers/areas";

import Grid from "@material-ui/core/Grid";

class Area extends Component {
  componentWillMount() {
    this.props.fetchArea(this.props.match.params.id);
  }

  render() {
    const { area } = this.props.areas;
    return (
      <Navbar currentPage="areas">
        <Grid container>
          <AreaEdit area={area} id={this.props.match.params.id} />
        </Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  areas: state.areas
});

export default connect(
  mapStateToProps,
  {
    fetchArea
  }
)(Area);
