import { SET_NOTIFICATION } from "../actions/notifications";
import { camelizeKeys, decamelizeKeys } from "humps";
import { normalize, schema } from "normalizr";
import axios from "axios";
import axiosRetry from "axios-retry";

export const API_ROOT = process.env.REACT_APP_BASE_URL;

const callApi = (endpoint, schema, token, method, data, extra) => {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;
  let request = {
    method: method,
    url: fullUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
  if (data && data["image"]) {
    request["data"] = data;
  } else if (data) {
    request["data"] = decamelizeKeys(data);
  }
  axiosRetry(axios, {
    retries: 3,
    retryDelay: retryCount => {
      return retryCount * 1000;
    }
  });

  return axios(request).then(response => {
    if (!(response.status >= 200 && response.status < 300)) {
      return Promise.reject(response.data);
    }
    if (response.status === 206) {
      return response.data;
    }
    if (!schema) {
      return camelizeKeys(response.data);
    }
    const camelizedJson = camelizeKeys(response.data);
    const meta = camelizedJson.meta;
    if (meta) {
      delete camelizedJson["meta"];
      const schemaKey = schema["schema"]["key"];
      return Object.assign(
        { meta: meta },
        normalize(camelizedJson[schemaKey], schema)
      );
    } else {
      return Object.assign({}, normalize(camelizedJson, schema));
    }
  });
};

const personalView = new schema.Entity(
  "personalView",
  {},
  {
    idAttribute: entity => entity.key
  }
);
const personalViews = new schema.Entity(
  "personalViews",
  {},
  {
    idAttribute: entity => entity.key
  }
);
const profile = new schema.Entity("user", {});

const criterium = new schema.Entity("criteria", {
  commodity: new schema.Entity("commodity"),
  subcriteria: [new schema.Entity("subcriterium")]
});
const subcriterium = new schema.Entity("subcriteria", {
  criterium: criterium
});
const label = new schema.Entity("labels", {
  commodity: new schema.Entity("commodity"),
  tags: [new schema.Entity("tags")]
});
const tag = new schema.Entity("tags", {
  label: new schema.Entity("labels")
});
const commodities = new schema.Entity("commodities", {
  criteria: [criterium],
  subcriteria: [subcriterium],
  labels: [label],
  tags: [tag]
});

const multiEvaluation = new schema.Entity(
  "multiEvaluations",
  {
    label: new schema.Entity("labels", {}),
    tag: new schema.Entity("tags", {})
  },
  {
    idAttribute: entity => entity.key
  }
);

const evaluation = new schema.Entity(
  "evaluations",
  {
    criterium: new schema.Entity("criteria", {}),
    subcriterium: new schema.Entity("subcriteria", {})
  },
  {
    idAttribute: entity => entity.key
  }
);

const plotBed = new schema.Entity(
  "plotBeds",
  {},
  {
    idAttribute: entity => entity.key
  }
);

const company = new schema.Entity("companies", {});

const commodity = new schema.Entity("commodity", {
  criteria: [criterium],
  subcriteria: [subcriterium],
  labels: [label],
  tags: [tag]
});

const varietyExtra = new schema.Entity("varieties", {
  supplier: new schema.Entity("companies", {}),
  commodities: [commodity]
});

const fieldVariety = new schema.Entity("fieldVarieties", {
  multiEvaluations: [multiEvaluation],
  evaluations: [evaluation],
  plotBeds: [plotBed],
  commodity: commodity,
  variety: varietyExtra,
  trial: new schema.Entity("trials")
});

const trialVariety = new schema.Entity("trialVarieties", {
  multiEvaluations: [multiEvaluation],
  evaluations: [evaluation],
  plotBeds: [plotBed],
  commodity: commodity,
  fieldVariety: fieldVariety,
  variety: varietyExtra,
  trial: new schema.Entity("trials")
});

const variety = new schema.Entity("varieties", {
  trialVarieties: [trialVariety],
  fieldVarieties: [fieldVariety],
  supplier: company
});
const varieties = new schema.Entity("varieties", {
  trialVarieties: [trialVariety],
  fieldVarieties: [fieldVariety],
  commodities: [commodity],
  experimentalVarieties: [new schema.Entity("varieties", {})]
});

const trial = new schema.Entity("trial", {
  trialVarieties: [trialVariety],
  fieldVarieties: [fieldVariety]
});

const trials = new schema.Entity("trials", {
  trialVarieties: [trialVariety],
  fieldVarieties: [fieldVariety]
});

const nurseVariety = new schema.Entity("nurseVarieties", {
  plotBeds: [plotBed],
  nursery: new schema.Entity("nursery"),
  variety: variety
});

const nursery = new schema.Entity("nursery", {
  nurseVarieties: [nurseVariety]
});

export const Schemas = {
  AREA: new schema.Entity("area", {}),
  AREAS: new schema.Values(new schema.Entity("areas", {})),
  COMMODITY: commodity,
  COMMODITIES: new schema.Values(commodities),
  COMMODITIES_DETAIL: new schema.Values(commodity),
  COMPANY: company,
  COMPANIES: new schema.Values(company),
  COMPANY_CATEGORIES: new schema.Values(
    new schema.Entity("companyCategory", {})
  ),
  CRITERIUM: criterium,
  CRITERIA: new schema.Values(criterium),
  EVALUATIONS: new schema.Values(evaluation),
  FIELD_VARIETY: fieldVariety,
  FIELD_VARIETIES: new schema.Values(fieldVariety),
  IMAGES: new schema.Values(new schema.Entity("images", {}, {})),
  LABEL: label,
  LABELS: new schema.Values(label),
  MULTI_EVALUATIONS: new schema.Values(multiEvaluation),
  NURSE_VARIETY: nurseVariety,
  NURSE_VARIETIES: new schema.Values(nurseVariety),
  NURSERY: nursery,
  NURSERIES: new schema.Values(new schema.Entity("nurseries", {})),
  PEOPLE: new schema.Values(new schema.Entity("people", {})),
  PERSON: new schema.Entity("person", {}, {}),
  PERSONAL_VIEW: personalView,
  PERSONAL_VIEWS: new schema.Values(personalViews),
  PLOT_BEDS: new schema.Values(plotBed),
  PROFILE: profile,
  PROFILES: new schema.Values(new schema.Entity("users", {})),
  RANCH: new schema.Entity("ranch", {}),
  RANCHES: new schema.Values(new schema.Entity("ranches", {})),
  REGION: new schema.Entity("region", {}),
  REGIONS: new schema.Values(new schema.Entity("regions", {})),
  REPORT: new schema.Entity("report", {}, {}),
  TAG: tag,
  TAGS: new schema.Values(tag),
  TRIAL: trial,
  TRIALS_STATUS: new schema.Values(new schema.Entity("trials", {})),
  TRIALS: new schema.Values(trials),
  TRIAL_VARIETY: trialVariety,
  TRIAL_VARIETIES: new schema.Values(trialVariety),
  SUBCRITERIUM: subcriterium,
  SUBCRITERIA: new schema.Values(subcriterium),
  VARIETY: variety,
  VARIETIES: new schema.Values(varieties),
  VARIETY_EDIT: new schema.Entity("varieties", {})
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "CALL_API";
export const AFTER_SUCCESS = "AFTER_SUCCESS";
export const AFTER_FAILURE = "AFTER_FAILURE";
export const AFTER_ETAG = "AFTER_ETAG";

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { schema, types, method, data, extra } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }
  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType, extra: extra }));

  const userStore = store.getState().user;

  const afterSuccess = action[AFTER_SUCCESS];
  const afterFailure = action[AFTER_FAILURE];

  return callApi(endpoint, schema, userStore.token, method, data, extra)
    .then(response => {
      const returnedData = response.entities || response || {};
      const metaData = response.meta || {};
      next(
        actionWith({
          data: returnedData,
          meta: metaData,
          type: successType,
          extra: extra
        })
      );
      if (typeof afterSuccess !== "undefined") {
        afterSuccess.forEach(successAction => {
          successAction["data"] = returnedData;
          next(actionWith(successAction));
        });
      }
      return response;
    })
    .catch(error => {
      let message = null;
      if (error.request && error.request.status === 412) {
        let data = null;
        const camelizedJson = camelizeKeys(error.response.data);
        if (Array.isArray(camelizedJson)) {
          data = normalize(camelizedJson, Schemas["TRIAL_VARIETIES"]);
        } else {
          data = normalize(camelizedJson, schema);
        }
        message =
          "This page has been updated since you have been on it, please try your action again.";
        next(
          actionWith({
            type: failureType,
            status: 412,
            error: message,
            data: data.entities
          })
        );
        next(
          actionWith({
            type: SET_NOTIFICATION,
            error: message
          })
        );
      } else {
        message = error.message || "Something bad happened";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        }
        next(
          actionWith({
            type: failureType,
            error: message
          })
        );
      }
      if (typeof afterFailure !== "undefined") {
        afterFailure.forEach(failureAction => {
          failureAction["error"] = message;
          next(failureAction);
        });
      }
      return Promise.reject(error);
    });
};
