import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DownshiftMultiple from "../DownshiftMultiple";

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.state = {
      results: []
    };
  }
  search = value => {
    // trial varitiety ids props
    const { trials } = this.props.trials;
    const inputValue = value.trim().toLowerCase();

    const records = this.props.records.map(record => {
      const trial = trials[record.trialId] || {};
      return trial[this.props.type] || {};
    });
    const filteredRecords = {};
    records
      .filter(record => {
        let name = record.name || "";
        name = name.toLowerCase();
        return record.name && name.includes(inputValue) ? true : false;
      })
      .forEach(record => {
        filteredRecords[record.id] = record;
      });
    this.setState({ ...this.state, results: filteredRecords });
  };

  render() {
    return (
      <DownshiftMultiple
        placeholder={this.props.placeholder}
        search={this.search} // part is different since I will be storing all the trialvarietis
        // in the props and will have to create a filter search by name as function
        initialItems={this.props.initialResults}
        results={this.state.results}
        selectedItemsParent={this.props.updateSelected}
      />
    );
  }
}

SearchFilter.propTypes = {
  records: PropTypes.array.isRequired,
  updateSelected: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    trials: state.trials,
    trialVarieties: state.trialVarieties
  };
};

export default connect(mapStateToProps, null)(SearchFilter);
