import React, { Component } from "react";
import PropTypes from "prop-types";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export const API_ROOT = process.env.REACT_APP_BASE_URL;

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  serverUrl = () => {
    let urlStr = `${API_ROOT}/trial_varieties/${this.props.recordId}/images`;
    if (this.props.type === "fieldVarieties") {
      urlStr = `${API_ROOT}/field_varieties/${this.props.recordId}/images`;
    }
    return urlStr;
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="body1" gutterBottom>
          Max 3 files at a time
        </Typography>
        <FilePond
          ref={ref => (this.pond = ref)}
          allowMultiple={true}
          allowRevert={false}
          maxFiles={3}
          server={this.serverUrl()}
          //onaddfilestart={this.onaddfilestart}
        />
        <Button
          size="large"
          variant="contained"
          color="default"
          fullWidth
          onClick={this.props.hideCamera}
        >
          Close
        </Button>
      </React.Fragment>
    );
  }
}

ImageUpload.propTypes = {
  type: PropTypes.string.isRequired
};

export default ImageUpload;
