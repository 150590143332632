import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import LabelIcon from "@material-ui/icons/Label";
import CheckIcon from "@material-ui/icons/CheckCircle";

export const View = ({
  fieldVariety,
  variety,
  confirmDelete,
  isCompleted,
  setSelectedFieldVarietey,
  ...props
}) => (
  <Chip
    icon={
      isCompleted(fieldVariety) ? <CheckIcon color="primary" /> : <LabelIcon />
    }
    label={
      <React.Fragment>
        <Typography variant="body2">{variety.name}</Typography>
      </React.Fragment>
    }
    style={{ margin: 5 }}
    variant="outlined"
    key={fieldVariety.id}
    onClick={setSelectedFieldVarietey}
    onDelete={confirmDelete}
  />
);

View.propTypes = {
  variety: PropTypes.object.isRequired,
  fieldVariety: PropTypes.object.isRequired,
  confirmDelete: PropTypes.func,
  setSelectedFieldVarietey: PropTypes.func.isRequired
};
