import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { View } from "./views";
import { deleteTrialVarietyImage } from "../../actions/trial_varieties";
import { deleteFieldVarietyImage } from "../../actions/field_varieties";
import { isTypeFieldVariety, getRecord } from "../../helpers";

class TrialImages extends Component {
  deleteImage = imageId => () => {
    if (isTypeFieldVariety(this.props.type)) {
      this.props.deleteFieldVarietyImage(this.props.recordId, imageId);
    } else {
      this.props.deleteTrialVarietyImage(this.props.recordId, imageId);
    }
  };

  render() {
    const record = getRecord(this.props, this.props.type, this.props.recordId);
    return (
      <View
        deleteImage={this.deleteImage}
        images={record.images}
        hideImages={this.props.hideImages}
      />
    );
  }
}

TrialImages.propTypes = {
  type: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  hideImages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  trialVarieties: state.trialVarieties,
  fieldVarieties: state.fieldVarieties
});

export default connect(
  mapStateToProps,
  {
    deleteFieldVarietyImage,
    deleteTrialVarietyImage
  }
)(TrialImages);
