import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    marginTop: 25
  },
  headerText: {
    marginTop: 20
  },
  searchBar: {
    marginTop: 25
  }
});

export const View = withStyles(styles)(
  ({ variety, onSave, children, ...props }) => (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Typography
          variant="h2"
          gutterBottom
          className={props.classes.headerText}
        >
          Combine {variety.name}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            className={props.classes.button}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            Combine
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className={props.classes.searchBar}>
        {children}
      </Grid>
    </Grid>
  )
);
