import React from "react";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const createLabel = (trialVariety, plotBedNumbers, varieties) => {
  const plotNumber = plotBedNumbers[trialVariety.id];

  if (plotNumber) {
    return `${plotNumber} ${(varieties[trialVariety.variety] || {}).name}`;
  }
  return trialVariety.variety.name;
};

export const Row = ({
  archived,
  fieldVarietyId,
  trialVarieties,
  updateTrialVariety,
  plotBedNumbers,
  varieties,
  ...props
}) => (
  <FormGroup row>
    {trialVarieties.map(trialVariety => (
      <FormControlLabel
        key={trialVariety.id}
        control={
          <Checkbox
            disabled={archived}
            checked={trialVariety.fieldVarietyId ? true : false}
            onChange={updateTrialVariety(trialVariety)}
            value={`${fieldVarietyId}`}
          />
        }
        label={createLabel(trialVariety, plotBedNumbers, varieties)}
      />
    ))}
  </FormGroup>
);
