import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";
import { UPDATE_TRIAL_AREA_ID_LOCALLY } from "./trials";

export const UPDATE_AREA_LOCALLY = "UPDATE_AREA_LOCALLY";
export const UPDATE_SEARCH = "UPDATE_SEARCH";

export const FETCH_AREAS = "FETCH_AREAS";
export const FETCH_AREAS_SUCCESS = "FETCH_AREAS_SUCCESS";
export const FETCH_AREAS_FAILURE = "FETCH_AREAS_FAILURE";

export const FETCH_MORE_AREAS = "FETCH_MORE_AREAS";
export const FETCH_MORE_AREAS_SUCCESS = "FETCH_MORE_AREAS_SUCCESS";
export const FETCH_MORE_AREAS_FAILURE = "FETCH_MORE_AREAS_FAILURE";

export const FETCH_AREA = "FETCH_AREA";
export const FETCH_AREA_SUCCESS = "FETCH_AREA_SUCCESS";
export const FETCH_AREA_FAILURE = "FETCH_AREA_FAILURE";

export const CREATE_AREA = "CREATE_AREA";
export const CREATE_AREA_SUCCESS = "CREATE_AREA_SUCCESS";
export const CREATE_AREA_FAILURE = "CREATE_AREA_FAILURE";

export const UPDATE_AREA = "UPDATE_AREA";
export const UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS";
export const UPDATE_AREA_FAILURE = "UPDATE_AREA_FAILURE";

export const DELETE_AREA = "DELETE_AREA";
export const DELETE_AREA_SUCCESS = "DELETE_AREA_SUCCESS";
export const DELETE_AREA_FAILURE = "DELETE_AREA_FAILURE";

export const UPDATE_AREA_SEARCH = "UPDATE_AREA_SEARCH";

export const updateSearch = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_AREA_SEARCH,
    value: value
  });

export const updateAreaLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_AREA_LOCALLY,
    key: key,
    value: value
  });

export const fetchAreas = (search = "") => (dispatch, getState) => {
  const { page } = getState().areas;
  dispatch({
    [CALL_API]: {
      types: [FETCH_AREAS, FETCH_AREAS_SUCCESS, FETCH_AREAS_FAILURE],
      endpoint: `/areas?page=${page}&search=${search}`,
      schema: Schemas.AREAS,
      method: "get"
    }
  });
};

export const fetchMoreAreas = (search = "") => (dispatch, getState) => {
  const { page } = getState().areas;
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_MORE_AREAS,
        FETCH_MORE_AREAS_SUCCESS,
        FETCH_MORE_AREAS_FAILURE
      ],
      endpoint: `/areas?search=${search}&page=${page + 1}`,
      schema: Schemas.AREAS,
      method: "get"
    }
  });
};

export const fetchArea = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_AREA, FETCH_AREA_SUCCESS, FETCH_AREA_FAILURE],
      endpoint: `/areas/${id}`,
      schema: Schemas.AREA,
      method: "get"
    }
  });

export const createArea = name => (dispatch, getState) =>
  dispatch(createAreaCall(name));

export const createAreaUpdateTrial = name => (dispatch, getState) => {
  const updateTrialCall = {
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created an Area successfully"
      },
      {
        type: UPDATE_TRIAL_AREA_ID_LOCALLY
      }
    ]
  };
  const apiCall = Object.assign(createAreaCall(name), updateTrialCall);
  return dispatch(apiCall);
};

const createAreaCall = name => ({
  [CALL_API]: {
    types: [CREATE_AREA, CREATE_AREA_SUCCESS, CREATE_AREA_FAILURE],
    endpoint: "/areas",
    schema: Schemas.AREA,
    method: "post",
    data: { area: { name: name } }
  },
  [AFTER_SUCCESS]: [
    {
      type: SET_NOTIFICATION,
      payload: "Created an Area successfully"
    },
    {
      type: UPDATE_TRIAL_AREA_ID_LOCALLY
    }
  ],
  [AFTER_FAILURE]: [
    {
      type: SET_NOTIFICATION,
      payload: "Failed to create Area"
    }
  ]
});

export const updateArea = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_AREA, UPDATE_AREA_SUCCESS, UPDATE_AREA_FAILURE],
      endpoint: `/areas/${id}`,
      schema: Schemas.AREA,
      method: "patch",
      data: { area: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated an Area successfully"
      },
      {
        type: UPDATE_TRIAL_AREA_ID_LOCALLY
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update Area"
      }
    ]
  });

export const deleteArea = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_AREA, DELETE_AREA_SUCCESS, DELETE_AREA_FAILURE],
      endpoint: `/areas/${id}`,
      schema: Schemas.AREA,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted a Area successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete Area"
      }
    ]
  });
