import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const UPDATE_TRIAL_LOCALLY = "UPDATE_TRIAL_LOCALLY";
export const UPDATE_TRIAL_AREA_ID_LOCALLY = "UPDATE_TRIAL_AREA_ID_LOCALLY";
export const UPDATE_TRIAL_RANCH_ID_LOCALLY = "UPDATE_TRIAL_RANCH_ID_LOCALLY";
export const UPDATE_TRIAL_REGION_ID_LOCALLY = "UPDATE_TRIAL_REGION_ID_LOCALLY";
export const UPDATE_TRIAL_COMMODITY_ID_LOCALLY =
  "UPDATE_TRIAL_COMMODITY_ID_LOCALLY";

export const FETCH_TRIALS_STATUS = "FETCH_TRIALS_STATUS";
export const FETCH_TRIALS_STATUS_SUCCESS = "FETCH_TRIALS_STATUS_SUCCESS";
export const FETCH_TRIALS_STATUS_FAILURE = "FETCH_TRIALS_STATUS_FAILURE";

export const FETCH_TRIALS = "FETCH_TRIALS";
export const FETCH_TRIALS_SUCCESS = "FETCH_TRIALS_SUCCESS";
export const FETCH_TRIALS_FAILURE = "FETCH_TRIALS_FAILURE";

export const FETCH_TRIALS_PAGINATION = "FETCH_TRIALS_PAGINATION";
export const FETCH_TRIALS_PAGINATION_SUCCESS =
  "FETCH_TRIALS_PAGINATION_SUCCESS";
export const FETCH_TRIALS_PAGINATION_FAILURE =
  "FETCH_TRIALS_PAGINATION_FAILURE";

export const FETCH_TRIAL = "FETCH_TRIAL";
export const FETCH_TRIAL_SUCCESS = "FETCH_TRIAL_SUCCESS";
export const FETCH_TRIAL_FAILURE = "FETCH_TRIAL_FAILURE";

export const CREATE_TRIAL = "CREATE_TRIAL";
export const CREATE_TRIAL_SUCCESS = "CREATE_TRIAL_SUCCESS";
export const CREATE_TRIAL_FAILURE = "CREATE_TRIAL_FAILURE";

export const UPDATE_TRIAL = "UPDATE_TRIAL";
export const UPDATE_TRIAL_SUCCESS = "UPDATE_TRIAL_SUCCESS";
export const UPDATE_TRIAL_FAILURE = "UPDATE_TRIAL_FAILURE";

export const DELETE_TRIAL = "DELETE_TRIAL";
export const DELETE_TRIAL_SUCCESS = "DELETE_TRIAL_SUCCESS";
export const DELETE_TRIAL_FAILURE = "DELETE_TRIAL_FAILURE";

export const UPDATE_TRIAL_SEARCH = "UPDATE_TRIAL_SEARCH";

export const updateTrialSearch = (key, value) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_TRIAL_SEARCH,
      key: key,
      value: value
    });
    resolve();
  });

export const updateTrialLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_TRIAL_LOCALLY,
    key: key,
    value: value
  });

export const fetchTrialStatus = ids => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_TRIALS_STATUS,
        FETCH_TRIALS_STATUS_SUCCESS,
        FETCH_TRIALS_STATUS_FAILURE
      ],
      endpoint: `/trials/status?ids=${ids.join(",")}`,
      schema: Schemas.TRIALS_STATUS,
      method: "get"
    }
  });

export const fetchTrialsByVarietyIds = varietyIds => (dispatch, getState) =>
  dispatch(paginationCallApiObject(varietyIds, 1)).then(
    success => {
      checkIfMorePagesExist(varietyIds, success["meta"], dispatch);
    },
    error => {
      console.log("FETCH_TRIALS_PAGINATION_FAILURE", error);
    }
  );

const fetchMoreTrialsByVarietyIds = (varietyIds, nextPage) => (
  dispatch,
  getState
) => {
  return dispatch(paginationCallApiObject(varietyIds, nextPage)).then(
    success => {
      checkIfMorePagesExist(varietyIds, success["meta"], dispatch);
    },
    error => console.log("FETCH_TRIALS_PAGINATION_FAILURE")
  );
};

const paginationCallApiObject = (varietyIds, currentPage) => ({
  [CALL_API]: {
    types: [
      FETCH_TRIALS_PAGINATION,
      FETCH_TRIALS_PAGINATION_SUCCESS,
      FETCH_TRIALS_PAGINATION_FAILURE
    ],
    method: "get",
    endpoint: `/trials/by_variety_ids?ids=${varietyIds.join(
      ","
    )}&page=${currentPage}`,
    schema: Schemas.TRIALS
  }
});

const checkIfMorePagesExist = (varietyIds, meta, dispatch) => {
  if (meta && meta["totalPages"] > meta["currentPage"]) {
    dispatch(fetchMoreTrialsByVarietyIds(varietyIds, meta["currentPage"] + 1));
  }
};

export const fetchTrial = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_TRIAL, FETCH_TRIAL_SUCCESS, FETCH_TRIAL_FAILURE],
      method: "get",
      endpoint: `/trials/${id}`,
      schema: Schemas.TRIAL
    }
  });

export const createTrial = attributes => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_TRIAL, CREATE_TRIAL_SUCCESS, CREATE_TRIAL_FAILURE],
      method: "post",
      endpoint: "/trials",
      schema: Schemas.TRIAL,
      data: { trial: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a Trial successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Trial"
      }
    ]
  });

export const updateTrial = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_TRIAL, UPDATE_TRIAL_SUCCESS, UPDATE_TRIAL_FAILURE],
      method: "patch",
      endpoint: `/trials/${id}`,
      schema: Schemas.TRIAL,
      data: { trial: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated the Trial successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update the Trial"
      }
    ]
  });

export const deleteTrial = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_TRIAL, DELETE_TRIAL_SUCCESS, DELETE_TRIAL_FAILURE],
      method: "delete",
      endpoint: `/trials/${id}`,
      schema: Schemas.TRIAL
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted the Trial successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete the Trial"
      }
    ]
  });
