import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const ErrorPage = ({ msg, ...props }) => (
  <Grid container spacing={2} className={props.classes.mainContainer}>
    <Grid item xs={12}>
      <Typography variant="h2" gutterBottom>
        Error: {msg}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        {msg === "Network Error" &&
          "Please check your network connection and then try again."}
      </Typography>
    </Grid>
  </Grid>
);

ErrorPage.propTypes = {
  msg: PropTypes.string.isRequired
};

const styles = theme => ({
  mainContainer: {
    marginTop: 60
  }
});

export default withStyles(styles)(ErrorPage);
