import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  graphFormatDateStr,
  dateStrToNumber,
  filterEmptyObjects,
  REPORT_TYPE_SINGLE_VARIETY,
  fetchEmailDrawerCompanies
} from "../../../helpers";

import {
  submitStatisticalReport,
  submitExcelReport,
  emailStatisticalReport,
  checkStatisticalReportStatus,
  updateCheckReportStatus
} from "../../../actions/reports";
import { fetchPeopleAssociatedWith } from "../../../actions/people";
import { fetchCompaniesById } from "../../../actions/companies";
import { GraphView, Navbar } from "../../index";
import { ReportEmailDrawer } from "../index";
import { ReportProcessing } from "../../../ui";
import { NoEvaluations, ReportColumns, HeaderInfo } from "./views";
import Grid from "@material-ui/core/Grid";

class ReportSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailDrawer: false,
      showProcessingReport: false
    };
  }

  downloadPDF = () => {
    this.props.submitStatisticalReport(
      REPORT_TYPE_SINGLE_VARIETY,
      this.props.filteredFieldVarietyList,
      this.props.filteredTrialVarietyList,
      this.props.hideList,
      [this.props.varietyId],
      { orderByWetDate: this.props.wetDateSearch }
    );
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  downloadExcel = () => {
    this.props.submitExcelReport(
      this.props.filteredFieldVarietyList,
      this.props.filteredTrialVarietyList,
      [this.props.varietyId]
    );
    this.setState({ ...this.state, showProcessingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  sendEmail = peopleIds => {
    this.props.emailStatisticalReport(
      REPORT_TYPE_SINGLE_VARIETY,
      this.props.filteredFieldVarietyList,
      this.props.filteredTrialVarietyList,
      this.props.hideList,
      [this.props.varietyId],
      peopleIds,
      { orderByWetDate: this.props.wetDateSearch }
    );
    this.closeEmailDrawer();
  };

  fetchCompaniesForEmail = (
    varieties,
    filteredTrialAndFieldVarieties
  ) => () => {
    const { trials } = this.props.trials;
    const filteredTrials = [];
    filteredTrialAndFieldVarieties.forEach(obj => {
      if (trials[obj.trialId]) {
        filteredTrials.push(trials[obj.trialId]);
      }
    });
    fetchEmailDrawerCompanies(
      varieties,
      filteredTrials,
      this.props.fetchCompaniesById
    );
    this.setState({ ...this.state, showEmailDrawer: true });
  };

  closeEmailDrawer = () => {
    this.setState({ ...this.state, showEmailDrawer: false });
  };

  createDate = str => new Date(str);

  orderColumns = records => {
    const { trials } = this.props.trials;
    let convertDate = this.props.excludeYear
      ? dateStrToNumber
      : this.createDate;
    const orderByWetDate = this.props.wetDateSearch;
    return records.sort((a, b) => {
      let dateA = null;
      let dateB = null;
      if (orderByWetDate) {
        const trialA = trials[a.trialId] || {};
        dateA = convertDate(trialA.wetDate);
        const trialB = trials[b.trialId] || {};
        dateB = convertDate(trialB.wetDate);
      } else {
        dateA = convertDate(a.maturityDate);
        dateB = convertDate(b.maturityDate);
      }
      if (dateA > dateB) {
        return 1;
      }
      if (dateA < dateB) {
        return -1;
      }
      return 0;
    });
  };

  formatFieldVarietiesForGraph = trialVarieties =>
    trialVarieties
      .filter(trialVariety =>
        trialVariety.score !== null && trialVariety.maturityDate ? true : false
      )
      .map(trialVariety => ({
        x: graphFormatDateStr(trialVariety.maturityDate),
        y: trialVariety.score
      }));

  combineTrialAndFieldVarieties = () => {
    const trialVarieties = Object.values(this.props.filteredTrialVarietyList);
    const fieldVarieties = Object.values(this.props.filteredFieldVarietyList);
    const combinedTrialAndFieldVarieties = trialVarieties.concat(
      fieldVarieties
    );
    return filterEmptyObjects(combinedTrialAndFieldVarieties);
  };

  compileData = () => {
    // go through all field varieties and see what its variety is
    const { varieties } = this.props.varieties;
    const variety = varieties[this.props.varietyId] || {};

    const fieldVarietiesScoresHash = {};
    Object.values(this.props.filteredFieldVarietyList).forEach(fv => {
      // as long as a trial variety has this field variety, it should be shown
      // not sure how to check for this without a full loop
      fieldVarietiesScoresHash[fv.id] = fv;
    });

    const { fieldVarieties } = this.props.fieldVarieties;
    const trialVarieties = {};
    Object.values(this.props.filteredTrialVarietyList).forEach(tv => {
      if (tv.variety === variety.id) {
        trialVarieties[tv.id] = tv;
      }
      // get TrialVariety's field variety
      const fieldVariety = fieldVarieties[tv.fieldVariety];
      if (fieldVariety) {
        fieldVarietiesScoresHash[fieldVariety.id] = fieldVariety;
      }
    });

    const fieldVarietyScores = this.formatFieldVarietiesForGraph(
      Object.values(fieldVarietiesScoresHash)
    );
    const weightedScores = this.formatFieldVarietiesForGraph(
      Object.values(trialVarieties)
    );
    return [
      {
        id: "Field Variety Scores",
        data: fieldVarietyScores
      },
      {
        id: "Weighted Scores",
        data: weightedScores
      }
    ];
  };

  render() {
    const { status } = this.props.reports;
    const combinedTrialAndFieldVarieties = this.combineTrialAndFieldVarieties();
    const graphData = this.compileData();
    const { showProcessingReport, showEmailDrawer } = this.state;
    const { varieties } = this.props.varieties;
    const variety = varieties[this.props.varietyId] || {};
    let processingReport = showProcessingReport;
    if (status === "completed" || status === "failed") {
      processingReport = false;
    }
    return (
      <Navbar currentPage="reports">
        <Grid container spacing={2}>
          <HeaderInfo
            fetchCompaniesForEmail={this.fetchCompaniesForEmail(
              [variety],
              combinedTrialAndFieldVarieties
            )}
            downloadExcel={this.downloadExcel}
            downloadPDF={this.downloadPDF}
            toggleFilterDrawer={this.props.toggleFilterDrawer}
            variety={variety}
            processingReport={processingReport}
          />
          {showProcessingReport && (
            <ReportProcessing
              checkReportStatusCall={this.props.checkStatisticalReportStatus}
            />
          )}
          {Object.values(combinedTrialAndFieldVarieties).length === 0 ? (
            <NoEvaluations />
          ) : (
            <React.Fragment>
              <GraphView data={graphData} />
              <ReportColumns
                variety={variety}
                combinedTrialAndFieldVarieties={this.orderColumns(
                  combinedTrialAndFieldVarieties
                )}
                hideList={this.props.hideList}
              />
            </React.Fragment>
          )}
        </Grid>

        <ReportEmailDrawer
          sendEmail={this.sendEmail}
          showDrawer={showEmailDrawer}
          closeDrawer={this.closeEmailDrawer}
        />
      </Navbar>
    );
  }
}

ReportSingle.propTypes = {
  filteredTrialVarietyList: PropTypes.object.isRequired,
  hideList: PropTypes.object.isRequired,
  filteredFieldVarieties: PropTypes.array,
  varietyId: PropTypes.string,
  toggleFilterDrawer: PropTypes.func
};

const mapStateToProps = ({ fieldVarieties, reports, trials, varieties }) => ({
  fieldVarieties,
  reports,
  trials,
  varieties
});
export default connect(mapStateToProps, {
  submitStatisticalReport,
  submitExcelReport,
  fetchPeopleAssociatedWith,
  checkStatisticalReportStatus,
  emailStatisticalReport,
  updateCheckReportStatus,
  fetchCompaniesById
})(ReportSingle);
