import {
  FETCH_SUPPLIERS,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_SUPPLIERS_FAILURE,
  FETCH_SUPPLIER,
  FETCH_SUPPLIER_SUCCESS,
  FETCH_SUPPLIER_FAILURE
} from "../actions/suppliers";
import { FETCH_TRIAL_SUCCESS } from "../actions/trials";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  suppliers: {},
  supplier: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1
};

export default function(state = INITIAL_STATE, action) {
  let company, suppliers;
  switch (action.type) {
    case FETCH_TRIAL_SUCCESS:
      suppliers = combine(state.suppliers, action.data.companies);
      return { ...state, suppliers: suppliers };
    case FETCH_SUPPLIERS:
      return { ...state, suppliers: {}, error: null, waiting: true };
    case FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.data.companies || {},
        error: null,
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_SUPPLIERS_FAILURE:
      return { ...state, suppliers: {}, error: action.error, waiting: false };
    case FETCH_SUPPLIER:
      return { ...state, supplier: {}, error: null, waiting: true };
    case FETCH_SUPPLIER_SUCCESS:
      company = getSingleEntity(action.data.company);
      return { ...state, supplier: company || {}, error: null, waiting: false };
    case FETCH_SUPPLIER_FAILURE:
      return { ...state, supplier: {}, error: action.error, waiting: false };
    default:
      return state;
  }
}
