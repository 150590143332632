import { CALL_API, Schemas } from "../middleware/api";
import { isTypeFieldVariety } from "../helpers";

export const FETCH_EVALUATIONS = "FETCH_EVALUATIONS";
export const FETCH_EVALUATIONS_SUCCESS = "FETCH_EVALUATIONS_SUCCESS";
export const FETCH_EVALUATIONS_FAILURE = "FETCH_EVALUATIONS_FAILURE";

export const FETCH_EVALUATIONS_PAGINATION = "FETCH_EVALUATIONS_PAGINATION";
export const FETCH_EVALUATIONS_PAGINATION_SUCCESS =
  "FETCH_EVALUATIONS_PAGINATION_SUCCESS";
export const FETCH_EVALUATIONS_PAGINATION_FAILURE =
  "FETCH_EVALUATIONS_PAGINATION_FAILURE";

export const SAVE_EVALUATIONS = "SAVE_EVALUATIONS";
export const SAVE_EVALUATIONS_SUCCESS = "SAVE_EVALUATIONS_SUCCESS";
export const SAVE_EVALUATIONS_FAILURE = "SAVE_EVALUATIONS_FAILURE";

export const UPDATE_EVALUATION_LOCALLY = "UPDATE_EVALUATION_LOCALLY";

export const fetchEvaluations = (type, parentId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_EVALUATIONS,
        FETCH_EVALUATIONS_SUCCESS,
        FETCH_EVALUATIONS_FAILURE
      ],
      method: "get",
      endpoint: generateUrl(type, parentId),
      schema: Schemas.EVALUATIONS
    }
  });

export const fetchEvaluationsByVarietyIds = varietyIds => (
  dispatch,
  getState
) => {
  return dispatch(paginateCallApiObject(varietyIds, 1)).then(
    succcess => {
      checkIfMorePagesExist(varietyIds, succcess["meta"], dispatch);
    },
    error => {
      console.log("FETCH_EVALUATIONS_PAGINATION_FAILURE");
    }
  );
};

const fetchMoreEvaluationsByVarietyIds = (varietyIds, nextPage) => (
  dispatch,
  getState
) => {
  return dispatch(paginateCallApiObject(varietyIds, nextPage)).then(
    succcess => {
      checkIfMorePagesExist(varietyIds, succcess["meta"], dispatch);
    },
    error => {
      console.log("FETCH_EVALUATIONS_PAGINATION_FAILURE");
    }
  );
};

const checkIfMorePagesExist = (varietyIds, meta, dispatch) => {
  if (meta && meta["totalPages"] > meta["currentPage"]) {
    dispatch(
      fetchMoreEvaluationsByVarietyIds(varietyIds, meta["currentPage"] + 1)
    );
  }
};

const paginateCallApiObject = (varietyIds, currentPage) => ({
  [CALL_API]: {
    types: [
      FETCH_EVALUATIONS_PAGINATION,
      FETCH_EVALUATIONS_PAGINATION_SUCCESS,
      FETCH_EVALUATIONS_PAGINATION_FAILURE
    ],
    method: "get",
    endpoint: `/evaluations/by_variety_ids?ids=${varietyIds.join(
      ","
    )}&page=${currentPage}`,
    schema: Schemas.EVALUATIONS
  }
});

export const updateEvaluationLocally = (id, data) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_EVALUATION_LOCALLY,
    id: id,
    data: data
  });

export const saveEvaluations = (type, parentId) => (dispatch, getState) => {
  const { evaluations } = getState().evaluations;
  const evaluableType = isTypeFieldVariety(type)
    ? "FieldVariety"
    : "TrialVariety";

  const relaventEvaluations = {};
  Object.values(evaluations).forEach(evaluation => {
    if (
      evaluation.evaluableId === parentId &&
      evaluation.evaluableType === evaluableType
    ) {
      relaventEvaluations[evaluation.key] = evaluation;
    }
  });
  return dispatch({
    [CALL_API]: {
      types: [
        SAVE_EVALUATIONS,
        SAVE_EVALUATIONS_SUCCESS,
        SAVE_EVALUATIONS_FAILURE
      ],
      method: "post",
      endpoint: generateUrl(type, parentId),
      schema: Schemas.EVALUATIONS,
      data: { evaluations: Object.values(relaventEvaluations) }
    }
  });
};

const generateUrl = (type, parentId) => {
  let url = "/evaluations";
  if (type === "fieldVarieties") {
    url += `?field_variety_id=${parentId}`;
  } else {
    url += `?trial_variety_id=${parentId}`;
  }
  return url;
};
