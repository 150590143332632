import React, { Component } from "react";
import { Navbar } from "../containers";
import { NurseriesList } from "../containers/nurseries";

class Nurseries extends Component {
  render() {
    return (
      <Navbar currentPage="nurseries">
        <NurseriesList />
      </Navbar>
    );
  }
}

export default Nurseries;
