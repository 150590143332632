import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export const DrawerColumnDisplay = ({ records, ...props }) =>
  records.map(record => (
    <FormControlLabel
      key={record.id}
      control={
        <Checkbox
          checked={!props.filters[record.id]}
          onChange={props.handleChange(record.id)}
        />
      }
      label={record.name}
    />
  ));

DrawerColumnDisplay.propTypes = {
  records: PropTypes.array,
  filters: PropTypes.object,
  handleChange: PropTypes.func
};
