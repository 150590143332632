import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class SupplierReportSelect extends Component {
  getSupplier = supplierId => {
    const { companies } = this.props.companies;
    return companies[supplierId] || {};
  };
  render() {
    const {
      supplierIds,
      supplierChange,
      selectedSupplier,
      classes
    } = this.props;
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} className={classes.selectGrid}>
        <InputLabel shrink htmlFor="supplier-label">
          Choose Supplier
        </InputLabel>
        <Select fullWidth value={selectedSupplier} onChange={supplierChange}>
          <MenuItem value={""}>
            <em>None</em>
          </MenuItem>
          {supplierIds.map(supplierId => {
            const supplier = this.getSupplier(supplierId);
            return (
              <MenuItem key={supplierId} value={supplierId}>
                {supplier.name}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    );
  }
}

SupplierReportSelect.propTypes = {
  supplierIds: PropTypes.array,
  supplierChange: PropTypes.func.isRequired
};

const styles = theme => ({
  selectGrid: {
    marginBottom: 20
  }
});
const mapStateToProps = ({ companies }) => ({ companies });
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(SupplierReportSelect));
