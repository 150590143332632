import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCompany } from "../actions/companies";
import { fetchRegions } from "../actions/regions";
import { fetchCompanyCategories } from "../actions/company_categories";

import { LoadingSpinner } from "../ui";
import { Navbar } from "../containers";
import { CompanyEdit } from "../containers/companies";

import Grid from "@material-ui/core/Grid";

class Company extends Component {
  componentDidMount() {
    this.props.fetchCompany(this.props.match.params.id);
    this.props.fetchRegions();
    this.props.fetchCompanyCategories();
  }

  renderCompany() {
    const { waiting } = this.props.companies;
    const { regions, waiting: regionsWaiting } = this.props.regions;
    const {
      companyCategories,
      waiting: companyCategoriesWaiting
    } = this.props.companyCategories;

    if (waiting || regionsWaiting || companyCategoriesWaiting) {
      return <LoadingSpinner />;
    }
    return (
      <CompanyEdit
        id={this.props.match.params.id}
        update={true}
        regions={regions}
        companyCategories={companyCategories}
      />
    );
  }

  render() {
    return (
      <Navbar currentPage="companies">
        <Grid container>{this.renderCompany()}</Grid>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ companies, companyCategories, regions }) => ({
  companies,
  companyCategories,
  regions
});

export default connect(
  mapStateToProps,
  {
    fetchRegions,
    fetchCompany,
    fetchCompanyCategories
  }
)(Company);
