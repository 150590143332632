import React from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";

import VarietyHit from "./VarietyHit";
import VarietyCombineHit from "./VarietyCombineHit";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const VarietyLoadMore = ({ hits, hasMore, refine, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={props.classes.tableRoot}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Supplier
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Commodity
                  </Typography>
                </TableCell>
                {props.combine === true && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {hits.map(hit => {
                if (
                  props.combine === true &&
                  !props.experiementalVarieties
                    .map(exp => `${exp.id}`)
                    .includes(hit.objectID) &&
                  `${props.variety.id}` !== hit.objectID
                ) {
                  return (
                    <VarietyCombineHit
                      key={hit.objectID}
                      hit={hit}
                      handleToggle={props.handleToggle}
                      checked={props.checked}
                    />
                  );
                } else if (props.combine !== true) {
                  return <VarietyHit key={hit.objectID} hit={hit} />;
                }
                return null;
              })}
            </TableBody>
          </Table>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          fullWidth
          onClick={refine}
          disabled={!hasMore}
          className={props.classes.button}
        >
          Show more results
        </Button>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  button: {
    cursor: "pointer"
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  progressGrid: {
    marginTop: 40
  }
});

export default connectInfiniteHits(withStyles(styles)(VarietyLoadMore));
