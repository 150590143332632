import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

class EvaluationReportCard extends Component {
  getCriteriumLocation = criteriumId => {
    const { criteria } = this.props.criteria;
    return (criteria[criteriumId] || {}).location;
  };

  getCriteriumName = criteriumId => {
    const { criteria } = this.props.criteria;
    return (criteria[criteriumId] || {}).name;
  };

  getSubcriteriumName = subcriteriumId => {
    const { subcriteria } = this.props.subcriteria;
    return (subcriteria[subcriteriumId] || {}).name;
  };

  render() {
    const { evaluations, color, classes } = this.props;
    return (
      <Card style={{ backgroundColor: color }}>
        <CardContent>
          <Typography variant="subtitle1">Evaluation</Typography>
          {evaluations
            .sort((a, b) => {
              let locationA = this.getCriteriumLocation(a.criterium);
              let locationB = this.getCriteriumLocation(b.criterium);
              if (locationA < locationB) {
                return -1;
              } else if (locationA > locationB) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((e, index) => (
              <ListItem key={index} className={classes.evaluationListItem}>
                <ListItemText
                  className={classes.evaluationListItem}
                  secondary={`${this.getCriteriumName(
                    e.criterium
                  )} : ${this.getSubcriteriumName(e.subcriterium)}`}
                />
              </ListItem>
            ))}
        </CardContent>
      </Card>
    );
  }
}

const styles = theme => ({
  evaluationListItem: {
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  }
});

const mapStateToProps = ({ criteria, subcriteria }) => ({
  criteria,
  subcriteria
});
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(EvaluationReportCard));
