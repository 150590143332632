import React, { Component } from "react";
import { connect } from "react-redux";

import { MultiEvaluationDisplay } from "./views";
import { toggleMultiEvaluationLocally } from "../../actions/multi_evaluations";
import { isTypeFieldVariety } from "../../helpers/utils";

class MultiEvaluation extends Component {
  _getRecordId = () => {
    let recordId = "";
    if (isTypeFieldVariety(this.props.type)) {
      const { fieldVariety } = this.props.fieldVarieties;
      recordId = fieldVariety.id;
    } else {
      const { trialVariety } = this.props.trialVarieties;
      recordId = trialVariety.id;
    }
    return recordId;
  };

  handleChange = e => {
    const tagIds = e.target.value;
    const recordId = this._getRecordId();
    this.props.toggleMultiEvaluationLocally(
      recordId,
      this.props.labelId,
      tagIds
    );
  };

  selectedTags = label => {
    const { multiEvaluations } = this.props.multiEvaluations;
    const recordId = this._getRecordId();
    const selectedTags = {};
    label.tags.forEach(tagId => {
      if (multiEvaluations[`${recordId}-${label.id}-${tagId}`]) {
        selectedTags[tagId] = true;
      }
    });
    return Object.keys(selectedTags).map(tagId => parseInt(tagId, 10));
  };

  sortTags = label => {
    const { commodities } = this.props.commodities;
    const commodity = commodities[this.props.commodityId];
    const { tags } = this.props.tags;
    if (commodity.tags === undefined) {
      return [];
    }
    return commodity.tags
      .map(tagId => tags[tagId])
      .sort((tagA, tagB) => tagA.location - tagB.location)
      .filter(tag => tag.label === label.id);
  };

  render() {
    const { labels } = this.props.labels;
    const label = labels[this.props.labelId];
    const selectedTags = this.selectedTags(label);
    const tags = this.sortTags(label);
    return (
      <MultiEvaluationDisplay
        label={label}
        tags={tags}
        selectedTags={selectedTags}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = ({
  commodities,
  fieldVarieties,
  labels,
  multiEvaluations,
  tags,
  trialVarieties
}) => ({
  commodities,
  fieldVarieties,
  labels,
  multiEvaluations,
  tags,
  trialVarieties
});

export default connect(mapStateToProps, {
  toggleMultiEvaluationLocally
})(MultiEvaluation);
