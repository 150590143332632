import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

import CompaniesMultipleSelect from "../companies/CompaniesMultipleSelect";
import RegionsMultipleSelect from "../regions/RegionsMultipleSelect";

import { DatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const ReportSearchBox = ({
  currentRefinement,
  isSearchStalled,
  refine,
  state,
  handleChange,
  handleDateChange,
  handleCheckChange,
  handleMultipleSelect,
  handleRegionSelect,
  ...props
}) => (
  <Grid container spacing={2} className={props.classes.searchContainer}>
    <Grid item xs={12} md={5}>
      <TextField
        id="input-with-icon-textfield"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
        type="search"
        autoFocus={true}
        value={currentRefinement}
        onChange={event => handleChange(event.currentTarget.value, refine)}
      />
    </Grid>
    <Grid item xs={12} sm={2} md={2}>
      <FormControlLabel
        control={
          <Switch
            checked={state.excludeYear}
            onChange={handleCheckChange("excludeYear")}
            value="excludeYear"
            color="primary"
          />
        }
        label="Exclude Years"
      />
    </Grid>
    <Grid item xs={12} sm={10} md={5}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CompaniesMultipleSelect
            label={"Growers"}
            placeholder={"Select Growers"}
            setCompanies={handleMultipleSelect("growers")}
            initialItems={state.growers}
            companyCategory={null} //Grower
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompaniesMultipleSelect
            label={"Shippers"}
            placeholder={"Select Shippers"}
            setCompanies={handleMultipleSelect("shippers")}
            initialItems={state.shippers}
            companyCategory={null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompaniesMultipleSelect
            initialItems={state.suppliers}
            label={"Suppliers"}
            placeholder={"Select Supplier"}
            setCompanies={handleMultipleSelect("suppliers")}
            companyCategory={null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RegionsMultipleSelect
            setRegions={handleRegionSelect}
            initialItems={state.regions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={state.startWetDate || null}
            onChange={handleDateChange("startWetDate")}
            fullWidth
            clearable
            format={"MMMM Do, YYYY"}
            label="Start Wet Date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={state.endWetDate || null}
            onChange={handleDateChange("endWetDate")}
            fullWidth
            clearable
            format={"MMMM Do, YYYY"}
            label="End Wet Date"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const styles = theme => ({
  searchContainer: {
    marginBottom: 20
  }
});

export default connectSearchBox(withStyles(styles)(ReportSearchBox));
