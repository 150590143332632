import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { SignInForm } from "../containers";

class SignIn extends Component {
  render() {
    if (this.props.authenticated) {
      return <Redirect push to="/" />;
    }

    return <SignInForm />;
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.user.authenticated,
    errorMsg: state.user.error
  };
};

export default connect(
  mapStateToProps,
  null
)(SignIn);
