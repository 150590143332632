import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { getMonthName } from "../../helpers/utils";
import { getColor, getAllColors } from "../../helpers/colors";
import { line } from "d3-shape";
import { GraphViewDisplay } from "./views";

import Typography from "@material-ui/core/Typography";

const getMonthAverages = points => {
  const months = {};
  points.forEach(point => {
    const date = point.data.x;
    const month = date.getMonth();
    if (months[month]) {
      months[month].push(point.data.y);
    } else {
      months[month] = [point.data.y];
    }
  });
  const monthAverages = {};
  Object.keys(months).forEach(monthKey => {
    const total = months[monthKey].reduce((total, num) => total + num);
    monthAverages[monthKey] = total / months[monthKey].length;
  });
  return monthAverages;
};

const LineOfAverages = (color, idName) => ({ points, xScale, yScale }) => {
  const filteredPoints = points.filter(point =>
    point.id.includes(idName) ? true : false
  );
  const monthAverages = getMonthAverages(filteredPoints);
  if (Object.keys(monthAverages).length <= 1) {
    return;
  }
  const lineGenerator = line()
    .x(d => {
      const date = d.data.x;
      date.setDate(15);
      return xScale(date);
    })
    .y(d => {
      const date = d.data.x;
      return yScale(monthAverages[date.getMonth()]);
    });

  const circlePoints = filteredPoints.map(point => {
    const date = point.data.x;
    date.setDate(15);
    const y = monthAverages[date.getMonth()];
    return { x: xScale(date), y: yScale(y) };
  });
  const sortedFilteredPoints = filteredPoints.sort(
    (pointA, pointB) => pointA.x - pointB.x
  );
  return (
    <Fragment>
      <path
        d={lineGenerator(sortedFilteredPoints)}
        fill="none"
        style={{ pointerEvents: "none" }}
        stroke={color}
      />
      {circlePoints.map((point, index) => (
        <circle
          key={index}
          r={5}
          strokeWidth={2}
          cx={point.x}
          cy={point.y}
          fill="white"
          stroke={color}
          style={{ pointerEvents: "none" }}
        />
      ))}
    </Fragment>
  );
};

// const getScale = (data) => {
//   const minDate = new Date;
//   minDate.setDate(1);
//   minDate.setMonth(0);
//   const maxDate = new Date;
//   maxDate.setMonth(11);
//   maxDate.setDate(1);
//   return {min: minDate, max: maxDate}
// }

class GraphView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 220
    };
  }

  updateDimensions() {
    if (window.innerHeight < 400) {
      this.setState({ height: 220 });
    } else {
      const height = Math.round(window.innerHeight / 1.7);
      this.setState({ height: height });
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  tooltip = data => {
    const y = Math.round(data.y * 100) / 100;
    const date = new Date(data.x);
    return (
      <Typography variant="body1" gutterBottom>
        {getMonthName(date.getMonth())}, {y}
      </Typography>
    );
  };

  createLayers = () => {
    const lines = this.props.data.map((dataGroup, index) => {
      const color = getColor(index);
      return LineOfAverages(color, dataGroup.id);
    });
    let layers = [];
    layers = layers.concat(["grid", "axes", "points"]);
    layers = layers.concat(lines);
    layers = layers.concat(["markers", "mesh", "legends"]);
    return layers;
  };

  render() {
    let marginHeight = 50;
    if (this.props.data.length > 2) {
      marginHeight = this.props.data.length * 25;
    }
    const layers = this.createLayers();
    const colors = getAllColors();
    // const scale = getScale(this.props.data)
    return (
      <GraphViewDisplay
        data={this.props.data}
        tooltip={this.tooltip}
        height={this.state.height}
        marginHeight={marginHeight}
        colors={colors}
        layers={layers}
      />
    );
  }
}

GraphView.propTypes = {
  data: PropTypes.array
};

export default GraphView;
