import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchVariety } from "../actions/varieties";
import { LoadingSpinner } from "../ui";
import { Navbar } from "../containers";
import { VarietyCombineEdit } from "../containers/varieties";

class VarietyCombine extends Component {
  componentDidMount() {
    this.props.fetchVariety(this.props.match.params.id);
  }

  render() {
    const { waiting } = this.props.varieties;
    return (
      <Navbar currentPage="varieties">
        {waiting ? <LoadingSpinner /> : <VarietyCombineEdit />}
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    varieties: state.varieties
  };
};

export default connect(
  mapStateToProps,
  {
    fetchVariety
  }
)(VarietyCombine);
