import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateEvaluationLocally } from "../../actions/evaluations";
import { updateFieldVarietyLocally } from "../../actions/field_varieties";
import { updateTrialVarietyLocally } from "../../actions/trial_varieties";
import { isTypeFieldVariety } from "../../helpers/utils";
import { SubcriteriumSelect } from "./views";

class SubcriteriumEvaluation extends Component {
  getRecordId = () => {
    let recordId = null;
    if (isTypeFieldVariety(this.props.type)) {
      const { fieldVariety } = this.props.fieldVarieties;
      recordId = fieldVariety.id;
    } else {
      const { trialVariety } = this.props.trialVarieties;
      recordId = trialVariety.id;
    }
    return recordId;
  };

  createEvaluationKey = () => {
    const recordId = this.getRecordId();
    return `${recordId}-${this.props.commodityId}-${this.props.criteriumId}`;
  };

  updateRecordEvaluation = evaluationKey => {
    if (isTypeFieldVariety(this.props.type)) {
      const { fieldVariety } = this.props.fieldVarieties;
      const evaluations = fieldVariety.evaluations;
      if (!evaluations.includes(evaluationKey)) {
        evaluations.push(evaluationKey);
        this.props.updateFieldVarietyLocally(
          fieldVariety.id,
          "evaluations",
          evaluations
        );
      }
    } else {
      const { trialVariety } = this.props.trialVarieties;
      const evaluations = trialVariety.evaluations;
      if (!evaluations.includes(evaluationKey)) {
        evaluations.push(evaluationKey);
        this.props.updateTrialVarietyLocally(
          trialVariety.id,
          "evaluations",
          evaluations
        );
      }
    }
  };

  handleChange = criteriumId => e => {
    const key = this.createEvaluationKey();
    const subcriteriumId = e.target.value;

    const evaluableType = isTypeFieldVariety(this.props.type)
      ? "FieldVariety"
      : "TrialVariety";
    const evaluableId = this.getRecordId();
    const data = {
      commodityId: this.props.commodityId,
      criteriumId: this.props.criteriumId,
      subcriteriumId: subcriteriumId,
      evaluableId: evaluableId,
      evaluableType: evaluableType,
      key: key
    };
    this.props.updateEvaluationLocally(key, data);
    this.updateRecordEvaluation(key);
  };

  render() {
    const { criteria } = this.props.criteria;
    const { subcriteria } = this.props.subcriteria;
    const { evaluations } = this.props.evaluations;

    const criterium = criteria[this.props.criteriumId];
    if (!criterium) {
      return <div />;
    }

    const evaluationKey = this.createEvaluationKey();
    const evaluation = evaluations[evaluationKey] || {};
    const choosenSubcriteriumId = evaluation.subcriteriumId || "";

    if(criterium.archived && !evaluations[evaluationKey]){
      return <div />;
    }

    const shownSubcriteria = criterium.subcriteria.filter(subcriteriumId => {
      const subcriterium = subcriteria[subcriteriumId];
      if(subcriterium.archived && subcriteriumId !== choosenSubcriteriumId){
        return false;
      }
      return true;
    })

    return (
      <SubcriteriumSelect
        criterium={criterium}
        subcriteria={subcriteria}
        choosenSubcriteriumId={choosenSubcriteriumId}
        handleChange={this.handleChange}
        shownSubcriteria={shownSubcriteria}
      />
    );
  }
}

SubcriteriumEvaluation.propTypes = {
  type: PropTypes.string.isRequired,
  commodityId: PropTypes.number.isRequired,
  criteriumId: PropTypes.number.isRequired
};

const mapStateToProps = ({
  criteria,
  evaluations,
  fieldVarieties,
  subcriteria,
  trialVarieties
}) => ({ criteria, evaluations, fieldVarieties, subcriteria, trialVarieties });

export default connect(mapStateToProps, {
  updateEvaluationLocally,
  updateTrialVarietyLocally,
  updateFieldVarietyLocally
})(SubcriteriumEvaluation);
