import React, { Component } from "react";
import DownshiftSingle from "../DownshiftSingle";
import { connect } from "react-redux";
import { fetchShipper, fetchShippers } from "../../actions/shippers";

class ShipperSelect extends Component {
  render() {
    const { classes } = this.props;
    const { shippers, waiting } = this.props.shippers;
    const results = shippers || {};

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Shipper"}
        label={"Shipper"}
        classes={classes}
        search={this.props.fetchShippers}
        results={results}
        initialItem={this.props.shipper}
        selectedItemParent={this.props.setShipper}
      />
    );
  }
}

const mapStateToProps = ({ shippers }) => ({ shippers });
export default connect(
  mapStateToProps,
  {
    fetchShipper,
    fetchShippers
  }
)(ShipperSelect);
