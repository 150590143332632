import { CALL_API, Schemas } from '../middleware/api';
import { fetchNurseryPlotBeds, removePlotBedsLocally } from './plot_beds';

export const FETCH_NURSE_VARIETIES = 'FETCH_NURSE_VARIETIES';
export const FETCH_NURSE_VARIETIES_SUCCESS = 'FETCH_NURSE_VARIETIES_SUCCESS';
export const FETCH_NURSE_VARIETIES_FAILURE = 'FETCH_NURSE_VARIETIES_FAILURE';

export const SET_NURSE_VARIETY_LOCALLY = 'SET_NURSE_VARIETY_LOCALLY';
export const REMOVE_NURSE_VARIETY_LOCALLY = 'REMOVE_NURSE_VARIETY_LOCALLY';

export const CREATE_NURSE_VARIETY = 'CREATE_NURSE_VARIETY';
export const CREATE_NURSE_VARIETY_SUCCESS = 'CREATE_NURSE_VARIETY_SUCCESS';
export const CREATE_NURSE_VARIETY_FAILURE = 'CREATE_NURSE_VARIETY_FAILURE';

export const DELETE_NURSE_VARIETY = 'DELETE_NURSE_VARIETY';
export const DELETE_NURSE_VARIETY_SUCCESS = 'DELETE_NURSE_VARIETY_SUCCESS';
export const DELETE_NURSE_VARIETY_FAILURE = 'DELETE_NURSE_VARIETY_FAILURE';

export const fetchNurseVarieties = (nurseryId) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [FETCH_NURSE_VARIETIES, FETCH_NURSE_VARIETIES_SUCCESS, FETCH_NURSE_VARIETIES_FAILURE],
      method: 'get',
      endpoint: `/nurseries/${nurseryId}/nurse_varieties`,
      schema: Schemas.NURSE_VARIETIES
    }
  })
)

export const setNurseVarietyLocally = (id) => (dispatch, getState) => (
  dispatch({
    type: SET_NURSE_VARIETY_LOCALLY,
    id: id
  })
)

export const createNurseVariety = (nurseryId, varietyId) => (dispatch, getState) => {
  const { selected } = getState().plotBeds;
  return dispatch({
    [CALL_API]: {
      types: [CREATE_NURSE_VARIETY, CREATE_NURSE_VARIETY_SUCCESS, CREATE_NURSE_VARIETY_FAILURE ],
      method: 'post',
      endpoint: `/nurseries/${nurseryId}/nurse_varieties`,
      schema: Schemas.NURSE_VARIETY,
      data: { nurseVarieties: { varietyId: varietyId }, newPlotBeds: Object.values(selected) }
    }
  }).then(() => {
    dispatch(fetchNurseryPlotBeds(nurseryId));
    dispatch(fetchNurseVarieties(nurseryId));
  })
}

export const deleteNurseVariety = (nurseryId, id) => (dispatch, getState) => (
  dispatch({
    [CALL_API]: {
      types: [ DELETE_NURSE_VARIETY, DELETE_NURSE_VARIETY_SUCCESS, DELETE_NURSE_VARIETY_FAILURE ],
      method: 'delete',
      endpoint: `/nurseries/${nurseryId}/nurse_varieties/${id}`,
      schema: Schemas.TRIAL_VARIETIES,
    }
  }).then(() => {
    dispatch(removePlotBedsLocally(id));
    dispatch(removeNurseVarietyLocally(id));
  })
)

export const removeNurseVarietyLocally = (id) => (dispatch, getState) => (
  dispatch({
    type: REMOVE_NURSE_VARIETY_LOCALLY,
    id: id
  })
)
