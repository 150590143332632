import {
  FETCH_SHIPPER,
  FETCH_SHIPPER_SUCCESS,
  FETCH_SHIPPER_FAILURE,
  FETCH_SHIPPERS,
  FETCH_SHIPPERS_SUCCESS,
  FETCH_SHIPPERS_FAILURE
} from "../actions/shippers";
import { getSingleEntity } from "./util";

const INITIAL_STATE = {
  shippers: {},
  shpper: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1
};

export default function(state = INITIAL_STATE, action) {
  let company;
  switch (action.type) {
    case FETCH_SHIPPERS:
      return { ...state, shippers: {}, error: null, waiting: true };
    case FETCH_SHIPPERS_SUCCESS:
      return {
        ...state,
        shippers: action.data.companies || {},
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_SHIPPERS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_SHIPPER:
      return { ...state, shipper: {}, error: null, waiting: true };
    case FETCH_SHIPPER_SUCCESS:
      company = getSingleEntity(action.data.company);
      return { ...state, shipper: company, error: null, waiting: false };
    case FETCH_SHIPPER_FAILURE:
      return { ...state, shipper: {}, error: action.error, waiting: false };
    default:
      return state;
  }
}
