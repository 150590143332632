import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const ScrollableImageCard = ({ images, ...props }) => (
  <Card style={{ backgroundColor: props.color }}>
    <CardContent>
      {images.length > 0 ? (
        <GridList className={props.classes.gridList} cols={2.5}>
          {images.map(image => (
            <GridListTile key={image.id}>
              <img src={image.url} alt="Evaluation" />
              <GridListTileBar
                title=""
                classes={{
                  root: props.classes.titleBar,
                  title: props.classes.title
                }}
                actionIcon={<IconButton />}
              />
            </GridListTile>
          ))}
        </GridList>
      ) : (
        <Typography variant="subtitle1">No images</Typography>
      )}
    </CardContent>
  </Card>
);

ScrollableImageCard.propTypes = {
  images: PropTypes.array
};

const styles = theme => ({
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  }
});

export default withStyles(styles)(ScrollableImageCard);
