import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCheckReportStatus } from "../actions/reports";

import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const intervalCheck = 5000;

class ReportProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalTime: 0
    };
  }
  componentDidMount() {
    this.interval = setInterval(() => this._checkReportStatus(), intervalCheck);
  }
  componentWillUnmount() {
    this.props.updateCheckReportStatus(false);
    clearInterval(this.interval);
  }
  _checkReportStatus = () => {
    const { reportId, status, checkReportStatus } = this.props.reports;
    if (!checkReportStatus) {
      // do not check report status at this time
      return;
    } else if (status === "failed" || status === "completed") {
      this.props.updateCheckReportStatus(false);
    } else if (this.state.totalTime > 300000) {
      // 5 minutes
      //clearInterval(this.interval);
      // show some type of error
    } else {
      this.props.checkReportStatusCall(reportId);
    }
  };

  render() {
    const { status, error, link, percentageLeft } = this.props.reports;
    if (status === "failed") {
      return (
        <Grid item xs={12} className={this.props.classes.container}>
          <Typography variant="h4" gutterBottom>
            Error: {error}
          </Typography>
        </Grid>
      );
    } else if (status === "completed") {
      // display link to click on
      return (
        <Grid item xs={12} className={this.props.classes.container}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            Link to download file
          </a>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} className={this.props.classes.container}>
        {percentageLeft === 0 ? (
          <LinearProgress color={"primary"} />
        ) : (
          <LinearProgress
            color={"primary"}
            variant="determinate"
            value={percentageLeft}
          />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = ({ reports }) => ({ reports });

const styles = theme => ({
  container: {
    marginBottom: 10
  }
});

export default connect(
  mapStateToProps,
  { updateCheckReportStatus }
)(withStyles(styles)(ReportProcessing));
