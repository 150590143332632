import React, { Component } from "react";
import { connect } from "react-redux";
import { deletePerson, updateSearch } from "../../../actions/people";

import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";

import { PersonTable } from "./views";
import {
  IndexLinkButton,
  PageHeader,
  SimpleAlgoliaSearchBox
} from "../../../ui";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

class PeopleList extends Component {
  handleChange = (value, refine) => {
    this.props.updateSearch(value);
    refine(value);
  };

  render() {
    const { search } = this.props.people;
    return (
      <React.Fragment>
        <PageHeader title={"People"} half={7}>
          <IndexLinkButton linkUrl="/people/new" />
        </PageHeader>
        <InstantSearch indexName={`Person_${process.env.REACT_APP_NAME}`}  searchClient={searchClient}>
          <Configure hitsPerPage={25} />
          <SimpleAlgoliaSearchBox
            defualtRefinement={search}
            handleChange={this.handleChange}
          />
          <PersonTable onDelete={this.props.deletePerson} />
        </InstantSearch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    people: state.people
  };
};

export default connect(
  mapStateToProps,
  {
    deletePerson,
    updateSearch
  }
)(PeopleList);
