import { uniq } from "lodash";

export const REPORT_TYPE_EVALUATION = "evaluation";
export const REPORT_TYPE_PLOT_MAP = "plot_map";
export const REPORT_TYPE_SINGLE_VARIETY = "single_variety";
export const REPORT_TYPE_MULTIPLE_VARIETY = "multiple_variety";

export const allUniqueSuppliersFromVarieties = varieties => {
  const suppliers = {};
  varieties.forEach(variety => {
    const supplierId = variety.supplier;
    if (supplierId) {
      suppliers[supplierId] = supplierId;
    }
  });
  return Object.keys(suppliers);
};

export const filterVarietiesBySupplier = (supplierId, varieties) => {
  if (supplierId.length === 0) {
    return varieties;
  }
  const selectedSupplierId = parseInt(supplierId);
  return varieties.filter(variety => variety.supplier === selectedSupplierId);
};

export const fetchEmailDrawerPeople = (
  trial,
  selectedSupplierId,
  fetchPeopleAssociatedWith
) => {
  let regionId = null;
  if (trial.region) {
    regionId = trial.region.id;
  }
  let commodityId = null;
  if (trial.commodity) {
    commodityId = trial.commodity.id;
  }
  if (selectedSupplierId === "") {
    let growerIds = [];
    if (trial.grower && trial.grower.id) {
      growerIds.push(trial.grower.id);
    }
    let subGrowerIds = [];
    if (trial.subGrower && trial.subGrower.id) {
      subGrowerIds.push(trial.subGrower.id);
    }
    let shipperIds = [];
    if (trial.shipper && trial.shipper.id) {
      shipperIds.push(trial.shipper.id);
    }
    fetchPeopleAssociatedWith({
      regionId: regionId,
      commodityId: commodityId,
      growerIds: growerIds,
      subGrowerIds: subGrowerIds,
      shipperIds: shipperIds,
      culturalPractice: trial.culturalPractice
    });
  } else {
    let supplierIds = [];
    supplierIds.push(selectedSupplierId);
    fetchPeopleAssociatedWith({
      regionId: regionId,
      commodityId: commodityId,
      supplierIds: supplierIds,
      culturalPractice: trial.culturalPractice
    });
  }
};

export const fetchEmailDrawerCompanies = (
  varieties,
  trials,
  fetchCompaniesById
) => {
  const companies = [];
  varieties.forEach(variety => {
    if (variety.supplier) {
      companies.push(variety.supplier.id);
    }
  });
  // add growers and shippers
  trials.forEach(trial => {
    if (trial.grower && trial.grower.id) {
      companies.push(trial.grower.id);
    }
    if (trial.shipper && trial.shipper.id) {
      companies.push(trial.shipper.id);
    }
  });
  // compact to only have unique ids
  fetchCompaniesById(uniq(companies));
};
