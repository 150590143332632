import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  updateLabelLocally,
  deleteLabel,
  updateLabel
} from "../../../actions/labels";
import { createTag } from "../../../actions/tags";
import { LabelHeader, LabelButtons } from "./views";
import { TagDragAndDropArea } from "../../tags";
import { LoadingSpinner } from "../../../ui";

import Grid from "@material-ui/core/Grid";

class LabelEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { deleted: false };
  }
  handleChange = e => {
    this.props.updateLabelLocally(this.props.id, "name", e.target.value);
  };

  addTag = () => {
    this.props.createTag(this.props.id, { name: "" });
  };
  onSave = () => {
    const { labels } = this.props.labels;
    const label = labels[this.props.id];
    const { tags } = this.props.tags;
    this.props.updateLabel(this.props.id, label, tags);
  };
  onDelete = () => {
    this.setState({ ...this.state, deleted: true }, () => {
      this.props.deleteLabel(this.props.id);
    });
  };

  render() {
    const { labels, waiting, error } = this.props.labels;
    const label = labels[this.props.id];
    if (waiting || label === undefined) {
      return <LoadingSpinner />;
    }
    if (this.state.deleted && !error) {
      return <Redirect push to="/diseases" />;
    }
    return (
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 60
        }}
      >
        <LabelHeader label={label} handleChange={this.handleChange} />
        <Grid item xs={12}>
          <TagDragAndDropArea id={this.props.id} />
          <LabelButtons
            onSave={this.onSave}
            addTag={this.addTag}
            onDelete={this.onDelete}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ labels, tags }) => ({ labels, tags });
export default connect(mapStateToProps, {
  updateLabelLocally,
  updateLabel,
  deleteLabel,
  createTag
})(LabelEdit);
