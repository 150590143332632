import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  updateCompany,
  createCompany,
  updateCompanyLocally
} from "../../../actions/companies";
import { LoadingSpinner } from "../../../ui";
import { PeopleMultipleSelect } from "../../people";
import { CompanyButtons, CompanyEditableItems } from "./views";
import { Grid } from "@material-ui/core";

class CompanyEdit extends Component {
  constructor(props) {
    super(props);
    let header = "Create";
    if (props.update) {
      header = "Update";
    }
    this.state = {
      header: header,
      created: false
    };
  }

  handleChange = name => e => {
    const { company } = this.props.companies;
    this.props.updateCompanyLocally(company.id, name, e.target.value);
  };

  setPeople = people => {
    const personIds = people.map(person => person.id || person.objectID);
    const { company } = this.props.companies;
    this.props.updateCompanyLocally(company.id, "personIds", personIds);
    this.props.updateCompanyLocally(company.id, "people", people);
  };

  onSave = () => {
    const { company } = this.props.companies;
    if (this.props.update) {
      this.props.updateCompany(this.props.id, company);
    } else {
      this.props.createCompany(company);
      this.setState({ ...this.state, created: true });
    }
  };

  render() {
    const { company, error } = this.props.companies;
    const { companyCategories } = this.props.companyCategories;
    const { regions } = this.props.regions;
    const title = this.state.header + " Company";

    if (this.state.created && !company.id && !error) {
      return <LoadingSpinner />;
    }
    if (this.state.created && company.id) {
      return <Redirect push to="/companies" />;
    }
    return (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <CompanyEditableItems
          title={title}
          company={company}
          companyCategories={companyCategories}
          regions={regions}
          handleChange={this.handleChange}
        />
        <Grid item xs={12}>
          <PeopleMultipleSelect
            initialItems={company.people}
            setPeople={this.setPeople}
          />
        </Grid>
        <CompanyButtons onSave={this.onSave} />
      </Grid>
    );
  }
}

CompanyEdit.defaultProps = {
  update: false
};

const mapStateToProps = state => {
  return {
    companies: state.companies,
    people: state.people,
    companyCategories: state.companyCategories,
    regions: state.regions
  };
};

export default connect(mapStateToProps, {
  updateCompanyLocally,
  updateCompany,
  createCompany
})(CompanyEdit);
