import React, { Component } from "react";

import { Navbar } from "../containers";
import { CommoditiesList } from "../containers/commodities";

class Commodities extends Component {
  render() {
    return (
      <Navbar currentPage="commodities">
        <CommoditiesList />
      </Navbar>
    );
  }
}
export default Commodities;
