import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { CircleIcon } from "../helpers";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";

const PlotGridLayout = ({ rows, selectPlotBed, ...props }) => (
  <div>
    {rows.map((cols, rowIndex) => (
      <Grid item xs={12} key={rowIndex}>
        {cols.map((plot, colIndex) => (
          <IconButton
            key={plot.key}
            aria-label="Set Variety"
            className={props.classes.iconButton}
            onClick={() => selectPlotBed(plot.rowIndex, plot.colIndex)}
          >
            <CircleIcon
              key={plot.key}
              className={classNames(
                props.classes.circleIcon,
                props.classes[plot.color]
              )}
              style={{ fontSize: 40 }}
              value={plot.plotNumber ? plot.plotNumber : "-"}
            />
          </IconButton>
        ))}
      </Grid>
    ))}
  </div>
);

PlotGridLayout.propTypes = {
  rows: PropTypes.array.isRequired,
  selectPlotBed: PropTypes.func.isRequired
};
const styles = theme => ({
  circleIcon: {
    "border-radius": "0.4em",
    display: "inline-block",
    "line-height": "1.4em",
    "text-align": "center",
    width: "1.4em",
    color: "white",
    "&:hover": {
      background: red[800]
    }
  },
  iconButton: {
    padding: "7px"
  },
  selected: {
    background: red[200]
  },
  unselected: {
    background: theme.palette.primary.main
  }
});

export default withStyles(styles)(PlotGridLayout);
