import React from "react";
import PropTypes from "prop-types";

import TrialVarietyReport from "../TrialVarietyReport";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";
import PrintIcon from "@material-ui/icons/Print";
import ExcelIcon from "@material-ui/icons/Description";
import FilterIcon from "@material-ui/icons/FilterList";

const styles = theme => ({
  headerText: {
    marginTop: theme.headerText.marginTop
  },
  topButton: {
    marginTop: 25
  }
});

export const HeaderInfo = withStyles(styles)(
  ({
    fetchCompaniesForEmail,
    processingReport,
    downloadPDF,
    downloadExcel,
    toggleFilterDrawer,
    variety,
    ...props
  }) => (
    <React.Fragment>
      <Grid item xs={6}>
        <Typography
          variant="h2"
          gutterBottom
          className={props.classes.headerText}
        >
          {variety.name}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            onClick={fetchCompaniesForEmail}
          >
            <EmailIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            onClick={downloadExcel}
            disabled={processingReport}
          >
            <ExcelIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={props.classes.topButton}
            onClick={downloadPDF}
            disabled={processingReport}
          >
            <PrintIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            className={props.classes.filterButton}
            onClick={toggleFilterDrawer(true)}
          >
            <FilterIcon />
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
);

HeaderInfo.propTypes = {
  variety: PropTypes.object,
  toggleFilterDrawer: PropTypes.func,
  downloadPDF: PropTypes.func,
  filteredFieldVarieties: PropTypes.array
};

export const NoEvaluations = ({ ...props }) => (
  <Grid item xs={12}>
    <Grid container direction="row" justify="center" alignItems="center">
      <Typography variant="h4" gutterBottom>
        No Evaluations
      </Typography>
    </Grid>
  </Grid>
);

export const ReportColumns = ({
  variety,
  hideList,
  combinedTrialAndFieldVarieties,
  ...props
}) => (
  <React.Fragment>
    {combinedTrialAndFieldVarieties.map((trialVariety, index) => (
      <Grid item md={4} sm={6} xs={12} key={index}>
        <TrialVarietyReport
          record={trialVariety}
          varietyName={variety.name}
          hideList={hideList}
        />
      </Grid>
    ))}
  </React.Fragment>
);

ReportColumns.propTypes = {
  variety: PropTypes.object.isRequired,
  combinedTrialAndFieldVarieties: PropTypes.array.isRequired
};
