import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { createLabel, deleteLabel, fetchLabels } from "../../actions/labels";
import { IndexNewField } from "../index";
import { IndexHeaderButton, PageHeader, SimpleListName } from "../../ui";

class LabelsList extends Component {
  constructor(props) {
    super(props);
    this.state = { searchField: "", showNew: false, labelId: null };
  }
  componentWillMount() {
    this.props.fetchLabels(this.state.searchField);
  }

  toggleNew = () => {
    this.setState({ ...this.state, showNew: !this.state.showNew });
  };

  handleChange = e => {
    this.setState({ ...this.state, searchField: e.target.value }, () => {
      this.props.fetchLabels(this.state.searchField);
    });
  };

  onSave = async str => {
    const label = await this.props.createLabel(str);
    if (label !== null) {
      this.setState({
        labelId: label.id,
        fireRedirect: true
      });
    }
  };

  render() {
    const { labels, totalPages, page, waiting } = this.props.labels;
    const { fireRedirect, labelId } = this.state;
    if (!waiting && fireRedirect) {
      return <Redirect push to={`/diseases/${labelId}`} />;
    }
    return (
      <React.Fragment>
        <PageHeader title={"Diseases"} half={6}>
          <IndexHeaderButton create={this.toggleNew} />
        </PageHeader>
        {this.state.showNew && (
          <IndexNewField onSave={this.onSave} label={"Create Disease"} />
        )}
        <SimpleListName
          waiting={waiting}
          baseUrl={"diseases"}
          searchField={this.state.searchField}
          handleChange={this.handleChange}
          showMoreResults={this.showMoreResults}
          onDelete={this.props.deleteLabel}
          rows={labels}
          page={page}
          totalPages={totalPages}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ labels }) => ({ labels });
export default connect(mapStateToProps, {
  createLabel,
  deleteLabel,
  fetchLabels
})(LabelsList);
