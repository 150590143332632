import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3)
  }
});

export const EmailCompanyDrawer = withStyles(styles)(
  ({
    showDrawer,
    closeDrawer,
    listOfCompanies,
    checkedCompanies,
    handleCompanyToggle,
    togglePersonDisplay,
    ...props
  }) => (
    <Drawer anchor="bottom" open={showDrawer} onClose={closeDrawer}>
      <FormControl component="fieldset" className={props.classes.formControl}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                {listOfCompanies.map(company => (
                  <TableRow key={company.id}>
                    <TableCell align={"left"}>
                      <Checkbox
                        checked={checkedCompanies.indexOf(company.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        onClick={handleCompanyToggle(company.id)}
                      />
                    </TableCell>
                    <TableCell style={{ fontSize: 16, fontWeight: "600" }}>
                      {company.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl component="fieldset" className={props.classes.formControl}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              size="large"
              variant="contained"
              color="default"
              fullWidth
              onClick={closeDrawer}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={togglePersonDisplay(true)}
            >
              Show People
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Drawer>
  )
);

EmailCompanyDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired
};

export const EmailPeopleDrawer = withStyles(styles)(
  ({
    closeDrawer,
    showDrawer,
    listOfPeople,
    checkedPeople,
    handlePersonToggle,
    togglePersonDisplay,
    sendEmail,
    ...props
  }) => (
    <Drawer anchor="bottom" open={showDrawer} onClose={closeDrawer}>
      <FormControl component="fieldset" className={props.classes.formControl}>
        <Grid container spacing={2}>
          <Table>
            <TableBody>
              {listOfPeople.map(person => (
                <TableRow key={person.id}>
                  <TableCell align={"left"}>
                    <Checkbox
                      checked={checkedPeople.indexOf(person.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      onClick={handlePersonToggle(person.id)}
                    />
                  </TableCell>
                  <TableCell style={{ fontSize: 16, fontWeight: "600" }}>
                    {person.name}
                  </TableCell>
                  <TableCell>{person.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </FormControl>
      <FormControl component="fieldset" className={props.classes.formControl}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              size="large"
              variant="contained"
              color="default"
              fullWidth
              onClick={closeDrawer}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={togglePersonDisplay(false)}
            >
              Back to Companies
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={sendEmail}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Drawer>
  )
);
