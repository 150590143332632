import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";
import { ADD_CRITERIUM_SUBCRITERIUM_LOCALLY } from "./criteria";

export const CLEAR_SUBCRITERIA = "CLEAR_SUBCRITERIA";
export const UPDATE_SUBCRITERIUM_LOCALLY = "UPDATE_SUBCRITERIUM_LOCALLY";

export const FETCH_SUBCRITERIA = "FETCH_SUBCRITERIA";
export const FETCH_SUBCRITERIA_SUCCESS = "FETCH_SUBCRITERIA_SUCCESS";
export const FETCH_SUBCRITERIA_FAILURE = "FETCH_SUBCRITERIA_FAILURE";

export const FETCH_SUBCRITERIUM = "FETCH_SUBCRITERIUM";
export const FETCH_SUBCRITERIUM_SUCCESS = "FETCH_SUBCRITERIUM_SUCCESS";
export const FETCH_SUBCRITERIUM_FAILURE = "FETCH_SUBCRITERIUM_FAILURE";

export const CREATE_SUBCRITERIUM = "CREATE_SUBCRITERIUM";
export const CREATE_SUBCRITERIUM_SUCCESS = "CREATE_SUBCRITERIUM_SUCCESS";
export const CREATE_SUBCRITERIUM_FAILURE = "CREATE_SUBCRITERIUM_FAILURE";

export const UPDATE_SUBCRITERIUM = "UPDATE_SUBCRITERIUM";
export const UPDATE_SUBCRITERIUM_SUCCESS = "UPDATE_SUBCRITERIUM_SUCCESS";
export const UPDATE_SUBCRITERIUM_FAILURE = "UPDATE_SUBCRITERIUM_FAILURE";

export const DELETE_SUBCRITERIUM = "DELETE_SUBCRITERIUM";
export const DELETE_SUBCRITERIUM_SUCCESS = "DELETE_SUBCRITERIUM_SUCCESS";
export const DELETE_SUBCRITERIUM_FAILURE = "DELETE_SUBCRITERIUM_FAILURE";

export const updateSubcriteriumLocally = (id, key, value) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_SUBCRITERIUM_LOCALLY,
    id: id,
    key: key,
    value: value
  });

export const clearSubcriteria = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_SUBCRITERIA
  });

export const fetchSubcriteria = criteriumId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_SUBCRITERIA,
        FETCH_SUBCRITERIA_SUCCESS,
        FETCH_SUBCRITERIA_FAILURE
      ],
      endpoint: `/criteria/${criteriumId}/subcriteria`,
      schema: Schemas.SUBCRITERIA,
      method: "get"
    }
  });

export const fetchSubcriterium = (id, criteriumId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_SUBCRITERIUM,
        FETCH_SUBCRITERIUM_SUCCESS,
        FETCH_SUBCRITERIUM_FAILURE
      ],
      endpoint: `/criteria/${criteriumId}/subcriteria/${id}`,
      schema: Schemas.SUBCRITERIUM,
      method: "get"
    }
  });

export const createSubcriterium = (criteriumId, attributes) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        CREATE_SUBCRITERIUM,
        CREATE_SUBCRITERIUM_SUCCESS,
        CREATE_SUBCRITERIUM_FAILURE
      ],
      endpoint: `/criteria/${criteriumId}/subcriteria`,
      schema: Schemas.SUBCRITERIUM,
      method: "post",
      data: { subcriterium: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: ADD_CRITERIUM_SUBCRITERIUM_LOCALLY,
        id: criteriumId
      },
      {
        type: SET_NOTIFICATION,
        payload: "Created a Subcriterium successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Subcriterium"
      }
    ]
  });

export const updateSubcriterium = (id, criteriumId, attributes) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        UPDATE_SUBCRITERIUM,
        UPDATE_SUBCRITERIUM_SUCCESS,
        UPDATE_SUBCRITERIUM_FAILURE
      ],
      endpoint: `/criteria/${criteriumId}/subcriteria/${id}`,
      schema: Schemas.SUBCRITERIUM,
      method: "patch",
      data: { subcriterium: attributes }
    }
  });

export const deleteSubcriterium = (id, criteriumId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_SUBCRITERIUM,
        DELETE_SUBCRITERIUM_SUCCESS,
        DELETE_SUBCRITERIUM_FAILURE
      ],
      endpoint: `/criteria/${criteriumId}/subcriteria/${id}`,
      schema: Schemas.SUBCRITERIUM,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted the Subcriterium successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete the Subcriterium"
      }
    ]
  });
