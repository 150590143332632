import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "block",
    position: "fixed",
    top: "45%",
    left: "45%"
  }
});

const LoadingSpinner = ({ ...props }) => (
  <CircularProgress
    className={props.classes.root}
    size={100}
    thickness={7}
    color={"primary"}
  />
);

export default withStyles(styles)(LoadingSpinner);
