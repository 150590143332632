import React, { Component } from "react";
import PropTypes from "prop-types";

import { WebcamCaptureDisplay } from "./views";

class WebcamCapture extends Component {
  constructor(props) {
    super(props);
    this.capture = this.capture.bind(this);
    this.setRef = this.setRef.bind(this);
    this.state = {
      shouldShake: false
    };
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.props.uploadImage(this.props.record.id, imageSrc);
    this.setState({ shouldShake: true }, () => {
      const self = this;
      setTimeout(() => self.setState({ shouldShake: false }), 1000);
    });
  };

  render() {
    return (
      <WebcamCaptureDisplay
        capture={this.capture}
        setRef={this.setRef}
        hideCamera={this.props.hideCamera}
        shouldShake={this.state.shouldShake}
        uploadingImage={this.props.uploadingImage}
      />
    );
  }
}

WebcamCapture.propTypes = {
  record: PropTypes.object.isRequired,
  hideCamera: PropTypes.func.isRequired,
  uploadingImage: PropTypes.bool,
  uploadImage: PropTypes.func.isRequired
};

export default WebcamCapture;
