import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setCommodityLocally } from "../../../actions/commodities";
import {
  saveEvaluations,
  fetchEvaluations
} from "../../../actions/evaluations";
import {
  fetchMultiEvaluations,
  saveMultiEvaluations
} from "../../../actions/multi_evaluations";
import { clearCommodity } from "../../../actions/commodities";
import { clearSelectedPlotBeds } from "../../../actions/plot_beds";
import {
  updateFieldVariety,
  updateFieldVarietyLocally
} from "../../../actions/field_varieties";
import {
  updateTrialVariety,
  updateTrialVarietyLocally
} from "../../../actions/trial_varieties";
import { isTypeFieldVariety, getRecord } from "../../../helpers";
import {
  EvaluationCommoditiesLoading,
  VarietyNoAssociatedCommodities,
  EvaluationScoring,
  View
} from "./views";

class Evaluation extends Component {
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCommoditySelected = this.onCommoditySelected.bind(this);
  }

  _updateRecordLocally = (name, value) => {
    if (isTypeFieldVariety(this.props.type)) {
      this.props.updateFieldVarietyLocally(this.props.recordId, name, value);
    } else {
      this.props.updateTrialVarietyLocally(this.props.recordId, name, value);
    }
  };

  onCommoditySelected = e => {
    const { commodities } = this.props.commodities;
    const commodity = commodities[e.target.value];
    // set trial variety to commodity
    this._updateRecordLocally("commodityId", commodity.id);
    // fetch evaluations
    this.props.fetchEvaluations(this.props.type, this.props.recordId); // add to only grab for commodity too
    this.props.fetchMultiEvaluations(this.props.type, this.props.recordId);
    // set the commodity locally
    this.props.setCommodityLocally(e.target.value);
  };

  onSave = () => {
    this.props
      .saveEvaluations(this.props.type, this.props.recordId)
      .then(() => {
        const record = getRecord(
          this.props,
          this.props.type,
          this.props.recordId
        );
        if (isTypeFieldVariety(this.props.type)) {
          this.props.updateFieldVariety(
            record.trialId,
            this.props.recordId,
            record
          );
        } else {
          this.props.updateTrialVariety(
            record.trialId,
            this.props.recordId,
            record
          );
        }
      });
    this.props.saveMultiEvaluations(this.props.type, this.props.recordId);
  };

  onCancel = () => {
    const { preSetVariety } = this.props.plotBeds;
    if (preSetVariety) {
      this.props.clearSelectedPlotBeds();
    }
    this.props.clearCommodity();
    this.props.openEvaluation(false);
  };

  render() {
    const { commodities, waiting } = this.props.commodities;

    if (waiting) {
      return <EvaluationCommoditiesLoading />;
    }

    const record = getRecord(this.props, this.props.type, this.props.recordId);
    const { varieties } = this.props.varieties;
    const variety = varieties[record.variety] || {};
    const associatedCommodities = variety.commodities.map(
      commodityId => commodities[commodityId]
    );

    if (associatedCommodities.length === 0) {
      return <VarietyNoAssociatedCommodities />;
    }

    const { trial } = this.props.trials;

    return (
      <View
        trial={trial}
        variety={variety}
        onSave={this.onSave}
        onCancel={this.onCancel}
      >
        {this.props.children}
        <EvaluationScoring
          archive={trial.archive}
          record={record}
          commodities={associatedCommodities}
          onCommoditySelected={this.onCommoditySelected}
          type={this.props.type}
        />
      </View>
    );
  }
}

Evaluation.propTypes = {
  type: PropTypes.string.isRequired,
  openEvaluation: PropTypes.func.isRequired,
  recordId: PropTypes.number.isRequired,
  fieldVarieties: PropTypes.object.isRequired,
  trialVarieties: PropTypes.object.isRequired
};

const mapStateToProps = ({
  commodities,
  plotBeds,
  trials,
  trialVarieties,
  fieldVarieties,
  varieties
}) => ({
  commodities,
  plotBeds,
  trials,
  trialVarieties,
  fieldVarieties,
  varieties
});

export default connect(mapStateToProps, {
  setCommodityLocally,
  saveEvaluations,
  fetchEvaluations,
  fetchMultiEvaluations,
  saveMultiEvaluations,
  clearCommodity,
  clearSelectedPlotBeds,
  updateFieldVariety,
  updateFieldVarietyLocally,
  updateTrialVariety,
  updateTrialVarietyLocally
})(Evaluation);
