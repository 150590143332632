import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createFieldVariety } from "../../../actions/field_varieties";
import { VarietySelect } from "../../varieties";
import { FieldVarietyChips, EvaluationWrapper } from "./views";
import Grid from "@material-ui/core/Grid";

class FieldVarietyGrouping extends Component {
  filterFieldVarietiesByTrial = () => {
    const { trial } = this.props.trials;
    const { fieldVarieties } = this.props.fieldVarieties;
    const filtered = {};
    Object.values(fieldVarieties).forEach(fieldVariety => {
      if (fieldVariety.trialId === trial.id) {
        filtered[fieldVariety.id] = fieldVariety;
      }
    });
    return Object.values(filtered);
  };

  createFieldVariety = variety => {
    if (variety && variety.objectID) {
      const { trial } = this.props.trials;
      this.props.createFieldVariety(trial.id, variety.objectID);
    }
  };

  render() {
    const fieldVarieties = this.filterFieldVarietiesByTrial();
    const { fieldVariety } = this.props.fieldVarieties;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <VarietySelect
            label="Add Field Variety"
            placeholder="Add Field Variety"
            setVariety={this.createFieldVariety}
          />
        </Grid>
        <FieldVarietyChips
          fieldVarieties={fieldVarieties}
          openEvaluation={this.props.openEvaluation}
        />
        <EvaluationWrapper
          openEvaluation={this.props.openEvaluation}
          showEvaluation={this.props.showEvaluation}
          selectedFieldVariety={fieldVariety}
        />
      </Grid>
    );
  }
}

FieldVarietyGrouping.propTypes = {
  openEvaluation: PropTypes.func.isRequired,
  showEvaluation: PropTypes.bool.isRequired
};

const mapStateToProps = ({ trials, fieldVarieties }) => ({
  trials,
  fieldVarieties
});
export default connect(mapStateToProps, {
  createFieldVariety
})(FieldVarietyGrouping);
