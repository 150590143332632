import React, { Component } from "react";
import DownshiftSingle from "../DownshiftSingle";
import { connect } from "react-redux";
import { fetchSubGrower, fetchSubGrowers } from "../../actions/growers";

class SubGrowerSelect extends Component {
  render() {
    const { classes } = this.props;
    const { subGrowers, waiting } = this.props.growers;
    const results = subGrowers || {};

    return (
      <DownshiftSingle
        waiting={waiting}
        placeholder={"Select Sub Grower"}
        label={"Sub Grower"}
        classes={classes}
        search={this.props.fetchSubGrowers}
        results={results}
        initialItem={this.props.subGrower}
        selectedItemParent={this.props.setSubGrower}
      />
    );
  }
}

const mapStateToProps = ({ growers }) => ({ growers });
export default connect(
  mapStateToProps,
  {
    fetchSubGrower,
    fetchSubGrowers
  }
)(SubGrowerSelect);
