import {
  FETCH_MULTI_EVALUATIONS,
  FETCH_MULTI_EVALUATIONS_SUCCESS,
  FETCH_MULTI_EVALUATIONS_FAILURE,
  SAVE_MULTI_EVALUATIONS,
  SAVE_MULTI_EVALUATIONS_SUCCESS,
  SAVE_MULTI_EVALUATIONS_FAILURE,
  UPDATE_MULTI_EVALUATION_LOCALLY
} from "../actions/multi_evaluations";
import {
  CREATE_TRIAL_SUCCESS,
  FETCH_TRIAL_SUCCESS,
  UPDATE_TRIAL_SUCCESS
} from "../actions/trials";
import {
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS
} from "../actions/trial_varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS
} from "../actions/field_varieties";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";

import { combine } from "./util";

const INITIAL_STATE = {
  error: null,
  waiting: false,
  multiEvaluations: {}
};

export default function(state = INITIAL_STATE, action) {
  let multiEvaluations, multiEvaluation;
  switch (action.type) {
    case CREATE_TRIAL_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case UPDATE_TRIAL_SUCCESS:
    case FETCH_TRIAL_VARIETIES_SUCCESS:
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
      multiEvaluations = combine(
        state.multiEvaluations,
        action.data.multiEvaluations
      );
      return { ...state, multiEvaluations: multiEvaluations };
    case FETCH_MULTI_EVALUATIONS:
      return { ...state, error: null, waiting: true };
    case FETCH_MULTI_EVALUATIONS_SUCCESS:
      multiEvaluations = combine(
        state.multiEvaluations,
        action.data.multiEvaluations
      );
      return {
        ...state,
        multiEvaluations: multiEvaluations,
        error: null,
        waiting: false
      };
    case FETCH_MULTI_EVALUATIONS_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case SAVE_MULTI_EVALUATIONS:
      return { ...state, error: null, waiting: true };
    case SAVE_MULTI_EVALUATIONS_SUCCESS:
      // parentId = action.extra.parentId
      multiEvaluations = combine(
        state.multiEvaluations,
        action.data.multiEvaluations
      );
      return {
        ...state,
        multiEvaluations: multiEvaluations,
        error: null,
        waiting: false
      };
    case SAVE_MULTI_EVALUATIONS_FAILURE:
      if (action.status === 412) {
        multiEvaluations = combine(
          state.multiEvaluations,
          action.data.multiEvaluations
        );
        return {
          ...state,
          error: action.error,
          waiting: false,
          multiEvaluations: multiEvaluations
        };
      }
      return { ...state, error: action.error, waiting: false };
    case UPDATE_MULTI_EVALUATION_LOCALLY:
      multiEvaluations = state.multiEvaluations;
      const { recordId, labelId, tagIds } = action;
      Object.keys(multiEvaluations).forEach(key => {
        if (key.toString().includes(`${recordId}-${labelId}-`)) {
          delete multiEvaluations[key];
        }
      });

      tagIds.forEach(tagIdStr => {
        const tagId = parseInt(tagIdStr, 10);
        const key = getMultiEvaluationKey(recordId, labelId, tagId);
        multiEvaluation = multiEvaluations[key] || {};
        multiEvaluation = {
          multiEvalId: recordId,
          labelId: labelId,
          tagId: tagId
        };
        multiEvaluations[key] = multiEvaluation;
      });

      return { ...state, multiEvaluations: multiEvaluations };
    default:
      return state;
  }
}

const getMultiEvaluationKey = (recordId, labelId, tagId) =>
  `${recordId}-${labelId}-${tagId}`;
