import React from "react";
import PropTypes from "prop-types";
import CommodityLabelTagSelectors from "../CommodityLabelTagSelectors";
import DeleteConfirmation from "../../DeleteConfirmation";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button
    variant="outlined"
    color="default"
    fullWidth
    className={props.classes.addCriteriumButton}
    onClick={confirmDelete}
  >
    Delete
  </Button>
);

const buttonStyle = theme => ({
  addCriteriumButton: {
    marginLeft: 0
  }
});

export const Buttons = withStyles(buttonStyle)(
  ({ addCriterium, onSave, onDelete, ...props }) => (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            className={props.classes.addCriteriumButton}
            onClick={onSave}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DeleteConfirmation onDelete={onDelete}>
            <DeleteButton {...props} />
          </DeleteConfirmation>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            className={props.classes.addCriteriumButton}
            onClick={addCriterium}
          >
            Add Criterium
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
);

export const CommodityHeader = ({
  showLabelSelector,
  toggleLabel,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={9}>
      <Typography variant="h2" gutterBottom>
        Commodity
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={toggleLabel}
      >
        {showLabelSelector ? "Hide Label Search" : "Add Label"}
      </Button>
    </Grid>
  </React.Fragment>
);

export const CommodityEditableItems = ({
  commodityName,
  maxScore,
  handleChange,
  ...props
}) => (
  <React.Fragment>
    <Grid item xs={7} sm={8} md={9}>
      <TextField
        fullWidth
        label="Name"
        value={commodityName || ""}
        onChange={handleChange("name")}
        variant="outlined"
      />
    </Grid>
    <Grid item xs={5} sm={4} md={3}>
      <TextField
        fullWidth
        label="Max Score"
        disabled={true}
        value={maxScore || 0}
        variant="outlined"
        error={maxScore > 10 ? true : false}
      />
    </Grid>
  </React.Fragment>
);

export const CommodityLabels = ({ commodityLabels, ...props }) => (
  <Grid item xs={12}>
    <Grid container spacing={2}>
      {commodityLabels.map((labelId, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <CommodityLabelTagSelectors key={index} labelId={labelId} />
        </Grid>
      ))}
    </Grid>
  </Grid>
);

CommodityLabels.propTypes = {
  commodityLabels: PropTypes.array.isRequired
};
