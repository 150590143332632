import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchTrial } from "../actions/trials";

import { Navbar } from "../containers";
import { LoadingSpinner } from "../ui";
import { PlotReport } from "../containers/reports";

class PlotReportPage extends Component {
  componentDidMount() {
    const trialId = this.props.match.params.id;
    this.props.fetchTrial(trialId);
  }
  render() {
    const { waitingTrial } = this.props.trials;
    return (
      <Navbar currentPage="trials">
        {waitingTrial ? <LoadingSpinner /> : <PlotReport />}
      </Navbar>
    );
  }
}
const mapStateToProps = ({ trials }) => ({ trials });
export default connect(
  mapStateToProps,
  {
    fetchTrial
  }
)(PlotReportPage);
