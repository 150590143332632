import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import promise from "redux-promise";
import api from "../middleware/api";
import thunk from "redux-thunk";
import token from "../middleware/token";
import logRocketIdentify from "../middleware/logRocketIdentify";
import LogRocket from "logrocket";

LogRocket.init("t8m6vc/holaday");
// Middleware you want to use in production:
const enhancer = applyMiddleware(
  promise,
  thunk,
  token,
  api,
  LogRocket.reduxMiddleware(),
  logRocketIdentify
);

export default function configureStore(initialState) {
  // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  return createStore(rootReducer, initialState, enhancer);
}
