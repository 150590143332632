import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchTrialVariety } from "../../../actions/trial_varieties";
import { fetchFieldVariety } from "../../../actions/field_varieties";
import { isTypeFieldVariety, getRecord } from "../../../helpers/utils";
import { View } from "./views";

class TrialModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camera: false,
      images: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = type => () => {
    this.setState({ ...this.state, [type]: !this.state[type] }, () => {
      if (this.state["images"]) {
        const { trial } = this.props.trials;
        if (isTypeFieldVariety(this.props.type)) {
          this.props.fetchFieldVariety(trial.id, this.props.recordId);
        } else {
          this.props.fetchTrialVariety(trial.id, this.props.recordId);
        }
      }
    });
  };

  render() {
    const record = getRecord(this.props, this.props.type, this.props.recordId);
    return (
      <View
        type={this.props.type}
        showCamera={this.state.camera}
        showImages={this.state.images}
        record={record}
        toggle={this.toggle}
      />
    );
  }
}

TrialModal.propTypes = {
  recordId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  trials: state.trials,
  trialVarieties: state.trialVarieties,
  fieldVarieties: state.fieldVarieties
});

export default connect(
  mapStateToProps,
  {
    fetchTrialVariety,
    fetchFieldVariety
  }
)(TrialModal);
