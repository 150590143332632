import {
  FETCH_STATISTICS,
  FETCH_STATISTICS_SUCCESS,
  FETCH_STATISTICS_FAILURE,
  FETCH_EST_HARVEST_DATE,
  FETCH_EST_HARVEST_DATE_FAILURE
} from "../actions/statistics";

const INITIAL_STATE = {
  error: null,
  waiting: false,
  data: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_STATISTICS:
    case FETCH_EST_HARVEST_DATE:
      return { ...state, waiting: true, error: null };
    case FETCH_STATISTICS_SUCCESS:
      return { ...state, data: action.data.records, waiting: false };
    case FETCH_STATISTICS_FAILURE:
    case FETCH_EST_HARVEST_DATE_FAILURE:
      return { ...state, waiting: false, error: action.error };
    default:
      return state;
  }
}
