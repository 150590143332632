import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { sortObjectByKey } from "../../../helpers";
import DeleteConfirmation from "../../DeleteConfirmation";

import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const DeleteButton = ({ confirmDelete }) => (
  <Button size="small" aria-label="Delete" onClick={confirmDelete}>
    <DeleteIcon />
  </Button>
);

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  linkStyle: theme.linkStyle,
  progressGrid: {
    marginTop: theme.spacing(1)
  }
});

export const View = withStyles(styles)(
  ({ profiles, onDelete, showMoreResults, ...props }) => (
    <div className={props.classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={props.classes.tableRoot}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Last Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    First Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    PD
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortObjectByKey(profiles, "lastName").map(profile => (
                <TableRow key={profile.id}>
                  <TableCell component="th" scope="row">
                    <Link
                      to={"/profiles/" + profile.id}
                      className={props.classes.linkStyle}
                    >
                      <Typography variant="body1" gutterBottom>
                        {profile.lastName}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {profile.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {profile.email}
                    </Typography>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox checked={profile.admin} disabled={true} />
                  </TableCell>
                  <TableCell align={"right"}>
                    <DeleteConfirmation onDelete={() => onDelete(profile.id)}>
                      <DeleteButton />
                    </DeleteConfirmation>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          {props.waiting ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={props.classes.progressGrid}
            >
              <CircularProgress size={80} thickness={7} color={"primary"} />
            </Grid>
          ) : (
            <React.Fragment>
              {props.page < props.totalPages && (
                <Button color="primary" fullWidth onClick={showMoreResults}>
                  Show more results
                </Button>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  )
);

View.propTypes = {
  waiting: PropTypes.bool,
  profiles: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  showMoreResults: PropTypes.func.isRequired,
  page: PropTypes.number,
  totalPages: PropTypes.number
};
