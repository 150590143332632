import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dateStrToFriendlyFormat } from "../../helpers/utils";
import { capitalize } from 'lodash';

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class TrialInfoHeader extends Component {
  getVarietyName = varietyId => {
    const { varieties } = this.props.varieties;
    return varieties[varietyId] || {};
  };
  render() {
    const { trial, fieldVarieties } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body1">Evaluator: {trial.userName}</Typography>
          <Typography variant="body1">
            Commodity: {trial.commodity && trial.commodity.name}
          </Typography>
          <Typography variant="body1">
            Shipper: {trial.shipper && trial.shipper.name}
          </Typography>
          <Typography variant="body1">
            Field Varieties:{" "}
            {fieldVarieties
              .map(
                fieldVariety => this.getVarietyName(fieldVariety.variety).name
              )
              .join(", ")}
          </Typography>
          <Typography variant="body1">
            Cultural Practice: {capitalize(trial.culturalPractice)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            Grower: {trial.grower && trial.grower.name}
          </Typography>
          <Typography variant="body1">
            SubGrower: {trial.subGrower && trial.subGrower.name}
          </Typography>
          <Typography variant="body1">
            City: {trial.area && trial.area.name}
          </Typography>
          <Typography variant="body1">
            Ranch: {trial.ranch && trial.ranch.name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">Lot: {trial.lot}</Typography>
          <Typography variant="body1">
            Wet Date: {dateStrToFriendlyFormat(trial.wetDate)}
          </Typography>
          <Typography variant="body1">
            Harvest Date: {dateStrToFriendlyFormat(trial.estHarvestDate)}
          </Typography>
          <Typography variant="body1">Lng: {trial.lat && trial.lat.substring(0, trial.lat.length - 8)}</Typography>
          <Typography variant="body1">Lng: {trial.lng && trial.lng.substring(0, trial.lng.length - 8)}</Typography>
        </Grid>
        <Grid item xs={12}>
          {(trial.comments || "").split("\n").map((i, key) => {
            return (
              <Typography
                key={key}
                variant="body1"
                style={{ wordWrap: "break-word" }}
              >
                {i}
              </Typography>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

TrialInfoHeader.propTypes = {
  trial: PropTypes.object.isRequired,
  fieldVarieties: PropTypes.array
};

const mapStateToProps = ({ varieties }) => ({ varieties });

export default connect(mapStateToProps, {})(TrialInfoHeader);
