import { clearToken } from "../actions/users";
import decode from "jwt-decode";

const isTokenExpired = token => {
  try {
    const decoded = decode(token);
    if (decoded.exp > Date.now() / 1000) {
      // Checking if token is expired. N
      return false;
    }
    return true;
  } catch (err) {
    return true;
  }
};

export default store => next => action => {
  const userStore = store.getState().user;
  if (userStore.authenticated && isTokenExpired(userStore.token)) {
    localStorage.removeItem("jwtToken");
    return next(clearToken());
  }
  return next(action);
};

