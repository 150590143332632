import React, { Component } from "react";
import { connect } from "react-redux";

import { createSubcriterium } from "../../../actions/subcriteria";
import {
  fetchCriterium,
  createCriterium,
  updateCriterium,
  deleteCriterium,
  updateCriteriumLocally
} from "../../../actions/criteria";
import { deleteCommodityCriteriumLocally } from "../../../actions/commodities";
import { LoadingListSpinner } from "../../../ui";
import { View } from "./views";

class CriteriumEdit extends Component {
  computeWeightedScore = (score, weight) => {
    if (score && weight) {
      return score * weight;
    }
    return 0;
  };

  handleChange = name => e => {
    this.props.updateCriteriumLocally(this.props.id, name, e.target.value);
  };

  onDelete = () => {
    this.props.deleteCriterium(this.props.id, this.props.commodityId);
    this.props.deleteCommodityCriteriumLocally(
      this.props.commodityId,
      this.props.id
    );
  };

  addSubCriterium = () => {
    this.props.createSubcriterium(this.props.id, {});
  };

  render() {
    const { criteria, waiting } = this.props.criteria;
    const criterium = criteria[this.props.id] || {};
    if (waiting) {
      return <LoadingListSpinner />;
    }
    return (
      <View
        criterium={criterium}
        handleChange={this.handleChange}
        onDelete={this.onDelete}
        addSubCriterium={this.addSubCriterium}
      />
    );
  }
}

const mapStateToProps = ({ criteria, subcriteria }) => ({
  criteria,
  subcriteria
});

export default connect(
  mapStateToProps,
  {
    fetchCriterium,
    createCriterium,
    updateCriterium,
    deleteCriterium,
    updateCriteriumLocally,
    deleteCommodityCriteriumLocally,
    createSubcriterium
  }
)(CriteriumEdit);
