import React from "react";
import PropTypes from "prop-types";

import { dateStrToFriendlyFormat } from "../../helpers";
import { DeleteConfirmation } from "../index";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import LabelIcon from "@material-ui/icons/Label";
import CheckIcon from "@material-ui/icons/CheckCircle";

const styles = theme => ({
  chipGrid: {
    marginTop: 10,
    marginBottom: 10
  },
  chipText: {
    marginLeft: 5,
    marginRight: 10
  },
  checkIcon: {
    marginLeft: 5
  },
  captionDate: {
    marginLeft: 10
  }
});

const PlotVarietyChip = withStyles(styles)(
  ({
    plotBedNumbers,
    getVariety,
    setSelectedPlotBeds,
    confirmDelete,
    varietyConnector,
    ...props
  }) => (
    <Chip
      icon={
        props.isCompleted && props.isCompleted(varietyConnector) ? (
          <CheckIcon color="primary" />
        ) : (
          <LabelIcon />
        )
      }
      label={
        <React.Fragment>
          <Typography variant="body2" className={props.classes.chipText}>
            {`${plotBedNumbers[varietyConnector.id]}`}
          </Typography>
          <Typography variant="body1">
            {getVariety(varietyConnector.variety).name}
          </Typography>
          <Typography variant="caption" className={props.classes.captionDate}>
            {varietyConnector.latestUpdate &&
              dateStrToFriendlyFormat(varietyConnector.latestUpdate)}
          </Typography>
        </React.Fragment>
      }
      variant="outlined"
      key={plotBedNumbers[varietyConnector.id]}
      onClick={() => setSelectedPlotBeds(varietyConnector.id)}
      onDelete={confirmDelete}
    />
  )
);

export const PlotChipRows = withStyles(styles)(
  ({
    varietyConnectors,
    getVariety,
    setSelectedPlotBeds,
    deletePlotVarietyConnector,
    plotBedNumbers,
    ...props
  }) =>
    varietyConnectors.map(varietyConnector => (
      <Grid
        item
        xs={12}
        className={props.classes.chipGrid}
        key={varietyConnector.id}
      >
        <DeleteConfirmation
          onDelete={() => deletePlotVarietyConnector(varietyConnector)}
        >
          <PlotVarietyChip
            getVariety={getVariety}
            setSelectedPlotBeds={setSelectedPlotBeds}
            plotBedNumbers={plotBedNumbers}
            varietyConnector={varietyConnector}
            {...props}
          />
        </DeleteConfirmation>
      </Grid>
    ))
);

PlotChipRows.propTypes = {
  setSelectedPlotBeds: PropTypes.func.isRequired,
  deletePlotVarietyConnector: PropTypes.func.isRequired,
  varietyConnectors: PropTypes.array.isRequired,
  plotBedNumbers: PropTypes.object.isRequired,
  isCompleted: PropTypes.func
};
