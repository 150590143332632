import {
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  FETCH_PROFILES,
  FETCH_PROFILES_SUCCESS,
  FETCH_PROFILES_FAILURE,
  CREATE_PROFILE,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_LOCALLY,
  CLEAR_PROFILE,
  DELETE_PROFILE,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_FAILURE
} from "../actions/profiles";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  profiles: {},
  profile: {},
  error: null,
  waiting: false,
  page: 1,
  totalPages: 1
};

export default function(state = INITIAL_STATE, action) {
  let profile, profiles;
  switch (action.type) {
    case CLEAR_PROFILE:
      return { ...state, profile: {} };
    case UPDATE_PROFILE_LOCALLY:
      profile = state.profile;
      profile[action.key] = action.value;
      return { ...state, profile: profile };
    case FETCH_PROFILE:
      return { ...state, profile: {}, error: null, waiting: true };
    case FETCH_PROFILE_SUCCESS:
      profile = getSingleEntity(action.data.user);
      return { ...state, profile: profile, error: null, waiting: false };
    case FETCH_PROFILE_FAILURE:
      return { ...state, profile: {}, error: action.error, waiting: false };
    case FETCH_PROFILES:
      return { ...state, profiles: {}, error: null, waiting: true };
    case FETCH_PROFILES_SUCCESS:
      profiles = combine(state.profiles, action.data.users);
      return {
        ...state,
        profiles: profiles,
        waiting: false,
        page: action.meta.currentPage,
        totalPages: action.meta.totalPages
      };
    case FETCH_PROFILES_FAILURE:
      return { ...state, profiles: {}, error: action.error, waiting: false };
    case CREATE_PROFILE:
      return { ...state, error: null, waiting: true };
    case CREATE_PROFILE_SUCCESS:
      profile = getSingleEntity(action.data.user);
      profiles = state.profiles;
      profiles[profile.id] = profile;
      return {
        ...state,
        profiles: profiles,
        profile: profile,
        error: null,
        waiting: false
      };
    case CREATE_PROFILE_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case UPDATE_PROFILE:
      return { ...state, error: null };
    case UPDATE_PROFILE_SUCCESS:
      profile = getSingleEntity(action.data.user);
      return { ...state, profile: profile, error: null };
    case UPDATE_PROFILE_FAILURE:
      return { ...state, error: action.error };
    case DELETE_PROFILE:
      return { ...state, error: null, waiting: true };
    case DELETE_PROFILE_SUCCESS:
      profiles = state.profiles;
      delete profiles[action.data.id];
      return {
        ...state,
        profiles: profiles,
        error: action.error,
        waiting: false
      };
    case DELETE_PROFILE_FAILURE:
      return { ...state, error: action.error, waiting: false };

    default:
      return state;
  }
}
