import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const UPDATE_NURSERY_LOCALLY = "UPDATE_NURSERY_LOCALLY";

export const FETCH_NURSERIES = "FETCH_NURSERIES";
export const FETCH_NURSERIES_SUCCESS = "FETCH_NURSERIES_SUCCESS";
export const FETCH_NURSERIES_FAILURE = "FETCH_NURSERIES_FAILURE";

export const FETCH_MORE_NURSERIES = "FETCH_MORE_NURSERIES";
export const FETCH_MORE_NURSERIES_SUCCESS = "FETCH_MORE_NURSERIES_SUCCESS";
export const FETCH_MORE_NURSERIES_FAILURE = "FETCH_MORE_NURSERIES_FAILURE";

export const FETCH_NURSERY = "FETCH_NURSERY";
export const FETCH_NURSERY_SUCCESS = "FETCH_NURSERY_SUCCESS";
export const FETCH_NURSERY_FAILURE = "FETCH_NURSERY_FAILURE";

export const CREATE_NURSERY = "CREATE_NURSERY";
export const CREATE_NURSERY_SUCCESS = "CREATE_NURSERY_SUCCESS";
export const CREATE_NURSERY_FAILURE = "CREATE_NURSERY_FAILURE";

export const UPDATE_NURSERY = "UPDATE_NURSERY";
export const UPDATE_NURSERY_SUCCESS = "UPDATE_NURSERY_SUCCESS";
export const UPDATE_NURSERY_FAILURE = "UPDATE_NURSERY_FAILURE";

export const DELETE_NURSERY = "DELETE_NURSERY";
export const DELETE_NURSERY_SUCCESS = "DELETE_NURSERY_SUCCESS";
export const DELETE_NURSERY_FAILURE = "DELETE_NURSERY_FAILURE";

export const UPDATE_NURSERY_SEARCH = "UPDATE_NURSERY_SEARCH";
export const UPDATE_NURSERY_SHOW_INACTIVE = "UPDATE_NURSERY_SHOW_INACTIVE";

export const updateShowInactive = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_NURSERY_SHOW_INACTIVE,
    value: value === true ? true : false
  });

export const updateSearch = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_NURSERY_SEARCH,
    value: value
  });

export const updateNurseryLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_NURSERY_LOCALLY,
    key: key,
    value: value
  });

export const fetchNurseries = (search = "", showInactive = false) => (
  dispatch,
  getState
) => {
  const inactiveStr = showInactive ? "true" : "false";
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_NURSERIES,
        FETCH_NURSERIES_SUCCESS,
        FETCH_NURSERIES_FAILURE
      ],
      endpoint: `/nurseries?search=${search}&inactive=${inactiveStr}`,
      schema: Schemas.NURSERIES,
      method: "get"
    }
  });
};

export const fetchMoreNurseries = (search = "", showInactive = false) => (
  dispatch,
  getState
) => {
  const { page } = getState().nurseries;
  const inactiveStr = showInactive ? "true" : "false";
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_MORE_NURSERIES,
        FETCH_MORE_NURSERIES_SUCCESS,
        FETCH_MORE_NURSERIES_FAILURE
      ],
      endpoint: `/nurseries?search=${search}&page=${page +
        1}&inactive=${inactiveStr}`,
      schema: Schemas.NURSERIES,
      method: "get"
    }
  });
};

export const fetchNursery = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_NURSERY, FETCH_NURSERY_SUCCESS, FETCH_NURSERY_FAILURE],
      endpoint: `/nurseries/${id}`,
      schema: Schemas.NURSERY,
      method: "get"
    }
  });

export const createNursery = name => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_NURSERY, CREATE_NURSERY_SUCCESS, CREATE_NURSERY_FAILURE],
      endpoint: "/nurseries",
      schema: Schemas.NURSERY,
      method: "post",
      data: { nursery: { name: name } }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a nursery successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create nursery"
      }
    ]
  });

export const updateNursery = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_NURSERY, UPDATE_NURSERY_SUCCESS, UPDATE_NURSERY_FAILURE],
      endpoint: `/nurseries/${id}`,
      schema: Schemas.NURSERY,
      method: "patch",
      data: { nursery: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated nursery successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update nursery"
      }
    ]
  });

export const deleteNursery = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_NURSERY, DELETE_NURSERY_SUCCESS, DELETE_NURSERY_FAILURE],
      endpoint: `/nurseries/${id}`,
      schema: Schemas.NURSERY,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted nursery successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete nursery"
      }
    ]
  });
