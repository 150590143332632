import React, { Component } from "react";
import { connect } from "react-redux";

import { updateTrialLocally } from "../../actions/trials";
import { updateLiveLocation } from "../../actions/users";
import { setErrorNotification } from "../../actions/notifications";
import { MapButtons } from "./views";
import { LoadingSpinner, FarmMapView } from "../../ui";

import Grid from "@material-ui/core/Grid";

const liveLocationOptions = {
  enableHighAccuracy: true,
  maximumAge: 0
};

export class FarmMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locked: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.refreshGeoLocation = this.refreshGeoLocation.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onZoomChanged = this.onZoomChanged.bind(this);
    this.onCenterChanged = this.onCenterChanged.bind(this);
    this.googleMap = React.createRef();
  }

  componentDidMount() {
    const { trial } = this.props.trials;
    if (!trial.lat && !trial.lng) {
      this.refreshGeoLocation();
    }
    this.liveLocation();
  }

  componentWillUnmount() {}

  handleClick = e => {
    if (!this.state.locked) {
      const { trial } = this.props.trials;
      const lat = e.latLng.lat();
      this.props.updateTrialLocally(trial.id, "lat", lat);
      const lng = e.latLng.lng();
      this.props.updateTrialLocally(trial.id, "lng", lng);
    }
  };

  onZoomChanged = () => {
    if (!this.state.locked) {
      const { trial } = this.props.trials;
      const zoomLevel = this.googleMap.current.getZoom();
      this.props.updateTrialLocally(trial.id, "zoom", zoomLevel);
    }
  };

  onCenterChanged = () => {
    if (!this.state.locked) {
      const { trial } = this.props.trials;
      const centerPoint = this.googleMap.current.getCenter();
      this.props.updateTrialLocally(trial.id, "centerLat", centerPoint.lat());
      this.props.updateTrialLocally(trial.id, "centerLng", centerPoint.lng());
    }
  };

  refreshGeoLocation = () => {
    if (!this.state.locked) {
      const { trial } = this.props.trials;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.props.updateTrialLocally(
              trial.id,
              "lat",
              position.coords.latitude
            );
            this.props.updateTrialLocally(
              trial.id,
              "lng",
              position.coords.longitude
            );
            this.props.updateTrialLocally(
              trial.id,
              "centerLat",
              position.coords.latitude
            );
            this.props.updateTrialLocally(
              trial.id,
              "centerLng",
              position.coords.longitude
            );
          },
          error =>
            this.props.setErrorNotification("Not able to access GPS on device"),
          liveLocationOptions
        );
      } else {
        this.props.setErrorNotification("Not able to access GPS on device");
      }
    }
  };

  liveLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.props.updateLiveLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            accuracy: position.coords.accuracy
          });
        },
        error =>
          this.props.setErrorNotification("Not able to access GPS on device"),
        liveLocationOptions
      );
    } else {
      this.props.setErrorNotification("Not able to access GPS on device");
    }
  };

  toggleLock = () => {
    this.setState({ ...this.state, locked: !this.state.locked });
  };

  handleChange = name => e => {
    if (!this.state.locked) {
      const { trial } = this.props.trials;
      this.props.updateTrialLocally(trial.id, name, e.target.value);
    }
  };

  render() {
    const { trial, waiting } = this.props.trials;
    if (waiting) {
      return <LoadingSpinner />;
    }
    const lat = trial.lat || "";
    const lng = trial.lng || "";
    const centerLat = trial.centerLat || "";
    const centerLng = trial.centerLng || "";
    const {
      lat: liveLocationLat,
      lng: liveLocationLng,
      accuracy
    } = this.props.liveLocation;
    return (
      <Grid container spacing={2}>
        <MapButtons
          refreshGeoLocation={this.refreshGeoLocation}
          toggleLock={this.toggleLock}
          locked={this.state.locked}
          handleChange={this.handleChange}
          lat={lat}
          lng={lng}
        />
        <Grid item xs={12}>
          <FarmMapView
            liveLocationLat={liveLocationLat}
            liveLocationLng={liveLocationLng}
            liveLocationAccuracy={accuracy}
            lat={parseFloat(lat)}
            lng={parseFloat(lng)}
            centerLat={parseFloat(centerLat)}
            centerLng={parseFloat(centerLng)}
            zoom={trial.zoom}
            handleClick={this.handleClick}
            onCenterChanged={this.onCenterChanged}
            onZoomChanged={this.onZoomChanged}
            googleMapRef={this.googleMap}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    trials: state.trials,
    liveLocation: state.user.liveLocation
  };
};

export default connect(mapStateToProps, {
  updateTrialLocally,
  setErrorNotification,
  updateLiveLocation
})(FarmMap);
