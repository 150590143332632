import React from "react";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const IndexLinkButton = ({ linkUrl, disabled, ...props }) => (
  <Button
    size={"large"}
    className={props.classes.button}
    variant="contained"
    color="secondary"
    component={Link}
    disabled={disabled}
    to={linkUrl}
  >
    <AddIcon size={"large"} />
  </Button>
);

const styles = theme => ({
  button: {
    marginTop: theme.addButton.marginTop
  }
});

export default withStyles(styles)(IndexLinkButton);
