import React from "react";

import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export const MultiEvaluationDisplay = ({
  label,
  tags,
  selectedTags,
  handleChange,
  ...props
}) => (
  <Grid item xs={4} md={3} key={label.id}>
    <InputLabel>{label.name}</InputLabel>
    <Select
      multiple
      variant="outlined"
      fullWidth
      label={label.name}
      value={selectedTags}
      input={
        <OutlinedInput
          name="labelId"
          labelWidth={65}
          id={`${label.id}`}
          onChange={handleChange}
        />
      }
    >
      {tags.map((tag, index) => (
        <MenuItem key={index} value={tag.id}>
          {tag.name}
        </MenuItem>
      ))}
    </Select>
  </Grid>
);
