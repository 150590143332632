import {
  UPDATE_TRIAL_VARIETY_LOCALLY,
  REMOVE_TRIAL_VARIETY_LOCALLY,
  SET_TRIAL_VARIETY_LOCALLY,
  CREATE_TRIAL_VARIETY,
  CREATE_TRIAL_VARIETY_SUCCESS,
  CREATE_TRIAL_VARIETY_FAILURE,
  FETCH_TRIAL_VARIETIES,
  FETCH_TRIAL_VARIETIES_SUCCESS,
  FETCH_TRIAL_VARIETIES_FAILURE,
  FETCH_TRIAL_VARIETIES_PAGINATION,
  FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS,
  FETCH_TRIAL_VARIETIES_PAGINATION_FAILURE,
  FETCH_TRIAL_VARIETY,
  FETCH_TRIAL_VARIETY_SUCCESS,
  FETCH_TRIAL_VARIETY_FAILURE,
  UPDATE_TRIAL_VARIETY,
  UPDATE_TRIAL_VARIETY_SUCCESS,
  UPDATE_TRIAL_VARIETY_FAILURE,
  DELETE_TRIAL_VARIETY,
  DELETE_TRIAL_VARIETY_SUCCESS,
  DELETE_TRIAL_VARIETY_FAILURE,
  DELETE_IMAGE_TRIAL_VARIETY,
  DELETE_IMAGE_TRIAL_VARIETY_SUCCESS,
  DELETE_IMAGE_TRIAL_VARIETY_FAILURE
} from "../actions/trial_varieties";
import {
  FETCH_FIELD_VARIETIES_SUCCESS,
  FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS,
  DELETE_FIELD_VARIETY_SUCCESS
} from "../actions/field_varieties";
import { FETCH_VARIETIES_SUCCESS } from "../actions/varieties";
import {
  CREATE_TRIAL_SUCCESS,
  FETCH_TRIAL_SUCCESS,
  UPDATE_TRIAL_SUCCESS
} from "../actions/trials";
import { combine, getSingleEntity } from "./util";

const INITIAL_STATE = {
  trialVarieties: {},
  trialVariety: {},
  error: null,
  waiting: false,
  uploadingImage: false
};

export default function(state = INITIAL_STATE, action) {
  let trialVarieties, trialVariety, newTrialVariety;
  switch (action.type) {
    case CREATE_TRIAL_SUCCESS:
    case FETCH_FIELD_VARIETIES_SUCCESS:
    case FETCH_FIELD_VARIETIES_PAGINATION_SUCCESS:
    case FETCH_VARIETIES_SUCCESS:
    case FETCH_TRIAL_SUCCESS:
    case UPDATE_TRIAL_SUCCESS:
      trialVarieties = combine(
        state.trialVarieties,
        action.data.trialVarieties
      );
      return { ...state, trialVarieties: trialVarieties };

    case REMOVE_TRIAL_VARIETY_LOCALLY:
      trialVarieties = state.trialVarieties;
      delete trialVarieties[action.id];
      return { ...state, trialVarieties: trialVarieties };
    case UPDATE_TRIAL_VARIETY_LOCALLY:
      trialVarieties = state.trialVarieties;
      trialVariety = trialVarieties[action.id];
      trialVariety[action.key] = action.value;
      trialVarieties[action.id] = trialVariety;
      if (state.trialVariety.id === action.id) {
        return {
          ...state,
          trialVarieties: trialVarieties,
          trialVariety: trialVariety
        };
      } else {
        return { ...state, trialVarieties: trialVarieties };
      }
    case SET_TRIAL_VARIETY_LOCALLY:
      return { ...state, trialVariety: state.trialVarieties[action.id] || {} };
    case FETCH_TRIAL_VARIETIES:
      return { ...state, error: null, waiting: true };
    case FETCH_TRIAL_VARIETIES_SUCCESS:
      trialVarieties = combine(
        state.trialVarieties,
        action.data.trialVarieties
      );
      return {
        ...state,
        trialVarieties: trialVarieties,
        error: null,
        waiting: false
      };
    case FETCH_TRIAL_VARIETIES_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_TRIAL_VARIETIES_PAGINATION:
      return { ...state, error: null, waiting: true };
    case FETCH_TRIAL_VARIETIES_PAGINATION_SUCCESS:
      trialVarieties = combine(
        state.trialVarieties,
        action.data.trialVarieties
      );
      let waiting = false;
      if (action.meta.totalPages > action.meta.currentPage) {
        waiting = true;
      }
      return {
        ...state,
        trialVarieties: trialVarieties,
        error: null,
        waiting: waiting
      };
    case FETCH_TRIAL_VARIETIES_PAGINATION_FAILURE:
      return { ...state, error: action.error, waiting: false };
    case FETCH_TRIAL_VARIETY:
      return { ...state, error: null };
    case FETCH_TRIAL_VARIETY_SUCCESS:
      trialVarieties = state.trialVarieties;
      newTrialVariety = getSingleEntity(action.data.trialVarieties);
      trialVarieties[newTrialVariety.id] = newTrialVariety;

      trialVariety = state.trialVariety;
      if (trialVariety.id === newTrialVariety.id) {
        trialVariety = newTrialVariety;
      }
      return {
        ...state,
        trialVarieties: trialVarieties,
        trialVariety: trialVariety,
        error: null
      };
    case FETCH_TRIAL_VARIETY_FAILURE:
      return { ...state, error: action.error };
    case CREATE_TRIAL_VARIETY:
    case UPDATE_TRIAL_VARIETY:
      return { ...state, error: null };
    case CREATE_TRIAL_VARIETY_SUCCESS:
    case UPDATE_TRIAL_VARIETY_SUCCESS:
      trialVariety = getSingleEntity(action.data.trialVarieties);
      trialVarieties = state.trialVarieties;
      trialVarieties[trialVariety.id] = trialVariety;
      return {
        ...state,
        trialVarieties: trialVarieties,
        trialVariety: trialVariety
      };
    case CREATE_TRIAL_VARIETY_FAILURE:
    case DELETE_IMAGE_TRIAL_VARIETY:
    case DELETE_TRIAL_VARIETY:
    case UPDATE_TRIAL_VARIETY_FAILURE:
      if (action.status === 412) {
        trialVariety = getSingleEntity(action.data.trialVarieties);
        trialVarieties = state.trialVarieties;
        trialVarieties[trialVariety.id] = trialVariety;
        return {
          ...state,
          error: action.error,
          trialVarieties: trialVarieties,
          trialVariety: trialVariety
        };
      }
      return { ...state, error: action.error };
    case DELETE_FIELD_VARIETY_SUCCESS:
      let dataId = parseInt(action.data.id);
      trialVarieties = state.trialVarieties;
      trialVariety = state.trialVariety;
      if (trialVariety.fieldVarietyId === dataId) {
        trialVariety.fieldVarietyId = null;
        trialVariety.fieldVariety = null;
      }
      Object.values(trialVarieties).forEach(tv => {
        if (tv.fieldVarietyId === dataId) {
          tv.fieldVarietyId = null;
          tv.fieldVariety = null;
          trialVarieties[tv.id] = tv;
        }
      });
      return {
        ...state,
        trialVarieties: trialVarieties,
        trialVariety: trialVariety
      };
    case DELETE_TRIAL_VARIETY_SUCCESS:
      trialVarieties = state.trialVarieties;
      delete trialVarieties[action.data.id];
      return { ...state, trialVarieties: trialVarieties };
    case DELETE_IMAGE_TRIAL_VARIETY_SUCCESS:
      trialVarieties = state.trialVarieties;
      newTrialVariety = getSingleEntity(action.data.trialVarieties);
      trialVarieties[newTrialVariety.id] = newTrialVariety;

      trialVariety = state.trialVariety;
      if (trialVariety.id === newTrialVariety.id) {
        trialVariety = newTrialVariety;
      }
      return {
        ...state,
        trialVarieties: trialVarieties,
        trialVariety: trialVariety
      };
    case DELETE_IMAGE_TRIAL_VARIETY_FAILURE:
    case DELETE_TRIAL_VARIETY_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
