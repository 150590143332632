import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ProfileDetails, ProfileButtons, AdminToggle } from "./views";
import { pluralize } from "../../../helpers/utils";
import {
  updateProfile,
  createProfile,
  updateProfileLocally,
  sendPasswordReset
} from "../../../actions/profiles";
import { LoadingSpinner } from "../../../ui";
import PersonalViews from "../PersonalViews";
import { Grid } from "@material-ui/core";

class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    let header = "Create Profile";
    if (props.update) {
      header = "Update Profile";
    }
    this.state = {
      header: header,
      password: "",
      passwordConfirmation: "",
      created: false
    };

    this.setManyToManyRelationship = this.setManyToManyRelationship.bind(this);
  }

  handleChange = name => e => {
    const { profile } = this.props.profiles;
    this.props.updateProfileLocally(profile.id, name, e.target.value);
  };

  handleChangeToggle = name => e => {
    const { profile } = this.props.profiles;
    this.props.updateProfileLocally(profile.id, name, e.target.checked);
  };

  sendPasswordReset = _ => {
    const { profile } = this.props.profiles;
    this.props.sendPasswordReset(profile.id);
  };

  stateChange = name => e => {
    this.setState({
      ...this.state,
      [name]: e.target.value
    });
  };

  onSave = () => {
    const { profile } = this.props.profiles;
    if (this.props.update) {
      this.props.updateProfile(this.props.id, profile);
    } else {
      this.props.createProfile(profile);
      this.setState({ ...this.state, created: true });
    }
  };

  onUpdatePassword = _ => {
    const passwords = {
      password: this.state.password,
      passwordConfirmation: this.state.passwordConfirmation
    };
    this.props.updateProfile(this.props.id, passwords);
  };

  setManyToManyRelationship = modelName => models => {
    const { profile } = this.props.profiles;
    const modelIds = models.map(m => m.id);
    this.props.updateProfileLocally(profile.id, `${modelName}Ids`, modelIds);
    const pluralizedName = pluralize(modelName);
    this.props.updateProfileLocally(profile.id, pluralizedName, models);
  };

  render() {
    const { profile, error } = this.props.profiles;
    if (this.state.created && !profile.id && !error) {
      return <LoadingSpinner />;
    }
    if (this.state.created && profile.id) {
      return <Redirect push to="/profiles" />;
    }
    const { update } = this.props;
    return (
      <Grid container spacing={2}>
        <ProfileDetails
          profile={profile}
          header={this.state.header}
          handleChange={this.handleChange}
        />
        {update && (
          <Grid item xs={12}>
            <PersonalViews />
          </Grid>
        )}
        <AdminToggle
          admin={profile.admin || false}
          hideExtraTrialInfo={profile.hideExtraTrialInfo}
          handleChangeToggle={this.handleChangeToggle}
          sendPasswordReset={this.sendPasswordReset}
        />
        <ProfileButtons onSave={this.onSave} />
      </Grid>
    );
  }
}

ProfileEdit.defaultProps = {
  update: false
};

const mapStateToProps = state => {
  return {
    token: state.user.token,
    profiles: state.profiles,
    userId: state.user.userId,
    admin: state.user.admin
  };
};

export default connect(
  mapStateToProps,
  {
    updateProfile,
    createProfile,
    updateProfileLocally,
    sendPasswordReset
  }
)(ProfileEdit);
