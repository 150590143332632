import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateTrial,
  createTrial,
  updateTrialLocally,
  deleteTrial
} from "../../../actions/trials";
import moment from "moment";
import { createAreaUpdateTrial } from "../../../actions/areas";
import { createCommodityUpdateTrial } from "../../../actions/commodities";
import { createRegionUpdateTrial } from "../../../actions/regions";
import { fetchEstHarvestDate } from '../../../actions/statistics';
import ToggleEvaluation from "../../ToggleEvaluation";
import FarmMap from "../../FarmMap";
import { TrialHeader, TrialDetails, TrialButtons } from "./views";
import Grid from "@material-ui/core/Grid";

class TrialEdit extends Component {

  componentDidUpdate(prevProps, prevState, snapshot){
    const { trial } = this.props.trials;
    const { trial: prevTrial } = prevProps.trials;
    if(trial && prevTrial === undefined){
      this.estHarvestDateHint();
    } else if(trial && !trial.tempEstimatedHarvestDate){
      this.estHarvestDateHint();
    }
  }

  estHarvestDateHint = ({ ...props }) => {
    const { trial } = this.props.trials;
    let dateType = 'wet';
    let searchDate = trial.wetDate;
    let commodityId = props.commodityId;
    if (!commodityId){
      commodityId = trial && trial.commodity ? trial.commodity.id : null;
    }
    if(trial.transplantDate){
      dateType = 'transplant'
      searchDate = trial.transplantDate;
    }
    if(commodityId && searchDate && trial.culturalPractice){
      this.props.fetchEstHarvestDate({
        commodityId,
        culturalPractice: trial.culturalPractice,
        dateType,
        searchDate
      })
    }
  };

  handleDateChange = name => momentObj => {
    // API call to fetch the harvest date estimation
    if (momentObj === null) {
      this.props.updateTrialLocally(this.props.id, name, null);
      this.estHarvestDateHint();
    } else {
      const dateObj = moment(momentObj._d);
      const dateStr = dateObj.format("YYYY-MM-DD");
      this.props.updateTrialLocally(this.props.id, name, dateStr);
      this.estHarvestDateHint();
    }
  };

  handleChange = name => e => {
    this.props.updateTrialLocally(this.props.id, name, e.target.value);
    if(name === 'culturalPractice'){
      this.estHarvestDateHint();
    }
  };

  handleArchiveOverrideChecked = e => {
    this.props.updateTrialLocally(
      this.props.id,
      "archiveOverride",
      e.target.checked
    );
    this.onSave();
  }

  handleAutoArchiveChecked = e => {
    this.props.updateTrialLocally(
      this.props.id,
      "autoArchive",
      e.target.checked
    );
    this.onSave();
  }

  onSave = () => {
    const { trial } = this.props.trials;
    this.props.updateTrial(this.props.id, trial);
  };

  onDelete = () => {
    this.props.deleteTrial(this.props.id);
  };

  setRecord = recordKey => record => {
    this.props.updateTrialLocally(this.props.id, recordKey, record);
    const id = record.id || null;
    const recordKeyId = recordKey + "Id";
    this.props.updateTrialLocally(this.props.id, recordKeyId, id);
    if(recordKey === 'commodity'){
      this.estHarvestDateHint({commodityId: id});
    }
  };

  render() {
    const { trial } = this.props.trials;
    return (
      <Grid container spacing={2}>
        <TrialHeader
          trial={trial}
          handleArchiveOverrideChecked={this.handleArchiveOverrideChecked}
          handleAutoArchiveChecked={this.handleAutoArchiveChecked}
        />
        <TrialDetails
          trial={trial}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          setRecord={this.setRecord}
          createArea={this.props.createAreaUpdateTrial}
          createRegion={this.props.createRegionUpdateTrial}
          createCommodity={this.props.createCommodityUpdateTrial}
        />
        <Grid item xs={12}>
          <ToggleEvaluation />
        </Grid>
        <Grid item xs={12}>
          <FarmMap />
        </Grid>
        <TrialButtons onSave={this.onSave} onDelete={this.onDelete} />
      </Grid>
    );
  }
}

const mapStateToProps = ({ trials, trialVarieties }) => ({
  trials,
  trialVarieties
});
export default connect(mapStateToProps, {
  updateTrial,
  createTrial,
  deleteTrial,
  updateTrialLocally,
  createAreaUpdateTrial,
  createCommodityUpdateTrial,
  createRegionUpdateTrial,
  fetchEstHarvestDate
})(TrialEdit);
