import { SET_NOTIFICATION } from "./notifications";
import { CALL_API, AFTER_SUCCESS, AFTER_FAILURE } from "../middleware/api";

export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";

export const CHECK_REPORT_STATUS = "CHECK_REPORT_STATUS";
export const CHECK_REPORT_STATUS_SUCCESS = "CHECK_REPORT_STATUS_SUCCESS";
export const CHECK_REPORT_STATUS_FAILURE = "CHECK_REPORT_STATUS_FAILURE";

export const UPDATE_REPORT_SEARCH = "UPDATE_REPORT_SEARCH";

export const EMAIL_REPORT = "EMAIL_REPORT";
export const EMAIL_REPORT_SUCCESS = "EMAIL_REPORT_SUCCESS";
export const EMAIL_REPORT_FAILURE = "EMAIL_REPORT_FAILURE";

export const UPDATE_CHECK_REPORT_STATUS = "UPDATE_CHECK_REPORT_STATUS";

export const updateReportSearch = (key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_REPORT_SEARCH,
    key: key,
    value: value
  });

export const updateCheckReportStatus = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_CHECK_REPORT_STATUS,
    value: value
  });

export const submitReport = (report_type, trialId, supplierId = "") => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_REPORT, CREATE_REPORT_SUCCESS, CREATE_REPORT_FAILURE],
      endpoint: `/trials/${trialId}/${report_type}`,
      method: "post",
      data: {
        supplierId: supplierId
      }
    }
  });

export const submitEvaluationExcelReport = (trialId, supplierId = "") => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_REPORT, CREATE_REPORT_SUCCESS, CREATE_REPORT_FAILURE],
      endpoint: `/trials/${trialId}/evaluation_report_excel`,
      method: "post",
      data: {
        supplierId: supplierId
      }
    }
  });

export const checkReportStatus = (trialId, reportId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        CHECK_REPORT_STATUS,
        CHECK_REPORT_STATUS_SUCCESS,
        CHECK_REPORT_STATUS_FAILURE
      ],
      endpoint: `/trials/${trialId}/report_status?report_id=${reportId}`,
      method: "get"
    }
  });

export const emailEvaluationReport = (trialId, supplierId, peopleIds) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [EMAIL_REPORT, EMAIL_REPORT_SUCCESS, EMAIL_REPORT_FAILURE],
      endpoint: `/trials/${trialId}/send_evaluation_report`,
      method: "post",
      data: {
        supplierId: supplierId,
        people: peopleIds
      }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Successfully sent trial evaluation report emails"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload:
          "Encountered an error while trying to send trial evaluation report emails"
      }
    ]
  });

export const emailPlotReport = (trialId, supplierId, peopleIds) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [EMAIL_REPORT, EMAIL_REPORT_SUCCESS, EMAIL_REPORT_FAILURE],
      endpoint: `/trials/${trialId}/send_plot_report`,
      method: "post",
      data: {
        supplierId: supplierId,
        people: peopleIds
      }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Successfully sent trial plot report emails"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload:
          "Encountered an error while trying to send trial plot report emails"
      }
    ]
  });

export const emailReportSingle = (
  varietyId,
  fieldVarieties,
  trialVarieties,
  hideList
) => (dispatch, getState) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const url = baseUrl + "/reports/" + varietyId + "/send_single";
  return dispatch({
    [CALL_API]: {
      types: [EMAIL_REPORT, EMAIL_REPORT_SUCCESS, EMAIL_REPORT_FAILURE],
      endpoint: url,
      method: "post",
      data: {
        fieldVarieties: Object.keys(fieldVarieties),
        trialVarieties: Object.keys(trialVarieties),
        hideList: hideList
      }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Successfully sent report"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Encountered an error while trying to send the report"
      }
    ]
  });
};

export const submitStatisticalReport = (
  reportType,
  fieldVarieties,
  trialVarieties,
  hideList,
  varietyIds,
  extraDetailObj
) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_REPORT, CREATE_REPORT_SUCCESS, CREATE_REPORT_FAILURE],
      endpoint: "/reports",
      method: "post",
      data: {
        varietyIds: varietyIds,
        fieldVarietyIds: Object.keys(fieldVarieties),
        trialVarietyIds: Object.keys(trialVarieties),
        hideList: hideList,
        reportType: reportType,
        extra: extraDetailObj
      }
    }
  });

export const submitExcelReport = (
  fieldVarieties,
  trialVarieties,
  varietyIds
) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_REPORT, CREATE_REPORT_SUCCESS, CREATE_REPORT_FAILURE],
      endpoint: "/reports/excel",
      method: "post",
      data: {
        varietyIds: varietyIds,
        fieldVarietyIds: Object.keys(fieldVarieties),
        trialVarietyIds: Object.keys(trialVarieties)
      }
    }
  });

export const checkStatisticalReportStatus = reportId => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        CHECK_REPORT_STATUS,
        CHECK_REPORT_STATUS_SUCCESS,
        CHECK_REPORT_STATUS_FAILURE
      ],
      endpoint: `/reports/${reportId}`,
      method: "get"
    }
  });

export const emailStatisticalReport = (
  report_type,
  fieldVarieties,
  trialVarieties,
  hideList,
  varietyIds,
  peopleIds,
  extraDetailObj
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [EMAIL_REPORT, EMAIL_REPORT_SUCCESS, EMAIL_REPORT_FAILURE],
      endpoint: "/reports/email",
      method: "post",
      data: {
        varietyIds: varietyIds,
        reportType: report_type,
        fieldVarietyIds: Object.keys(fieldVarieties),
        trialVarietyIds: Object.keys(trialVarieties),
        hideList: hideList,
        peopleIds: peopleIds,
        extra: extraDetailObj
      }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Successfully sent report"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Encountered an error while trying to send the report"
      }
    ]
  });
};

export const submitTrialListExcel = (
  searchField,
  filtersHash,
  tagFilters,
  personalized = false
) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_REPORT, CREATE_REPORT_SUCCESS, CREATE_REPORT_FAILURE],
      endpoint: "/trials/excel_list",
      method: "post",
      data: {
        searchField: searchField,
        filtersHash: filtersHash,
        tagFilters: tagFilters,
        personalized: personalized
      }
    }
  });

export const submitTrialList = (
  searchField,
  filtersHash,
  tagFilters,
  orderBy,
  order,
  personalized = false
) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_REPORT, CREATE_REPORT_SUCCESS, CREATE_REPORT_FAILURE],
      endpoint: "/trials/list",
      method: "post",
      data: {
        searchField: searchField,
        filtersHash: filtersHash,
        tagFilters: tagFilters,
        order: order,
        orderBy: orderBy,
        personalized: personalized
      }
    }
  });
