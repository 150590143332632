import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";
import { UPDATE_TRIAL_COMMODITY_ID_LOCALLY } from "./trials";
import { updateCriterium } from "./criteria";
import { updateSubcriterium } from "./subcriteria";

export const UPDATE_COMMODITY_LOCALLY = "UPDATE_COMMODITY_LOCALLY";
export const SET_COMMODITY_LOCALLY = "SET_COMMODITY_LOCALLY";

export const DELETE_COMMODITY_CRITERIUM_LOCALLY =
  "DELETE_COMMODITY_CRITERIUM_LOCALLY";
export const ADD_COMMODITY_SUBCRITERIUM_LOCALLY =
  "ADD_COMMODITY_SUBCRITERIUM_LOCALLY";

export const CLEAR_COMMODITY = "CLEAR_COMMODITY";
export const CLEAR_COMMODITIES = "CLEAR_COMMODITIES";

export const COMMODITY_ADD_LABEL = "COMMODITY_ADD_LABEL";
export const COMMODITY_ADD_LABEL_SUCCESS = "COMMODITY_ADD_LABEL_SUCCESS";
export const COMMODITY_ADD_LABEL_FAILURE = "COMMODITY_ADD_LABEL_FAILURE";
export const COMMODITY_REMOVE_LABEL = "COMMODITY_REMOVE_LABEL";
export const COMMODITY_REMOVE_LABEL_SUCCESS = "COMMODITY_REMOVE_LABEL_SUCCESS";
export const COMMODITY_REMOVE_LABEL_FAILURE = "COMMODITY_REMOVE_LABEL_FAILURE";
export const COMMODITY_ADD_TAG = "COMMODITY_ADD_TAG";
export const COMMODITY_ADD_TAG_SUCCESS = "COMMODITY_ADD_TAG_SUCCESS";
export const COMMODITY_ADD_TAG_FAILURE = "COMMODITY_ADD_TAG_FAILURE";
export const COMMODITY_REMOVE_TAG = "COMMODITY_REMOVE_TAG";
export const COMMODITY_REMOVE_TAG_SUCCESS = "COMMODITY_REMOVE_TAG_SUCCESS";
export const COMMODITY_REMOVE_TAG_FAILURE = "COMMODITY_REMOVE_TAG_FAILURE";
export const COMMODITY_TOGGLE_TAG_LOCALLY = "COMMODITY_TOGGLE_TAG_LOCALLY";

export const CREATE_COMMODITY = "CREATE_COMMODITY";
export const CREATE_COMMODITY_SUCCESS = "CREATE_COMMODITY_SUCCESS";
export const CREATE_COMMODITY_FAILURE = "CREATE_COMMODITY_FAILURE";

export const FETCH_COMMODITIES_DETAILS = "FETCH_COMMODITIES_DETAILS";
export const FETCH_COMMODITIES_DETAILS_SUCCESS =
  "FETCH_COMMODITIES_DETAILS_SUCCESS";
export const FETCH_COMMODITIES_DETAILS_FAILURE =
  "FETCH_COMMODITIES_DETAILS_FAILURE";

export const FETCH_MORE_COMMODITIES = "FETCH_MORE_COMMODITIES";
export const FETCH_MORE_COMMODITIES_SUCCESS = "FETCH_MORE_COMMODITIES_SUCCESS";
export const FETCH_MORE_COMMODITIES_FAILURE = "FETCH_MORE_COMMODITIES_FAILURE";

export const FETCH_COMMODITIES_CLEAR = "FETCH_COMMODITIES_CLEAR";
export const FETCH_COMMODITIES_CLEAR_SUCCESS =
  "FETCH_COMMODITIES_CLEAR_SUCCESS";
export const FETCH_COMMODITIES_CLEAR_FAILURE =
  "FETCH_COMMODITIES_CLEAR_FAILURE";

export const FETCH_COMMODITY = "FETCH_COMMODITY";
export const FETCH_COMMODITY_SUCCESS = "FETCH_COMMODITY_SUCCESS";
export const FETCH_COMMODITY_FAILURE = "FETCH_COMMODITY_FAILURE";

export const UPDATE_COMMODITY = "UPDATE_COMMODITY";
export const UPDATE_COMMODITY_SUCCESS = "UPDATE_COMMODITY_SUCCESS";
export const UPDATE_COMMODITY_FAILURE = "UPDATE_COMMODITY_FAILURE";

export const DELETE_COMMODITY = "DELETE_COMMODITY";
export const DELETE_COMMODITY_SUCCESS = "DELETE_COMMODITY_SUCCESS";
export const DELETE_COMMODITY_FAILURE = "DELETE_COMMODITY_FAILURE";

export const updateCommodityLocally = (id, key, value) => (
  dispatch,
  getState
) =>
  dispatch({
    type: UPDATE_COMMODITY_LOCALLY,
    key: key,
    value: value
  });

export const deleteCommodityCriteriumLocally = (id, value) => (
  dispatch,
  getState
) =>
  dispatch({
    type: DELETE_COMMODITY_CRITERIUM_LOCALLY,
    id: id,
    value: value
  });

export const setCommodityLocally = id => (dispatch, getState) =>
  dispatch({
    type: SET_COMMODITY_LOCALLY,
    id: id
  });

export const clearCommodity = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_COMMODITY
  });

export const clearCommodities = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_COMMODITIES
  });

export const fetchCommoditiesByVariety = (type, varietyId, commodityId) => (
  dispatch,
  getState
) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_COMMODITIES_DETAILS,
        FETCH_COMMODITIES_DETAILS_SUCCESS,
        FETCH_COMMODITIES_DETAILS_FAILURE
      ],
      endpoint: `/varieties/${varietyId}/commodities`,
      schema: Schemas.COMMODITIES_DETAIL,
      method: "get"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_COMMODITY_LOCALLY,
        id: commodityId
      }
    ]
  });

export const fetchCommoditiesClear = (search = "") => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_COMMODITIES_CLEAR,
        FETCH_COMMODITIES_CLEAR_SUCCESS,
        FETCH_COMMODITIES_CLEAR_FAILURE
      ],
      endpoint: `/commodities?search=${encodeURIComponent(search)}`,
      schema: Schemas.COMMODITIES,
      method: "get"
    }
  });

export const fetchMoreCommodities = (search = "") => (dispatch, getState) => {
  const { page } = getState().commodities;
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_MORE_COMMODITIES,
        FETCH_MORE_COMMODITIES_SUCCESS,
        FETCH_MORE_COMMODITIES_FAILURE
      ],
      endpoint: `/commodities?search=${encodeURIComponent(search)}&page=${page +
        1}`,
      schema: Schemas.COMMODITIES,
      method: "get"
    }
  });
};

export const fetchCommodity = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_COMMODITY,
        FETCH_COMMODITY_SUCCESS,
        FETCH_COMMODITY_FAILURE
      ],
      method: "get",
      endpoint: `/commodities/${id}`,
      schema: Schemas.COMMODITY
    }
  });

export const createCommodity = name => (dispatch, getState) =>
  dispatch(createCommodityCall(name));

export const createCommodityUpdateTrial = name => (dispatch, getState) => {
  const updateTrialCall = {
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created an Commodity successfully"
      },
      {
        type: UPDATE_TRIAL_COMMODITY_ID_LOCALLY
      }
    ]
  };
  const apiCall = Object.assign(createCommodityCall(name), updateTrialCall);
  return dispatch(apiCall);
};

const createCommodityCall = name => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        CREATE_COMMODITY,
        CREATE_COMMODITY_SUCCESS,
        CREATE_COMMODITY_FAILURE
      ],
      method: "post",
      endpoint: "/commodities",
      schema: Schemas.COMMODITY,
      data: { commodity: { name: name } }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a Commodity successfully"
      },
      {
        type: UPDATE_TRIAL_COMMODITY_ID_LOCALLY
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Commodity"
      }
    ]
  });

export const updateCommodity = (id, commodity) => (dispatch, getState) => {
  const { criteria } = getState().criteria;
  const { subcriteria } = getState().subcriteria;
  commodity.criteria.forEach(criteriumId => {
    const criterium = criteria[criteriumId];
    if (criterium) {
      dispatch(updateCriterium(criterium.id, id, criterium));
      criterium.subcriteria.forEach(subcriteriumId => {
        const subcriterium = subcriteria[subcriteriumId];
        if (subcriterium) {
          dispatch(
            updateSubcriterium(
              subcriterium.id,
              subcriterium.criteriumId,
              subcriterium
            )
          );
        }
      });
    }
  });
  const reducedCommodity = { name: commodity.name };
  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_COMMODITY,
        UPDATE_COMMODITY_SUCCESS,
        UPDATE_COMMODITY_FAILURE
      ],
      method: "patch",
      endpoint: `/commodities/${id}`,
      schema: Schemas.COMMODITY,
      data: {
        commodity: reducedCommodity,
        labels: []
      }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated the Commodity successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update the Commodity"
      }
    ]
  });
};

export const commodityAddLabel = (id, labelId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        COMMODITY_ADD_LABEL,
        COMMODITY_ADD_LABEL_SUCCESS,
        COMMODITY_ADD_LABEL_FAILURE
      ],
      endpoint: `/commodities/${id}/add_label`,
      schema: Schemas.COMMODITY,
      method: "post",
      data: { label_id: labelId }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Add label to Commodity successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to add label to Commodity"
      }
    ]
  });

export const commodityRemoveLabel = (id, labelId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        COMMODITY_REMOVE_LABEL,
        COMMODITY_REMOVE_LABEL_SUCCESS,
        COMMODITY_REMOVE_LABEL_FAILURE
      ],
      method: "post",
      endpoint: `/commodities/${id}/remove_label`,
      schema: Schemas.COMMODITY,
      data: { label_id: labelId }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Removed label fromm Commodity successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to remove label from Commodity"
      }
    ]
  });

export const commodityAddTag = (id, tagId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        COMMODITY_ADD_TAG,
        COMMODITY_ADD_TAG_SUCCESS,
        COMMODITY_ADD_TAG_FAILURE
      ],
      method: "post",
      endpoint: `/commodities/${id}/add_tag`,
      schema: Schemas.COMMODITY,
      data: { tag_id: tagId }
    }
  });

export const commodityToggleTagLocally = (id, tagId) => (dispatch, getState) =>
  dispatch({
    type: COMMODITY_TOGGLE_TAG_LOCALLY,
    id: id,
    tagId: tagId
  });

export const commodityRemoveTag = (id, tagId) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        COMMODITY_REMOVE_TAG,
        COMMODITY_REMOVE_TAG_SUCCESS,
        COMMODITY_REMOVE_TAG_FAILURE
      ],
      method: "post",
      endpoint: `/commodities/${id}/remove_tag`,
      schema: Schemas.COMMODITY,
      data: { tag_id: tagId }
    }
  });

export const deleteCommodity = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        DELETE_COMMODITY,
        DELETE_COMMODITY_SUCCESS,
        DELETE_COMMODITY_FAILURE
      ],
      method: "delete",
      endpoint: `/commodities/${id}`,
      schema: Schemas.COMMODITY
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted the Commodity successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete the Commodity"
      }
    ]
  });
