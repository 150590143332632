import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  updateCommodity,
  createCommodity,
  deleteCommodity,
  updateCommodityLocally
} from "../../../actions/commodities";
import { LoadingSpinner } from "../../../ui";
import { setErrorNotification } from "../../../actions/notifications";
import { createCriterium } from "../../../actions/criteria";
import { fetchLabels } from "../../../actions/labels";
import {
  Buttons,
  CommodityHeader,
  CommodityEditableItems,
  CommodityLabels
} from "./views";
import { LabelSelector } from "../../labels";
import CommodityDragAndDropArea from "../CommodityDragAndDropArea";
import { Grid } from "@material-ui/core";

class CommodityEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: false,
      showLabelSelector: false
    };
  }
  handleChange = name => e => {
    this.props.updateCommodityLocally(this.props.id, name, e.target.value);
  };

  addCriterium = () => {
    this.props.createCriterium(this.props.id, { name: "" });
  };

  onSave = () => {
    const { commodity } = this.props.commodities;
    const criteria = commodity.criteria || [];
    const maxScore = this.calculateMaxScore(criteria);
    if (maxScore > 10) {
      this.props.setErrorNotification(
        "The max commodity score could be greater then 10, please fix before saving."
      );
    } else {
      this.props.updateCommodity(this.props.id, commodity);
    }
  };

  toggleLabel = () =>
    this.setState(
      {
        ...this.state,
        showLabelSelector: !this.state.showLabelSelector
      },
      () => {
        if (this.state.showLabelSelector) {
          this.props.fetchLabels();
        }
      }
    );

  onDelete = () => {
    this.setState({ ...this.state, deleted: true }, () => {
      this.props.deleteCommodity(this.props.id);
    });
  };

  getSum = (total, num) => total + num;

  calculateMaxScore = commodity => {
    const { criteria } = this.props.criteria;
    const { subcriteria } = this.props.subcriteria;
    let criteriumMaxScore = {};
    Object.values(subcriteria).forEach(subcriterium => {
      const criteriumId = subcriterium.criteriumId;
      const criterium = criteria[criteriumId] || {};
      if (
        criterium.commodityId === commodity.id &&
        (!criteriumMaxScore[criteriumId] ||
          subcriterium.weightedScore > criteriumMaxScore[criteriumId])
      ) {
        criteriumMaxScore[criteriumId] = subcriterium.weightedScore;
      }
    });
    return Object.values(criteriumMaxScore)
      .reduce(this.getSum, 0)
      .toFixed(2);
  };

  render() {
    const { commodity, waiting, error } = this.props.commodities;

    if (waiting) {
      return <LoadingSpinner />;
    }
    if (this.state.deleted && !error) {
      return <Redirect push to="/commodities" />;
    }

    const maxScore = this.calculateMaxScore(commodity);
    const { showLabelSelector } = this.state;
    return (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <CommodityHeader
          showLabelSelector={showLabelSelector}
          toggleLabel={this.toggleLabel}
        />
        <CommodityEditableItems
          commodityName={commodity.name}
          maxScore={maxScore}
          handleChange={this.handleChange}
        />
        {showLabelSelector && (
          <Grid item xs={12}>
            <LabelSelector commodity={commodity} />
          </Grid>
        )}
        <CommodityLabels commodityLabels={commodity.labels || []} />
        <Grid item xs={12}>
          <CommodityDragAndDropArea commodityId={commodity.id} />
        </Grid>
        <Buttons
          onSave={this.onSave}
          onDelete={this.onDelete}
          addCriterium={this.addCriterium}
        />
      </Grid>
    );
  }
}

const mapStateToProps = ({ commodities, criteria, subcriteria }) => ({
  commodities,
  criteria,
  subcriteria
});

export default connect(mapStateToProps, {
  fetchLabels,
  updateCommodity,
  createCommodity,
  deleteCommodity,
  updateCommodityLocally,
  createCriterium,
  setErrorNotification
})(CommodityEdit);
