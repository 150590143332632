import React, { Component } from "react";
import { Navbar } from "../containers";
import { ProfilesList } from "../containers/profiles";

class Profiles extends Component {
  render() {
    return (
      <Navbar currentPage="users">
        <ProfilesList />
      </Navbar>
    );
  }
}

export default Profiles;
