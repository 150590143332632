import React from "react";
import DeleteConfirmation from "../../DeleteConfirmation";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import Button from "@material-ui/core/Button";

const DeleteButton = ({ confirmDelete, ...props }) => (
  <Button
    size="small"
    aria-label="Delete"
    fullWidth
    className={props.classes.deleteButton}
    onClick={confirmDelete}
  >
    <DeleteIcon />
  </Button>
);

const styles = theme => ({
  name: {
    paddingRight: 10,
    paddingTop: 5
  },
  changeIcon: {
    paddingTop: 20
  },
  deleteButton: {
    height: "100%"
  }
});

export const TagRow = withStyles(styles)(
  ({ tag, handleChange, onDelete, ...props }) => (
    <Grid container>
      <Grid item xs={1} className={props.classes.changeIcon}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <ViewHeadlineIcon />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={10} className={props.classes.name}>
        <TextField
          fullWidth
          required={true}
          label="Tag Name"
          variant="outlined"
          onChange={handleChange(tag.id)}
          value={tag.name}
        />
      </Grid>
      <Grid item xs={1}>
        <DeleteConfirmation onDelete={onDelete(tag.label, tag.id)}>
          <DeleteButton {...props} />
        </DeleteConfirmation>
      </Grid>
    </Grid>
  )
);
