import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getVarietyPlotNumber,
  allUniqueSuppliersFromVarieties,
  filterVarietiesBySupplier,
  fetchEmailDrawerPeople,
  filterPlotBedsByTrial
} from "../../../helpers";
import { fetchPeopleAssociatedWith } from "../../../actions/people";
import {
  submitReport,
  emailPlotReport,
  checkReportStatus,
  updateCheckReportStatus
} from "../../../actions/reports";
import { updateTrial } from '../../../actions/trials'
import { View } from "./views";
import { EmailDrawer } from "../../index";
import { SupplierReportSelect, TrialInfoHeader } from "../index";
import { ReportProcessing, FarmMapView } from "../../../ui";

class PlotReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier: "",
      showEmailDrawer: false,
      processingReport: false
    };
    this.openEmailDrawerAction = this.openEmailDrawerAction.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.googleMap = React.createRef();
  }

  supplierChange = e => {
    this.setState({ ...this.state, supplier: e.target.value });
  };

  allVarietiesAssociatedWithTrialByPlotNumber = () => {
    const varietiesByPlotNumber = {};
    const { varieties } = this.props.varieties;
    const { trial } = this.props.trials;
    const { trialVarieties } = this.props.trialVarieties;
    const { plotBeds } = this.props.plotBeds;
    const filteredPlotBeds = filterPlotBedsByTrial(
      trial,
      trialVarieties,
      plotBeds
    );
    const plotNumbers = getVarietyPlotNumber(
      filteredPlotBeds,
      trial.plotType,
      trial.plotRows,
      trial.plotCols
    );
    Object.values(trialVarieties).forEach(tv => {
      const variety = varieties[tv.variety] || {};
      variety.plotNumber = plotNumbers[tv.id];
      if (variety.plotNumber) {
        varietiesByPlotNumber[variety.plotNumber] = variety;
      } else {
        console.warn(
          "Plot Number not found for Trial Variety.",
          tv,
          plotNumbers,
          filteredPlotBeds
        );
      }
    });
    return Object.values(varietiesByPlotNumber).sort(
      (a, b) => a.plotNumber - b.plotNumber
    );
  };

  sendEmail = supplierId => peopleIds => {
    const { trial } = this.props.trials;
    this.props.emailPlotReport(trial.id, supplierId, peopleIds);
    this.toggleEmailDrawer(false);
  };

  sendOnDateUpdateTrial = () => {
    const { trial } = this.props.trials;
    this.props.updateTrial(trial.id, { auto_send_plot_report: true })
    this.toggleEmailDrawer(false);
  }

  downloadPDF = supplierId => () => {
    const { trial } = this.props.trials;
    this.props.submitReport("plot_report", trial.id, supplierId);
    this.setState({ ...this.state, processingReport: true });
    this.props.updateCheckReportStatus(true);
  };

  openEmailDrawerAction = () => {
    const { trial } = this.props.trials;
    fetchEmailDrawerPeople(
      trial,
      this.state.supplier,
      this.props.fetchPeopleAssociatedWith
    );
    this.toggleEmailDrawer(true);
  };

  toggleEmailDrawer = value => {
    this.setState({ ...this.state, showEmailDrawer: value });
  };

  handleClick = e => {};

  onZoomChanged = () => {};

  onCenterChanged = () => {};

  _checkReportStatusCall = trial => reportId =>
    this.props.checkReportStatus(trial.id, reportId);

  render() {
    const { trial } = this.props.trials;
    const varietiesByPlotNumber = this.allVarietiesAssociatedWithTrialByPlotNumber();
    const supplierIds = allUniqueSuppliersFromVarieties(varietiesByPlotNumber);
    const filteredVarieties = filterVarietiesBySupplier(
      this.state.supplier,
      varietiesByPlotNumber
    );
    const { fieldVarieties } = this.props.fieldVarieties;
    const filteredFieldVarieties = Object.values(fieldVarieties).filter(
      fv => fv.trialId === trial.id
    );
    const { checkReportStatus } = this.props.reports;
    return (
      <React.Fragment>
        <View
          trial={trial}
          fieldVarieties={filteredFieldVarieties}
          varieties={filteredVarieties}
          openEmailDrawerAction={this.openEmailDrawerAction}
          downloadPDF={this.downloadPDF(this.state.supplier)}
          processingReport={checkReportStatus}
        >
          {this.state.processingReport && (
            <ReportProcessing
              checkReportStatusCall={this._checkReportStatusCall(trial)}
            />
          )}
          <SupplierReportSelect
            supplierIds={supplierIds}
            selectedSupplier={this.state.supplier}
            supplierChange={this.supplierChange}
          />
          <TrialInfoHeader
            trial={trial}
            fieldVarieties={filteredFieldVarieties}
          />
        </View>
        <FarmMapView
          lat={parseFloat(trial.lat)}
          lng={parseFloat(trial.lng)}
          centerLat={parseFloat(trial.centerLat)}
          centerLng={parseFloat(trial.centerLng)}
          zoom={trial.zoom}
          handleClick={this.handleClick}
          onZoomChanged={this.onZoomChanged}
          onCenterChanged={this.onCenterChanged}
          googleMapRef={this.googleMap}
        />
        <EmailDrawer
          showDrawer={this.state.showEmailDrawer}
          toggleDrawer={this.toggleEmailDrawer}
          sendEmail={this.sendEmail(this.state.supplier)}
          sendOnDateUpdateTrial={this.sendOnDateUpdateTrial}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  fieldVarieties,
  plotBeds,
  reports,
  trials,
  trialVarieties,
  varieties
}) => ({
  fieldVarieties,
  plotBeds,
  reports,
  trials,
  trialVarieties,
  varieties
});

export default connect(mapStateToProps, {
  updateTrial,
  fetchPeopleAssociatedWith,
  submitReport,
  emailPlotReport,
  checkReportStatus,
  updateCheckReportStatus
})(PlotReport);
