import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchField, LabelTable } from "./views";
import { commodityAddLabel } from "../../../actions/commodities";
import { fetchLabels } from "../../../actions/labels";
import { Grid } from "@material-ui/core";

class LabelSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: ""
    };
  }
  addLabel = labelId => () => {
    this.props.commodityAddLabel(this.props.commodity.id, labelId);
  };
  handleChange = e => {
    this.setState({ searchField: e.target.value });
    this.props.fetchLabels(e.target.value);
  };

  render() {
    const { searchField } = this.state;
    const { labels } = this.props.labels;
    return (
      <Grid
        container
        spacing={2}
        style={{ flexGrow: 1, marginTop: 20, marginBottom: 40 }}
      >
        <SearchField
          searchField={searchField}
          handleChange={this.handleChange}
        />
        <LabelTable
          searchField={searchField}
          addLabel={this.addLabel}
          labels={labels}
        />
      </Grid>
    );
  }
}
const mapStateToProps = ({ labels }) => ({ labels });
export default connect(mapStateToProps, { commodityAddLabel, fetchLabels })(
  LabelSelector
);
