import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchLabel } from "../actions/labels";
import { Navbar } from "../containers";
import { LabelEdit } from "../containers/labels";

class Label extends Component {
  componentDidMount() {
    this.props.fetchLabel(this.props.match.params.id);
  }
  render() {
    return (
      <Navbar currentPage="diseases">
        <LabelEdit id={this.props.match.params.id} />
      </Navbar>
    );
  }
}
export default connect(
  null,
  { fetchLabel }
)(Label);
