import { SET_NOTIFICATION } from "./notifications";
import {
  CALL_API,
  Schemas,
  AFTER_SUCCESS,
  AFTER_FAILURE
} from "../middleware/api";

export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const CLEAR_COMPANIES = "CLEAR_COMPANIES";
export const UPDATE_COMPANY_LOCALLY = "UPDATE_COMPANY_LOCALLY";
export const UPDATE_COMPANY_SEARCH = "UPDATE_COMPANY_SEARCH";

export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";

export const FETCH_COMPANIES_BY_ID = "FETCH_COMPANIES_BY_ID";
export const FETCH_COMPANIES_BY_ID_SUCCESS = "FETCH_COMPANIES_BY_ID_SUCCESS";
export const FETCH_COMPANIES_BY_ID_FAILURE = "FETCH_COMPANIES_BY_ID_FAILURE";

export const FETCH_COMPANY = "FETCH_COMPANY";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const updateSearch = value => (dispatch, getState) =>
  dispatch({
    type: UPDATE_COMPANY_SEARCH,
    value: value
  });

export const clearCompanies = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_COMPANIES
  });

export const clearCompany = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_COMPANY
  });

export const updateCompanyLocally = (id, key, value) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_COMPANY_LOCALLY,
    key: key,
    value: value
  });

export const fetchCompanies = (search = "") => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        FETCH_COMPANIES,
        FETCH_COMPANIES_SUCCESS,
        FETCH_COMPANIES_FAILURE
      ],
      endpoint: `/companies?search=${encodeURIComponent(search)}`,
      schema: Schemas.COMPANIES,
      method: "get"
    }
  });

export const fetchCompany = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [FETCH_COMPANY, FETCH_COMPANY_SUCCESS, FETCH_COMPANY_FAILURE],
      endpoint: `/companies/${id}`,
      schema: Schemas.COMPANY,
      method: "get"
    }
  });

export const fetchCompaniesById = (ids = []) => (dispatch, getState) => {
  if (ids.length === 0) {
    return;
  }
  ids = ids.join(",");
  return dispatch({
    [CALL_API]: {
      types: [
        FETCH_COMPANIES_BY_ID,
        FETCH_COMPANIES_BY_ID_SUCCESS,
        FETCH_COMPANIES_BY_ID_FAILURE
      ],
      endpoint: `/companies/by_ids?ids=${ids}`,
      schema: Schemas.COMPANIES,
      method: "get"
    }
  });
};

export const createCompany = attributes => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [CREATE_COMPANY, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAILURE],
      endpoint: `/companies`,
      schema: Schemas.COMPANY,
      method: "post",
      data: { company: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Created a Company successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to create Company"
      }
    ]
  });
export const updateCompany = (id, attributes) => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [UPDATE_COMPANY, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE],
      endpoint: `/companies/${id}`,
      schema: Schemas.COMPANY,
      method: "patch",
      data: { company: attributes }
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Updated Company successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to update Company"
      }
    ]
  });

export const deleteCompany = id => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [DELETE_COMPANY, DELETE_COMPANY_SUCCESS, DELETE_COMPANY_FAILURE],
      endpoint: `/companies/${id}`,
      schema: Schemas.COMPANY,
      method: "delete"
    },
    [AFTER_SUCCESS]: [
      {
        type: SET_NOTIFICATION,
        payload: "Deleted a Company successfully"
      }
    ],
    [AFTER_FAILURE]: [
      {
        type: SET_NOTIFICATION,
        payload: "Failed to delete Company"
      }
    ]
  });
