import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { uniq } from "lodash";

import { EmailCompanyDrawer, EmailPeopleDrawer } from "./views";
import { LoadingDrawerDisplay } from "../../../ui";

class ReportEmailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedCompanies: [],
      showPeople: false,
      checkedPeople: []
    };
  }
  togglePersonDisplay = value => () => {
    if (value === false) {
      this.setState({ ...this.state, showPeople: value, checkedPeople: [] });
    } else {
      let checkedPeople = [];
      const { companiesById } = this.props.companies;
      this.state.checkedCompanies.forEach(companyId => {
        const company = companiesById[companyId];
        company.people.forEach(person => {
          checkedPeople.push(person.id);
        });
      });
      checkedPeople = uniq(checkedPeople);
      this.setState({
        ...this.state,
        showPeople: value,
        checkedPeople: checkedPeople
      });
    }
  };
  sendEmail = () => {
    const peopleIds = this.state.checkedPeople.map(person => person.id);
    this.props.sendEmail(peopleIds);
    this.setState({ ...this.state, showPeople: false, checkedPeople: [] });
  };
  handleToggle = (currentChecked, value) => {
    const currentIndex = currentChecked.indexOf(value);
    const newChecked = [...currentChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    return newChecked;
  };
  handleCompanyToggle = value => () => {
    const { checkedCompanies } = this.state;
    const newChecked = this.handleToggle(checkedCompanies, value);
    this.setState({
      ...this.state,
      checkedCompanies: newChecked
    });
  };
  handlePersonToggle = value => () => {
    const { checkedPeople } = this.state;
    const newChecked = this.handleToggle(checkedPeople, value);
    this.setState({
      ...this.state,
      checkedPeople: newChecked
    });
  };

  render() {
    const { companiesById, waiting } = this.props.companies;
    const { checkedCompanies, checkedPeople, showPeople } = this.state;
    if (waiting) {
      return (
        <LoadingDrawerDisplay
          showDrawer={this.props.showDrawer}
          closeDrawer={this.props.closeDrawer}
        />
      );
    }
    if (showPeople) {
      let listOfPeople = {};
      checkedCompanies.forEach(companyId => {
        companiesById[companyId].people.forEach(person => {
          listOfPeople[person.id] = person;
        });
      });
      return (
        <EmailPeopleDrawer
          showDrawer={this.props.showDrawer}
          closeDrawer={this.props.closeDrawer}
          togglePersonDisplay={this.togglePersonDisplay}
          checkedPeople={checkedPeople}
          handlePersonToggle={this.handlePersonToggle}
          listOfPeople={Object.values(listOfPeople)}
          sendEmail={this.sendEmail}
        />
      );
    }
    return (
      <EmailCompanyDrawer
        showDrawer={this.props.showDrawer}
        closeDrawer={this.props.closeDrawer}
        checkedCompanies={checkedCompanies}
        handleCompanyToggle={this.handleCompanyToggle}
        listOfCompanies={Object.values(companiesById)}
        togglePersonDisplay={this.togglePersonDisplay}
      />
    );
  }
}

ReportEmailDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool.isRequired
};

const mapStateToProps = ({ companies }) => ({
  companies
});

export default connect(
  mapStateToProps,
  null
)(ReportEmailDrawer);
