import React from "react";
import PropTypes from "prop-types";

import { VarietySelect } from "../../varieties";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

export const View = ({ setVariety, createVarietyConnection, ...props }) => (
  <Grid container>
    <Grid item xs={6}>
      <VarietySelect classes={props.classes} setVariety={setVariety} />
    </Grid>
    <Grid item xs={6}>
      <IconButton
        aria-label="Set Variety"
        color="primary"
        variant="contained"
        onClick={createVarietyConnection}
      >
        <AddIcon fontSize="large" />
      </IconButton>
    </Grid>
  </Grid>
);

View.propTypes = {
  setVariety: PropTypes.func.isRequired,
  createVarietyConnection: PropTypes.func.isRequired
};
